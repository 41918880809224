import { Grid, Box, Skeleton } from '@mui/material';
import NoData from '@root/components/common/NoData';

import ContentBox from '@root/components/v2/content/ContentBox';

import './components.scss';
import moment from 'moment';
import { useHistory } from 'react-router';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import { useCallback } from 'react';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';
const ColorMap = {
  CRITICAL: {
    text: '#CD0404',
    iconBackground: '#FED7D7',
    fullBackground: '#FFF8F8',
  },
  HIGH: {
    text: '#F62121',
    iconBackground: '#FDD8D8',
    fullBackground: '#FFF8F8',
  },
  MEDIUM: {
    text: '#FFA825',
    iconBackground: '#FFEFD6',
    fullBackground: '#FFFAF1',
  },
  WARNING: {
    text: '#ED5656',
    iconBackground: '#FDD8D8',
    fullBackground: '#FFF8F8',
  },
  INFO: {
    text: '#476FFC',
    iconBackground: '#FFEFD6',
    fullBackground: '#FFFAF1',
  },
  LOW: {
    text: '#FFA825',
    iconBackground: '#FFEFD6',
    fullBackground: '#FFFAF1',
  },
};

const severityPriority = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
  WARNING: 4,
  INFO: 5,
};

const TopRiskRecommendation = ({ data, isFetchingTop3RiskRecommendations, cloudProvider }) => {
  const history = useHistory();
  if (!isFetchingTop3RiskRecommendations)
    data.sort((a, b) => severityPriority[a.severity] - severityPriority[b.severity]);

  const handleButtonClick = useCallback(
    rule_id => {
      const url = buildBreadcrumbUrl({
        path: `/risk/risk-table?rule_id=${rule_id}`,
        breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Risk' }],
      });

      history.push(url);
    },
    [history],
  );
  const findTimeDifference = timestamp => {
    const currentDate = moment.utc().format('YYYY-MM-DD HH:mm:ss');

    const alertDateGMT = timestamp;

    const stringWithDoubleSpaces = alertDateGMT.replace(/,/g, '');
    const alertDate = stringWithDoubleSpaces.replace(/\s+/g, ' ');

    const differenceMs = new Date(currentDate) - new Date(alertDate);

    const seconds = Math.floor(differenceMs / 1000);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  };

  return (
    <Grid xs={4} height="440px" item >
      <ContentBox
        infoMsg="Top 5 Risk recommendations based on the severity & impact"
        title="Top 5 Risk Recommendation"
        // width={'100%'}
        titleSize="medium"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        paddingMode="content"
        containerClassName={'border-box-no-pad'}
        height="100%"
        containerStyle={{ display: 'flex', flexDirection: 'column' }}>
        {isFetchingTop3RiskRecommendations ? (
          <Box display="flex" flexDirection="column" alignItems="center" height="440px" padding="15px" overflow="hidden">
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',
              }}>
              {Array.from(new Array(5)).map(i => (
                <Grid
                  key={i}
                  sx={{
                    border: '1px solid #cbd5e0',
                    padding: '8px',
                    borderRadius: '5px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Box width="100%" display="flex">
                    <Skeleton variant="rectangular" width="100%" height="100px" />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : cloudProvider === 'azure' ? (
          <NoData height={'100%'} />
        ) : !data?.length ? (
          <NoData height={'100%'} />
        ) : (
          <div className="dashboard_component" style={{ flexGrow: 1, flexShrink: 1, overflow: 'hidden' }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '15px 15px',
                cursor: 'pointer',
              }}
              className="CustomScrollbar">
              {data &&
                data.map((risk, idx) => (
                  <Box
                    height={'132px'}
                    onClick={() => handleButtonClick(risk.rule_id)}
                    key={idx}
                    sx={{
                      width: '100%',
                      padding: '15px 12px 14px 12px',
                      borderRadius: '8px',
                      backgroundColor: ColorMap[risk.severity]?.fullBackground,
                      margin: '2px 0px',
                      '@media (max-width: 1666px)': {
                        paddingTop: '14px !important',
                        paddingBottom: '10px !important',
                      },
                      '@media (max-width: 1595px)': {
                        paddingTop: '10px !important',
                        paddingBottom: '10px !important',
                      },
                    }}>
                    <Box
                      sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',

                        fontFamily: 'Manrope',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        textAlign: 'left',
                      }}>
                      <p
                        style={{
                          height: '20px',
                          margin: 0,
                          padding: 0,
                          marginBottom: '8px',
                          color: '#1a202c',
                        }}>
                        {`${risk.rule_id}`}
                      </p>
                      <Box
                        sx={{
                          lineHeight: '14px',
                          color: '#484D56',
                          display: 'flex',
                          height: '100%',
                          fontWeight: 500,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}>
                        <Tooltip title={risk.rule_description}>
                          <Text
                            style={{ margin: 0, padding: 0, marginBottom: '10px', fontSize: '12px', display: 'flex' }}>
                            {risk.rule_description && risk.rule_description.length < 80
                              ? risk.rule_description
                              : risk.rule_description.substring(0, 80) + '...'}
                          </Text>
                        </Tooltip>
                        <Box
                          sx={{
                            display: 'flex',
                            boxSizing: 'border-box',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // marginBottom: '8px',
                          }}>
                          <p style={{ fontSize: '12px', margin: 0, padding: 0 }}>
                            Created {findTimeDifference(risk.last_updated[0])}
                          </p>
                          <Box
                            sx={{
                              width:
                                risk.severity === 'CRITICAL'
                                  ? '70px'
                                  : risk.severity === 'HIGH'
                                  ? '50px'
                                  : risk.severity === 'MEDIUM'
                                  ? '75px'
                                  : risk.severity === 'LOW'
                                  ? '50px'
                                  : risk.severity === 'WARNING'
                                  ? '75px'
                                  : risk.severity === 'INFO'
                                  ? '50px'
                                  : '',
                              height: '25px',
                              backgroundColor: ColorMap[risk.severity].iconBackground,
                              borderRadius: '32px',
                              padding: '4px 12px 4px 12px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                            <p
                              style={{
                                padding: '0',
                                margin: 0,
                                fontWeight: 500,
                                fontSize: '12px',
                                color: ColorMap[risk.severity].text,
                              }}>
                              {' '}
                              {risk.severity[0] + risk.severity.slice(1).toLowerCase()}
                            </p>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </div>
          </div>
        )}
      </ContentBox>
    </Grid>
  );
};
export default TopRiskRecommendation;
