import closeButton from '@assets/svg/xmark.svg';
import { Dialog, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import successCheckMark from '@root/assets/img/auth/mail-sent-checkmark.svg';
import CircularCloseButton from '@root/components/v2/ui-elements/CircularCloseButton';
import Select from '@root/components/v2/ui-elements/Select';
import { axiosPost } from '@root/services/axios/axiosPost';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import './config.scss';
import Loader from '@root/components/common/Loader';

function JiraConfiguration({ setOpen, open, rowData, mode = 'Workload' }) {
  const [error, setError] = useState('');
  const [orgId, setOrgID] = useState('');
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState([{}]);
  const [issuesOptions, setIssuesOptions] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState([{}]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState([{}]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState([{}]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const title =
    mode === 'Identity'
      ? `Optimize over privileged ${rowData.type === 'role' ? 'role' : 'user'} ${rowData.name}`
      : mode === 'Events'
      ? // ? 'Optimize over privileged'
        'Threat Detected on CyberQshield'
      : `Vulnerability detected for ${rowData.cve_id}`;
  const description =
    mode === 'Identity'
      ? `CyberQ Shield has identified unused & Over-privileged for ${rowData.type === 'role' ? 'role' : 'user'} ${
          rowData.name
        } ${rowData.resource_id} . Refer link <https://cyberqshield.seclogic.io/identity/${
          rowData.type === 'role' ? 'roles' : 'users'
        }> for details on how to resolve the issue.`
      : mode === 'Events'
      ? `CyberQ Shield has identified Threat for rule ${rowData.event_name}.  Refer Link https://cyberqshield.seclogic.io/events/all?tab=Threat and go to Threats tab for details on how to resolve the issue.`
      : `CyberQ Shield has detected Vulnerability for ${rowData.cve_id} with ${rowData.cve_id} for ${rowData.resource_id}`;

  const user = useSelector(state => state.user);

  const clearAll = () => {
    setShowSuccess(false);
    setError(false);
    setSelectedIssue([{}]);
    setSelectedAssignee([{}]);
    setSelectedPriority([{}]);
  };

  const handleCreate = async () => {
    let payload =
      mode === 'Identity'
        ? {
            orgId: orgId,
            currentUserEmail: user.info.user.email,
            issuePayload: {
              projectKey: selectedProject[0],
              title: title,
              description: description,
              issueType: selectedIssue[0],
              priority: selectedPriority[0],
              assigneeEmail: selectedAssignee[0],
            },
            accountId: rowData.accountId,
            scanId: rowData.scanId,
            customInfo: {
              resourceId: rowData.resource_id,
              resourceName: rowData.name,
              resourceType: rowData.type,
              region: 'global',
            },
          }
        : mode === 'Events'
        ? {}
        : {
            orgId: orgId,
            currentUserEmail: user.info.user.email,
            issuePayload: {
              projectKey: selectedProject[0],
              title: title,
              description: description,
              issueType: selectedIssue[0],
              priority: selectedPriority[0],
              assigneeEmail: selectedAssignee[0],
            },
            accountId: rowData.environment_id,
            scanId: rowData.scan_id,
            customInfo: {
              resourceId: rowData.resource_id,
              resourceName: rowData.resource_name,
              resourceType: rowData.resource_type,
              region: rowData.region,
            },
          };

    const res = await axiosPost('jira/create_issue', payload, true);

    setError(res.data.error);
    if (!res.data.error) setShowSuccess(true);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await axiosPost('jira/org_id', { currentUserEmail: user.info.user.email }, true);
      setOrgID(res.data?.data?.orgId);

      // setProjectOptions(projects.data?.data);

      const res2 = await axiosPost('jira/issue_meta', { orgId: res.data?.data?.orgId }, true);
      const projects = await axiosPost('jira/projects', { orgId: res.data?.data?.orgId }, true);

      setSelectedProject([res2?.data?.data?.projectKey]);
      setProjectOptions([projects?.data?.data?.find(project => project.projectKey === res2?.data?.data?.projectKey)]);
      setSelectedIssue([res2?.data?.data?.defaultSelected?.issueTypes]);
      setIssuesOptions(res2.data.data.payload.issueTypes.map(issue => Object.values(issue)[0]));
      setPriorityOptions(res2.data.data.payload.issuePriority.map(prio => Object.keys(prio)[0]));
      setAssigneeOptions(res2.data.data.payload.userInfo);
      setLoading(false);
    })();
  }, [user]);

  // const handleSelectProjectChange = useCallback(
  //   async project => {
  //     setSelectedProject(project);

  //     const res = await axiosPost(
  //       'jira/project_info',
  //       {
  //         orgId: orgId,
  //         projectKey: project[0],
  //       },
  //       true,
  //     );

  //     setIssuesOptions(res.data.data.issueTypes.map(issue => Object.values(issue)[0]));
  //     setAssigneeOptions(res.data.data.userInfo);
  //     setPriorityOptions(res.data.data.issuePriority.map(prio => Object.keys(prio)[0]));
  //   },
  //   [setSelectedProject, setIssuesOptions, orgId],
  // );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" className="jira_workload">
      {!showSuccess ? (
        <>
          <DialogTitle>
            <div
              style={{
                background: '#EDF2F7',
                border: '1px solid #CBD5E0',
                padding: '3px 15px',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <div style={{ flexGrow: '1', display: 'flex', alignItems: 'center' }}>
                <p className="modal_heading m-0"> Create JIRA Ticket</p>
              </div>
              <div style={{ padding: '5px 0' }}>
                <div className="close-dialog" onClick={() => setOpen(false)}>
                  <img style={{ paddingLeft: '9px', paddingBottom: '1px' }} src={closeButton} />
                </div>
              </div>
            </div>
          </DialogTitle>
          <DialogContent className="CustomScrollbar" style={{ overflowX: 'hidden' }}>
            {loading ? (
              <div style={{ height: '70vh', width: '444px' }}>
                <Loader />
              </div>
            ) : (
              <div className="">
                <div className="card_container">
                  <div>
                    <p className="input_heading m-0"> Project*</p>
                    <div style={{ pointerEvents: 'none' }}>
                      <Select
                        placeholder="Select a Project"
                        options={projectOptions?.map(option => ({
                          value: option?.projectKey,
                          label: option?.projectName,
                        }))}
                        height="40px"
                        width="414px"
                        value={selectedProject}
                        // onChange={handleSelectProjectChange}
                        mode="single"
                        controlled
                        regionDropdown={false}
                        jira_input={true}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="input_heading m-0"> Issue Type*</p>
                    <Select
                      placeholder="Select an Issue Type"
                      options={issuesOptions?.map(issue => ({
                        value: issue,
                        label: issue,
                      }))}
                      controlled
                      value={selectedIssue}
                      onChange={value => setSelectedIssue(value)}
                      mode="single"
                      height="40px"
                      width="414px"
                      regionDropdown={false}
                      jira_input={true}
                    />
                  </div>
                  <div>
                    <p className="input_heading m-0"> Summary</p>
                    <input
                      className="input"
                      style={{ width: '414px', height: '40px' }}
                      disabled
                      placeholder="Issue Summary"
                      value={title}
                    />
                  </div>
                  <div>
                    <p className="input_heading m-0"> Description*</p>
                    <textarea
                      className="input"
                      style={{ width: '414px', height: '127px', pointerEvents: 'none' }}
                      value={description}
                    />
                  </div>
                  <div>
                    <p className="input_heading m-0"> Assignee*</p>
                    <Select
                      placeholder="Select an Assignee Type"
                      options={assigneeOptions?.map(assignee => ({
                        value: assignee.email,
                        label: assignee.name,
                      }))}
                      value={selectedAssignee}
                      controlled
                      onChange={value => setSelectedAssignee(value)}
                      mode="single"
                      height="40px"
                      width="414px"
                      regionDropdown={false}
                      jira_input={true}
                    />
                  </div>
                  <div>
                    <p className="input_heading m-0"> Priority*</p>
                    <Select
                      placeholder="Select an Priority Type"
                      options={priorityOptions?.map(prio => ({
                        value: prio,
                        label: prio,
                      }))}
                      controlled
                      value={selectedPriority}
                      onChange={value => setSelectedPriority(value)}
                      mode="single"
                      height="40px"
                      width="414px"
                      regionDropdown={false}
                      jira_input={true}
                    />
                  </div>
                  {error && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        width: '100%',
                        textAlign: 'right',
                        fontFamily: 'Manrope',
                        fontWeight: 500,
                      }}>
                      Something went wrong, please try again!
                    </div>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      color="primary"
                      onClick={() => {
                        clearAll();
                        setOpen(false);
                      }}
                      style={{
                        fontSize: '14px',
                        fontfamily: 'Manrope',
                        fontWeight: 600,
                        color: '#484D56',
                        background: '#F7FAFC',
                        border: '1px solid #CBD5E0',

                        lineHeight: 1,
                        borderRadius: '4px',

                        padding: '10px 16px',
                        marginTop: '15px',
                        marginRight: '15px',
                      }}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleCreate}
                      style={{
                        fontSize: '14px',
                        fontfamily: 'Manrope',
                        fontWeight: 600,
                        color: '#FFFFFF',
                        background: '#194BFB',
                        // border: '1px solid #CBD5E0',

                        lineHeight: 1,
                        borderRadius: '4px',

                        padding: '10px 16px',
                        marginTop: '15px',
                      }}>
                      Create
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </>
      ) : (
        <>
          <div style={{ padding: '15px', display: 'flex', width: '100%', paddingBottom: '0' }}>
            <div style={{ flexGrow: '1' }} />
            <CircularCloseButton
              onClick={() => {
                setOpen(false);
                setShowSuccess(false);
              }}
            />
          </div>
          <div className="success-box" style={{ margin: '1rem', width: '50rem' }}>
            <img src={successCheckMark} className="success-checkmark" />
            <Typography
              variation="body2"
              sx={{
                fontSize: '15px',
                fontFamily: 'inherit',
                color: '#1D8661',
                fontWeight: '500',
              }}>
              Ticket created successfully!
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              color="primary"
              className="fs--1"
              onClick={() => {
                setOpen(false);
                setShowSuccess(false);
              }}
              style={{
                fontSize: '14px',
                fontfamily: 'Manrope',
                fontWeight: 600,
                color: '#FFFFFF',
                background: '#194BFB',
                // border: '1px solid #CBD5E0',

                lineHeight: 1,
                borderRadius: '4px',

                padding: '10px 16px',
                margin: '0 1rem 1rem 1rem',
              }}>
              Done
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
}

export default JiraConfiguration;
