import React from "react";
import InputForm from "./forms/inputForm";
import SettingsForm from "./forms/settings";
import SetupForm from "./forms/setup";

const DEMO_DATA = {
  env: ["887634214489", "887634214480"],
  services: ["Lambda", "CloudWatch", "DynamoDB", "EC2"],
  rules: ["Ensue APIs has logging enabled", "Use client side SSL"],
  regions: ["us-east-1", "us-west-1", "us-south-1", "us-north-1"],
  resourceTypes: ["client-side-ssl", "server-side"],
  notification: ["Email", "Slack", "Jira"],
};
const EditPolicy = () => {
  const [currentSetup, setCurrentSetup] = React.useState({
    policyName: null,
    envId: null,
  });
  const [currentInput, setCurrentInput] = React.useState({
    service: null,
    rule: null,
    resouce: null,
    regions: [],
  });
  const [currentSetting, setCurrentSetting] = React.useState({
    allowManual: true,
    allowAutomated: true,
    notification: false,
    notificationConfig: null,
    notificationDest: null,
    notificationFreq: "RemediationInitiation",
  });
  const formsMap = {
    setup: () => (
      <SetupForm
        DEMO_DATA={DEMO_DATA}
        currentSetup={currentSetup}
        setCurrentSetup={setCurrentSetup}
      />
    ),
    input: () => (
      <InputForm
        DEMO_DATA={DEMO_DATA}
        currentInput={currentInput}
        setCurrentInput={setCurrentInput}
      />
    ),
    settings: () => (
      <SettingsForm
        DEMO_DATA={DEMO_DATA}
        currentSetting={currentSetting}
        setCurrentSetting={setCurrentSetting}
      />
    ),
  };
  return (
    <div>
      {formsMap.setup()}
      {formsMap.input()}
      {formsMap.settings()}
    </div>
  );
};

export default EditPolicy;
