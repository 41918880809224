import React from 'react';
import { Box, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageHeader from '@root/components/v2/layout/PageHeader';
import TableSkeleton from '@root/components/table/skeleton';

function PolicySkeleton() {
  return (
    <PageContainer>
      <PageHeader title="Policies" />

      <PageContent>
        <CloudProviderBarSkeleton />

        <ContentBox paddingMode="content" marginBottom="15px">
          <Box
            className="Borders RoundedCorners"
            marginLeft="15px"
            marginRight="15px"
            marginBottom="15px"
            marginTop="15px">
            <Skeleton height="40px" style={{ transform: 'none' }} />
          </Box>

          <TableSkeleton paddingLeft="15px" paddingRight="15px" />
        </ContentBox>
      </PageContent>
    </PageContainer>
  );
}

export default PolicySkeleton;
