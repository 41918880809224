import './charts.scss';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import moment from 'moment';

// const data = {
//   trend_data: [
//     {
//       scan_date: '2023-08-17T10:23:05',
//       total_score: 6.4,
//       new_score: 1,
//       critical_score: 2,
//       resolved_scire: 4,
//     },
//     {
//       scan_date: '2023-08-17T09:26:14',
//       total_score: 4.5,
//       new_score: 1,
//       critical_score: 2,
//       resolved_scire: 2.5,
//     },
//   ],
//   // ... other data ...
// };

const CustomTooltip = ({ active, payload, timeFilter }) => {

  if (active && payload && payload.length) {
    const { critical_score, new_score, remediated_score, total_score, scan_date } = payload[0].payload;

    return (
      <div className="custom_tooltip" style={{ outline: 0 }}>
        <p className="custom_tooltip_count m-0 p-0">
          {timeFilter.includes('h')
            ? moment(scan_date ? scan_date : moment()).format('HH:mm')
            : moment(scan_date ? scan_date : moment()).format('D MMM')}
        </p>
        <div className="count_container">
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #194BFB', paddingLeft: '4px', borderRadius: '1px' }}>
            Total: <span className="custom_tooltip_count"> {total_score ? total_score : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #F84D4D', paddingLeft: '4px', borderRadius: '1px' }}>
            Critical: <span className="custom_tooltip_count"> {critical_score ? critical_score : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #1A202C', paddingLeft: '4px', borderRadius: '1px' }}>
            New: <span className="custom_tooltip_count"> {new_score ? new_score : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #30E0A1', paddingLeft: '4px', borderRadius: '1px' }}>
            Resolved: <span className="custom_tooltip_count"> {remediated_score ? remediated_score : '0'}</span>
          </p>
        </div>
      </div>
    );
  }
  return null;
};

const RiskTrendChart = ({ data, timeFilter, height = 148 }) => {
  // useEffect(() => {
  //   // if (!timeFilter.includes('h')) {
  //   //   data?.trend_data?.reverse();
  //   // }
  //   // console.log('riskTrendModifiedData', data);
  // });

  return (
    <ResponsiveContainer height={height} className="dashboard_charts" id="line_chart_dashboard_new">
      <LineChart data={data}>
        <XAxis dataKey="scan_date" hide={true} />
        <YAxis domain={[0, 'auto']} interval={0} />
        <Tooltip content={<CustomTooltip timeFilter={timeFilter} />} />
        <CartesianGrid vertical={false} stroke="#EDF2F7" />

        <Line type="monotone" dataKey="total_score" stroke="#194BFB" dot={false} />
        <Line type="monotone" dataKey="critical_score" stroke="#F84D4D" dot={false} />
        <Line type="monotone" dataKey="new_score" stroke="#1A202C" dot={false} />
        <Line type="monotone" dataKey="remediated_score" stroke="#30E0A1" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RiskTrendChart;
