import CodeContainer from "@components/v2/content/CodeContainer";
import Button from "@components/v2/ui-elements/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Column from "@root/components/v2/layout/columns/Column";
import ColumnsContainer from "@root/components/v2/layout/columns/ColumnsContainer";
import Text from "@root/components/v2/ui-elements/Text";
import { saveAs } from "file-saver";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import CircularCloseButton from "@root/components/v2/ui-elements/CircularCloseButton";
import Tabs from "@root/components/v2/ui-elements/Tabs";

import "./style.scss";

const MIN_NUMBER_OF_LINES = 30;

/**
 *
 * @param {*} param0
 * @param {*} param0.type policy|user|group|role
 * @returns
 */
function OptimizeModal({
  open,

  onClose,
  configData,
}: any) {
  const [tabIndex, setTabIndex] = useState(0);

  const oldConfigData = useMemo(() => {
    if (!configData?.data?.old_config) return "";

    const str = JSON.stringify(configData?.data?.old_config, null, 2);
    const lines = str.split("\n").length;
    if (lines < MIN_NUMBER_OF_LINES) {
      return `${str}${Array.from(new Array(MIN_NUMBER_OF_LINES - lines))
        .map(() => "\n")
        .join("")}`;
    } else {
      return str;
    }
  }, [configData]);

  const newConfigData = useMemo(() => {
    if (!configData?.data?.new_config) return "";

    const str = JSON.stringify(configData?.data?.new_config, null, 2);
    const lines = str.split("\n").length;
    if (lines < MIN_NUMBER_OF_LINES) {
      return `${str}${Array.from(new Array(MIN_NUMBER_OF_LINES - lines))
        .map(() => "\n")
        .join("")}`;
    } else {
      return str;
    }
  }, [configData]);

  const handleCopyEffectivePolicy = useCallback(() => {
    navigator.clipboard.writeText((oldConfigData || "").trim());
    toast.success("Copied to clipboard", {
      hideProgressBar: true,
      autoClose: 1500,
    });
  }, [oldConfigData]);

  const handleCopyOptimizedPolicy = useCallback(() => {
    navigator.clipboard.writeText((newConfigData || "").trim());
    toast.success("Copied to clipboard", {
      hideProgressBar: true,
      autoClose: 1500,
    });
  }, [newConfigData]);

  const handleDownloadEffectivePolicy = useCallback(() => {
    const blob = new Blob([(oldConfigData || "").trim()], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "oldConfigData-policy.json");
    toast.success("Successfully downloaded", {
      hideProgressBar: true,
      autoClose: 1500,
    });
  }, [oldConfigData]);

  const handleDownloadOptimizedPolicy = useCallback(() => {
    const blob = new Blob([(newConfigData || "").trim()], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "newConfigData-policy.json");
    toast.success("Successfully downloaded", {
      hideProgressBar: true,
      autoClose: 1500,
    });
  }, [newConfigData]);

  return (
    <Dialog
      className="IdentityOptimizeModal__Container"
      fullWidth
      maxWidth="lg"
      style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      PaperProps={{ style: { height: "90vh" } }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {" "}
        <div style={{ display: "flex", alignItems: "center" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <g clip-path="url(#clip0_12695_394631)">
              <rect width="30" height="30" rx="4" fill="#194BFB" />
              <path
                d="M24 6H6C4.9 6 4 6.9 4 8V22C4 23.1 4.9 24 6 24H24C25.1 24 26 23.1 26 22V8C26 6.9 25.1 6 24 6ZM24 22H6V8H24V22ZM19 20C19.6 20 20 19.6 20 19V15.5C20 14.9 19.6 14.5 19 14.5C18.4 14.5 18 14.9 18 15.5V19C18 19.6 18.4 20 19 20ZM15 20C15.6 20 16 19.6 16 19V11C16 10.4 15.6 10 15 10C14.4 10 14 10.4 14 11V19C14 19.6 14.4 20 15 20ZM11 20C11.6 20 12 19.6 12 19V13C12 12.4 11.6 12 11 12C10.4 12 10 12.4 10 13V19C10 19.6 10.4 20 11 20Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_12695_394631">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span
            style={{
              fontFamily: "Manrope",
              fontWeight: 600,
              color: "#1A202C",
              fontSize: "24px",
              paddingLeft: "10px",
            }}
          >
            Old & New Configuration
          </span>
          <div style={{ flexGrow: "1" }} />
          <CircularCloseButton
            onClick={() => {
              onClose();
            }}
          />
        </div>
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flexShrink: 1,
          minHeight: 0,
          overflow: "hidden",
        }}
      >
        <Box width="1024px"></Box>

        <Tabs
          activeTabIndex={tabIndex}
          tabs={[`JSON`]}
          style={{ marginBottom: 16 }}
          onChange={setTabIndex}
        />

        {tabIndex === 0 ? (
          <ColumnsContainer className="IdentityOptimizeModal__ColumnsContainer">
            <Column className="IdentityOptimizeModal__Column" flexGrow={1}>
              <Box marginBottom="10px">
                <Text color="#718096" font="semi-bold" className="fs__4">
                  Old Configuration
                </Text>
              </Box>

              <CodeContainer
                code={oldConfigData}
                onDownloadClick={handleDownloadEffectivePolicy}
                onCopyClick={handleCopyEffectivePolicy}
                height="100%"
                flexGrow={1}
                className="IdentityOptimizeModal__CodeContainer"
              />
            </Column>
            <Column className="IdentityOptimizeModal__Column" flexGrow={1}>
              <Box marginBottom="10px">
                <Text color="#718096" font="semi-bold" className="fs__4">
                  New Configuration
                </Text>
              </Box>

              <CodeContainer
                code={newConfigData}
                onDownloadClick={handleDownloadOptimizedPolicy}
                onCopyClick={handleCopyOptimizedPolicy}
                height="100%"
                flexGrow={1}
                className="IdentityOptimizeModal__CodeContainer"
              />
            </Column>
          </ColumnsContainer>
        ) : null}
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button backgroundColor="neutral" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OptimizeModal;
