import { useCallback, useMemo } from 'react';

import AllIcon from '@root/assets/img/header/cloud_providers/all.png';
import AWSIcon from '@root/assets/img/header/cloud_providers/aws.png';
import AzureIcon from '@root/assets/img/header/cloud_providers/azure.png';
import GCPIcon from '@root/assets/img/header/cloud_providers/gcp.png';

import { Box } from '@mui/material';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { useCloudProvider } from './CloudProviderContext';
import './style.scss';

function CloudProviderBar({ rightElement, showTab = true }) {
  const { selectedEnvironments } = useSelectedEnvironments();
  // const allEnvironments = useEnvironments();

  const { cloudProvider, handleChangeCloudProvider } = useCloudProvider();

  const awsEnvironments = useMemo(() => {
    return selectedEnvironments.filter(item => item.cloudProvider === 'aws');
  }, [selectedEnvironments]);

  const gcpEnvironments = useMemo(() => {
    return selectedEnvironments.filter(item => item.cloudProvider === 'gcp');
  }, [selectedEnvironments]);

  const azureEnvironments = useMemo(() => {
    return selectedEnvironments.filter(item => item.cloudProvider === 'azure');
  }, [selectedEnvironments]);

  const handleClick = useCallback(
    e => {
      if (
        e?.currentTarget?.dataset?.cloudProvider === 'all' ||
        selectedEnvironments.some(item => item.cloudProvider === e?.currentTarget?.dataset?.cloudProvider)
      ) {
        handleChangeCloudProvider(e?.currentTarget?.dataset?.cloudProvider);
      }
    },
    [handleChangeCloudProvider,selectedEnvironments],
  );

  return (
    <div className="CloudProviderBar" style={{ position: 'relative' }}>
      {showTab && (
        <>
          <div
            className={`CloudProviderBar__Option ${cloudProvider === 'all' ? 'Active' : ''}`}
            data-cloud-provider="all"
            onClick={handleClick}
            // onClick={()=> selectedEnvironments.length === allEnvironments.length ?  handleClick():''}
          >
            <img src={AllIcon} />
            <div>All ({selectedEnvironments.length === 0 ? 1 : selectedEnvironments.length})</div>
          </div>

          <div
            className={`CloudProviderBar__Option ${cloudProvider === 'aws' ? 'Active' : ''}`}
            data-cloud-provider="aws"
            onClick={handleClick}>
            <img src={AWSIcon} style={{ width: '20px' }} />
            <div>AWS ({selectedEnvironments.length === 0 ? 1 : awsEnvironments.length})</div>
          </div>

          <div
            className={`CloudProviderBar__Option ${cloudProvider === 'gcp' ? 'Active' : ''}`}
            data-cloud-provider="gcp"
            onClick={handleClick}>
            <img src={GCPIcon} />
            <div>GCP ({gcpEnvironments.length})</div>
          </div>

          <div
            className={`CloudProviderBar__Option ${cloudProvider === 'azure' ? 'Active' : ''}`}
            data-cloud-provider="azure"
            onClick={handleClick}>
            <img src={AzureIcon} />
            <div>Azure ({azureEnvironments.length})</div>
          </div>
        </>
      )}

      <Box flexGrow={1} />

      {rightElement}
    </div>
  );
}

export default CloudProviderBar;
