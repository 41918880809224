import { Box, Grid, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';

function DashboardSkeleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />

      <ContentBox title={'Status'} paddingMode="content" marginBottom="15px">
        <Box display="flex" flexDirection="row" gap="15px" padding="15px">
          {['Security Score', 'Risk Trends', 'Compliance', 'Threats'].map(title => (
            <ContentBox
              key={title}
              title={title}
              paddingMode="content"
              style={{ flexGrow: 1, width: 'calc(25% - 15px)' }}>
              {/* Set a fixed width for each ContentBox */}

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                height="406px"
                padding="15px"
                paddingTop={title === 'Compliance' ? '15px' : '30px'}>
                {title === 'Compliance' ? (
                  <>
                    {/* <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginBottom="10px">
                      <Skeleton width="100px" />
                      <Skeleton width="100px" />
                    </Box> */}
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%',

                        // pt: '15px',
                      }}>
                      {Array.from(new Array(5)).map(i => (
                        <Grid
                          key={i}
                          sx={{
                            border: '1px solid #cbd5e0',
                            padding: '10px 10px',
                            borderRadius: '5px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Grid>
                            <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                              <Skeleton variant="rectangular" width="36px" height="36px" />
                            </Box>
                          </Grid>
                          <Grid sx={{ width: '100%', marginLeft: '10px' }}>
                            <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                              <Skeleton width="30px" />
                            </Box>
                            <Box width="100%">
                              <Skeleton width="100%" />
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    {' '}
                    <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                      {title === 'Risk Trends' || title === 'Resource Trend' ? (
                        <Skeleton variant="rectangular" height="190px" sx={{ width: '100%', flexGrow: 1 }} />
                      ) : (
                        <Skeleton variant="circular" width="190px" height="190px" />
                      )}
                    </Box>
                    <Box flexGrow={1} height="10px" width="100%" />
                    <Box
                      width="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      marginBottom="10px">
                      <Skeleton width="80px" />
                      {title === 'Risk Trends' && <Skeleton width="70px" />}
                      <Skeleton width="80px" />
                    </Box>
                    <Grid sx={{ width: '100%' }}>
                      {Array.from(new Array(title === 'Cloud Provider' ? 3 : 4)).map((i, index) => (
                        <Box
                          key={i}
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          gap={title === 'Cloud Provider' ? '15px' : '10px'}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            marginTop={index === 0 ? '0px' : title === 'Cloud Provider' ? '20px' : '8px'}>
                            <Skeleton width="70px" />
                            {title === 'Risk Trends' && <Skeleton width="70px" />}
                            <Skeleton width="70px" />
                          </Box>
                        </Box>
                      ))}
                    </Grid>
                  </>
                )}
              </Box>
            </ContentBox>
          ))}
        </Box>
      </ContentBox>

      <Box display="flex" flexDirection="row" gap="15px">
        {['Top 5 Risk Recommendations', 'Internet Exposed Resources', 'CQ Shield Priority'].map(title => (
          <ContentBox
            key={title}
            title={title}
            paddingMode="content"
            style={{ flexGrow: 1, width: 'calc(33.33% - 15px)' }}>
            {/* Set a fixed width for each ContentBox */}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="440px"
              padding="15px"
              overflow="hidden">
              {title === 'Top 5 Risk Recommendations' ? (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                  }}>
                  {Array.from(new Array(5)).map(i => (
                    <Grid
                      key={i}
                      sx={{
                        border: '1px solid #cbd5e0',
                        padding: '8px',
                        borderRadius: '5px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <Box width="100%" display="flex">
                        <Skeleton variant="rectangular" width="100%" height="100px" />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : title === 'Internet Exposed Resources' ? (
                <>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      width: '100%',

                      // pt: '15px',
                    }}>
                    {Array.from(new Array(4)).map(i => (
                      <Grid
                        key={i}
                        sx={{
                          border: '1px solid #cbd5e0',
                          padding: '10px 10px',
                          borderRadius: '5px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <Grid>
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                            <Skeleton variant="circular" width="36px" height="36px" />
                          </Box>
                        </Grid>
                        <Grid sx={{ width: '100%', marginLeft: '10px' }}>
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                            <Skeleton width="100px" />
                            <Skeleton width="30px" />
                          </Box>
                          <Box width="100%">
                            <Skeleton width="100%" />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  height="100%"
                  width="100%"
                  justifyContent="space-between">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    paddingLeft="12px"
                    marginRight={'12px'}>
                    <Skeleton variant="circular" width="140px" height="140px" />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    paddingRight="8px"
                    width="100%"
                    sx={{
                      backgroundColor: '#fafafa',
                    }}
                    paddingBottom="15px"
                    borderRadius="5px"
                    component="div">
                    <Box minWidth="100%" minHeight="100px">
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent={'space-between'}
                        gap={'8px'}
                        height={'100px'}>
                        <Skeleton width="150px" height="120px" />
                        <Skeleton width="150px" height="120px" />
                        <Skeleton width="150px" height="120px" />
                      </Box>
                      <Grid sx={{ width: '100%' }}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent={'space-between'}
                          gap={'8px'}
                          height={'100px'}>
                          <Skeleton width="150px" height="120px" />
                          <Skeleton width="150px" height="120px" />
                          <Skeleton width="150px" height="120px" />
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </ContentBox>
        ))}
      </Box>
    </PageContent>
  );
}

export default DashboardSkeleton;
