import React from 'react';
import Text from '@components/v2/ui-elements/Text';

import './style.scss';

function Tabs({ activeTabIndex, onChange, tabs, style }) {
  return (
    <div className="Tabs" style={style || {}}>
      {tabs.map((t, index) => (
        <div
          key={t}
          className={`Tabs__Item ${activeTabIndex === index ? 'Active' : ''}`}
          onClick={() => onChange(index)}>
          <Text font="semi-bold">{t}</Text>
        </div>
      ))}
    </div>
  );
}

export default Tabs;
