import { useCallback, useEffect, useRef, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Loader } from '@googlemaps/js-api-loader';

import FullscreenLoader from '@root/components/FulscreenLoader';

import { Box, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import Text from '@root/components/v2/ui-elements/Text';

import mapStyle from './map.style';
import './style.scss';

const MARKER_SIZE = 64;
const MARKER_FONT_SIZE = '10px';

type Region = {
  name: string;
  lat: number;
  lng: number;
  totalCount: number | undefined;
  environmentsCount: number | undefined;
  resourcesCount: number | undefined;
  servicesCount: number | undefined;
  country: string;
  regionName: string;
  inactive: boolean;
  regions?: Region[];
};

interface CustomMarker extends google.maps.Marker {
  customData?: {
    regionName: string | null;
    inactive: boolean;
    totalCount: number | null | undefined;
    environmentsCount: number | null | undefined;
    resourcesCount: number | null | undefined;
    servicesCount: number | null | undefined;
  };
}

interface Props {
  data: Region[];
  useFailureMarkers?: boolean;
  useSuccessMarkers?: boolean;
  loading?: boolean;
}

function contractNumber(value: number) {
  const suffixes = ['', 'k', 'm', 'b', 't'];
  const suffixNum = Math.floor(String(value).length / 3);
  let shortValue = parseFloat((suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(2));
  if (shortValue % 1 !== 0) {
    shortValue = parseFloat(shortValue.toFixed(1));
  }
  return `${shortValue}${suffixes[suffixNum]}`;
}

const successfulMarkerSvg = window.btoa(`
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21255_59428)">
        <path opacity="0.7"
            d="M15.685 30.3055C15.685 29.1546 15.8187 28.007 16.0821 26.8957L16.2363 26.2444L15.5936 26.0868L12.5844 25.349L11.9404 25.1914L11.7856 25.8447C11.4408 27.2987 11.2654 28.7998 11.2654 30.3061C11.2654 32.4034 11.6009 34.4638 12.2621 36.431C12.9014 38.3331 13.8332 40.1138 15.0318 41.7248C16.2171 43.3177 17.634 44.706 19.2442 45.8523C20.8769 47.0146 22.6697 47.8992 24.5725 48.4814L25.2058 48.6752L25.3971 48.0334L26.2932 45.0313L26.4851 44.3895L25.8517 44.1956C19.8656 42.3613 15.685 36.6503 15.685 30.3061V30.3055Z"
            fill="#03BB34" />
        <path opacity="0.7"
            d="M40.5061 20.4525L40.9555 20.9442L41.4412 20.4888L43.7126 18.3586L44.1984 17.9032L43.749 17.4109C42.0131 15.5102 39.9542 14.0132 37.6299 12.9615C35.2235 11.8723 32.6563 11.3203 30.0005 11.3203C27.3446 11.3203 25.021 11.822 22.7119 12.8113C20.4823 13.7663 18.4796 15.1339 16.7596 16.8757L16.291 17.3499L16.7589 17.8241L18.9469 20.0434L19.4148 20.5183L19.8827 20.0441C22.5849 17.307 26.1785 15.7992 30.0005 15.7992C33.8225 15.7992 37.8046 17.4954 40.5068 20.4525H40.5061Z"
            fill="#03BB34" />
        <path opacity="0.7"
            d="M40.3122 46.1585C42.0045 45.0257 43.4975 43.6306 44.7497 42.0116C46.0151 40.3764 46.9999 38.5575 47.6769 36.6051C48.3778 34.5856 48.7325 32.4662 48.7325 30.3059C48.7325 29.0476 48.61 27.7901 48.3691 26.5701L48.2394 25.9121L47.5902 26.0442L44.5558 26.6613L43.9072 26.7934L44.0369 27.4507C44.2209 28.3843 44.3149 29.3454 44.3149 30.3065C44.3149 36.9149 39.9125 42.6896 33.6094 44.3489L32.9688 44.518L33.1355 45.1672L33.9151 48.2028L34.0819 48.8513L34.7219 48.683C36.7113 48.1598 38.5922 47.3101 40.3129 46.1585H40.3122Z"
            fill="#03BB34" />
        <path opacity="0.4"
            d="M10.4157 30.3052C10.4157 28.727 10.599 27.1576 10.9597 25.6398L11.1146 24.9879L10.4719 24.8303L7.46267 24.0932L6.81873 23.9355L6.6632 24.5881C6.22045 26.448 5.99609 28.3715 5.99609 30.3045C5.99609 32.9913 6.42561 35.6319 7.27273 38.1523C8.09206 40.589 9.28597 42.8714 10.8214 44.9351C12.3396 46.976 14.1556 48.7554 16.2191 50.2242C18.3111 51.7132 20.6083 52.8467 23.0457 53.5938L23.6791 53.7877L23.871 53.1458L24.7678 50.1431L24.9597 49.5006L24.3257 49.3067C16.1357 46.7983 10.4157 38.9847 10.4157 30.3045V30.3052Z"
            fill="#03BB34" />
        <path opacity="0.4"
            d="M39.7763 8.08192C36.6929 6.68687 33.4037 5.97928 30.0007 5.97928C26.5976 5.97928 23.6201 6.62181 20.6618 7.88943C17.8048 9.11345 15.2383 10.8653 13.0344 13.0974L12.5665 13.5716L13.0344 14.0458L15.2224 16.2651L15.6903 16.74L16.1582 16.2658C19.8564 12.5206 24.773 10.4582 30.0013 10.4582C35.2297 10.4582 40.679 12.7788 44.3753 16.8251L44.8246 17.3174L45.3104 16.862L47.5818 14.7312L48.0675 14.2758L47.6182 13.7835C45.3938 11.3482 42.7558 9.43002 39.7776 8.08259L39.7763 8.08192Z"
            fill="#03BB34" />
        <path opacity="0.4"
            d="M43.2122 50.6165C45.3803 49.1651 47.2936 47.377 48.8985 45.3032C50.5199 43.208 51.7813 40.8773 52.6496 38.3763C53.5477 35.7887 54.0024 33.0731 54.0024 30.3051C54.0024 28.6934 53.8455 27.083 53.5371 25.5183L53.4074 24.8604L52.7582 24.9925L49.7237 25.6095L49.0752 25.7416L49.2049 26.3989C49.457 27.6766 49.5848 28.9912 49.5848 30.3057C49.5848 39.3474 43.5616 47.2476 34.9375 49.5179L34.2969 49.6869L34.4637 50.3362L35.2433 53.3717L35.41 54.0203L36.05 53.852C38.5987 53.1813 41.009 52.0927 43.2128 50.6172L43.2122 50.6165Z"
            fill="#03BB34" />
        <path opacity="0.08"
            d="M5.21377 30.403C5.21377 28.4063 5.4454 26.4197 5.90271 24.4989L6.05758 23.8469L5.41496 23.6893L1.63269 22.7631L0.988749 22.6055L0.833223 23.2581C0.280609 25.5827 0 27.9865 0 30.403C0 33.7612 0.536731 37.061 1.59563 40.2113C2.61946 43.2569 4.11118 46.1088 6.03044 48.6889C7.92786 51.2396 10.1979 53.4636 12.7763 55.2993C15.3911 57.1605 18.2614 58.577 21.3084 59.51L21.9418 59.7038L22.1337 59.0619L23.2608 55.2879L23.4527 54.6454L22.8187 54.4516C12.4533 51.2772 5.21443 41.3877 5.21443 30.403H5.21377Z"
            fill="#03BB34" />
        <path opacity="0.08"
            d="M11.5452 12.6339L12.0131 13.1081L12.481 12.6339C17.1613 7.89412 23.3837 5.28376 30.0005 5.28376C36.6173 5.28376 43.5134 8.22075 48.1918 13.3415L48.6412 13.8338L49.1269 13.3784L51.982 10.7003L52.4678 10.2449L52.0184 9.75262C50.6491 8.25361 49.1263 6.89008 47.4909 5.69892C45.8417 4.4977 44.068 3.46416 42.2189 2.6278C38.3658 0.88398 34.2547 0 30.0012 0C25.7477 0 22.027 0.802826 18.3294 2.38702C14.7583 3.91688 11.5512 6.10671 8.79603 8.89547L8.32812 9.36965L8.79603 9.84384L11.5459 12.6333L11.5452 12.6339Z"
            fill="#03BB34" />
        <path opacity="0.08"
            d="M59.4188 24.4197L59.2891 23.7617L58.6398 23.8938L54.8258 24.6692L54.1772 24.8013L54.3069 25.4586C54.6259 27.0756 54.7874 28.739 54.7874 30.403C54.7874 41.8458 47.1646 51.8445 36.25 54.7171L35.6094 54.8861L35.7762 55.5354L36.7563 59.351L36.9231 59.9996L37.563 59.8312C40.7484 58.9928 43.7603 57.6327 46.5154 55.7882C49.2255 53.974 51.6167 51.7399 53.6226 49.147C55.6484 46.5286 57.2262 43.6157 58.3102 40.4896C59.432 37.2555 60.0012 33.8618 60.0012 30.4023C60.0012 28.3875 59.8053 26.3748 59.4194 24.4197H59.4188Z"
            fill="#03BB34" />
        <path
            d="M30.0007 43.2787C37.0707 43.2787 42.8021 37.4703 42.8021 30.3053C42.8021 23.1404 37.0707 17.332 30.0007 17.332C22.9306 17.332 17.1992 23.1404 17.1992 30.3053C17.1992 37.4703 22.9306 43.2787 30.0007 43.2787Z"
            fill="#03BB34" />
    </g>
    <defs>
        <clipPath id="clip0_21255_59428">
            <rect width="60" height="60" fill="white" />
        </clipPath>
    </defs>
</svg>
`);

const inactiveMarkerSvg = window.btoa(`
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_18135_29036)">
<path opacity="0.7" d="M15.685 30.3055C15.685 29.1546 15.8187 28.007 16.0821 26.8957L16.2363 26.2444L15.5936 26.0868L12.5844 25.349L11.9404 25.1914L11.7856 25.8447C11.4408 27.2988 11.2654 28.7998 11.2654 30.3062C11.2654 32.4034 11.6009 34.4638 12.2621 36.431C12.9014 38.3331 13.8332 40.1138 15.0318 41.7248C16.2171 43.3177 17.634 44.7061 19.2442 45.8523C20.8769 47.0146 22.6697 47.8993 24.5725 48.4814L25.2058 48.6753L25.3971 48.0334L26.2932 45.0314L26.4851 44.3895L25.8517 44.1957C19.8656 42.3613 15.685 36.6503 15.685 30.3062V30.3055Z" fill="#476FFC"/>
<path opacity="0.7" d="M40.5071 20.4525L40.9565 20.9442L41.4422 20.4888L43.7136 18.3586L44.1993 17.9032L43.75 17.4109C42.014 15.5102 39.9551 14.0132 37.6308 12.9615C35.2245 11.8723 32.6573 11.3203 30.0015 11.3203C27.3456 11.3203 25.022 11.822 22.7129 12.8113C20.4833 13.7663 18.4806 15.1339 16.7606 16.8757L16.292 17.3499L16.7599 17.8241L18.9478 20.0434L19.4158 20.5183L19.8837 20.0441C22.5858 17.307 26.1795 15.7992 30.0015 15.7992C33.8234 15.7992 37.8056 17.4954 40.5077 20.4525H40.5071Z" fill="#476FFC"/>
<path opacity="0.7" d="M40.3122 46.1585C42.0045 45.0257 43.4975 43.6306 44.7497 42.0116C46.0151 40.3764 46.9999 38.5575 47.6769 36.6051C48.3778 34.5856 48.7325 32.4662 48.7325 30.3059C48.7325 29.0476 48.61 27.7901 48.3691 26.5701L48.2394 25.9121L47.5902 26.0442L44.5558 26.6613L43.9072 26.7934L44.0369 27.4507C44.2209 28.3843 44.3149 29.3454 44.3149 30.3065C44.3149 36.9149 39.9125 42.6896 33.6094 44.3489L32.9688 44.518L33.1355 45.1672L33.9151 48.2028L34.0819 48.8513L34.7219 48.683C36.7113 48.1598 38.5922 47.3101 40.3129 46.1585H40.3122Z" fill="#476FFC"/>
<path opacity="0.4" d="M10.4147 30.3052C10.4147 28.727 10.598 27.1576 10.9587 25.6398L11.1136 24.9879L10.471 24.8303L7.4617 24.0932L6.81775 23.9355L6.66223 24.5881C6.21947 26.448 5.99512 28.3715 5.99512 30.3045C5.99512 32.9913 6.42463 35.6319 7.27176 38.1523C8.09108 40.589 9.28499 42.8714 10.8204 44.9351C12.3386 46.976 14.1546 48.7554 16.2181 50.2242C18.3101 51.7132 20.6073 52.8467 23.0448 53.5938L23.6781 53.7877L23.87 53.1458L24.7668 50.1431L24.9587 49.5006L24.3247 49.3067C16.1348 46.7983 10.4147 38.9847 10.4147 30.3045V30.3052Z" fill="#476FFC"/>
<path opacity="0.4" d="M39.7763 8.08191C36.6929 6.68686 33.4037 5.97928 30.0007 5.97928C26.5976 5.97928 23.6201 6.6218 20.6618 7.88942C17.8048 9.11345 15.2383 10.8653 13.0344 13.0974L12.5665 13.5716L13.0344 14.0458L15.2224 16.2651L15.6903 16.74L16.1582 16.2658C19.8564 12.5206 24.773 10.4582 30.0013 10.4582C35.2297 10.4582 40.679 12.7788 44.3753 16.8251L44.8246 17.3174L45.3104 16.862L47.5818 14.7312L48.0675 14.2758L47.6182 13.7835C45.3938 11.3482 42.7558 9.43002 39.7776 8.08259L39.7763 8.08191Z" fill="#476FFC"/>
<path opacity="0.4" d="M43.2131 50.6165C45.3812 49.1651 47.2945 47.377 48.8994 45.3032C50.5209 43.208 51.7823 40.8773 52.6506 38.3763C53.5487 35.7887 54.0033 33.0731 54.0033 30.3051C54.0033 28.6934 53.8465 27.083 53.5381 25.5183L53.4084 24.8604L52.7591 24.9925L49.7247 25.6095L49.0761 25.7416L49.2059 26.3989C49.458 27.6766 49.5857 28.9912 49.5857 30.3057C49.5857 39.3474 43.5626 47.2476 34.9385 49.5179L34.2979 49.6869L34.4646 50.3362L35.2442 53.3717L35.411 54.0203L36.051 53.852C38.5996 53.1813 41.01 52.0927 43.2138 50.6172L43.2131 50.6165Z" fill="#476FFC"/>
<path opacity="0.08" d="M5.21377 30.403C5.21377 28.4063 5.4454 26.4197 5.90271 24.4989L6.05758 23.8469L5.41496 23.6893L1.63269 22.7631L0.988749 22.6055L0.833223 23.2581C0.280609 25.5827 0 27.9865 0 30.403C0 33.7612 0.536731 37.061 1.59563 40.2113C2.61946 43.2569 4.11119 46.1087 6.03044 48.6889C7.92786 51.2396 10.1979 53.4636 12.7763 55.2993C15.3911 57.1605 18.2614 58.577 21.3084 59.51L21.9418 59.7038L22.1337 59.0619L23.2608 55.2879L23.4527 54.6454L22.8187 54.4516C12.4533 51.2771 5.21443 41.3877 5.21443 30.403H5.21377Z" fill="#476FFC"/>
<path opacity="0.08" d="M11.5442 12.6339L12.0121 13.1081L12.48 12.6339C17.1604 7.89412 23.3827 5.28376 29.9995 5.28376C36.6164 5.28376 43.5125 8.22075 48.1908 13.3415L48.6402 13.8338L49.126 13.3784L51.981 10.7003L52.4668 10.2449L52.0174 9.75262C50.6481 8.25361 49.1253 6.89008 47.49 5.69892C45.8407 4.4977 44.0671 3.46416 42.2179 2.6278C38.3649 0.88398 34.2537 0 30.0002 0C25.7467 0 22.026 0.802826 18.3285 2.38702C14.7573 3.91688 11.5502 6.10671 8.79505 8.89547L8.32715 9.36965L8.79505 9.84384L11.5449 12.6333L11.5442 12.6339Z" fill="#476FFC"/>
<path opacity="0.08" d="M59.4178 24.4197L59.2881 23.7617L58.6388 23.8938L54.8248 24.6692L54.1762 24.8013L54.306 25.4586C54.6249 27.0756 54.7864 28.739 54.7864 30.403C54.7864 41.8458 47.1637 51.8445 36.249 54.7171L35.6084 54.8861L35.7752 55.5354L36.7553 59.351L36.9221 59.9995L37.5621 59.8312C40.7474 58.9928 43.7593 57.6327 46.5144 55.7882C49.2246 53.974 51.6157 51.7399 53.6216 49.147C55.6474 46.5286 57.2252 43.6157 58.3093 40.4896C59.431 37.2555 60.0002 33.8618 60.0002 30.4023C60.0002 28.3875 59.8043 26.3748 59.4185 24.4197H59.4178Z" fill="#476FFC"/>
<path d="M29.9997 43.2787C37.0697 43.2787 42.8012 37.4703 42.8012 30.3053C42.8012 23.1404 37.0697 17.332 29.9997 17.332C22.9296 17.332 17.1982 23.1404 17.1982 30.3053C17.1982 37.4703 22.9296 43.2787 29.9997 43.2787Z" fill="#476FFC"/>
</g>
<defs>
<clipPath id="clip0_18135_29036">
<rect width="60" height="60" fill="white"/>
</clipPath>
</defs>
</svg>
`);

function getMarkerImage(
  regionData: Pick<Region, 'inactive' | 'totalCount' | 'environmentsCount' | 'resourcesCount' | 'servicesCount'>,
  useSuccessMarkers: boolean,
  useFailureMarkers: boolean,
) {
  if (useSuccessMarkers) {
    return successfulMarkerSvg;
  }

  if (useFailureMarkers) {
    return inactiveMarkerSvg;
  }

  if (regionData.inactive) {
    return inactiveMarkerSvg;
  }

  return successfulMarkerSvg;
}

function RegionsMap(props: Props) {
  const { data: regions, useFailureMarkers, useSuccessMarkers, loading } = props;

  const idRef = useRef(`map-${Date.now()}`);
  const mapContainerRef = useRef();
  const [isContainerAvailable, setIsContainerAvailable] = useState(false);
  const [map, setMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const markersRef = useRef(markers);
  markersRef.current = markers;

  const markerClustererRef = useRef<MarkerClusterer | null>(null);

  const kmlLayersRef = useRef<google.maps.KmlLayer[]>([]);

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipPositionRef = useRef({
    x: 0,
    y: 0,
  });
  const tooltipPopperRef = useRef<any>(null);
  const [tooltipRegion, setTooltipRegion] = useState<
    Pick<Region, 'regionName' | 'totalCount' | 'environmentsCount' | 'resourcesCount' | 'servicesCount'>
  >({ regionName: '', totalCount: 0, environmentsCount: 0, resourcesCount: 0, servicesCount: 0 });

  const setMapContainerRef = useCallback(ref => {
    mapContainerRef.current = ref;
    setIsContainerAvailable(true);
  }, []);

  const initializeTooltip = useCallback(
    (
      marker: google.maps.Marker,
      region: Pick<Region, 'regionName' | 'totalCount' | 'environmentsCount' | 'resourcesCount' | 'servicesCount'>,
      onMarkerHover: () => void,
      onMarkerMouseOut: () => void,
    ) => {
      marker.addListener('mouseover', (e: any) => {
        setTooltipRegion(region);

        const rect = e.domEvent.target.getBoundingClientRect();

        tooltipPositionRef.current.x = rect.left + e.domEvent.target.width / 2;
        tooltipPositionRef.current.y = rect.top + e.domEvent.target.width / 3;

        tooltipPopperRef.current?.update();
        onMarkerHover();
      });

      marker.addListener('mouseout', () => {
        onMarkerMouseOut();
      });
    },
    [],
  );

  useEffect(() => {
    if (map) return;
    if (!isContainerAvailable) return;

    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      version: '3.54',
    });

    loader.load().then(async () => {
      const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;

      const mapControls = {
        zoomControl: true,
        scaleControl: false,
        rotateControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
      };

      const map = new Map(document.getElementById(idRef.current) as HTMLElement, {
        styles: mapStyle,
        center: { lat: 33, lng: 5 },
        minZoom: 1.6,
        maxZoom: 5,
        zoom: 1.6,
        restriction: {
          latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        },
        controlSize: 32,
        ...mapControls,
      });

      setMap(map);
    });
  }, [isContainerAvailable, map]);

  useEffect(() => {
    if (!map) return;

    // Clean up previous markers and clusterer
    for (const marker of markersRef.current || []) {
      marker.setMap(null);
      markersRef.current = [];
    }

    if (markerClustererRef.current) {
      markerClustererRef.current.removeMarkers(markersRef.current);
      markerClustererRef.current.setMap(null);
      markerClustererRef.current = null;
    }

    if (kmlLayersRef.current) {
      for (const layer of kmlLayersRef.current || []) {
        layer.setMap(null);
      }

      kmlLayersRef.current = [];
    }

    const _markers: google.maps.Marker[] = [];

    const initializeMarkers = () => {
      for (const region of regions) {
        const _marker = new google.maps.Marker({
          position: {
            lat: Number(region.lat),
            lng: Number(region.lng),
          },
          map,
          icon: {
            url: `data:image/svg+xml;base64,${getMarkerImage(
              region,
              Boolean(useSuccessMarkers),
              Boolean(useFailureMarkers),
            )}`,
            scaledSize: new google.maps.Size(MARKER_SIZE, MARKER_SIZE),
          },
          label: {
            text: String(contractNumber(region.totalCount || 0)),
            color: 'rgba(255,255,255,0.9)',
            fontSize: MARKER_FONT_SIZE,
          },
        });

        const marker: CustomMarker = _marker;
        marker.customData = {
          regionName: region.regionName,
          inactive: region.inactive,
          totalCount: region.totalCount,
          environmentsCount: region.environmentsCount,
          resourcesCount: region.resourcesCount,
          servicesCount: region.servicesCount,
        };

        initializeTooltip(
          marker,
          region,
          () => setShowTooltip(true),
          () => setShowTooltip(false),
        );
        _markers.push(marker);
      }

      setMarkers(_markers);

      const markerClusterer = new MarkerClusterer({
        markers: _markers,
        map,
        renderer: {
          render(cluster, stats, map) {
            const { count, position } = cluster;

            const sumCount = ((cluster.markers || []) as unknown as CustomMarker[]).reduce(
              (acc, curr) => acc + (curr.customData?.totalCount || 0),
              0,
            );

            // We preserve `undefined` values and don't default to 0. This is used for the tooltip text.
            const mergedData = ((cluster.markers || []) as unknown as CustomMarker[]).reduce(
              (acc, curr) => ({
                ...acc,
                inactive: Boolean(acc.inactive || curr.customData?.inactive),
                totalCount:
                  typeof curr.customData?.totalCount === 'number'
                    ? (acc.totalCount || 0) + (curr.customData?.totalCount || 0)
                    : undefined,
                environmentsCount:
                  typeof curr.customData?.environmentsCount === 'number'
                    ? (acc.environmentsCount || 0) + (curr.customData?.environmentsCount || 0)
                    : undefined,
                resourcesCount:
                  typeof curr.customData?.resourcesCount === 'number' || typeof acc.resourcesCount === 'number'
                    ? (acc.resourcesCount || 0) + (curr.customData?.resourcesCount || 0)
                    : undefined,
                servicesCount:
                  typeof curr.customData?.servicesCount === 'number' || typeof acc.servicesCount === 'number'
                    ? (acc.servicesCount || 0) + (curr.customData?.servicesCount || 0)
                    : undefined,
              }),
              {
                regionName: ((cluster.markers || []) as unknown as CustomMarker[])
                  .map(m => m.customData?.regionName)
                  .join(', '),
                inactive: false,
                totalCount: undefined,
                environmentsCount: undefined,
                resourcesCount: undefined,
                servicesCount: undefined,
              } as Pick<
                Region,
                'regionName' | 'inactive' | 'totalCount' | 'environmentsCount' | 'resourcesCount' | 'servicesCount'
              >,
            );

            const marker = new google.maps.Marker({
              position,
              map,
              icon: {
                url: `data:image/svg+xml;base64,${getMarkerImage(
                  mergedData,
                  Boolean(useSuccessMarkers),
                  Boolean(useFailureMarkers),
                )}`,
                scaledSize: new google.maps.Size(MARKER_SIZE, MARKER_SIZE),
              },
              label: {
                text: String(contractNumber(sumCount)),
                color: 'rgba(255,255,255,0.9)',
                fontSize: MARKER_FONT_SIZE,
              },
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            });

            initializeTooltip(
              marker,
              mergedData,
              () => setShowTooltip(true),
              () => setShowTooltip(false),
            );

            return marker;
          },
        },
      });

      markerClustererRef.current = markerClusterer;
    };

    // const initializeCountriesKml = (map: google.maps.Map) => {
    //   const countryKmls: { [key: string]: string } = {
    //     AUSTRALIA: 'https://storage.googleapis.com/map-kml/kml/australia.kml',
    //     BAHRAIN: 'https://storage.googleapis.com/map-kml/kml/bahrain.kml',
    //     BRAZIL: 'https://storage.googleapis.com/map-kml/kml/brazil.kml',
    //     CANADA: 'https://storage.googleapis.com/map-kml/kml/canada.kml',
    //     CHINA: 'https://storage.googleapis.com/map-kml/kml/china.kml',
    //     FRANCE: 'https://storage.googleapis.com/map-kml/kml/france.kml',
    //     GERMANY: 'https://storage.googleapis.com/map-kml/kml/germany.kml',
    //     INDIA: 'https://storage.googleapis.com/map-kml/kml/india.kml',
    //     INDONESIA: 'https://storage.googleapis.com/map-kml/kml/indonesia.kml',
    //     IRELAND: 'https://storage.googleapis.com/map-kml/kml/ireland.kml',
    //     ISRAEL: 'https://storage.googleapis.com/map-kml/kml/israel.kml',
    //     ITALY: 'https://storage.googleapis.com/map-kml/kml/italy.kml',
    //     JAPAN: 'https://storage.googleapis.com/map-kml/kml/japan.kml',
    //     SINGAPORE: 'https://storage.googleapis.com/map-kml/kml/singapore.kml',
    //     SOUTH_AFRICA: 'https://storage.googleapis.com/map-kml/kml/south-africa.kml',
    //     SOUTH_KOREA: 'https://storage.googleapis.com/map-kml/kml/south-korea.kml',
    //     SPAIN: 'https://storage.googleapis.com/map-kml/kml/spain.kml',
    //     SWEDEN: 'https://storage.googleapis.com/map-kml/kml/sweden.kml',
    //     SWITZERLAND: 'https://storage.googleapis.com/map-kml/kml/switzerland.kml',
    //     UAE: 'https://storage.googleapis.com/map-kml/kml/uae.kml',
    //     ENGLAND: 'https://storage.googleapis.com/map-kml/kml/uk.kml',
    //     USA: 'https://storage.googleapis.com/map-kml/kml/usa.kml',
    //   };

    //   const layers = [];

    //   for (const country of regions) {
    //     const url = countryKmls[country.country];

    //     if (url) {
    //       const kmlLayer = new google.maps.KmlLayer({
    //         url,
    //         map: map,
    //         preserveViewport: true,
    //         suppressInfoWindows: true,
    //       });

    //       layers.push(kmlLayer);
    //     }
    //   }

    //   kmlLayersRef.current = layers;
    // };

    map.addListener('zoom_changed', () => {
      // const zoomLevel = map.getZoom() || 3;
    });

    map.addListener('drag', () => {
      setShowTooltip(false);
    });

    initializeMarkers();
    // initializeCountriesKml(map);
  }, [regions, isContainerAvailable, map, useSuccessMarkers, useFailureMarkers, initializeTooltip]);

  return (
    <Box width="100%" flexGrow={1} position="relative">
      <Box id={idRef.current} ref={setMapContainerRef} component="div" width="100%" height="100%"></Box>

      {loading ? <FullscreenLoader useAbsolutePosition /> : null}

      <HtmlTooltip
        title={
          <>
            <Box marginBottom="4px">
              <Text fontWeight={700} className="fs__1">
                {tooltipRegion.regionName}
              </Text>
            </Box>

            {/* {typeof tooltipRegion.totalCount === 'number' ? (
              <Box marginBottom="4px">
                <Text className="fs__1">Environments: {tooltipRegion.environmentsCount}</Text>
              </Box>
            ) : null} */}

            {typeof tooltipRegion.resourcesCount === 'number' ? (
              <Box marginBottom="4px">
                <Text className="fs__1">Resources: {tooltipRegion.resourcesCount}</Text>
              </Box>
            ) : null}

            {typeof tooltipRegion.servicesCount === 'number' ? (
              <Box marginBottom="4px">
                <Text className="fs__1">Services: {tooltipRegion.servicesCount}</Text>
              </Box>
            ) : null}
          </>
        }
        placement="top"
        arrow
        open={showTooltip}
        disableFocusListener
        disableHoverListener
        disableInteractive
        PopperProps={{
          tooltipPopperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(tooltipPositionRef.current.x, tooltipPositionRef.current.y, 0, 0);
            },
          },
        }}>
        <div></div>
      </HtmlTooltip>
    </Box>
  );
}

const HtmlTooltip = styled(({ className, ...props }: any) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 240,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '8px',
      borderRadius: '8px',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#fff',
    },
  }),
);

export default RegionsMap;
export { type Region };
