import React from "react";

import { azure_icon } from "../../../components/utilities/CloudIconsUtil";
import aws_icon from "@root/assets/img/cloud_provider_icons/aws_icon.png";
import CountTooltip from "@root/components/table/cell-types/CountTooltip";
import gcp_icon from "@root/assets/img/header/cloud_providers/gcp.png";

const EnvCellComponent = ({
  cloud,
  environmentName,
  environmentId,
  environmentTags,
}) => {
  return (
    <div className="d-flex" style={{ overflow: "hidden" }}>
      <div className="d-flex align-items-center">
        {cloud === "aws" && (
          <img src={aws_icon} alt="AWS" style={{ height: "20px" }} />
        )}
        {cloud === "azure" && (
          <img src={azure_icon} alt="AZURE" style={{ height: "20px" }} />
        )}
        {cloud === "gcp" && (
          <img src={gcp_icon} alt="GCP" style={{ height: "20px" }} />
        )}
      </div>
      <div className="d-flex flex-column align-items-start ml-2 flex-grow-1 overflow-hidden">
        <div>{environmentName}</div>
        <div>{environmentId}</div>
        {environmentTags && environmentTags.length ? (
          <CountTooltip
            labels={environmentTags}
            width="auto"
            containerClassName="RemediationPolicyTable__EnvironmentTag"
          />
        ) : null}
      </div>
    </div>
  );
};

export default EnvCellComponent;
