import { Grid, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Attack_Path_Flow from '@root/assets/svg/priority_funnel/Attack_Path_Flow.svg';
import Recommendation from '@root/assets/svg/priority_funnel/Recommendation.svg';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
// import Wave from '@root/assets/svg/priority_funnel/Wave.svg';
import NoData from '@root/components/common/NoData';
import ContentBox from '@root/components/v2/content/ContentBox';
import Text from '@root/components/v2/ui-elements/Text';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import new_label from '@root/assets/img/newTag.svg';
import './components.scss';
import SankeyChart from './sankey';
// import SankeyDiagram from './SankeyChart';

// const SAMPLE_DATA = {
//   error: false,
//   data: [
//     [
//       'Misconfiguration',
//       'Data Exposure',
//       [
//         ['417460b2819bf2e63eec5e0085bfba3b3db5a9eb', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['417460b2819bf2e63eec5e0085bfba3b3db5a9eb', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['5f77c68e78a776f23cf92d18471a82703f30a173', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['c466c204008e064d9f8bfbb7d7d250c17249d520', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['6871c8e1346bda9f0145f244d1d03bab2ea2b1d5', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['7e6b3167652bf02220443d7b9904ca64db299977', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['e36c9d3c7a4c7a5637d50715da200d8bf6009859', 'AWS.S3.Bucket', ['ap-south-1']],
//       ],
//     ],
//     [
//       'Data Exposure',
//       'critical',
//       [
//         ['417460b2819bf2e63eec5e0085bfba3b3db5a9eb', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['417460b2819bf2e63eec5e0085bfba3b3db5a9eb', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['5f77c68e78a776f23cf92d18471a82703f30a173', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['c466c204008e064d9f8bfbb7d7d250c17249d520', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['6871c8e1346bda9f0145f244d1d03bab2ea2b1d5', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['7e6b3167652bf02220443d7b9904ca64db299977', 'AWS.S3.Bucket', ['ap-south-1']],
//         ['e36c9d3c7a4c7a5637d50715da200d8bf6009859', 'AWS.S3.Bucket', ['ap-south-1']],
//       ],
//     ],
//     [
//       'Identity',
//       'Admin Access Compromise',
//       [
//         ['AIDA5WHJCOVMQFQG3POPT', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMSLRD4JVJ6', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMW47SVNFKN', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMW7QLEEQAT', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMY35BWXTWL', 'AWS.IAM.User', ['global']],
//       ],
//     ],
//     [
//       'Admin Access Compromise',
//       'critical',
//       [
//         ['AIDA5WHJCOVMQFQG3POPT', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMSLRD4JVJ6', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMW47SVNFKN', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMW7QLEEQAT', 'AWS.IAM.User', ['global']],
//         ['AIDA5WHJCOVMY35BWXTWL', 'AWS.IAM.User', ['global']],
//       ],
//     ],
//   ],
//   risk_engines: {
//     Misconfiguration: 1380,
//     'Exposed Secrets': 4,
//     'Threat/Anomaly': 0,
//     Malware: 0,
//     Vulnerabilities: 554,
//     Identity: 946,
//   },
// };

const SEVERITIES = ['critical', 'high', 'medium', 'low'];

function makeSankeyData(data) {
  const riskEngines = data?.risk_engines || {};
  const connections = data?.data || [];

  const existingNodes = {};
  const existingLinks = {};

  const nodes = [];
  const links = [];

  const max = Object.keys(riskEngines).reduce((acc, key) => Math.max(riskEngines[key], acc), 0);

  for (const connection of connections) {
    if (!existingNodes[connection[0]]) {
      nodes.push({
        id: connection[0],
        name: connection[0],
        count: riskEngines[connection[0]] || undefined,
      });
      existingNodes[connection[0]] = true;
    }

    if (!existingNodes[connection[1]]) {
      let severityResources = [];

      // Check if the target node is a severity node
      if (SEVERITIES.includes(connection[1])) {
        // Get all the resources with the same severity nodes in all connections and merge them to get the total count for this specific severity.
        for (const c of connections) {
          if (SEVERITIES.includes(c[1]) && c[1] == connection[1]) {
            severityResources = [...severityResources, ...c[2]];
          }
        }
      }

      severityResources = severityResources.reduce(
        (acc, curr) => (acc.find(item => item[0] === curr[0]) ? acc : [...acc, curr]),
        [],
      ); // Remove duplicates

      nodes.push({
        id: connection[1],
        name: connection[1],
        count: SEVERITIES.includes(connection[1]) ? severityResources.length : undefined,
      });
      existingNodes[connection[1]] = true;
    }

    if (!existingLinks[`${connection[0]}-${connection[1]}`]) {
      links.push({
        source: connection[0],
        target: connection[1],
        // value: 10,
        value: Math.max(riskEngines[connection[0]] || 0, max * 0.25),
      });

      existingLinks[`${connection[0]}-${connection[1]}`] = true;
    }
  }

  // Add fake nodes and links for the risk engines which aren't added as nodes above.

  let isFakeMiddleNodeAdded = false;

  for (const key in riskEngines) {
    const isNodeAdded = nodes.find(n => n.id === key);

    if (!isNodeAdded) {
      // We add fake nodes for riskEngines items. These nodes will connect to one of the attack path nodes.

      nodes.push({
        id: key,
        name: key,
        count: riskEngines[key],
        value: max,
        placeAtBottom: true,
      });

      if (!isFakeMiddleNodeAdded) {
        nodes.push({
          id: 'fake-middle-node',
          name: '',
          value: Math.max(riskEngines[key] || 0, max * 0.25),
          isFakeNode: true,
          placeAtBottom: true,
          depth: 0,
        });

        nodes.push({
          id: 'fake-right-node',
          name: '',
          value: Math.max(riskEngines[key] || 0, max * 0.25),
          isFakeNode: true,
          placeAtBottom: true,
          depth: 0,
        });

        links.push({
          source: 'fake-middle-node',
          target: 'fake-right-node',
          value: Math.max(riskEngines[key] || 0, max * 0.25),
          isFakeLink: true,
        });

        isFakeMiddleNodeAdded = true;
      }

      links.push({
        source: key,
        target: 'fake-middle-node',
        value: Math.max(riskEngines[key] || 0, max * 0.25),
        isFakeLink: true,
      });
    }
  }

  return { nodes, links };
}

const PrioritizationFunnel = ({ data, isFetchingPrioritizationFunnel, cloudProvider }) => {
  const history = useHistory();
  const [selectedTab, setActiveTab] = useState('All');
  const sankeyData = useMemo(() => {
    return makeSankeyData(data);
  }, [data]);

  const totalCount = useMemo(() => {
    return (data?.data || [])
      .map(connections => connections[2])
      .flat()
      .reduce((acc, curr) => (acc.find(item => item[0] === curr[0]) ? acc : [...acc, curr]), []).length; // reduce to remove duplicates
  }, [data]);

  const handleTabChange = useCallback(provider => {
    setActiveTab(provider);
  }, []);

  const handleClick = useCallback(
    e => {
      handleTabChange(e.currentTarget.dataset.cloudProvider);
    },
    [handleTabChange],
  );

  const handleAttackPathClick = useCallback(
    attackPath => {
      const connectionsContainingAttackPath = (data?.data || []).filter(
        d => d[0] === attackPath || d[1] === attackPath,
      );

      const resourceIds = connectionsContainingAttackPath
        .map(c => c[2])
        .flat()
        .map(d => d[0])
        .reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []); // Remove duplicates

      const resourceTypes = connectionsContainingAttackPath
        .map(c => c[2])
        .flat()
        .map(d => d[1])
        .reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []); // Remove duplicates

      const regions = connectionsContainingAttackPath
        .map(c => c[2])
        .flat()
        .map(d => d[2])
        .flat()
        .reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []); // Remove duplicates

      history.push(
        buildBreadcrumbUrl({
          path: `/inventory/resources-table?id=${resourceIds.join(',')}&resource_type=${resourceTypes.join(
            ',',
          )}&regions=${regions.join(',')}&from=sankeyChart`,
          breadcrumbs: [
            {
              title: 'Dashboard',
              path: '/dashboard',
            },
            {
              title: 'Prioritization Funnel',
            },
          ],
        }),
      );
    },
    [history, data],
  );

  return (
    <Grid>
      <ContentBox
        // title="Prioritization Funnel"
        title={
          <div style={{ display: 'flex' }}>
            <span style={{ fontSize: '16px', fontWeight: 700, marginRight: '10px' }}>Prioritization Funnel</span>

            <img src={new_label} />
          </div>
        }
        titleSize="medium"
        height="100%"
        borderBottom="0px"
        padding="0px"
        titleContainerStyle={{
          border: '0px',
          fontSize: '16px !important',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          padding: '15px',
          margin: '0px',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        containerClassName={'border-box-no-pad'}>
        {cloudProvider === 'azure' ? (
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingTop="88px"
            paddingBottom="88px">
            <img src="/images/visualizer/coming-soon.png" alt="" style={{ width: 120 }} />
          </Box>
        ) : (
          // :(<NoData height={'442px'} />)
          <Grid className="dashboard_component" sx={{ height: '100%' }}>
            <div
              className="CloudProviderBar"
              style={{
                marginBottom: '0',
                paddingLeft: '15px',
                paddingRight: '15px',
                alignItems: 'stretch',
                height: 'initial',
              }}>
              <div
                className={`CloudProviderBar__Option_CE ${selectedTab === 'All' ? 'Active' : ''}`}
                data-cloud-provider="All"
                style={{ marginRight: '30px' }}
                onClick={handleClick}>
                <img src={Attack_Path_Flow} style={{ width: '12px', height: '12px' }} />
                <Text font="medium" className="bar_text">
                  Attack Path Flow
                </Text>
              </div>
              <div
                className={`CloudProviderBar__Option_CE ${selectedTab === 'Drift' ? 'Active' : ''}`}
                data-cloud-provider="Drift"
                onClick={handleClick}>
                <img src={Recommendation} style={{ width: '12px', height: '12px' }} />
                <Text font="medium" className="bar_text">
                  Recommendation
                </Text>
              </div>

              <Box flexGrow={1} />

              {/* {rightElement} */}
            </div>
            {isFetchingPrioritizationFunnel ? (
              <>
                <div style={{ width: '100%', height: '400px', padding: '40px 15px' }}>
                  {Array.from(new Array(3)).map((i, index) => (
                    <Box key={i} width="100%" display="flex" flexDirection="column" gap={'8px'}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        marginTop={index === 0 ? '0px' : '10px'}>
                        <Skeleton width="300px" height="100px" />
                        <Skeleton width="300px" height="100px" />
                        <Skeleton width="300px" height="100px" />
                      </Box>
                    </Box>
                  ))}
                </div>
              </>
            ) : (
              <Grid sx={{ padding: '15px' }}>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                    fontFamily: 'Manrope',
                  }}>
                  <p className="priority-funel-textb m-0"> Risk Engines</p>
                  <p className="priority-funel-textb m-0 pr-4"> Attack paths</p>
                  <p className="priority-funel-textb m-0"> Severity </p>
                </Grid>
                {/* <Grid className="card-bg-image" sx={{ height: '474px', marginTop: '15px' }}></Grid> */}
                {/* <SankeyDiagram /> */}
                {!isFetchingPrioritizationFunnel ? (
                  // sankeyData?.nodes?.length && data?.data?.length !== 0 ? (
                  sankeyData?.nodes?.length ? (
                    <SankeyChart data={sankeyData} totalCount={totalCount} onAttackPathClick={handleAttackPathClick} />
                  ) : (
                    <NoData message="No Data" height="500px" />
                  )
                ) : null}
                {/* <NivoSankeyChartMain data={data} /> */}
                {/* <div class="image-container">
                    <img src={Wave} alt="Description of the image" class="cover-image" />
                  </div> */}
              </Grid>
            )}
          </Grid>
        )}
      </ContentBox>
    </Grid>
  );
};
export default PrioritizationFunnel;
