import { Box, Skeleton } from '@mui/material';
// import EntitlmentsGraph from '@root/pages/identity/pages/users/DetailsPanel/EntitlmentsGraph';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
// mport './charts.scss';
import useTimeFilter from '@root/hooks/SelectTimeFilter';
import moment from 'moment';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import agent from '@root/agent';
import ContentBox from '@root/components/v2/content/ContentBox';

const CustomLegend = ({ totalCounts }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '85px',
        display: 'flex',
        marginTop: '16px',
        gap: '8px',
        padding: '0px 10px',
        marginLeft: '16px',
      }}>
      <ContentBox
        style={{
          flex: '1',
          border: '1px solid #CBD5E0',
          borderRadius: '8px',
          padding: '12px 0px 12px 12px',
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Manrope',
        }}>
        <Box sx={{ display: 'flex', height: '20px', alignItems: 'center', gap: '9px' }}>
          <Box
            sx={{
              height: '12px',
              width: '12px',
              borderRadius: '12px',
              backgroundColor: '#CD0404',
            }}>
            {' '}
          </Box>
          <Box
            sx={{
              fontWeight: '600',
              color: ' rgb(26, 32, 44)',
              fontSize: '14px',
            }}>
            Threats Triggered
          </Box>
        </Box>
        <Box
          sx={{
            width: '42px',
            height: '33px',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '32px',
            color: '#242424',
          }}>
          {totalCounts.created}
        </Box>
      </ContentBox>
      <ContentBox
        style={{
          flex: '1',
          border: '1px solid #CBD5E0',
          borderRadius: '8px',
          padding: '12px 0px 12px 12px',
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Manrope',
        }}>
        <Box sx={{ display: 'flex', height: '20px', alignItems: 'center', gap: '9px' }}>
          <Box
            sx={{
              height: '12px',
              width: '12px',
              borderRadius: '12px',
              backgroundColor: '#30E0A1',
            }}>
            {' '}
          </Box>
          <Box
            sx={{
              fontWeight: '600',
              color: ' rgb(26, 32, 44)',
              fontSize: '14px',
            }}>
            Threats Resolved
          </Box>
        </Box>
        <Box
          sx={{
            width: '42px',
            height: '33px',
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '32px',
            color: '#242424',
          }}>
          {totalCounts.resolved}
        </Box>
      </ContentBox>
    </Box>
  );
};
const CustomTooltip = ({ payload }) => {
  if (payload && payload.length) {
    const { created, resolved, detectiondate } = payload[0].payload;

    return (
      <div className="custom_tooltip" style={{ outline: 0 }}>
        <p className="custom_tooltip_count m-0 p-0">{detectiondate}</p>
        <div className="count_container">
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #CD0404', paddingLeft: '4px', borderRadius: '1px' }}>
            Created: <span className="custom_tooltip_count"> {created ? created : '0'}</span>
          </p>
          <p
            className="custom_tooltip_severity m-0"
            style={{ borderLeft: '4px solid #30E0A1', paddingLeft: '4px', borderRadius: '1px' }}>
            Resolved: <span className="custom_tooltip_count"> {resolved ? resolved : '0'}</span>
          </p>
        </div>
      </div>
    );
  }
  return null;
};

const ThreatExposed = ({ cloudProvider, dateFilter }) => {
  const { timeFilter } = useTimeFilter();
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  const queryEnabled = useMemo(
    () =>
      Boolean(
        (selectedEnvironments?.length && cloudProvider === 'all') || cloudProvider === 'aws' || cloudProvider === 'gcp',
      ),
    [cloudProvider, selectedEnvironments],
  );
  // function kFormatter(num) {
  //   return Math.abs(num) > 999
  //     ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
  //     : Math.sign(num) * Math.abs(num);
  // }

  const { data: resource_compliance_trends, isFetching } = useQuery(
    ['threat-graph', selectedEnvironmentTags, selectedEnvironments?.map(e => e.id) || [], cloudProvider, dateFilter],
    () =>
      agent.Dashboard_New.get_threats_graph_data({
        scope: selectedEnvironmentTags,
        environments: selectedEnvironments?.map(e => e.id) || [],
        cloud_provider: cloudProvider,
        date_filter: dateFilter,
      }),
    {
      enabled: queryEnabled,
    },
  );

  const ChartData = useMemo(() => {
    return resource_compliance_trends?.data.map(item => ({
      detectiondate:
        timeFilter === '24h'
          ? moment(item.detection_date ? item.detection_date : moment()).format('HH:mm')
          : moment(item.detection_date ? item.detection_date : moment()).format('D MMM'),
      created: item.events.created,
      resolved: item.events.resolved,
    }));
  }, [resource_compliance_trends, timeFilter]);

  const totalCounts = useMemo(() => {
    return {
      created: resource_compliance_trends?.data.reduce((prev, curr) => prev + curr.events.created, 0),
      resolved: resource_compliance_trends?.data.reduce((prev, curr) => prev + curr.events.resolved, 0),
    };
  }, [resource_compliance_trends]);

  if (isFetching) {
    return (
      <ContentBox
        titleSize="medium"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        paddingMode="content"
        containerClassName={'border-box-no-pad'}
        height="100%"
        style={{
          height: '518px',
          width: '100%',
          border: '1px solid #CBD5E0',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',

              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '44px',
              borderBottom: '1px solid #CBD5E0',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Box
                sx={{
                  height: '44px',
                  color: '#1A202C',
                  fontSize: '14px',
                  fontWeight: 600,
                  paddingTop: '12px',
                  fontFamily: 'Manrope',
                  lineHeight: '17px',
                  paddingLeft: '12px',
                }}>
                Threats - Created vs Resolved
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '448px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '16px',
            }}>
            <Box height={'320px'} width={'100%'}>
              <Skeleton variant="rectangular" height={'320px'} width={'100%'} />
            </Box>
            <Box sx={{ display: 'flex', gap: ' 10px' }}>
              <Box sx={{ flex: 1, height: '130px' }}>
                <Skeleton width={'100%'} height={'130px'} />
              </Box>
              <Box sx={{ flex: 1, height: '130px' }}>
                <Skeleton width={'100%'} height={'130px'} />
              </Box>
            </Box>
          </Box>
        </div>
      </ContentBox>
    );
  }
  return (
    <ContentBox
      titleSize="medium"
      titleContainerStyle={{
        // border: "0px",
        fontSize: '16px !important',
        color: 'var(--keyinsight-text-color)',
        // background: '#F7FAFC',
        // backgroundColor: 'var(--keyinsight-box-header-background)',
      }}
      paddingMode="content"
      containerClassName={'border-box-no-pad'}
      height="100%"
      style={{
        height: '518px',
        width: '100%',
        border: '1px solid #CBD5E0',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div
        style={{
          height: '95%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          sx={{
            display: 'flex',

            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '44px',
            borderBottom: '1px solid #CBD5E0',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Box
              sx={{
                height: '44px',
                color: '#1A202C',
                fontSize: '14px',
                fontWeight: 600,
                paddingTop: '12px',
                fontFamily: 'Manrope',
                lineHeight: '17px',
                paddingLeft: '12px',
              }}>
              Threats - Created vs Resolved
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '440px',
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '16px',
            paddingRight: '24px',
            paddingLeft: '16px',
          }}>
          {/* <EntitlmentsGraph data={} /> */}
          <ResponsiveContainer height={440} width={'104%'} style={{ marginLeft: '-25px' }}>
            <LineChart data={ChartData}>
              <CartesianGrid vertical horizontal={false} />
              <XAxis dataKey="detectiondate" minTickGap={30} />
              <YAxis padding={{ right: 30 }} />
              <Legend content={<CustomLegend data={ChartData} totalCounts={totalCounts} />} />
              <Line type="category" dataKey="resolved" stroke="#30E0A1" name="Resolved" dot={false} strokeWidth={2} />
              <Line type="category" dataKey="created" stroke="#CD0404" name="Created" strokeWidth={2} dot={false} />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </div>
    </ContentBox>
  );
};

export default ThreatExposed;
