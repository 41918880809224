import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import './count-tooltip.scss';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.85rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: '#fafafa',
  },
}))(Tooltip);

function CountTooltip({ labels, showFirst = true }) {
  if (!labels || !labels.length) return null;

  return (
    <div>
      {
        showFirst ?
          labels[0] : null}

      {labels.length > 1 ? (
        <HtmlTooltip
          title={
            <div className="CountTooltip__Content">
              {labels.slice(1).map(label => (
                <div className="CountTooltip__Item" key={label}>
                  {label}
                </div>
              ))}
            </div>
          }
          arrow>
          {
            showFirst ?
              <span className="CountTooltip__CountContainer"> +{labels.length - 1}</span> :
              <span className="CountTooltip__CountContainer"> {labels.length}</span>
          }
        </HtmlTooltip>
      ) : null}
    </div>
  );
}

export default CountTooltip;