import { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/material';

import Text from '@root/components/v2/ui-elements/Text';

import { ReactComponent as BetaIcon } from '@assets/img/environment-tags/beta.svg';
import { ReactComponent as DevIcon } from '@assets/img/environment-tags/dev.svg';
import { ReactComponent as HfIcon } from '@assets/img/environment-tags/hf.svg';
import { ReactComponent as ProdIcon } from '@assets/img/environment-tags/prod.svg';
import { ReactComponent as TestIcon } from '@assets/img/environment-tags/test.svg';

import './style.scss';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.85rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: '#f7f7f7',
  },
}))(Tooltip);

function CountTooltip({
  showFirst = true,
  riskExpandTable = false,
  riskExpandRegionTable = false,
  labels,
  fontSize,
  borderless,
  visibleLabelClassName,
  visibleCountClassName,
  containerStyle = {},
  visibleLabelStyle = {},
}) {
  const VisibleLabelIcon = useMemo(() => {
    const visibleLabel = labels?.[0] || null;

    const icons = {
      Production: ProdIcon,
      'Hot Fix': HfIcon,
      'Hot-Fix': HfIcon,
      Staging: BetaIcon,
      Testing: TestIcon,
      Development: DevIcon,
    };

    return icons[visibleLabel];
  }, [labels]);

  if (!labels || !labels.length) return null;

  return (
    <div className={`CountTooltip2 ${borderless ? 'CountTooltip2-Borderless' : ''}`} style={{ ...containerStyle }}>
      {showFirst && (
        <HtmlTooltip title={<div className="CountTooltip2__Content">{labels[0]}</div>} arrow>
          {VisibleLabelIcon ? (
            <Box>
              <VisibleLabelIcon />
            </Box>
          ) : (
            <Text
              element="div"
              fontSize={fontSize || '12.25px'}
              className={`CountTooltip2__VisibleLabel SingleLine ${visibleLabelClassName || ''}`}
              style={{ ...visibleLabelStyle }}>
              {labels[0]}
            </Text>
          )}
        </HtmlTooltip>
      )}
      {labels.length > 1 ? (
        <HtmlTooltip
          title={
            <div className="CountTooltip2__Content">
              {labels.slice(1).map(label => (
                <Text className="CountTooltip2__Item" fontSize={fontSize || '12.25px'} key={label}>
                  {riskExpandTable || riskExpandRegionTable ? `${label.Key}: ${label.Value}` : label}
                </Text>
              ))}
            </div>
          }
          arrow>
          <Text
            element="span"
            fontSize={fontSize || '12.25px'}
            fontWeight={600}
            className={`CountTooltip2__CountContainer ${visibleCountClassName || ''}`}>
            +{labels.length - 1}
          </Text>
        </HtmlTooltip>
      ) : null}
    </div>
  );
}

export default CountTooltip;
