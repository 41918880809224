import { useCallback, useMemo, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useHistory } from 'react-router';
import './components.scss';
import agent from '@root/agent';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { useQuery } from '@tanstack/react-query';
import NoData from '@root/components/common/NoData';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import ContentBox from '@root/components/v2/content/ContentBox';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';

const TopTriggered = ({ cloudProvider, selectedValue }) => {
  const [isTopTriggered, setIsTopTriggered] = useState(true);
  const history = useHistory();
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  // const [selectedValue, setSelectedValue] = useState('');
  const queryEnabled = useMemo(
    () =>
      Boolean(
        (selectedEnvironments?.length && cloudProvider === 'all') || cloudProvider === 'aws' || cloudProvider === 'gcp',
      ),
    [cloudProvider, selectedEnvironments],
  );
  const handleClick = useCallback(
    (data, isTopTriggered) => {
      // console.log(item);
      const url = buildBreadcrumbUrl({
        path: `/events/all?tab=Threat&from=dashboardttp&masterRuleId=${data.master_rule_id}&threat=${
          isTopTriggered ? 'Threat' : 'Anomaly'
        }`,
        breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Threat' }],
      });
      history.push(url);
    },
    [history],
  );
  const { data: topTriggeredData, isFetching } = useQuery(
    ['get-top-triggered', selectedEnvironmentTags, isTopTriggered, cloudProvider, selectedValue],
    () =>
      agent.Dashboard_New.get_toptriggered_data({
        scope: selectedEnvironmentTags,
        rule_type: 'All',
        detection_type: isTopTriggered ? 'Threat' : 'Anomalous',
        cloud_provider: cloudProvider,
        date_filter: '90days',
        assessment_time_filter: selectedValue,
      }),
    {
      enabled: queryEnabled,
    },
  );

  if (isFetching) {
    return (
      <Box
        sx={{
          height: '264px',
          width: '100%',
          border: '1px solid #CBD5E0',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div
          style={{
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              display: 'flex',
              margin: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '44px',
              borderBottom: '1px solid #CBD5E0',
              paddingLeft: '12px',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Box
                onClick={() => setIsTopTriggered(prev => !prev)}
                sx={{
                  borderBottom: isTopTriggered ? '2px solid #194bfb' : '',
                  width: '140px',
                  height: '44px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#1A202C',
                  fontSize: '14px',
                  fontWeight: 600,
                  fontFamily: 'Manrope',
                  lineHeight: '17px',
                  marginRight: '8px',
                }}>
                Top Triggered TTPs
              </Box>
              <Box
                onClick={() => setIsTopTriggered(prev => !prev)}
                sx={{
                  borderBottom: !isTopTriggered ? '2px solid #194bfb' : '',
                  width: '160px',
                  height: '44px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#1A202C',
                  fontSize: '14px',
                  lineHeight: '17px',
                  fontFamily: 'Manrope',
                  fontWeight: 600,
                }}>
                Top Suspicious Entities
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              padding: '10px',
              width: '100%',
              height: '100%',
            }}>
            {Array.from(new Array(5)).map(idx => (
              <Box key={idx} sx={{ height: '36px', width: '100%', margin: '4px 0px' }}>
                <Skeleton variant="rectangular" width={'100%'} height={'36px'} />
              </Box>
            ))}
          </Box>
        </div>
      </Box>
    );
  }
  return (
    <ContentBox
      titleSize="medium"
      titleContainerStyle={{
        // border: "0px",
        fontSize: '16px !important',
        color: 'var(--keyinsight-text-color)',
        // background: '#F7FAFC',
        // backgroundColor: 'var(--keyinsight-box-header-background)',
      }}
      paddingMode="content"
      containerClassName={'border-box-no-pad'}
      height="100%"
      style={{
        height: '264px',
        width: '100%',
        overflowY: 'auto',
        border: '1px solid #CBD5E0',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div
        style={{
          height: '95%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '44px',
            borderBottom: '1px solid #CBD5E0',
            paddingLeft: '12px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box
              onClick={() => setIsTopTriggered(prev => !prev)}
              sx={{
                borderBottom: isTopTriggered ? '2px solid #194bfb' : '',
                width: '140px',
                height: '44px',
                paddingTop: '12px',
                cursor: 'pointer',
                color: '#1A202C',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                fontFamily: 'Manrope',
                marginRight: '8px',
              }}>
              Top Triggered TTPs
            </Box>
            <Box
              onClick={() => setIsTopTriggered(prev => !prev)}
              sx={{
                borderBottom: !isTopTriggered ? '2px solid #194bfb' : '',
                width: '160px',
                height: '44px',
                paddingTop: '12px',
                cursor: 'pointer',
                color: '#1A202C',
                fontSize: '14px',
                fontWeight: 600,
                fontFamily: 'Manrope',
                lineHeight: '17px',
              }}>
              Top Suspicious Entities
            </Box>
          </Box>
        </Box>

        <Box sx={{ padding: '10px', width: '100%', height: '100%', paddingRight: '16px', paddingTop: '4px' }}>
          {topTriggeredData?.data?.length > 0 ? (
            topTriggeredData?.data?.slice(0, 5).map((data, i) => (
              <Box
                key={i}
                sx={{
                  height: '36px',
                  width: '100%',

                  margin: '4px',
                  paddingRight: '8px',
                  paddingLeft: '8px',
                  paddingTop: '4px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  '&:hover': {
                    borderRadius: '8px',
                    boxShadow:
                      '-1px 0px 8px 0px rgba(0, 0, 0, 0.04), 1px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px -1px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 8px 0px rgba(0, 0, 0, 0.04)',
                  },
                }}
                onClick={() => handleClick(data, isTopTriggered)}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Tooltip title={data?.rule_name?.replace(/\.*$/, '')}>
                    <Box
                      sx={{
                        fontWeight: 600,
                        fontFamily: 'Manrope',
                        color: '#767980',
                        fontSize: '14px',
                        marginTop: '4px',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {data.name ? data?.name.replace(/\.*$/, '') : data?.rule_name?.replace(/\.*$/, '')}
                    </Box>
                  </Tooltip>
                  <Box
                    sx={{
                      color: '#1A202C',
                      fontWeight: 700,
                      fontFamily: 'Manrope',
                      fontSize: '16px',
                    }}>
                    {data.count}
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <NoData />
          )}
        </Box>
      </div>
    </ContentBox>
  );
};
export default TopTriggered;
