import { Box, Skeleton } from '@mui/material';

import CloudProviderBarSkeleton from '@root/components/v2/content/CloudProviderBar/skeleton';
import ContentBox from '@root/components/v2/content/ContentBox';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeaderSkeleton from '@root/components/v2/layout/PageHeader/Skeleton';
import Column from '@root/components/v2/layout/columns/Column';
import ColumnsContainer from '@root/components/v2/layout/columns/ColumnsContainer';

function CompliacneSkleton() {
  return (
    <PageContent>
      <PageHeaderSkeleton />
      <CloudProviderBarSkeleton />

      <ContentBox padding="15px">
        <ColumnsContainer marginBottom={'15px'}>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title="Average Score"
              titleSize="medium"
              infoMsg="Connected Environment(s) Score">
             <div
                        style={{
                          // overflowY: 'auto',
                          height: '160px',
                          display: 'flex',
                          // flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '0px 15px',
                        }}
                        className="compliance--metric"
                        id="average-score">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          <Skeleton height="82px" width="119px" style={{ transform: 'none' }} />
                          <div
                            className="average--score--right--container"
                            style={{
                              alignItems: 'center',
                            }}>
                            <Skeleton height="82px" width="150px" style={{ transform: 'none' }} />
                          </div>
                        </div>
                      </div>
            </ContentBox>
          </Column>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title="Non-Compliant Rules By Severity"
              titleSize="medium"
              infoMsg="Breakdown of Severity">
               <div
                      style={{ height: '160px', fontFamily: 'Manrope', padding: '0px 15px' }}
                      className="compliance--metric">
                      <div className="non--compliance--Severity">
                        <div className="left-side">
                          <div
                            className="rule-count"
                            style={{
                              fontFamily: 'Manrope',
                              fontWeight: 700,
                            }}>
                            <Skeleton height="45px" width="60px" style={{ transform: 'none' }} />
                          </div>
                          <div className="title" style={{ marginTop: '10px' }}>
                            <Skeleton height="20px" width="162px" style={{ transform: 'none' }} />
                          </div>
                        </div>
                        <div className="right-side" style={{ display: 'flex', flexDirection: 'column' }}>
                          <div className="r-l-side">
                            <Skeleton height="19px" width="106px" style={{ transform: 'none' }} />
                          </div>
                          <div className="r-r-side">
                            <Skeleton height="20px" width="40px" style={{ transform: 'none' }} />
                          </div>
                        </div>
                      </div>
                      <div className="lower-items--by-severity" style={{ margin: '5px' }}>
                        <div className="each-item">
                          <div>
                            <Skeleton height="28px" width="36px" style={{ transform: 'none' }} />
                          </div>
                          <p>Critical</p>
                        </div>
                        <div className="each-item">
                          <div>
                            {' '}
                            <Skeleton height="28px" width="36px" style={{ transform: 'none' }} />
                          </div>
                          <p>High</p>
                        </div>
                        <div className="each-item">
                          <div>
                            {' '}
                            <Skeleton height="28px" width="36px" style={{ transform: 'none' }} />
                          </div>
                          <p>Medium</p>
                        </div>
                        <div className="each-item">
                          <div>
                            <Skeleton height="28px" width="36px" style={{ transform: 'none' }} />
                          </div>
                          <p>Low</p>
                        </div>
                      </div>
                    </div>
            </ContentBox>
          </Column>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title={'Non-Compliant Resources'}
              titleSize="medium"
              infoMsg="Trend of Non-Compliant Resources">
                  <Box position={'relative'} height={'180px'} padding='20px' >
                     <Box display='flex'height="100%" justifyContent='space-between' alignItems='center' >
                     <Box marginLeft="20px">
                        <Skeleton variant='circular' width="130px" height="130px"  />
                     </Box>
                     <Box>
                        <Skeleton variant='rectangular' height="100px" width="250px"  />
                     </Box>
                     </Box>
               </Box>
            </ContentBox>
          </Column>
        </ColumnsContainer>
        <ColumnsContainer marginBottom={'15px'}>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title="Resource Compliance"
              titleSize="medium"
              infoMsg="Compliance Status of Resource(s)">
               <Box position={'relative'} height={'180px'} padding='20px' >
                     <Box display='flex'height="100%" justifyContent='space-between' alignItems='center' >
                     <Box marginLeft="20px">
                        <Skeleton variant='circular' width="130px" height="130px"  />
                     </Box>
                     <Box>
                        <Skeleton variant='rectangular' height="100px" width="250px"  />
                     </Box>
                     </Box>
               </Box>
            </ContentBox>
          </Column>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title="Remediations & Exceptions"
              titleSize="medium"
              infoMsg="Trend of Remediations & Exceptions">
              <Box position={'relative'} height={'165px'}>
                       <Box marginLeft="15px" display="flex" flexDirection="column" marginRight="15px" marginBottom="15px" marginTop="15px" >
             <Skeleton height="120px" width="100%" style={{ transform: 'none' }} />
              <Box margin="0px auto"display="flex" width="50%" justifyContent="center">
              <Box margin="10px">
             <Skeleton width="150px" />
             </Box>
              <Box margin="10px">
             <Skeleton width="150px" />
             </Box>
             </Box>
           </Box>
              </Box>
             
               

            </ContentBox>
          </Column>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title={'Resource Trend'}
              titleSize="medium"
              infoMsg="Resource Trend Over Time">
                 
                 <Box position={'relative'} height={'165px'}>
                <Box marginLeft="15px" display="flex" flexDirection="column" marginRight="15px" marginBottom="15px" marginTop="15px" >
             <Skeleton height="120px" width="100%" style={{ transform: 'none' }} />
              <Box margin="0px auto"display="flex" width="50%" justifyContent="center">
              <Box margin="10px">
             <Skeleton width="150px" />
             </Box>
              <Box margin="10px">
             <Skeleton width="150px" />
             </Box>
             </Box>
           </Box>
              </Box>
               
            </ContentBox>
          </Column>
        </ColumnsContainer>
        <ColumnsContainer marginBottom={'15px'}>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title="Rule Compliance"
              titleSize="medium"
              infoMsg="Compliance Status of Rule(s)">
                 <Box position={'relative'} height={'180px'} padding='20px' >
                     <Box display='flex'height="100%" justifyContent='space-between' alignItems='center' >
                     <Box marginLeft="20px">
                        <Skeleton variant='circular' width="130px" height="130px"  />
                     </Box>
                     <Box>
                        <Skeleton variant='rectangular' height="100px" width="250px"  />
                     </Box>
                     </Box>
               </Box>
            </ContentBox>
          </Column>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title="Resources"
              titleSize="medium"
              infoMsg="Breakdown of Resource Compliance">
              <div
                style={{ height: '160px', fontFamily: 'Manrope', padding: '0px 15px' }}
                className="compliance--metric">
                <div className="lower-items--by-severity" style={{ margin: '5px' }}>
                  <div className="each-item">
                    <div style={{ marginBottom: '7px' }}>
                      <div style={{ marginBottom: '16px', marginLeft: '5px' }}>
                        <Skeleton height="29px" width="29px" style={{ transform: 'none' }} />
                      </div>

                      <Skeleton height="36px" width="41px" style={{ transform: 'none' }} />
                    </div>
                    <p>Compliant</p>
                  </div>
                  <div className="each-item">
                    <div style={{ marginBottom: '7px' }}>
                      <div style={{ marginBottom: '16px', marginLeft: '5px' }}>
                        <Skeleton height="29px" width="29px" style={{ transform: 'none' }} />
                      </div>
                      <Skeleton height="36px" width="41px" style={{ transform: 'none' }} />
                    </div>
                    <p>Non-Compliant</p>
                  </div>
                  <div className="each-item">
                    <div style={{ marginBottom: '7px' }}>
                      <div style={{ marginBottom: '16px', marginLeft: '5px' }}>
                        <Skeleton height="29px" width="29px" style={{ transform: 'none' }} />
                      </div>
                      <Skeleton height="36px" width="41px" style={{ transform: 'none' }} />
                    </div>
                    <p>Non-Evaluated</p>
                  </div>
                </div>
              </div>
            </ContentBox>
          </Column>
          <Column>
            <ContentBox
              containerClassName={'border-box'}
              paddingMode={'content'}
              titleContainerStyle={{
                backgroundColor: '#F6F8FB',
                borderRadius: '5px 5px 0px 0px',
              }}
              title={'Compliance Over Times'}
              titleSize="medium"
              infoMsg="Compliance Score Over Time">
                      <Box position={'relative'} height={'165px'}>
                <Box marginLeft="15px" display="flex" flexDirection="column" marginRight="15px" marginBottom="15px" marginTop="15px" >
             <Skeleton height="120px" width="100%" style={{ transform: 'none' }} />
              <Box margin="0px auto"display="flex" width="50%" justifyContent="center">
              <Box margin="10px">
             <Skeleton width="150px" />
             </Box>
              <Box margin="10px">
             <Skeleton width="150px" />
             </Box>
             </Box>
           </Box>
              </Box>
            </ContentBox>
          </Column>
        </ColumnsContainer>
      </ContentBox>
    </PageContent>
  );
}

export default CompliacneSkleton;
