import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { LINE_HEIGHT_PX } from './constants';

interface Props {
  scrollToLine: number;
  onScroll: () => void;
  children: ReactElement[];
  props: any;
}

function Pre({ scrollToLine, onScroll, children, ...props }: Props) {
  const preRef = useRef<any>();
  const isProgrammaticScrollingRef = useRef(false);

  const handleScroll = useCallback(() => {
    if (isProgrammaticScrollingRef) {
      isProgrammaticScrollingRef.current = false;
      return;
    }

    if (onScroll) {
      onScroll();
    }
  }, [onScroll]);

  useEffect(() => {
    if (preRef.current && scrollToLine >= 0) {
      isProgrammaticScrollingRef.current = true;

      preRef.current.scrollTo(0, LINE_HEIGHT_PX * scrollToLine);
    }
  }, [props, scrollToLine]);

  return (
    <pre ref={preRef} onScroll={handleScroll} {...props}>
      {children}
    </pre>
  );
}

const PreComponent = React.memo(Pre);
export default PreComponent;
