type Key = 'Dashboard' | 'Identity';

const BreadcrumbParts: Record<Key, { title: string; path: string }> = {
  Dashboard: {
    title: 'Dashboard',
    path: '/dashboard',
  },
  Identity: {
    title: 'Identity',
    path: '/identity',
  },
};

export default BreadcrumbParts;
