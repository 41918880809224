import React from 'react';
import _ from 'lodash';
import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';
import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
import warning_icon from '@root/assets/svg/icons/warning_icon.svg';
import info_icon from '@root/assets/svg/icons/info_icon.svg';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import HighIcon from '@root/components/svg/high';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';

export const SeverityBadge = ({ propsValue, height }) => {
  if (!_.isEmpty(propsValue)) {
    if (propsValue.toUpperCase() === 'HIGH') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="HIGH">
            {/* <HighIcon width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <img src={highIcon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'MEDIUM') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="MEDIUM">
            {/* <MediumIcon height={height} width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <img src={mediumIcon} style={{ height: height, width: '88px' }} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'LOW') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="LOW">
            {/* <LowIcon textDisabled={true} width={'88px'} horizontalPosition="flex-start" /> */}
            <img src={lowIcon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'CRITICAL') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="CRITICAL">
            {/* <CriticalIcon width={'88px'} textDisabled={true} horizontalPosition="flex-start" /> */}
            <img src={criticalIcon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'WARNING') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="WARNING">
            <img src={warning_icon} style={{ width: '88px' }} />
          </Tooltip>
        </div>
      );
    } else if (propsValue.toUpperCase() === 'INFO') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <Tooltip title="INFO">
            <img src={info_icon} />
          </Tooltip>
        </div>
      );
    } else if (propsValue === 'non-compliant') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#F62121', color: 'white', width: '100px', height: '18px' }}>
            Non-Compliant
          </span>
        </div>
      );
    } else if (propsValue === 'compliant') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#03BB34', color: 'white', width: '100px', height: '18px' }}>
            Compliant
          </span>
        </div>
      );
    } else if (propsValue === 'not-applicable') {
      return (
        <div
          className="text-center"
          style={{
            textAlign: 'center',
            verticalAlign: 'middle',
          }}>
          <span className="badge" style={{ background: '#7657CC', color: 'white', width: '100px', height: '18px' }}>
            Not-Applicable
          </span>
        </div>
      );
    } else {
      return propsValue;
    }
  } else return null;
};
