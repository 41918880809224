import Input from 'reactstrap/lib/Input';
import './style.scss';

// import { SeverityBadge } from '@components/bootstrapTable/badges/severity';
import { Link } from 'react-router-dom';
import { appendBreadcrumbsToUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';

export const ExpandRow = ({ row, setSelectedPolicyForViewRegionsList, ruleStatusDataMap, exceptionDataMap }) => {
  const isRuleDisabled = ruleStatusDataMap[row.master_rule_id]
    ? ruleStatusDataMap[row.master_rule_id].isDisabled
    : false;
  const isExceptionCreated = Boolean(exceptionDataMap[row.master_rule_id]);
  return (
    <div className="RemediationPolicy__expanded-body">
      <div className="row-card">
        <div className="colu" style={{ width: '100%' }}>
          <div className="RemediationPolicy__DataMain">
            Rule Description : <span className="RemediationPolicy__DataDescription">{row.rule}</span>
          </div>
          <div className="RemediationPolicy__DataMain">
            Rule ID: <span className="RemediationPolicy__DataDescription">{row.master_rule_id}</span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            className="RemediationPolicy__DataMain">
            <span style={{ paddingRight: '4px' }}>Rule Severity:</span>
            <span style={{ paddingLeft: '3px' }}>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  color:
                    row.severity === 'CRITICAL'
                      ? '#CD0404'
                      : row.severity === 'HIGH'
                      ? '#FB1919'
                      : row.severity === 'MEDIUM'
                      ? '#FFA825'
                      : '#E8DF07',
                }}>
                {row.severity}
              </p>
            </span>
          </div>
          <div className="RemediationPolicy__DataMain">
            Rule Status:{' '}
            <Link
              to={appendBreadcrumbsToUrl({
                path: `/rules/table?service=${encodeURIComponent(row?.service)}&rule_id=${encodeURIComponent(
                  row.master_rule_id,
                )}&from=policy`,
                breadcrumbs: [
                  {
                    title: 'Rules',
                  },
                ],
              })}
              className="RemediationPolicy__DataDescription"
              style={{ color: '#194BFB', cursor: 'pointer', textDecoration: 'none' }}>
              {isRuleDisabled ? 'Disabled' : 'Enabled'}
            </Link>
            {isExceptionCreated ? (
              <span>
                {' '}
                &{' '}
                <Link
                  to={`/exceptions?rule_id=${row.master_rule_id}`}
                  target="_blank"
                  className="RemediationPolicy__DataDescription"
                  style={{ color: '#194BFB', cursor: 'pointer' }}>
                  Exception Created
                </Link>
              </span>
            ) : null}
          </div>
          <div className="RemediationPolicy__DataMain">
            Resource Type: <span className="RemediationPolicy__DataDescription">{row.resource_type}</span>
          </div>
          <div className="RemediationPolicy__DataMain">
            Resources:{' '}
            <span
              style={{ color: '#194BFB', cursor: 'pointer' }}
              className="RemediationPolicy__DataDescription"
              onClick={() => setSelectedPolicyForViewRegionsList({ data: row.resources, type: 'resources' })}>
              View List
            </span>
          </div>
          <div className="RemediationPolicy__DataMain">
            Region:{' '}
            <span
              style={{ color: '#194BFB', cursor: 'pointer' }}
              className="RemediationPolicy__DataDescription"
              onClick={() => setSelectedPolicyForViewRegionsList({ data: row.region, type: 'regions' })}>
              View List
            </span>
          </div>
        </div>
      </div>

      {Object.keys(row.custom_input).length && row.custom_input !== '{}' ? (
        <div className="d-flex flex-row align-items-start input-card">
          <span
            style={{
              minWidth: 'max-content',
              // marginRight: '10px',
              // marginLeft: '44px',
              color: '#1a202c',
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: '600',
              marginBottom: '10px',
            }}>
            Custom Input: &nbsp;
          </span>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {Object.keys(row.custom_input).map(key => {
              return (
                <div style={{ marginRight: '16px' }} key={`${row.id}-${key}`}>
                  <label style={{ textTransform: 'capitalize', fontFamily: 'Manrope' }}>{key.replace('_', ' ')}:</label>
                  <Input
                    style={{ maxWidth: '400px' }}
                    value={row.custom_input[key]}
                    onChange={e => {
                      e.persist();
                    }}
                    type="text"
                    disabled
                    placeholder="Optional (KMS Key ID)"></Input>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
