import Slider, { SliderThumb } from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#194BFB',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 8,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: '#D1DBFE',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_25684_4561)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 0C1.27614 0 1.5 0.223858 1.5 0.5V7.5C1.5 7.77614 1.27614 8 1 8C0.723858 8 0.5 7.77614 0.5 7.5V0.5C0.5 0.223858 0.723858 0 1 0ZM4 0C4.27614 0 4.5 0.223858 4.5 0.5V7.5C4.5 7.77614 4.27614 8 4 8C3.72386 8 3.5 7.77614 3.5 7.5V0.5C3.5 0.223858 3.72386 0 4 0ZM7 0C7.27614 0 7.5 0.223858 7.5 0.5V7.5C7.5 7.77614 7.27614 8 7 8C6.72386 8 6.5 7.77614 6.5 7.5V0.5C6.5 0.223858 6.72386 0 7 0Z"
              fill="#194BFB"
            />
          </g>
          <defs>
            <clipPath id="clip0_25684_4561">
              <rect width="8" height="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function CustomizedSlider(props) {
  return (
    <AirbnbSlider
      slots={{ thumb: AirbnbThumbComponent }}
      //   getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
      {...props}
    />
  );
}
