import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import Text from '@components/v2/ui-elements/Text';
import { BreadcrumbData, buildBreadcrumbUrl, useBreadcrumbs } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import { ReactComponent as ChevronIcon } from '@assets/svg/breadcrumb/chevron-right.svg';

import './style.scss';

declare const window: any;

function Breadcrumbs({ breadcrumbs }: { breadcrumbs?: BreadcrumbData[] }) {
  const history = useHistory();
  const { breadcrumbData } = useBreadcrumbs();
  const anchorElRef = useRef();
  const [showHiddenItems, setShowHiddenItems] = useState(false);

  useEffect(() => {
    if (breadcrumbs) {
      window.BREADCRUMB_PROPS = { breadcrumbs };
    }

    return () => {
      window.BREADCRUMB_PROPS = null;
    };
  }, [breadcrumbs]);

  useEffect(() => {
    if (!breadcrumbs?.length && !breadcrumbData?.length)
      throw new Error('Breadcrumb data is required either in the page URL or the props of the Breadcrumbs component.');
  }, [breadcrumbData, breadcrumbs]);

  const rootBreadcrumb = useMemo<BreadcrumbData>(() => {
    // breadcrumbData from the URL takes precedence.
    if (breadcrumbData) {
      return breadcrumbData[0];
    }

    return (breadcrumbs || [])[0];
  }, [breadcrumbData, breadcrumbs]);

  const subBreadcrumbs = useMemo(() => {
    if (breadcrumbData) {
      return breadcrumbData.slice(1);
    }
    return (breadcrumbs || []).slice(1);
  }, [breadcrumbData, breadcrumbs]);

  // When the number of breadcrumbs are 5 or more, we show the rootBreadcrumb and the first item
  // in subBreadcrumbs. We also show the last 2 items in subBreadcrumbs. Everything else is collapsed
  // into a Menu.
  const hiddenSubBreadcrumbs = useMemo(() => {
    return subBreadcrumbs.filter((b, index) => index > 0 && index < subBreadcrumbs.length - 2);
  }, [subBreadcrumbs]);

  const toggleHiddenItemsMenu = useCallback(() => {
    setShowHiddenItems(prev => !prev);
  }, []);

  const handleHiddenMenuItemClick = useCallback(
    (b, index) => {
      const path = buildBreadcrumbUrl({
        path: b.path,
        // + 1 + 1 to account for the first item in subBreadcrumbs which isn't in the Menu.
        // The `index` parameter is relative to the hiddenBreadcrumbs array which is a subset
        // of the subBreadcrumbs array.
        breadcrumbs: [rootBreadcrumb, ...subBreadcrumbs.slice(0, index + 1 + 1)],
      });

      history.push(path);
    },
    [history, rootBreadcrumb, subBreadcrumbs],
  );

  return (
    <div className="Breadcrumb">
      <Text className="fs__5 Breadcrumb__MainTitle" fontWeight="700" color="#1A202C">
        {rootBreadcrumb?.path ? <Link to={rootBreadcrumb?.path}>{rootBreadcrumb?.title}</Link> : rootBreadcrumb?.title}
      </Text>

      {subBreadcrumbs.length ? (
        <ChevronIcon className="Breadcrumb__MainChevron" width="12" height="12" color="#767980" />
      ) : null}

      {subBreadcrumbs.map((b, index) => {
        return (
          <React.Fragment key={`${b.path}-${index}`}>
            {subBreadcrumbs.length > 4 ? (
              <>
                {index === 1 ? (
                  <>
                    <Text className="Breadcrumb__Ellipsis" ref={anchorElRef} onClick={toggleHiddenItemsMenu}>
                      ...
                    </Text>
                    <ChevronIcon className="Breadcrumb__Chevron" width="12" height="12" color="#767980" />
                  </>
                ) : index > 1 && index < subBreadcrumbs.length - 2 ? null : (
                  <>
                    <Text className="fs__2" color="#767980">
                      {/* Last breadcrumb is made unclickable, i.e. it's not a link. */}
                      {index === subBreadcrumbs.length - 1 || !b.path ? (
                        b.title
                      ) : (
                        <Link
                          to={buildBreadcrumbUrl({
                            path: b.path,
                            breadcrumbs: [rootBreadcrumb, ...subBreadcrumbs.slice(0, index + 1)],
                          })}>
                          {b.title}
                        </Link>
                      )}
                    </Text>
                    {index < subBreadcrumbs.length - 1 ? (
                      <ChevronIcon className="Breadcrumb__Chevron" width="12" height="12" color="#767980" />
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                <Text className="fs__2" color="#767980">
                  {/* Last breadcrumb is made unclickable, i.e. it's not a link. */}
                  {index === subBreadcrumbs.length - 1 || !b.path ? (
                    b.title
                  ) : (
                    <Link
                      to={buildBreadcrumbUrl({
                        path: b.path,
                        breadcrumbs: [rootBreadcrumb, ...subBreadcrumbs.slice(0, index + 1)],
                      })}>
                      {b.title}
                    </Link>
                  )}
                </Text>
                {index < subBreadcrumbs.length - 1 ? (
                  <ChevronIcon className="Breadcrumb__Chevron" width="12" height="12" color="#767980" />
                ) : null}
              </>
            )}
          </React.Fragment>
        );
      })}

      <Menu anchorEl={anchorElRef.current} open={showHiddenItems} onClose={toggleHiddenItemsMenu}>
        {hiddenSubBreadcrumbs.map((b, index) => (
          <MenuItem
            key={`${b.path}-${index}`}
            className="Breadcrumb__HiddenMenuItem fs__2"
            onClick={() => handleHiddenMenuItemClick(b, index)}
            disabled={!b.path}>
            {b.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default Breadcrumbs;
