import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import score_cisa from '@root/assets/svg/workload/score_cisa.svg';
import score_epss from '@root/assets/svg/workload/score_epss.svg';
import PropTypes from 'prop-types';
import './threat.scss';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
import oswap from '@root/assets/svg/oswap.png';
import saans from '@root/assets/svg/SANS ICON.png';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            width: '25px',
            height: '25px',
            background: 'var(--background)',
            border: '1px solid var(--border-color)',
          }}>
          <Close
            sx={{
              width: '15px',
              color: 'var(--text-color)',
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ThreatDialog(props) {
  //   const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false);
    props.setShowDetailsPanel(false);
  };

  const showBoolean = value => {
    if (value === null || !value) return '-';
    return JSON.stringify(value);
  };
  const data = [
    {
      title: 'Name',
      Description: props.threat_data?.threat_trend_data.Name,
    },
    {
      title: 'Vendor',
      Description: props.threat_data?.threat_trend_data.Vendor,
    },

    {
      title: 'Product',
      Description:
        props.threat_data.threat_trend_data.Product.length > 0
          ? props.threat_data.threat_trend_data.Product.join(', ')
          : '',
    },
    {
      title: 'Description',
      Description: props.threat_data?.threat_trend_data.Description,
    },
    {
      title: 'Action',
      Description: props.threat_data?.threat_trend_data.Action,
    },
    {
      title: 'Date Added',
      Description: DateFormatPipe(props.threat_data.threat_trend_data['Date Added']),
    },
    {
      title: 'Due Date',
      Description: DateFormatPipe(props.threat_data.threat_trend_data['Due Date']),
    },
    {
      title: 'CISA Score',
      Description: props.selectedRow.threat_trend?.cisa,
    },
    {
      title: 'EPSS Score',
      Description: props.selectedRow.threat_trend?.epss,
    },

    {
      title: 'Hackerone Count ',
      Description: props.threat_data?.details.cve_details?.hackerone?.count,
    },
    {
      title: 'Hackerone  Rank',
      Description: props.threat_data?.details.cve_details?.hackerone?.rank,
    },
    {
      title: 'Age (Days)',
      Description: props.threat_data?.details.cve_details?.age_in_days,
    },
    {
      title: 'Is Exploitable',
      Description: showBoolean(props.threat_data?.details.cve_details?.is_exploited),
    },
    {
      title: 'Is POC',
      Description: showBoolean(props.threat_data?.details.cve_details?.is_poc),
    },
    {
      title: 'Is OSS',
      Description: showBoolean(props.threat_data?.details.cve_details?.is_oss),
    },
    {
      title: 'Is Remote',
      Description: showBoolean(props.threat_data?.details.cve_details?.is_remote),
    },
    {
      title: 'Is Template',
      Description: showBoolean(props.threat_data?.details.cve_details?.is_template),
    },
    {
      title: 'Last Modified ',
      Description: props.threat_data?.details.cve_details?.last_modified
        ? DateFormatPipe(props.threat_data?.details.cve_details?.last_modified)
        : '--',
    },
    {
      title: 'Patch URL',
      Description: props.threat_data?.details.References?.join(', '),
    },
    {
      title: 'Vendor Advisory',
      Description: props.threat_data?.details.cve_details?.vendor_advisory,
    },
    {
      title: 'Vulerability Status',
      Description: props.threat_data?.details.cve_details?.vuln_status,
    },
    {
      title: 'Weakness',
      Description:
        // [
        //   { cwe_id: 'qwe', cwe_name: 'fmdnvdmn' },
        //   { cwe_id: 'qwe', cwe_name: 'fmdnvdmn' },
        // ],

        props.threat_data?.details.cve_details?.weaknesses,
    },
  ];
  return (
    <div className="threat-modal">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '800px',
            boxShadow: 'none',
            // height: '780px', // Replace with your desired width
          },
          '.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop': {
            backgroundColor: 'rgba(0,0,0,0.5)',
          },
          //   '& .MuiDialog-paper': {
          //   background: 'var(--background-color)',
          //   borderRadius: 'var(--border-radius)',
          //   boxShadow: 'var(--box-shadow)',

          //   color: 'var(--text-color)',
          // },
          '& .MuiDialog-container': {
            background: 'transparent',
          },
        }}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <span
            className="heading"
            style={{ marginBottom: '0', fontFamily: 'Manrope', fontWeight: 700, fontSize: '16px', lineHeight: 1.5 }}>
            Threat Intelligence
            <span
              className="title"
              style={{ marginBottom: '0', marginLeft: '32rem', cursor: 'pointer', color: '#194BFB' }}>
              Filter by Trends
            </span>
          </span>
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ padding: '20px' }}>
          <Grid container>
            {data.map(details => {
              return (
                <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                  <Grid item xs={2.5} sx={{ minWidth: '200px', paddingRight: '20px ' }}>
                    <p className="title"> {details.title}: </p>
                  </Grid>
                  <Grid item xs={9.5} sx={{ textAlign: 'right', paddingLeft: '20px' }}>
                    {details.title === 'Weakness' ? (
                      <>
                        <ul style={{ paddingLeft: 0, marginBottom: 0, listStyleType: 'none' }}>
                          {details?.Description?.map(item => (
                            <li>
                              <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                                <p
                                  className="description"
                                  style={{
                                    color: '#F62121',
                                  }}>
                                  {item.cwe_id}
                                </p>
                                :
                                <p
                                  className="description overflow-description"
                                  style={{
                                    color: '#F62121',
                                  }}>
                                  {item.cwe_name}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : details.title === 'Patch URL' || details.title === 'Vendor Advisory' ? (
                      details.Description.split(',').map((link, index) => (
                        <a
                          key={index}
                          target="_blank"
                          href={link.trim()}
                          className="description"
                          style={{ color: 'rgb(25, 75, 251)', display: 'block', margin: '5px 0' }}>
                          {link.trim()}
                        </a>
                      ))
                    ) : (
                      <p
                        className="description"
                        style={{
                          color: 'rgb(25, 75, 251)',
                        }}>
                        {details.Description}
                      </p>
                    )}
                  </Grid>
                </Grid>
              );
            })}

            <Grid
              container
              sx={{ paddingTop: '28px', marginBottom: '55px', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
              <Grid item xs={2.5} sx={{ minWidth: '200px', paddingRight: '20px ' }}>
                <p className="title" style={{ marginTop: '1.4rem' }}>
                  Source:{' '}
                </p>
              </Grid>
              <Grid
                item
                xs={9.5}
                className="description"
                style={{ display: 'flex', gap: '15px', textAlign: 'right', paddingLeft: '20px' }}>
                {props.selectedRow.threat_trend.cisa > 0 && (
                  <img src={score_cisa} style={{ width: '60px', height: '60px', marginRight: '20px' }} />
                )}
                {props.selectedRow.threat_trend.epss > 0 && (
                  <img src={score_epss} style={{ width: '242px', height: '60px' }} />
                )}
                {props.selectedRow.details['OWASP Top 10'] && <img src={oswap} style={{ height: '60px' }} />}
                {props.selectedRow.details['SANS Top 25'] && <img src={saans} style={{ height: '60px' }} />}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
