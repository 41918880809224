import { Grid, Box } from '@mui/material';
import ContentBox from '@root/components/v2/content/ContentBox';
import './components.scss';
import columns from './Column';
import externaIcon from '@root/assets/svg/external-link.svg';
import MuiTable from '@components/table/table';
// import Box from '@mui/material/Box';
import TableSkeleton from '@root/components/table/skeleton';
import { useHistory } from 'react-router';
import { RANDOM_COLORS } from '@root/utilities/colors';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';

const Vulnerabilities = ({ data, isFetchingVulnerabilities }) => {
  const history = useHistory();

  return (
    <Grid sx={{ height: '100%' }}>
      <ContentBox
        title="Vulnerabilities"
        titleSize="medium"
        padding="0"
        height="371px"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottom: '1px solid #CBD5E0',
          margin: '0px',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        headerEndElement={
          <div
            onClick={() => {
              history.push(
                buildBreadcrumbUrl({
                  path: '/vulnerabilities',
                  breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Vulnerabilities' }],
                }),
              );
            }}>
            <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
          </div>
        }
        containerClassName={'border-box-no-pad'}
        paddingMode="content">
        {isFetchingVulnerabilities ? (
          <Box height="50%">
            <TableSkeleton ROWS_COUNT={3} hideFooter noBorder />
          </Box>
        ) : (
          <div className="dashboard_component  muiTable__custom" style={{ height: '85%' }}>
            <MuiTable
              columns={columns(RANDOM_COLORS)}
              // LoaderState={data.length === 0 ? true : false}
              rows={data}
              pagination={false}
              hideFooter={true}
            />
          </div>
        )}
      </ContentBox>
    </Grid>
  );
};
export default Vulnerabilities;
