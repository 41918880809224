import nist_logo from '@assets/img/complainceFamilyIcons/nist_logo.svg';
import cis_logo from '@assets/img/complainceFamilyIcons/cis_logo.svg';
// import ccm_logo from '@assets/img/complainceFamilyIcons/ccm_logo.png';
import iso_logo from '@root/assets/img/complainceFamilyIcons/ISO SVG.svg';
import aws_light from '@root/assets/img/complainceFamilyIcons/AWS SVG.svg';
import popi_logo from '@root/assets/img/complainceFamilyIcons/popi_small.svg';
import hipaa_logo from '@root/assets/img/complainceFamilyIcons/hipaa_small.svg';
import soc_logo from '@root/assets/img/complainceFamilyIcons/soc_small.svg';
import iso_new from '@root/assets/img/complainceFamilyIcons/iso_2013_small.svg';
import pci_logo from '@root/assets/img/complainceFamilyIcons/pci_small.svg';
import gdpr_logo from '@root/assets/img/complainceFamilyIcons/gdpr_small.svg';
import ccmd_logo from '@root/assets/img/complainceFamilyIcons/csa_small.svg';
import nydfs_logo from '@root/assets/img/complainceFamilyIcons/NYDFS_.png';
import fisma_logo from '@root/assets/img/complainceFamilyIcons/FISMA_.png';
import fedramp_logo from '@root/assets/img/complainceFamilyIcons/FedRAMP_.png';
import cmmc_logo from '@root/assets/img/complainceFamilyIcons/CMMC.svg';
// import cis_gcp from '@root/assets/img/complainceFamilyIcons/CIS-GCP-1-3-0.svg';
import gcp_well from '@root/assets/img/complainceFamilyIcons/GCP-Well-Architected_v2020-07-02.svg';
import sama from '@root/assets/img/complainceFamilyIcons/SAMA.png';
import cbb from '@root/assets/img/complainceFamilyIcons/CBB.svg';

const ComplianceIconMapping = ({ name, ...props }) => {
  return (
    <img
      {...props}
      src={
        name === 'CIS-GCP-1-3-0'
          ? cis_logo
          : name === 'CIS-GCP_v2.0.0'
          ? cis_logo
          : name === 'CIS-GCP_v1.3.0'
          ? cis_logo
          : name === 'GCP-Well-Architected_v2020-07-02'
          ? cis_logo
          : name === 'Google Cloud Architecture Framework'
          ? gcp_well
          : name === 'NIST-800-53_vRev4'
          ? nist_logo
          : name === 'CIS-AWS_v1.3.0'
          ? cis_logo
          : name === 'CSA-CCM_v3.0.1'
          ? ccmd_logo
          : name === 'AWS-Well-Architected_v2020-07-02'
          ? aws_light
          : name === 'POPI_Act_v2000'
          ? popi_logo
          : name === 'HIPAA_v2013'
          ? hipaa_logo
          : name === 'SOC-2_v2017'
          ? soc_logo
          : name === 'ISO-27001_v2013'
          ? iso_new
          : name === 'GDPR_v2016'
          ? gdpr_logo
          : name === 'PCI-DSS_v3.2.1' || name === 'PCI-DSS_v4.0'
          ? pci_logo
          : name === 'CSA-CCM_v4.0.5'
          ? ccmd_logo
          : name === 'NIST-800-53_vRev5'
          ? nist_logo
          : name === 'NYDFS'
          ? nydfs_logo
          : name === 'FISMA'
          ? fisma_logo
          : name === 'FedRAMP'
          ? fedramp_logo
          : name === 'CMMC'
          ? cmmc_logo
          : name === 'AWS_FSBP'
          ? aws_light
          : name === 'AWS-CIS-EKS-1.4.0'
          ? cis_logo
          : name === 'AWS-CIS-EKS-1.1.0'
          ? cis_logo
          : name === 'SAMA_CSF'
          ? sama
          : name === 'CBB'
          ? cbb
          : name === 'Google Cloud Architecture Framework'
          ? gcp_well
          : iso_logo
      }
      alt={name}
    />
  );
};

export default ComplianceIconMapping;
