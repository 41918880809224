/**
 *
 * @param {*} color
 * @param {*} midValue Number from 0-255
 * @returns
 */
function getColorLightness(color, midValue) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if ((midValue && hsp > midValue) || hsp > 180) {
    return 'light';
  } else {
    return 'dark';
  }
}

const RISK_COLORS = {
  critical: '#CD0404',
  high: '#EB6C6C',
  medium: '#FFA825',
  low: '#E8DF07',
  no_risk: '#03CB38',
};

const COMPLIANCE_COLORS = {
  passed: '#03BB34',
  failed: '#ED5656',
  not_applicable: '#936DFF',
  non_evaluavated: '#A0AEC0',
};

const RANDOM_COLORS = [
  '#476FFC',
  '#35C95D',
  '#BD47FB',
  '#7593FD',
  '#F62121',
  '#E9D415',
  '#FFA825',
  '#194BFB',
  '#03BB34',
  '#936DFF',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

// function shuffleArray(array) {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     [array[i], array[j]] = [array[j], array[i]];
//   }
//   return array;
// }

// const shuffledColors = shuffleArray([...RANDOM_COLORS]);

export { getColorLightness, RISK_COLORS, COMPLIANCE_COLORS, RANDOM_COLORS };
