import awsRegions from './aws-regions';
import { type Region } from '.';

function makeMapData(data: any) {
  const finalRegionsData: Region[] = [];

  for (const regionCode in data) {
    if (regionCode === 'global') continue;

    const awsRegion =
      awsRegions.find(r => r.regionCode === regionCode) || awsRegions.find(r => r.countryCode === 'USA');

    const d = data[regionCode];

    if (awsRegion && d) {
      const r: Region = {
        name: awsRegion?.countryCode || '',
        country: awsRegion?.countryCode || '',
        regionName: regionCode,
        lat: awsRegion?.lat || 0,
        lng: awsRegion?.lng || 0,
        inactive: Boolean(d.inactive),
        totalCount: d.total_resources || 0,
        environmentsCount: d.total_account || 0,
        resourcesCount: d.total_resources || 0,
        servicesCount: d.total_services || 0,
      };

      finalRegionsData.push(r);
    }
  }

  return finalRegionsData;
}

export { makeMapData };
