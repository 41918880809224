import Environment from '@root/components/table/cell-types/Environment';
import { RiskTypeIcons } from '@root/pipes/riskTypeIcons';
import _ from 'lodash';
import AWSServiceCategoryIcons from '../awsServiceCategoryIcons';
import AWSServiceIcons from '../awsServiceIcons';
import Tooltip from '../v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';

const commonTableColumns = {
  environment: envIdKey => ({
    field: 'environment',
    // flex: 1,
    width: 200,
    headerName: 'Environment ID/Tag',
    renderCell: params => {
      return <Environment environmentId={envIdKey ? params.row?.[envIdKey] : params.row.environmentId} />;
    },
  }),
  isPublic: () => ({
    field: 'is_public',
    headerName: 'Is Public',
    width: 200,
    sortable: true,
    renderCell: params => {
      if (!_.isBoolean(params.value)) return '-';
      return params.value ? 'True' : 'False';
    },
  }),
  serviceCategory: () => ({
    field: 'service_category',
    headerName: 'Service Category',
    width: 200,
    align: 'left',
    renderCell: params => (
      <>
        <AWSServiceCategoryIcons name={params.row.service_category} />
        <Tooltip title={params.row.service_category}>
          <Text className="SingleLine" style={{ paddingLeft: '7px' }}>
            {params.row.service_category}
          </Text>
        </Tooltip>
      </>
    ),
    sort: true,
  }),
  service: () => ({
    field: 'service',
    headerName: 'Service',
    width: 200,
    align: 'left',
    renderCell: params => (
      <div className="d-flex" style={{ width: '100%' }}>
        <AWSServiceIcons name={params.row.service} />
        <Tooltip title={params.row.service}>
          <Text className="SingleLine ml-2">{params.row.service}</Text>
        </Tooltip>
      </div>
    ),
    sort: true,
  }),
  resourceId: () => ({
    field: 'resource_id',
    width: 300,
    copyKey: 'resource_id',
    copyData: { key: 'resource_id', ignoreValues: ['No Resource Available'] },
    headerName: 'Resource ID',
    sort: true,
    renderCell: params => {
      return params.row.resource_id;
    },
  }),
  resourceName: () => ({
    field: 'resource_name',
    copyData: { key: 'resource_name', ignoreValues: ['No Resource Available'] },
    headerName: 'Resource Name',
    cellClassName: 'SingleLine',
    sort: true,
    width: 200,
    // renderCell: params => {
    //   if (params.row.resource_name === '')
    //     return (
    //       <div>
    //         <span>No Resource Name Available</span>
    //       </div>
    //     );
    //   return <div style={{ fontSize: '14px', fontFamily: 'Manrope' }}>{params.row.resource_name}</div>;
    // },
  }),
  risk: ({ height = 200 } = {}) => ({
    field: 'risk',
    headerName: 'Risk',
    width: height,
    cellClassName: 'text-center',
    sortable: true,
    renderCell: params => {
      if (!_.isArray(params.row?.risk[0])) {
        return params.row?.risk === 'No Risk' ? (
          'No Risk'
        ) : (
          <>
            {params.row?.risk.map(item => (
              <RiskTypeIcons tooltip={true} name={item} rowIndex={params.id} />
            ))}
          </>
        );
      }
      return params.row?.risk[0] === 'No Risk' ? (
        'No Risk'
      ) : (
        <>
          {params.row?.risk[0].map(item => (
            <RiskTypeIcons tooltip={true} name={item} rowIndex={params.id} />
          ))}
        </>
      );
    },
  }),
  risks: (otherColors = ['#C7FEFA', '#C1E4F3', '#EFE2E2', '#E8E6E6', '#F4F190', '#E6F2CA']) => ({
    field: 'risks',
    headerName: 'Risk',
    cellClassName: 'text-center',
    headerClassName: 'fs__2',
    // minWidth: 1000,
    flex: 1,
    renderCell: params => {
      const p1_risks = [
        'effective admin',
        'admin',
        'cross account',
        'inactive',
        'over privileged',
        'anomalous',
        'learning',
        'threat',
        'no mfa',
      ];
      const p1_risks_colors = {
        admin: '#D1DBFE',
        inactive: '#FDD3D3',
        'over privileged': '#FFEED3',
        anomalous: '#D6F9EC',
        learning: '#F2DAFE',
        threat: '#C4A484',
        'cross account': '#FDD3D3',
        'effective admin': '#FBECFD',
        'no mfa': '#FBACFD',
      };

      const p1Risks = params.row.risks
        .filter(risk => p1_risks.includes(risk.toLowerCase()))
        .map(risk => {
          return { risk: risk, color: p1_risks_colors[risk.toLowerCase()] };
        });

      let hiddenRisks = params.row.risks.filter(risk => !p1_risks.includes(risk.toLowerCase()));

      return params.row.risks && params.row.risks.length ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {p1Risks.map((risk, i) => (
            <div
              key={i}
              style={{
                borderRadius: '12px',
                background: risk.color,
                fontSize: '10px',
                fontWeight: '600',
                color: '#1A202C',
                padding: '3px 10px',
                height: '21px',
              }}>
              {risk.risk}
            </div>
          ))}
          {p1Risks.length < 8
            ? hiddenRisks.slice(0, 8 - p1Risks.length).map((risk, i) => (
                <div
                  key={i}
                  style={{
                    borderRadius: '12px',
                    background: otherColors[i],
                    fontSize: '10px',
                    fontWeight: '600',
                    color: '#1A202C',
                    padding: '3px 10px',
                    height: '21px',
                  }}>
                  {risk}
                </div>
              ))
            : null}

          {params.row.risks.length - 8 > 0 && (
            <Tooltip
              title={
                <div style={{ margin: '-5px -8px' }}>
                  {hiddenRisks.slice(8 - p1Risks.length, hiddenRisks.length).map((risk, index) => (
                    <div
                      style={{
                        borderBottom: index === params.row.risks.length - 6 ? 'none' : '1px solid #cbd5e0',
                        padding: '5px 8px',
                      }}
                      key={index}>
                      {risk}
                    </div>
                  ))}
                </div>
              }>
              <div style={{ fontSize: '10px', fontWeight: '600', color: '#194BFB', marginLeft: '10px' }}>
                +{params.row.risks.length - 8}
              </div>
            </Tooltip>
          )}
        </div>
      ) : (
        '-'
      );
    },
  }),
};

export default commonTableColumns;
