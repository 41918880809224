import { useCallback, useMemo } from 'react';

import agent from '@root/agent';
import { makeEnvironmentTagsKey } from '@root/query/environment-tags';
import { useQuery } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { SET_SELECTED_ENVIRONMENTS, SET_SELECTED_ENVIRONMENT_TAGS } from '@root/constants/actionTypes';
import _ from 'lodash';
let token = null;
const useEnvironmentsData = () => {
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    makeEnvironmentTagsKey(),
    agent.Environment.fetch_environments_and_cloud_providers,
    {
      enabled: token !== null,
    },
  );

  const onboarding = useMemo(() => {
    return data?.data && _.isArray(data.data) && _.isEmpty(data.data);
  }, [data]);

  return { data, isLoading, isFetching, isError, error, refetch, onboarding };
};

const useEnvironmentTags = () => {
  const { data } = useEnvironmentsData();

  const environmentTags = useMemo(() => {
    if (!data || !data.data || !data.data.length) return [];

    let tags = [];

    for (const env of data.data) {
      for (const tag of env.tags) {
        if (tag.type === 'primary') {
          tags.push(tag.name);
        }
      }
    }

    tags = tags.reduce((prev, curr) => (prev.includes(curr) ? prev : [...prev, curr]), []).filter(tag => tag);

    return ['Global', ...tags];
  }, [data]);

  return environmentTags;
};

const useEnvironments = () => {
  const { data } = useEnvironmentsData();

  const environments = useMemo(() => {
    if (!data || !data.data || !data.data.length) return [];

    let _environments = [];

    for (const env of data.data) {
      _environments.push({
        id: env.id,
        tags: env.tags.map(t => t.name),
        cloudProvider: env.cloud_provider,
        latest_scan_id: env.latest_scan_id,
        identity_scan_id: env.identity_scan_id,
      });
    }

    return _environments;
  }, [data]);

  return environments;
};

const useSelectedEnvironments = () => {
  const dispatch = useDispatch();

  const { selectedEnvironmentTags, selectedEnvironments } = useSelector(store => {
    return store.environments;
  });

  const setSelectedEnvironmentTags = useCallback(
    tags => {
      if (typeof tags === 'function') {
        // We treat this the same way as passing a callback to setState
        dispatch({ type: SET_SELECTED_ENVIRONMENT_TAGS, payload: tags(selectedEnvironmentTags) });
      } else {
        dispatch({ type: SET_SELECTED_ENVIRONMENT_TAGS, payload: tags });
      }
    },
    [dispatch, selectedEnvironmentTags],
  );

  const setSelectedEnvironments = useCallback(
    envs => {
      if (typeof envs === 'function') {
        // We treat this the same way as passing a callback to setState
        dispatch({ type: SET_SELECTED_ENVIRONMENTS, payload: envs(selectedEnvironments) });
      } else {
        dispatch({ type: SET_SELECTED_ENVIRONMENTS, payload: envs });
      }
    },
    [dispatch, selectedEnvironments],
  );

  return { selectedEnvironmentTags, selectedEnvironments, setSelectedEnvironmentTags, setSelectedEnvironments };
};

export { useEnvironmentsData, useEnvironmentTags, useEnvironments, useSelectedEnvironments };
export default {
  setToken: _token => {
    token = _token;
  },
};
