import { useCallback, useState } from 'react';

type sortDataType = {
  key: string;
  value: string;
};

const useSortData = () => {
  const [sortData, setSortData] = useState<sortDataType[]>([]);

  const handleSortModelChange = useCallback(
    (
      data: {
        field: string;
        sort: string;
      }[],
    ) => {
      if (!data?.[0]) {
        setSortData([]);
      } else {
        setSortData([
          {
            key: data[0].field,
            value: data[0].sort,
          },
        ]);
      }
    },
    [],
  );

  return { sortData, handleSortModelChange };
};

export default useSortData;
