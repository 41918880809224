import { Box } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import './style.scss';
import CustomDropdownOption from './TimeFilterDropdownOption';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import TimeRangeSelector from './TimeRange';
import { ChevronRight } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
const TimeFilterDropdown = ({ options, value, setValue, disabled, isLoading, singleSelect, onChange }) => {
  //   const options = [
  //     { label: 'Drift', children: ['Create', 'Modify', 'Delete'] },
  //     { label: 'Threat' },
  //     { label: 'Remediation', children: ['One Click / Manual', 'Auto Remediation'] },
  //   ];
  const [open, setOpen] = useState(false);
  const [openCustomSelector, setOpenCustomSelector] = useState(false);
  // const [values, setValues] = React.useState(dayjs('2022-04-17T15:30'));

  const ref = useRef(null);
  const width = useMemo(() => {
    if (['15m', '30m', '1h', '6h', '12h', '1d', '24h', '2d', '7d', '30d', '90d'].includes(value)) {
      return '160px';
    } else {
      return '300px';
    }
  }, [value]);

  const handleOutsideClick = e => {
    if (ref.current && !ref.current.contains(e.target) && !openCustomSelector) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  // useEffect(() => {
  //   if (value === 'custom') setOpenCustomSelector(true);
  // }, [value]);
  console.log(value, 'valuesss');
  return (
    <div
      className="CustomDropdown__Container"
      ref={ref}
      style={{ borderLeft: '1px solid #E2E8F0', paddingLeft: '15px', marginRight: '30px' }}>
      <Box
        className="CustomDropdown__ValueContainer"
        onClick={() => setOpen(prev => !prev)}
        style={{ pointerEvents: disabled || isLoading ? 'none' : '', width: width }}>
        <input
          className="CustomDropdown__ValueInput"
          onChange={() => {}}
          value={`Time Range: ${value}`}
          style={{ width: `calc(${width} - 35px)` }}
        />
        <div style={{ flexGrow: '1' }} />
        <Box
          className="CustomDropdown__DropdownArrow"
          style={{ transform: open ? 'rotate(180deg)' : '', transition: 'all 200ms', height: '40px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path
              d="M-3.1883e-08 9.7294C-2.31213e-08 9.52895 0.0703583 9.35078 0.211075 9.21715C0.492508 8.92762 0.985017 8.92762 1.26645 9.21715L4.50293 12.2684L7.71596 9.21715C7.99739 8.92762 8.4899 8.92762 8.77134 9.21715C9.07622 9.48441 9.07622 9.95212 8.77134 10.2194L5.01889 13.7829C4.73746 14.0724 4.24495 14.0724 3.96352 13.7829L0.211075 10.2194C0.0703582 10.0857 -3.96713e-08 9.90757 -3.1883e-08 9.7294Z"
              fill="#484D56"></path>
          </svg>
        </Box>
      </Box>

      <div style={{ display: open ? 'flex' : 'none' }}>
        <div style={{ display: 'block', width: '160px' }} className="CustomDropdown__Options">
          <div
            style={{
              height:
                location.pathname === '/events/all' ||
                location.pathname === '/events/suppressed' ||
                location.pathname === '/events/overview'
                  ? '211px'
                  : '173px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            className="CustomScrollbar">
            {options.map((option, index) => (
              <CustomDropdownOption
                key={index}
                option={option}
                index={index}
                length={options.length}
                onClick={onChange}
                selected={value === option.value}
                value={value}
                setFullOpen={setOpen}
                width={width}
                setValue={setValue}
                singleSelect={singleSelect}
                setOpenCustomSelector={setOpenCustomSelector}
              />
            ))}
          </div>
          {(location.pathname === '/events/all' ||
            location.pathname === '/events/suppressed' ||
            location.pathname === '/events/overview') && (
            <Box
              className={`CustomDropdown__Option ${openCustomSelector ? 'CustomDropdown__Option--selected' : ''}`}
              style={{
                borderTop: '1px solid var(--boder-line-light, #cbd5e0)',
                width: `calc(${'160px'} - 2px)`,
                boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.16)',
              }}
              onClick={() => {
                setOpenCustomSelector(true);
                // setFullOpen(false);
              }}>
              <DateRangeIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: '5px' }} />
              <div style={{ marginLeft: '2px' }}>{'Custom'}</div>
              <div style={{ flexGrow: '1' }} />
              <ChevronRight />

              {/* <CheckBox checked={selected} /> */}
            </Box>
          )}
        </div>
        {openCustomSelector && (
          <div
            className="CustomDropdown__Options"
            style={{ width: '262px', height: '256px', marginLeft: '160px', padding: '0px 15px', backgroundColor: '' }}>
            <TimeRangeSelector setOpenCustomSelector={setOpenCustomSelector} setOpen={setOpen} setValue={setValue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeFilterDropdown;
