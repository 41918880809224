import { useCallback } from 'react';
import { Grid, Box, Skeleton } from '@mui/material';

import ComputeIcon from '@root/assets/svg/Top Services Categories/Compute.svg';
import ContainersIcon from '@root/assets/svg/Top Services Categories/Containers.svg';
import Analytics from '@root/assets/svg/Top Services Categories/Data_Analytics.svg';
import Database from '@root/assets/svg/Top Services Categories/Database.svg';
import DeveloperIcon from '@root/assets/svg/Top Services Categories/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Top Services Categories/Machine_Learning.svg';
import ManagementToolsIcon from '@root/assets/svg/Top Services Categories/Management_Tools.svg';
import NetworkingIcon from '@root/assets/svg/Top Services Categories/Networking.svg';
import OperationIcon from '@root/assets/svg/Top Services Categories/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Top Services Categories/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Top Services Categories/Serverless.svg';
import StorageIcon from '@root/assets/svg/Top Services Categories/Storage.svg';
import externaIcon from '@root/assets/svg/external-link.svg';
// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';
import ContentBox from '@root/components/v2/content/ContentBox';
import LinearProgress from '@root/components/v2/ui-elements/LinearProgress';
import Text from '@root/components/v2/ui-elements/Text';
import { useHistory } from 'react-router';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import './components.scss';

const service_category_icons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
};

function Provider({ name, color }) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" marginRight="16px">
      <Box width="8px" height="8px" marginRight="5px" borderRadius="50%" backgroundColor={color} />
      <Text className="fs__1">{name}</Text>
    </Box>
  );
}

const InternetExposedResources = ({ data, isFetchingInternetExposedResources, cloudProvider }) => {
  const history = useHistory();
  const handleItemClick = useCallback(
    item => {
      const url = buildBreadcrumbUrl({
        path: `/inventory/resources-table?from=dashboard&isPublic=true&category=${item?.service_category}`,
        breadcrumbs: [
          BreadcrumbParts.Dashboard,
          { title: 'Resources' },
          { title: item?.service_category },
          { title: 'Internet Exposed Resources' },
        ],
      });
      history.push(url);
    },
    [history],
  );

  return (
    <Grid xs={4} height="440px" item>
      <ContentBox
        infoMsg="Resources having effective internet exposure across the connected environments"
        title="Internet Exposed Resources"
        // width={'100%'}
        titleSize="medium"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        paddingMode="content"
        headerEndElement={
          <Box display="flex" flexDirection="row" alignItems="center">
            <Provider color="#FFA825" name="AWS" />
            <Provider color="#03BB34" name="Google" />
            <Provider color="#476FFC" name="Azure" />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              onClick={() => {
                history.push(
                  buildBreadcrumbUrl({
                    path: `/inventory/resources-table?from=overview&&isPublic=true&resourceType=${JSON.stringify(
                      data?.map(item => item.resource_types || []).flat(),
                    )}&cp=${cloudProvider}`,
                    breadcrumbs: [
                      BreadcrumbParts.Dashboard,
                      {
                        title: 'Internet Exposed Resources',
                      },
                    ],
                  }),
                );
              }}>
              <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
            </Box>
          </Box>
        }
        containerClassName={'border-box-no-pad'}
        height="100%"
        containerStyle={{ display: 'flex', flexDirection: 'column' }}>
        {isFetchingInternetExposedResources ? (
          <Box display="flex" flexDirection="column" alignItems="center" height="315px" padding="15px">
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',

                // pt: '15px',
              }}>
              {Array.from(new Array(4)).map(i => (
                <Grid
                  key={i}
                  sx={{
                    border: '1px solid #cbd5e0',
                    padding: '10px 10px',
                    borderRadius: '5px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Grid>
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                      <Skeleton variant="circular" width="36px" height="36px" />
                    </Box>
                  </Grid>
                  <Grid sx={{ width: '100%', marginLeft: '10px' }}>
                    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                      <Skeleton width="100px" />
                      <Skeleton width="30px" />
                    </Box>
                    <Box width="100%">
                      <Skeleton width="100%" />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : cloudProvider === 'azure' ? (
          <NoData height={'100%'} />
        ) : !data?.length ? (
          <NoData height={'100%'} />
        ) : (
          <div className="dashboard_component" style={{ flexGrow: 1, flexShrink: 1, overflow: 'hidden' }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '15px 15px',
              }}
              className="CustomScrollbar">
              {data
                .sort((a, b) => b?.ie_resources - a?.ie_resources)
                .map((item, index) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      className="card"
                      marginTop="0 !important"
                      width="initial !important"
                      padding="10px 15px !important"
                      key={index}
                      onClick={() => {
                        // set resource ids in localStorage
                        localStorage.setItem('internetExposedResources', [...item.resource_ids]);
                        localStorage.setItem('internetExposedResourceTypes', [...item.resource_types]);
                        handleItemClick(item);
                      }}
                      borderRadius="5px !important"
                      boxShadow="none">
                      <Box marginBottom="0 !important" marginRight="15px">
                        <img
                          src={service_category_icons[item?.service_category]}
                          style={{ width: '30px', height: '30px' }}
                          alt=""
                        />
                      </Box>

                      <Box flexGrow={1}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom="8px">
                          <Text fontWeight={500} lineHeight={1} className="fs__2 service_type m-0">
                            {item?.service_category}
                          </Text>
                          <Text fontWeight={500} lineHeight={1} className="fs__2 total_count m-0">
                            {item?.ie_resources}
                          </Text>
                        </Box>
                        {/* <PieChartDashboard data={item?.cloud_breakup} /> */}
                        <LinearProgress
                          borderless
                          multi
                          max={(item?.cloud_breakup || []).reduce((acc, curr) => acc + curr.value || 0, 0)}
                          multiValues={[
                            {
                              tooltipText: `AWS: ${item?.cloud_breakup?.find(c => c.name === 'aws')?.value || 0}`,
                              value: item?.cloud_breakup?.find(c => c.name === 'aws')?.value || 0,
                              color: '#FFA825',
                            },
                            {
                              tooltipText: `Google: ${item?.cloud_breakup?.find(c => c.name === 'gcp')?.value || 0}`,
                              value: item?.cloud_breakup?.find(c => c.name === 'gcp')?.value || 0,
                              color: '#03BB34',
                            },
                            {
                              tooltipText: `Azure: ${item?.cloud_breakup?.find(c => c.name === 'azure')?.value || 0}`,
                              value: item?.cloud_breakup?.find(c => c.name === 'azure')?.value || 0,
                              color: '#476FFC',
                            },
                          ]}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </div>
          </div>
        )}
      </ContentBox>
    </Grid>
  );
};
export default InternetExposedResources;
