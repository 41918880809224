import { Grid, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import externaIcon from '@root/assets/svg/external-link.svg';
// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';
import ContentBox from '@root/components/v2/content/ContentBox';
import { useHistory } from 'react-router';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import ResourceTrendBarChart from '../../Charts/BarChart';
import './components.scss';

// const category_icons = { Compute: compute, Networking: network, Storage: storage };
const ResourceTrend = ({ data, isFetchingResourceTrend, cloudProvider }) => {
  const history = useHistory();

  return (
    <Grid>
      <ContentBox
      infoMsg={"Resource trend based on create, modify & delete events"}
        title="Resource Trend"
        // width="300px"
        titleSize="medium"
        height="500px"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottom: '1px solid #CBD5E0',

          margin: '0px',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        headerEndElement={
          <div
            onClick={() => {
              history.push(
                buildBreadcrumbUrl({
                  path: '/events/all',
                  breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'All Events' }, { title: 'Drift' }],
                }),
              );
            }}>
            <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
          </div>
        }
        containerClassName={'border-box-no-pad'}
        paddingMode="content">
        {cloudProvider === 'azure' ? (
          <NoData height={'100%'} />
        ) : (
          <Grid className="dashboard_component" sx={{ height: '100%' }}>
            <Grid sx={{ height: '100%', paddingBottom: '25px' }}>
              {isFetchingResourceTrend ? (
                <Box position={'relative'} height={'100%'}>
                  
                     <Box  height="83%">
                     <Skeleton variant="reactangluar" height="100%" style={{ transform: 'none' }} sx={{ margin: '15px' }} />
                   </Box>
                   <Grid sx={{ display: 'flex', margin: '15px 0px', justifyContent: 'center' }}>
                     <Skeleton height={20} width={100} style={{ transform: 'none' }} sx={{ marginRight: '15px' }} />
                     <Skeleton height={20} width={100} style={{ transform: 'none' }} sx={{ marginRight: '15px' }} />
                     <Skeleton height={20} width={100} style={{ transform: 'none' }} />
                   </Grid>
                </Box>
              ) : data?.service_category_breakup?.length === 0 ? (
                <NoData height={'100%'} />
              ) : (
                <ResourceTrendBarChart data={data} />
              )}
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </Grid>
  );
};
export default ResourceTrend;
