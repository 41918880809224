import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';

import fix_available_check from '@root/assets/svg/workload/fix_available_check.svg';
import info from '@root/assets/svg/workload/info.svg';
import question1 from '@root/assets/svg/workload/question1.png';
import sources from '@root/assets/svg/workload/sources.svg';
import threat_trend_cisa from '@root/assets/svg/workload/threat_trend_cisa.svg';
import threat_trend_epss from '@root/assets/svg/workload/threat_trend_epss.svg';
import exploit from '@root/assets/img/Exploit.svg';
import exposed from '@root/assets/img/exposed.svg';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';
// import HighIcon from '@root/components/svg/high';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';

export const columns = (open, setOpen, setShowDetailsPanel, setThreatModelData) => {
  return [
    {
      field: 'cve_id',
      headerName: 'CVE ID',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 200,
      sort: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row.cve_id}>
            <Text font="regular" className="SingleLine">
              {params.row.cve_id}
            </Text>
          </Tooltip>
        );
      },
    },
    {
      field: 'severity',
      headerName: 'CVSS Severity',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 170,
      sort: true,
      renderCell: params => {
        return (
          <Tooltip title={params.row.severity}>
            {params.row.severity.toUpperCase() === 'HIGH' ? (
              // <HighIcon textDisabled={true} />
              <img src={highIcon} />
            ) : params.row.severity.toUpperCase() === 'CRITICAL' ? (
              // <CriticalIcon textDisabled={true} />
              <img src={criticalIcon} />
            ) : params.row.severity.toUpperCase() === 'MEDIUM' ? (
              // <MediumIcon textDisabled={true} />
              <img src={mediumIcon} style={{ width: '88px' }} />
            ) : params.row.severity.toUpperCase() === 'LOW' ? (
              // <LowIcon textDisabled={true} />
              <img src={lowIcon} />
            ) : params.row.severity.toUpperCase() === 'INFORMATIONAL' ? (
              <img
                src={info}
                style={{
                  height:
                    params.row.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            ) : (
              <img
                src={question1}
                style={{
                  height:
                    params.row.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                  width:
                    params.row.severity.toUpperCase() === 'INFORMATIONAL' ||
                    params.row.severity.toUpperCase() === 'UNTRIAGED'
                      ? '30px'
                      : '',
                }}
              />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: 'details',
      headerName: 'CVSS',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 140,
      sort: true,
      renderCell: params => {
        return (
          <span
            style={{
              backgroundColor: '#E2E8F0',
              borderRadius: '50%',
              width: '35px',
              height: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: '#484D56',
              border: '1px solid #CBD5E0',
            }}>
            {params.row?.['cvss_score'] === 0 ? 0 : parseFloat(params.row?.['cvss_score']).toFixed(1)}
          </span>
        );
      },
    },
    {
      field: 'cve_risk',
      headerName: 'CVE Risks',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 150,
      sort: true,
      renderCell: params =>
        params.row.cve_risk === '-' || params.row.cve_risk?.length === 0 ? (
          '-'
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            {params.row.cve_risk?.exploit && (
              <Tooltip title={'Exploit'}>
                <img src={exploit} />
              </Tooltip>
            )}

            {params.row.cve_risk?.internet_exposed && (
              <Tooltip title={'Internet Exposed'}>
                <img src={exposed} />
              </Tooltip>
            )}
          </div>
        ),
    },
    {
      field: 'sources',
      headerName: 'Sources',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 140,
      sort: true,
      renderCell: params => {
        return params.row.sources === 'CQ Shield Engine' ? (
          <Tooltip title={'CQ Shield Engine'}>
            <img src={sources} />
          </Tooltip>
        ) : (
          params.row.sources
        );
      },
    },
    {
      field: 'resources',
      headerName: 'Resources',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 140,
      sort: true,
    },
    {
      field: 'fix',
      headerName: 'Fix',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 120,
      sort: true,
      renderCell: params => {
        return params.row.fix === 'YES' ? (
          <img src={fix_available_check} />
        ) : params.row.fix === '-' ? (
          '-'
        ) : (
          params.row.fix
        );
      },
    },
    {
      field: 'threat_trend_data',
      headerName: 'Threat Trend',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 160,
      sort: true,
      renderCell: params => {
        console.log('paramsThreat', params);
        return params.row.threat_trend.cisa !== 0 || params.row.threat_trend.epss !== 0 ? (
          <>
            {params.row.threat_trend.cisa !== 0 ? (
              <Tooltip title={'Cisa'}>
                <img
                  src={threat_trend_cisa}
                  style={{ marginRight: '16px' }}
                  onClick={() => {
                    // setShowDetailsPanel(false);
                    setThreatModelData(params.row);
                    setOpen(true);

                    // event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
            {params.row.threat_trend.epss !== 0 ? (
              <Tooltip title={'EPSS'}>
                <img
                  src={threat_trend_epss}
                  onClick={() => {
                    // setShowDetailsPanel(false);
                    setThreatModelData(params.row);
                    setOpen(true);
                    // event.stopPropagation();
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'state',
      headerName: 'Status',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 120,
      sort: true,
      renderCell: params => (params.row.state === 'CLOSED' ? 'Inactive' : 'Active'),
    },
    // {
    //   field: 'jira_status',
    //   headerName: 'Jira Status',
    //   classes: 'align-middle',
    //   headerClassName: 'fs__2',
    //   minWidth: 150,
    //   sort: true,
    //   renderCell: params => {
    //     return params.row.jira_status === '-' ? '-' : <img src={jira_status} />;
    //   },
    // },
    {
      field: 'last_seen',
      headerName: 'Last Seen',
      classes: 'align-middle',
      headerClassName: 'fs__2',
      minWidth: 200,
      sort: true,
      // renderCell: params => {
      //   const [datePart, timePart] = params.row.last_seen.split('T');
      //   return `${datePart}   ${timePart}`;
      // },
      renderCell: params => DateFormatPipe(params?.row?.last_seen),
    },
  ];
};
