import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './charts.scss';
import NetworkingIcon from '@root/assets/svg/Service_Category_Icons/Networking.svg';
import ComputeIcon from '@root/assets/svg/Service_Category_Icons/Compute.svg';
import DeveloperIcon from '@root/assets/svg/Service_Category_Icons/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Service_Category_Icons/Machine_Learning.svg';
import StorageIcon from '@root/assets/svg/Service_Category_Icons/Storage.svg';
import OperationIcon from '@root/assets/svg/Service_Category_Icons/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Service_Category_Icons/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Service_Category_Icons/Serverless.svg';
import ManagementToolsIcon from '@root/assets/svg/Service_Category_Icons/Management_Tools.svg';
import ContainersIcon from '@root/assets/svg/Service_Category_Icons/Containers.svg';
import Analytics from '@root/assets/svg/Service_Category_Icons/Data_Analytics.svg';
import Database from '@root/assets/svg/Service_Category_Icons/Database.svg';

const service_category_icons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
};

// const testData = [
//   {
//     name: 'Networking',
//     create: 2,
//     modify: 3,
//     delete: 0,
//   },
//   {
//     name: 'Serverless',
//     create: 2,
//     modify: 3,
//     delete: 3,
//   },
//   {
//     name: 'Compute',
//     create: 2,
//     modify: 0,
//     delete: 0,
//   },
//   {
//     name: 'Operations',
//     create: 2,
//     modify: 0,
//     delete: 0,
//   },
// ];

const CustomLegend = ({ payload, data }) => {
  const totalCreate = data?.total_events?.Create || 0;
  const totalModify = data?.total_events?.Modify || 0;
  const totalDelete = data?.total_events?.Delete || 0;

  return (
    <div
      className="custom-legend-resource-trend"
      style={{ display: 'flex', justifyContent: 'center', width: '100%', marginLeft: '26px' }}>
      <div
        // className="div"
        style={{
          // width: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#FAFAFA',
          padding: '10px',
          borderRadius: '5px',
          gap: '15px',
        }}>
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            className="legend-item"
            style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <span
              style={{
                width: '14px',
                height: '14px',
                background: entry.color,
                display: 'block',
              }}></span>
            <span className="legend-text">
              {entry.value === 'create'
                ? `Create (${totalCreate})`
                : entry.value === 'modify'
                ? `Modify (${totalModify})`
                : entry.value === 'delete'
                ? `Delete (${totalDelete})`
                : ''}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomXAxisLabel = ({ x, y, payload }) => {
  // const svgImage = payload.value === 'Label1' ? yourSvgImage1 : yourSvgImage2; // Use the appropriate SVG based on the label value
  const svgImage = service_category_icons[payload.value]; // Use the appropriate SVG based on the label value
  return (
    <g transform={`translate(${x},${y})`}>
      {svgImage && <image x="-15" y="-8" width="20" height="17" href={svgImage} />}
      {!svgImage && (
        <text x="0" y="0" dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      )}
    </g>
  );
};

// const CustomBar = props => {
//   const { x, y, width, height } = props;

//   let lastBar = 'delete'; // default last bar
//   if (props.payload.delete == 0) {
//     // now check for modify
//     if (props.payload.modify) {
//       lastBar = 'modify';
//     } else {
//       lastBar = 'create';
//     }
//   }

//   const borderRadius = 10;
//   const roundedCornerPath = `
//         M ${x},${y}
//         H ${x + width - borderRadius}
//         A ${borderRadius},${borderRadius} 0 0 1 ${x + width},${y + borderRadius}
//         V ${y + height - borderRadius}
//         A ${borderRadius},${borderRadius} 0 0 1 ${x + width - borderRadius},${y + height}
//         H ${x}
//         V ${y}
//         Z
//       `;

//   const normalPath = `
//         M ${x},${y}
//         L ${x + width},${y}
//         L ${x + width},${y + height}
//         L ${x},${y + height}
//         Z
//       `;

//   // write a condition that only applies the roundedCornerPath on the right most bar

//   return (
//     <g>
//       {lastBar === props.tooltipPayload[0].dataKey ? (
//         <path d={roundedCornerPath} fill={props.fill} />
//       ) : (
//         <path d={normalPath} fill={props.fill} />
//       )}
//     </g>
//   );
// };

const ResourceTrendBarChart = ({ data }) => {
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const formattedData = data.service_category_breakup?.map(category => {
      return {
        name: category.service_category_name,
        create: category.total_events.Create,
        modify: category.total_events.Modify,
        delete: category.total_events.Delete,
      };
    });

    setCategoryData(formattedData);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%" className="resource_trend_chart">
      <BarChart
        data={categoryData}
        layout="vertical" // Set the layout to 'vertical'
        margin={{
          top: 15,
          right: 27,
          left: 0, // Adjust the left margin to make space for icons
          bottom: 30,
        }}
        barSize={20}
        barCategoryGap={3}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <YAxis
          dataKey="name"
          // scale="point"
          type="category"
          // baseValue="dataMin"
          padding={{ top: 10, bottom: 10 }}
          tick={<CustomXAxisLabel />}
        />
        <XAxis type="number" padding={{ left: 10, right: 10 }} domain={[0, 'dataMax']} />
        <Tooltip cursor={{ fill: 'transparent' }} itemStyle={{ textTransform: 'capitalize' }} />
        <Legend content={<CustomLegend data={data} />} />
        <Bar dataKey="create" stackId="a" fill="#039BE5" />
        <Bar dataKey="modify" stackId="a" fill="#BA68C8" />
        <Bar dataKey="delete" stackId="a" fill="#C2185B" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ResourceTrendBarChart;
