import React, { useMemo, useState, useCallback, useEffect, useRef, ReactElement } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { json } from 'react-syntax-highlighter/dist/esm/languages/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { FixedSizeList, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

import IconButton from '@root/components/v2/ui-elements/IconButton';
import Text from '@root/components/v2/ui-elements/Text';
import Button from '@root/components/v2/ui-elements/Button';

import LeftRightArrows from '@root/assets/svg/identity/optimize-modal/left-right-arrows.svg';
import DownloadIcon from '@root/assets/svg/identity/optimize-modal/download.svg';
import CopyIcon from '@root/assets/svg/identity/optimize-modal/copy.svg';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import useDebouncedValue from '@root/hooks/useDebouncedValue';
import SearchBar from './SearchBar';
import Pre from './Pre';

import { LINE_HEIGHT_PX } from './constants';
import createElement from './createElement';

import './style.scss';
// import TEST from './test-json.json';
// const TEST_JSON = JSON.stringify(TEST, null, 2);

interface Props {
  isNested?: boolean;
  code: string;
  height: string | number;
  width?: string | number;
  flexGrow: number;
  className: string;
  wrapText?: boolean;
  onDownloadClick?: () => void;
  onCopyClick?: () => void;
  insights?: boolean;
  containerStyle?: CSSStyleSheet;
}

function LineContainerInner({
  index,
  prevHeight,
  setItemHeight,
  windowWidth,
  children,
}: {
  index: number;
  prevHeight: number;
  windowWidth: number; // Used to respond to window size change
  setItemHeight: (index: number, height: number) => void;
  children: ReactElement | ReactElement[];
}) {
  const ref = useRef<any>();

  const setRef = useCallback(
    el => {
      ref.current = el;
      if (el) {
        const height = ref.current.getBoundingClientRect().height;
        if (height !== prevHeight) {
          setItemHeight(index, height);
        }
      }
    },
    [index, prevHeight, setItemHeight],
  );

  useEffect(() => {
    // Update row size on window resize
    if (windowWidth && ref.current) {
      const height = ref.current.getBoundingClientRect().height;
      if (height !== prevHeight) {
        setItemHeight(index, height);
      }
    }
  }, [index, prevHeight, setItemHeight, windowWidth]);

  return (
    <div className="CodeContainer__LineContainer__Inner" style={{ minHeight: LINE_HEIGHT_PX }} ref={setRef}>
      {children}
    </div>
  );
}

function CodeContainer({
  isNested,
  code,
  height,
  width,
  flexGrow,
  className,
  wrapText,
  onDownloadClick,
  onCopyClick,
  insights = false,
  containerStyle,
}: Props) {
  const [isReady, setIsReady] = useState(false); // We delay rendering of SyntaxHighlighter to not slow down Modal opening
  const [openFullscreen, setOpenFullscreen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebouncedValue(searchString, 500);
  // const [scrollToLine, setScrollToLine] = useState(1);
  const [rows, setRows] = useState([]);
  const [currentResult, setCurrentResult] = useState<number>(0);
  const virtualizedListRef = useRef<any>();
  const [isRowsDataGenerated, setIsRowsDataGenerated] = useState(false);
  const rowHeightsMapRef = useRef<any>({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const getItemSize = useCallback(index => {
    return Math.max(rowHeightsMapRef.current[index] || LINE_HEIGHT_PX, LINE_HEIGHT_PX);
  }, []);

  const setItemHeight = useCallback((index: number, height: number) => {
    rowHeightsMapRef.current = { ...rowHeightsMapRef.current, [index]: height };
    if (virtualizedListRef.current) {
      virtualizedListRef.current.resetAfterIndex(index, true);
    }
  }, []);

  /**
   * searchResults is an array that is structurally similar to the
   * object generated by SyntaxHighlighter. At each place where the
   * search string is matched, we add a 1, and everywhere else, we
   * add a 0.
   *
   * Example: [[0], [0, 1, 0], [0, 0, 0]]
   */
  const searchResults = useMemo<any>(() => {
    const matches: any[] = [];
    let totalMatches = 0;

    if (!debouncedSearchString) return { matches: [], totalMatches };

    const searchTerm = String(debouncedSearchString).toLowerCase();

    rows.forEach((row: any) => {
      const tokenMatches: any[] = [];

      row.children.forEach((c: any) => {
        if (c.children) {
          const token = c.children[0];

          if (token.type === 'text') {
            const isLineNumber = c?.properties?.className?.includes('linenumber');
            if (isLineNumber) {
              tokenMatches.push(0);
            } else {
              const value = String(token.value);

              if (value.toLowerCase().includes(searchTerm)) {
                tokenMatches.push(1);
                totalMatches += 1;
              } else {
                tokenMatches.push(0);
              }
            }
          } else {
            tokenMatches.push(0);
          }
        }
      });

      matches.push(tokenMatches);
    });

    return { matches, totalMatches };
  }, [rows, debouncedSearchString]);

  const currentResultData = useMemo(() => {
    let resultData = { rowIndex: 0, tokenIndex: 0 };

    let resultIndex = 0;
    let rowIndex = 0;
    let tokenIndex = 0;

    for (const row of searchResults.matches) {
      for (const token of row) {
        if (token) {
          resultData = { rowIndex, tokenIndex };

          if (resultIndex === currentResult) {
            return resultData;
          }

          resultIndex += 1;
        }

        tokenIndex += 1;
      }

      rowIndex += 1;
      tokenIndex = 0;
    }

    return resultData;
  }, [currentResult, searchResults]);

  const scrollToItem = useMemo(
    () => (searchString ? currentResultData.rowIndex : -1),
    [currentResultData, searchString],
  );

  const setListRef = useCallback((ref: any) => {
    virtualizedListRef.current = ref;
    if (ref) {
      // To trigger updating of rowHeightsMapRef
      virtualizedListRef.current.resetAfterIndex(0, true);
    }
  }, []);

  const getRowComponent = useCallback(
    ({ rows, stylesheet, useInlineStyles }) => {
      return ({ index, style }: any) =>
        wrapText ? (
          <div className="CodeContainer__LineContainer" style={style}>
            <LineContainerInner
              index={index}
              prevHeight={rowHeightsMapRef.current[index] || LINE_HEIGHT_PX}
              setItemHeight={setItemHeight}
              windowWidth={windowWidth}>
              {createElement({
                node: rows[index],
                stylesheet,
                style: {},
                useInlineStyles,
                key: index,
                searchString: debouncedSearchString,
                searchMatches: searchResults.matches,
                currentResultData,
              })}
            </LineContainerInner>
          </div>
        ) : (
          createElement({
            node: rows[index],
            stylesheet,
            style,
            useInlineStyles,
            key: index,
            searchString: debouncedSearchString,
            searchMatches: searchResults.matches,
            currentResultData,
          })
        );
    },
    [wrapText, setItemHeight, windowWidth, debouncedSearchString, searchResults.matches, currentResultData],
  );

  const virtualizedRenderer = useCallback(
    ({ rows, stylesheet, useInlineStyles }) => {
      const _rows = rows.map(({ children, ...row }: any, rowIndex: number) => {
        return {
          children: children.map((token: any, tokenIndex: number) => ({
            rowIndex,
            tokenIndex,
            ...token,
          })),
          rowIndex,
          ...row,
        };
      });

      if (!isRowsDataGenerated) {
        // Prevent endless re-renders
        setIsRowsDataGenerated(true);
        setRows(prev => (!prev.length ? rows : prev));
      }

      return (
        <div style={{ height: '100%' }}>
          <AutoSizer>
            {({ height, width }: any) =>
              wrapText ? (
                <VariableSizeList
                  ref={setListRef}
                  height={height}
                  width={width}
                  estimatedItemSize={LINE_HEIGHT_PX}
                  itemSize={getItemSize}
                  itemCount={rows.length}>
                  {getRowComponent({ rows: _rows, stylesheet, useInlineStyles })}
                </VariableSizeList>
              ) : (
                <FixedSizeList
                  ref={virtualizedListRef}
                  height={height}
                  width={width}
                  itemSize={LINE_HEIGHT_PX}
                  itemCount={rows.length}>
                  {getRowComponent({ rows: _rows, stylesheet, useInlineStyles })}
                </FixedSizeList>
              )
            }
          </AutoSizer>
        </div>
      );
    },
    [getItemSize, getRowComponent, isRowsDataGenerated, setListRef, wrapText],
  );

  const handlePrevResultButtonClick = useCallback(() => {
    if (currentResult === 0) {
      setCurrentResult(searchResults.totalMatches - 1);
    } else {
      setCurrentResult(prev => prev - 1);
    }
  }, [currentResult, searchResults]);

  const handleNextResultButtonClick = useCallback(() => {
    if (currentResult === searchResults.totalMatches - 1) {
      setCurrentResult(0);
    } else {
      setCurrentResult(prev => prev + 1);
    }
  }, [currentResult, searchResults]);

  useEffect(() => {
    if (debouncedSearchString) {
      setCurrentResult(0);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (code) {
      setIsRowsDataGenerated(false);
    }
  }, [code]);

  // Styles
  const lineNumberStyle = useMemo<any>(() => {
    return {
      textAlign: 'right',
      width: '52px',
      maxWidth: '52px',
      minWidth: '52px',
      ...(!wrapText && { height: LINE_HEIGHT_PX }),
      paddingRight: '11px',
      backgroundColor: '#EDF2F7',
    };
  }, [wrapText]);

  const customStyle = useMemo<any>(() => {
    return { backgroundColor: 'transparent', height: '100%' };
  }, []);

  const codeTagProps = useMemo<any>(() => {
    return { style: { height: '100%' } };
  }, []);

  const lineProps = useMemo<any>(() => {
    return {
      style: {
        display: 'block',
        minHeight: LINE_HEIGHT_PX,
      },
    };
  }, []);

  const highlighter = useMemo(() => {
    return (
      <SyntaxHighlighter
        showLineNumbers
        lineNumberStyle={lineNumberStyle}
        customStyle={customStyle}
        codeTagProps={codeTagProps}
        language="json"
        wrapLines
        wrapLongLines={wrapText}
        lineProps={lineProps}
        renderer={virtualizedRenderer}
        PreTag={Pre}
        style={json}
        className="CustomScrollbar">
        {code}
      </SyntaxHighlighter>
    );
  }, [code, codeTagProps, customStyle, lineNumberStyle, lineProps, virtualizedRenderer, wrapText]);

  const toggleFullscreen = useCallback(() => {
    setOpenFullscreen(prev => !prev);
  }, []);

  useEffect(() => {
    if (scrollToItem && virtualizedListRef.current) {
      virtualizedListRef.current.scrollToItem(scrollToItem);
    }
  }, [scrollToItem]);

  useEffect(() => {
    let mounted = true;

    setTimeout(() => {
      if (mounted) {
        setIsReady(true);
      }
    }, 20);

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const cb = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', cb);

    return () => window.removeEventListener('resize', cb);
  });

  return (
    <>
      <Box
        className={`CodeContainer__Container ${className || ''}`}
        style={{
          height: height || '400px',
          width: width || 'auto',
          flexGrow: width ? 0 : flexGrow || 1,
          ...(containerStyle || {}),
        }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="40px"
          paddingRight="8px"
          borderBottom="1px solid #E2E9EF"
          className="IdentityOptimizeModal__PanelHeader">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="40px"
            width="52px"
            marginRight="8px"
            borderBottom="1px solid #E2E9EF"
            style={{ backgroundColor: '#EDF2F7' }}>
            <img src={LeftRightArrows} />
          </Box>

          <SearchBar value={searchString} insights={insights} onChange={setSearchString} />

          {searchString && searchResults.totalMatches ? (
            <>
              <IconButton
                size="small"
                style={{ width: 24, height: 24 }}
                borderless
                onClick={handlePrevResultButtonClick}>
                <ArrowBackIosNew className="CodeContainer__ArrowIcons" />
              </IconButton>
              <IconButton
                size="small"
                style={{ width: 24, height: 24 }}
                borderless
                onClick={handleNextResultButtonClick}>
                <ArrowForwardIos className="CodeContainer__ArrowIcons" />
              </IconButton>

              <Text className="CodeContainer__ResultsCount fs__2">
                {currentResult + 1} of {searchResults.totalMatches}
              </Text>
            </>
          ) : null}

          <Box flexGrow={1} />

          <Box display="flex" flexDirection="row" flexShrink={0}>
            {onDownloadClick ? (
              <IconButton
                size="small"
                style={{ width: 24, height: 24, marginRight: 8 }}
                borderless
                onClick={onDownloadClick}>
                <img src={DownloadIcon} style={{ width: '17px' }} alt="" />
              </IconButton>
            ) : null}

            {onCopyClick ? (
              <IconButton
                size="small"
                style={{ width: 24, height: 24, marginRight: 8 }}
                borderless
                onClick={onCopyClick}>
                <img src={CopyIcon} style={{ height: '14px' }} alt="" />
              </IconButton>
            ) : null}

            {/* <IconButton size="small" style={{ width: 24, height: 24 }}>
              <img src={ArrowDownIcon} style={{ width: '14px' }} alt="" />
            </IconButton> */}
          </Box>
        </Box>

        <Box className="CodeContainer__Inner" flexGrow={1}>
          {isReady ? (
            highlighter
          ) : (
            <Box height="100%" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={24} disableShrink />
            </Box>
          )}
        </Box>

        {!isNested ? (
          <IconButton className="CodeContainer__FullscreenButton" onClick={toggleFullscreen} circular borderless>
            <FullscreenIcon />
          </IconButton>
        ) : null}
      </Box>

      {!isNested ? (
        <Dialog
          fullWidth
          fullScreen
          style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
          PaperProps={{ style: { height: '100vh' } }}
          open={openFullscreen}>
          <DialogTitle>View JSON</DialogTitle>

          <DialogContent style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 0 }}>
            <CodeContainer
              code={code}
              onDownloadClick={onDownloadClick}
              onCopyClick={onCopyClick}
              height="100%"
              flexGrow={1}
              className="IdentityOptimizeModal__CodeContainer"
              isNested
            />
          </DialogContent>

          <DialogActions style={{ padding: 20 }}>
            <Button backgroundColor="neutral" onClick={toggleFullscreen}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

CodeContainer.defaultProps = {
  deferRendering: true,
  wrapText: false,
};

const CodeContainerMemo = React.memo(CodeContainer);
export default CodeContainerMemo;
