import Progressbar from 'react-js-progressbar';

const PieChartWithNeedle = ({ score = 0 }) => {
  // const [percentage, setPercentage] = useState(0);

  // const change_progressbar_input = () => {
  //   setPercentage(50);
  // };

  return (
    <>
      <div id="progressbarContainer" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Progressbar
          input={score}
          shape={'semi circle'}
          pathLinecap={'round'}
          pathShadow={'none'}
          size={'213px'}
          pathWidth={26}
          clockwise={false}
          pathColor={'#CD0404'} // use an array for gradient color.
          trailWidth={26}
          trailColor="#EDF2F7" // use a string for solid color.
          textStyle={{ fill: '#484D56', fontSize: '28px', fontFamily: 'Manrope', fontWeight: 700 }} // middle text style
        >
          <span
            style={{
              position: 'absolute',
              left: '35%',
              top: '56%',
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: '#484D56',
            }}>
            {' '}
            Compliant
          </span>
        </Progressbar>
      </div>
    </>
  );
};
export default PieChartWithNeedle;
