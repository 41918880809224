import { toast } from "react-toastify";
import * as XLSX from "xlsx";

// const processStringList = stringList => {
//   //   return JSON.stringify(stringList);
//   let res = '';
//   Object.keys(stringList).forEach(key => {
//     res += `${key}: ${stringList[key]}\n`;
//   });
//   console.log(res, 'dqatatatat');
//   return String(res);
// };

export const ExportPipe = (data) => {
  let exportableName = "Remediation Report";

  let HeadingForCloudEventsTable = [Object.keys(data[0])];

  const ws_scan_report = XLSX.utils.book_new();
  XLSX.utils.sheet_add_json(ws_scan_report, data, {
    origin: "A2",
    skipHeader: true,
  });

  XLSX.utils.sheet_add_aoa(ws_scan_report, HeadingForCloudEventsTable);

  XLSX.utils.book_append_sheet(
    ws_scan_report,
    ws_scan_report,
    "Remediation Report"
  );
  toast.dismiss();
  toast.success("Your Report is ready!");
  XLSX.writeFile(ws_scan_report, exportableName + ".xlsx");
};
