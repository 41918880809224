import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
// import PieCharts from '@root/pages/inventory1.3/charts/PieChart';
import { Box, Grid, Skeleton } from '@mui/material';
import agent from '@root/agent';
// import PieChart from '@root/components/v2/charts/PieChart';
// import { PieChart } from '@mui/x-charts';
import ContentBox from '@root/components/v2/content/ContentBox';
// import Text from '@root/components/v2/ui-elements/Text';
import { useSelectedEnvironments } from '@root/hooks/environments';
import { makeOpenThreatsAndAnamolyKey } from '@root/query/dashboard';
import { useQuery } from '@tanstack/react-query';

// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';

import './CQShieldPriority.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
// const TIMEZONE = dayjs.tz.guess();

const colorMapping = {
  Critical: '#CD0404',
  High: '#F62121',
  Medium: '#FFA825',
  Low: '#E8DF07',
  Info: '#476FFC',
  Warning: '#ED5656',
};

const InfoComponent = ({ severity, severity_count, onClick }) => {
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      onClick(severity.toLowerCase());
    },
    [severity, onClick],
  );
  return (
    <Box
      onClick={severity_count > 0 ? handleClick : () => {}}
      sx={{
        height: '80px',
        border: '1px solid #CBD5E0',
        borderRadius: '8px',
        cursor: severity_count ? 'pointer' : 'default',
        padding: '12px 16px 12px 16px',
        '&:hover': {
          boxShadow: severity_count > 0 ? '0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -1px rgba(0, 0, 0, 0.12)' : '',
        },
      }}>
      <Box sx={{ dislay: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '20px' }}>
          <Box
            sx={{
              height: '12px',
              width: '12px',
              borderRadius: '100%',
              backgroundColor: colorMapping[severity],
            }}></Box>
          <p
            style={{
              padding: 0,
              margin: 0,
              marginLeft: '8px',
              lineHeight: '20px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              fontSize: '14px',
              color: ' rgb(26, 32, 44)',
            }}>
            {severity}
          </p>
        </Box>
        <p
          style={{
            padding: 0,
            margin: 0,
            marginTop: '8px',
            fontFamily: 'Manrope',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            textAlign: 'left',
            color: '#242424',
          }}>
          {severity_count}
        </p>
      </Box>
    </Box>
  );
};

type Props = {
  cloudProvider: string;
  dateFilter: string;
  timezone: any;
};

function OpenThreatsAndAnamoly({ cloudProvider, timezone }: Props) {
  const history = useHistory();
  const { selectedEnvironments, selectedEnvironmentTags } = useSelectedEnvironments();
  // const [selectedValue, setSelectedValue] = useState('');
  const queryEnabled = useMemo(
    () =>
      Boolean(
        (selectedEnvironments?.length && cloudProvider === 'all') || cloudProvider === 'aws' || cloudProvider === 'gcp',
      ),
    [cloudProvider, selectedEnvironments],
  );

  const { data: openThreatsData, isFetching } = useQuery(
    makeOpenThreatsAndAnamolyKey(
      selectedEnvironmentTags,
      selectedEnvironments?.map((e: any) => e.id) || [],
      cloudProvider,
      timezone,
    ),
    () =>
      agent.Dashboard_New.get_open_threat_anamoly({
        scope: selectedEnvironmentTags,
        environments: selectedEnvironments?.map((e: any) => e.id) || [],
        cloud_provider: cloudProvider,
        filter: '90days',
        timezone: timezone,
      }),
    {
      enabled: queryEnabled,
    },
  );

  const _openThreatsData = useMemo(() => {
    return {
      critical: openThreatsData?.data?.threat_by_severity.critical || 0,
      high: openThreatsData?.data?.threat_by_severity.high || 0,
      low: openThreatsData?.data?.threat_by_severity.low || 0,
      medium: openThreatsData?.data?.threat_by_severity.meidum || 0,
      warning: openThreatsData?.data?.threat_by_severity.warning || 0,
      info: openThreatsData?.data?.threat_by_severity.info || 0,
      total: openThreatsData?.data?.threat_by_severity.total_threats || 0,
    };
  }, [openThreatsData]);

  const handleCountClick = useCallback(
    (priority: any) => {
      history.push(`/events/all?tab=Threat&severity=${priority}&fromDashBoardOpenThreats=true`);
    },
    [history],
  );

  // const valueArray = useMemo(() => {
  //   return [
  //     { value: _openThreatsData.critical, name: 'Critical' },
  //     { value: _openThreatsData.high, name: 'High' },
  //     { value: _openThreatsData.medium, name: 'Medium' },
  //     { value: _openThreatsData.low, name: 'Low' },
  //     { value: _openThreatsData.warning, name: 'Warning' },
  //     { value: _openThreatsData.info, name: 'Info' },
  //   ];
  // }, [_openThreatsData]);

  return (
    <Grid xs={6} item>
      <ContentBox
        title="Open Threats / Anomaly"
        height="264px"
        containerStyle={{ display: 'flex', flexDirection: 'column', borderRadius: '5px' }}
        paddingMode="content">
        {cloudProvider === 'azure' ? (
          <NoData height={'100%'} />
        ) : isFetching ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="264px"
            padding="15px"
            marginBottom="10px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              width="100%"
              justifyContent="space-between">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                borderRadius="5px"
                component="div">
                <Box minWidth="100%" minHeight="100px">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={'space-between'}
                    gap={'8px'}
                    height={'90px'}
                    alignItems={'center'}>
                    <Skeleton width="34%" height="120px" />
                    <Skeleton width="34%" height="120px" />
                    <Skeleton width="34%" height="120px" />
                  </Box>
                  <Grid sx={{ width: '100%' }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={'space-between'}
                      gap={'8px'}
                      alignItems={'center'}
                      height={'90px'}>
                      <Skeleton width="34%" height="120px" />
                      <Skeleton width="34%" height="120px" />
                      <Skeleton width="34%" height="120px" />
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" height="100%">
            <Box
              sx={{
                height: '220px',
                width: '100%',

                // border: '1px solid red',
                padding: '15px',
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: '10px',
              }}>
              <Grid direction="row" spacing="8px" container>
                <Grid item xs={4} sx={{ paddingTop: '0px !important' }}>
                  <InfoComponent
                    severity={'Critical'}
                    onClick={handleCountClick}
                    severity_count={_openThreatsData.critical}
                  />
                </Grid>
                <Grid item xs={4} sx={{ paddingTop: '0px !important' }}>
                  <InfoComponent severity={'High'} onClick={handleCountClick} severity_count={_openThreatsData.high} />
                </Grid>
                <Grid item xs={4} sx={{ paddingTop: '0px !important' }}>
                  <InfoComponent
                    severity={'Medium'}
                    onClick={handleCountClick}
                    severity_count={_openThreatsData.medium}
                  />
                </Grid>
              </Grid>
              <Grid direction="row" spacing="8px" container>
                <Grid item xs={4} sx={{ paddingTop: '0px !important' }}>
                  <InfoComponent severity={'Low'} onClick={handleCountClick} severity_count={_openThreatsData.low} />
                </Grid>
                <Grid item xs={4} sx={{ paddingTop: '0px !important' }}>
                  <InfoComponent
                    severity={'Warning'}
                    onClick={handleCountClick}
                    severity_count={_openThreatsData.warning}
                  />
                </Grid>
                <Grid item xs={4} sx={{ paddingTop: '0px !important' }}>
                  <InfoComponent severity={'Info'} onClick={handleCountClick} severity_count={_openThreatsData.info} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </ContentBox>
    </Grid>
  );
}

export default OpenThreatsAndAnamoly;
