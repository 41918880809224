import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
const ButtonIconBtn = ({ btnClassName, icon, iconAlign, iconClassName, transform, children, ...rest }) => (
  <button {...rest} className={cx('btn btn-primary d-flex align-items-center sl-btn', btnClassName)}>
    {iconAlign === 'right' && children}
    <div>
      <FontAwesomeIcon
        icon={icon}
        style={{ height: '1rem' }}
        className={cx(iconClassName, {
          'mr-1 fs-1': children && iconAlign === 'left',
          'ml-1 fs-1': children && iconAlign === 'right',
        })}
        transform={transform}
      />
    </div>
    <div style={{ flex: 1 }}>{iconAlign === 'left' && children}</div>
  </button>
);

const ButtonIcon = ({ isLink, to, ...props }) => {
  if (isLink && !props.disabled)
    return (
      <Link to={to} style={{ textDecoration: 'none' }}>
        <ButtonIconBtn {...props} />
      </Link>
    );
  else return <ButtonIconBtn {...props} />;
};

ButtonIconBtn.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string,
  btnClassName: PropTypes.string,
};

ButtonIconBtn.defaultProps = { iconAlign: 'left' };
export default ButtonIcon;
