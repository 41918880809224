import React from 'react';

import Search from '@mui/icons-material/Search';

import './style.scss';

function Searchbar({ placeholder, value = '', onChange, height }) {
  return (
    <div className="Searchbar" style={{ width: '100%', height: height ? height : '' }}>
      <Search />

      <input placeholder={placeholder || 'Search'} value={value} onChange={onChange} />
    </div>
  );
}

export default Searchbar;
