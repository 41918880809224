import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { removeNotificationBanner } from './actions';
import { withRouter } from 'react-router';
import './style.scss';
import _ from 'lodash';

const NotificationBanner = withRouter(props => {
  const dispatch = useDispatch();
  const { status, msg, id, redirect, history } = props;
  const removeBanner = () => removeNotificationBanner(dispatch, id);
  return (
    <div className={`notification-banner notification-theme-${status}`}>
      <div>
        {status === 'inProgress' && <Spinner animation="border" size="sm" />}{' '}
        {_.isEmpty(redirect) ? (
          <span dangerouslySetInnerHTML={{ __html: msg }}></span>
        ) : (
          <span
            style={{ cursor: 'pointer' }}
            dangerouslySetInnerHTML={{ __html: msg }}
            onClick={() => {
              history.push(redirect.path, {
                breadCrumbStack: redirect.stack,
                mode: redirect.mode,
              });
            }}></span>
        )}
      </div>
      {(status === 'success' || 'error') && (
        <div>
          <FontAwesomeIcon icon={faTimes} onClick={removeBanner} />
        </div>
      )}
    </div>
  );
});

export const NotificationContainer = () => {
  const notifications = useSelector(state => state.notification.array);
  // if (_.isEmpty(notifications)) return null;
  console.log(notifications, 'notifications');
  return (
    <div id="notification-container" style={{ padding: 0 }}>
      {notifications.map(data => (
        <NotificationBanner key={data} {...data} />
      ))}
    </div>
  );
};
