import { SeverityBadge } from "@components/bootstrapTable/badges/severity";
import AWSServiceIcons from "@root/components/awsServiceIcons";
import DateFormatPipe from "@root/components/utilities/DateFormatPipe";
import momentTz from "moment-timezone";
import EnvCellComponent from "./env-cell";
import Tooltip from "@root/components/v2/ui-elements/Tooltip";
import Text from "@root/components/v2/ui-elements/Text";
import lensIcon from "@root/assets/svg/lens_icon.svg";
// import CountTooltip from '@root/components/table/cell-types/CountTooltip';
import { Box } from "@mui/material";
import AWSServiceCategoryIcons from "@root/components/awsServiceCategoryIcons";
import commonTableColumns from "@root/components/table/commonTableColumns";
import { RiskTypeIcons } from "@root/pipes/riskTypeIcons";

const REMEDIATION_EXPIRY_SECONDS = 120;

export function makeColumns(
  setExpandedRowData,
  setOpenExpand,
  setShowOptimizeModal,
  setRegion,
  setResourceId,
  setRemediationId,
  setResourceType
) {
  const columns = [
    {
      field: "environment",
      width: 245,
      renderHeader: () => <span>Environment(ID/Tag)</span>,
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: (params) => {
        // if (cell === "-") {
        //   return <NoResourcePrompt />;
        // }
        // return cell;
        return (
          <EnvCellComponent
            cloud={"aws"}
            environmentName={""}
            environmentId={params.row.account_id}
            environmentTags={params.row.environment_tags}
          />
        );
      },
      sortFunc: (a, b, order) => {
        a = String(a).toString();
        b = String(b).toString();
        if (a.trim().length < 1) {
          return 1;
        }
        let first = a?.trim().charAt(0);
        let second = b?.trim().charAt(0);
        if (order === "asc") {
          return first == second ? 0 : first > second ? 1 : -1;
        }
        return first == second ? 0 : first < second ? 1 : -1; // desc
      },
    },
    {
      field: "remediation_date",
      width: 230,
      renderHeader: () => <span>Remediation Date</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", minWidth: "160px" };
      },
      renderCell: (params) => {
        return params.row.final_received_payload
          ? // ? moment(params.row.final_received_payload.datetime).format(
            //     "YYYY-MM-DD hh:mm A"
            //   )
            DateFormatPipe(
              JSON.parse(params.row.final_received_payload).datetime
            )
          : "--";
      },
    },
    {
      field: "service_category",
      headerName: "Service Category",
      headerClasses: " text-center",
      classes: "align-middle text-center",
      minWidth: 180,
      sort: true,
      renderCell: (params) => (
        <>
          <AWSServiceCategoryIcons name={params.row.service_category} />
          <span style={{ paddingLeft: "7px" }}>
            {params.row.service_category}
          </span>
        </>
      ),
    },
    {
      field: "service",
      width: 150,
      renderHeader: () => <span>Service</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: (params) => {
        // const service = rulesMap && rulesMap[params.row.rule_id] ? rulesMap[params.row.rule_id].service : '';

        return (
          <div className="d-flex">
            <AWSServiceIcons name={params.row.service} />
            <div className="ml-2">{params.row.service}</div>
          </div>
        );
      },
    },
    {
      field: "resource_name",
      width: 200,
      renderHeader: () => <span>Resource Name</span>,
      headerClasses: " text-center",
      classes: "align-middle text-center",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.resource_name}>
            <Text className="SingleLine">{params.row.resource_name}</Text>
          </Tooltip>
        );
      },
      sort: true,
    },
    commonTableColumns.resourceId(),
    {
      field: "resource_type",
      width: 200,
      renderHeader: () => <span>Resource Type</span>,
      headerClasses: " text-center",
      classes: "align-middle text-center",
      sort: true,
    },
    // {
    //   field: 'resource_tags',
    //   width: 250,
    //   renderHeader: props => <span>Resource Tag</span>,
    //   headerClasses: ' text-center',
    //   classes: 'align-middle text-center',
    //   sort: true,
    //   renderCell: params => {
    //     console.log(params?.row.resource_tags, 'checcckkkk');
    //     return (
    //       <>
    //         {params?.row.resource_tags === 'No Tags Available' ? (
    //           'No Tags Available'
    //         ) : (
    //           <CountTooltip labels={params?.row.resource_tags} />
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      field: "risk_category",
      width: 220,
      renderHeader: () => <span>Risk Category</span>,
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: (params) => {
        return <RiskTypeIcons tooltip={true} name={params.value} />;
      },
    },
    {
      field: "classification",

      renderHeader: () => <span>Classification</span>,
      headerClasses: " text-center",
      classes: "align-middle text-center",
      sort: true,
    },
    {
      field: "production",
      width: 180,
      renderHeader: () => <span>Production</span>,
      headerClasses: " text-center",
      classes: "align-middle text-center",
      sort: true,
      renderCell: (params) => {
        return (
          <div className="SingleLine">
            {params?.row.production ? "True" : "-"}
          </div>
        );
      },
    },
    {
      field: "region_name",
      width: 180,
      renderHeader: () => <span>Region</span>,
      headerClasses: " text-center",
      classes: "align-middle text-center",
      sort: true,
      renderCell: (params) => {
        return (
          <div className="SingleLine">
            {params?.row.sent_payload.region_name}
          </div>
        );
      },
    },
    // {
    //   field: 'rule_description',
    //   width: 320,
    //   renderHeader: props => <span>Rule Description</span>,
    //   headerClasses: '',
    //   classes: 'align-middle',
    //   sort: true,
    //   headerStyle: () => {
    //     return { whiteSpace: 'nowrap', minWidth: '300px' };
    //   },
    //   renderCell: params => {
    //     return (
    //       <div className="SingleLine">
    //         {rulesMap && rulesMap[params.row.rule_id] ? rulesMap[params.row.rule_id].ruleDescription : ''}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "rule_id",

      renderHeader: () => <span>Rule ID</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", minWidth: "160px" };
      },
    },
    {
      field: "severity",
      renderHeader: () => <span>Severity</span>,
      headerClasses: "text-center",
      classes: "align-middle text-center",
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: (params) => (
        <SeverityBadge propsValue={params.row.risk ? params.row.risk : ""} />
      ),
      sort: true,
      sortFunc: (a, b, order) => {
        let priorityArray = ["HIGH", "MEDIUM", "LOW"];
        let firstPrio = priorityArray.indexOf(a);
        let secPrio = priorityArray.indexOf(b);
        if (a === "-") {
          return 1;
        }
        if (order === "asc") {
          return firstPrio - secPrio;
        }
        return secPrio - firstPrio;
      },
    },
    {
      field: "trigger",
      renderHeader: () => <span>Trigger</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: () => {
        return "Manual";
      },
    },
    {
      field: "action",
      renderHeader: () => <span>Action</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: () => {
        return "Remediate";
      },
    },
    {
      field: "status",
      renderHeader: () => <span>Status</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", minWidth: "96px" };
      },
      renderCell: (params) => {
        if (params.row.is_remediation_success) {
          return (
            <span style={{ color: "#03BB34" }}>
              Success
              <Box
                style={{
                  display: "inline-flex",
                  marginLeft: "5px",
                  alignItems: "center",
                }}
              >
                <span
                  onClick={() => {
                    setExpandedRowData(params.row);
                    setOpenExpand(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className="RemediationStatus__Icon"
                    style={{ cursor: "pointer" }}
                  >
                    <g clip-path="url(#clip0_12772_20297)">
                      <path
                        d="M4.88571 3.46198H10.269C11.2235 3.46198 12 2.68545 12 1.731C12 0.776555 11.2235 0 10.269 0H4.88571C3.93124 0 3.15473 0.776531 3.15473 1.73098C3.15473 2.68542 3.93124 3.46198 4.88571 3.46198ZM4.91254 1.38281H10.2422C10.4363 1.38281 10.5937 1.54022 10.5937 1.73438C10.5937 1.92853 10.4363 2.08594 10.2422 2.08594H4.91254C4.71839 2.08594 4.56098 1.92853 4.56098 1.73438C4.56098 1.54022 4.71836 1.38281 4.91254 1.38281Z"
                        fill="#CBD5E0"
                      />
                      <path
                        d="M10.269 8.53802H4.88571C3.93124 8.53802 3.15473 9.31455 3.15473 10.269C3.15473 11.2234 3.93124 12 4.88571 12H10.269C11.2235 12 12 11.2235 12 10.269C12 9.31458 11.2235 8.53802 10.269 8.53802ZM10.2422 10.624H4.91254C4.71839 10.624 4.56098 10.4666 4.56098 10.2724C4.56098 10.0782 4.71839 9.92083 4.91254 9.92083H10.2422C10.4363 9.92083 10.5937 10.0782 10.5937 10.2724C10.5937 10.4666 10.4363 10.624 10.2422 10.624Z"
                        fill="#CBD5E0"
                      />
                      <path
                        d="M10.269 4.26902H4.88571C3.93124 4.26902 3.15473 5.04556 3.15473 6C3.15473 6.95445 3.93124 7.73098 4.88571 7.73098H10.269C11.2235 7.73098 12 6.95447 12 6C12 5.04553 11.2235 4.26902 10.269 4.26902ZM10.2422 6.35496H4.91254C4.71839 6.35496 4.56098 6.19756 4.56098 6.0034C4.56098 5.80924 4.71839 5.65184 4.91254 5.65184H10.2422C10.4363 5.65184 10.5937 5.80924 10.5937 6.0034C10.5937 6.19756 10.4363 6.35496 10.2422 6.35496Z"
                        fill="#CBD5E0"
                      />
                      <path
                        d="M2.34375 1.73098C2.34375 1.0848 1.81805 0.5591 1.17188 0.5591C0.525703 0.5591 0 1.0848 0 1.73098C0 2.25471 0.345375 2.69923 0.820312 2.8489V4.88205C0.345375 5.03175 0 5.47626 0 6C0 6.52373 0.345375 6.96825 0.820312 7.11792V9.15108C0.345375 9.30075 0 9.74527 0 10.269C0 10.9152 0.525703 11.4409 1.17188 11.4409C1.81805 11.4409 2.34375 10.9152 2.34375 10.269C2.34375 9.74527 1.99837 9.30075 1.52344 9.15108V7.11792C1.99837 6.96825 2.34375 6.52373 2.34375 6C2.34375 5.47626 1.99837 5.03175 1.52344 4.88208V2.84892C1.99837 2.69923 2.34375 2.25471 2.34375 1.73098Z"
                        fill="#CBD5E0"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12772_20297">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                {params.row.show_configuration && (
                  <span
                    onClick={() => {
                      setRegion(params?.row.sent_payload.region_name);
                      setRemediationId(params.row.remediation_id);
                      setResourceType(params.row.resource_type);
                      setResourceId(params.row.resource_id);
                      setShowOptimizeModal(true);
                    }}
                    style={{ display: "none" }}
                    className="critical"
                  >
                    <img
                      src={lensIcon}
                      style={{ marginLeft: "4px", cursor: "pointer" }}
                    />
                  </span>
                )}
              </Box>
            </span>
          );
        } else {
          const remediationAgeMillis =
            Date.now() -
            momentTz.tz(params.row.triggered_at, "UTC").toDate().getTime();

          if (remediationAgeMillis > REMEDIATION_EXPIRY_SECONDS * 1000) {
            return (
              <span style={{ color: "#F62121" }}>
                Failed
                <Box
                  style={{ display: "inline", marginLeft: "5px" }}
                  onClick={() => {
                    setExpandedRowData(params.row);
                    setOpenExpand(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className="RemediationStatus__Icon"
                    style={{ cursor: "pointer" }}
                  >
                    <g clip-path="url(#clip0_12772_20297)">
                      <path
                        d="M4.88571 3.46198H10.269C11.2235 3.46198 12 2.68545 12 1.731C12 0.776555 11.2235 0 10.269 0H4.88571C3.93124 0 3.15473 0.776531 3.15473 1.73098C3.15473 2.68542 3.93124 3.46198 4.88571 3.46198ZM4.91254 1.38281H10.2422C10.4363 1.38281 10.5937 1.54022 10.5937 1.73438C10.5937 1.92853 10.4363 2.08594 10.2422 2.08594H4.91254C4.71839 2.08594 4.56098 1.92853 4.56098 1.73438C4.56098 1.54022 4.71836 1.38281 4.91254 1.38281Z"
                        fill="#CBD5E0"
                      />
                      <path
                        d="M10.269 8.53802H4.88571C3.93124 8.53802 3.15473 9.31455 3.15473 10.269C3.15473 11.2234 3.93124 12 4.88571 12H10.269C11.2235 12 12 11.2235 12 10.269C12 9.31458 11.2235 8.53802 10.269 8.53802ZM10.2422 10.624H4.91254C4.71839 10.624 4.56098 10.4666 4.56098 10.2724C4.56098 10.0782 4.71839 9.92083 4.91254 9.92083H10.2422C10.4363 9.92083 10.5937 10.0782 10.5937 10.2724C10.5937 10.4666 10.4363 10.624 10.2422 10.624Z"
                        fill="#CBD5E0"
                      />
                      <path
                        d="M10.269 4.26902H4.88571C3.93124 4.26902 3.15473 5.04556 3.15473 6C3.15473 6.95445 3.93124 7.73098 4.88571 7.73098H10.269C11.2235 7.73098 12 6.95447 12 6C12 5.04553 11.2235 4.26902 10.269 4.26902ZM10.2422 6.35496H4.91254C4.71839 6.35496 4.56098 6.19756 4.56098 6.0034C4.56098 5.80924 4.71839 5.65184 4.91254 5.65184H10.2422C10.4363 5.65184 10.5937 5.80924 10.5937 6.0034C10.5937 6.19756 10.4363 6.35496 10.2422 6.35496Z"
                        fill="#CBD5E0"
                      />
                      <path
                        d="M2.34375 1.73098C2.34375 1.0848 1.81805 0.5591 1.17188 0.5591C0.525703 0.5591 0 1.0848 0 1.73098C0 2.25471 0.345375 2.69923 0.820312 2.8489V4.88205C0.345375 5.03175 0 5.47626 0 6C0 6.52373 0.345375 6.96825 0.820312 7.11792V9.15108C0.345375 9.30075 0 9.74527 0 10.269C0 10.9152 0.525703 11.4409 1.17188 11.4409C1.81805 11.4409 2.34375 10.9152 2.34375 10.269C2.34375 9.74527 1.99837 9.30075 1.52344 9.15108V7.11792C1.99837 6.96825 2.34375 6.52373 2.34375 6C2.34375 5.47626 1.99837 5.03175 1.52344 4.88208V2.84892C1.99837 2.69923 2.34375 2.25471 2.34375 1.73098Z"
                        fill="#CBD5E0"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12772_20297">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
              </span>
            );
          } else {
            return <span style={{ color: "#AEB1C0" }}>In Progress</span>;
          }
        }
        // if (params.row.final_received_payload) {
        //   if (params.row.final_received_payload.status === "SUCCESS") {
        //     return (
        //       <span style={{ color: "#27D658" }} className="text-green">
        //         Success
        //       </span>
        //     );
        //   } else if (params.row.final_received_payload.status === "FAILED") {
        //     return (
        //       <span style={{ color: "#ff2222" }} className="text-red">
        //         Failed
        //       </span>
        //     );
        //   } else {
        //     return params.row.final_received_payload.status;
        //   }
        // } else {
        //   return (
        //     <span style={{ color: "#27D658" }} className="text-green">
        //       In Progress
        //     </span>
        //   );
        // }
      },
    },
    {
      field: "executer",
      width: 196,
      renderHeader: () => <span>Triggered By</span>,
      headerClasses: "",
      classes: "align-middle",
      sort: true,
      headerStyle: () => {
        return { whiteSpace: "nowrap", width: "auto" };
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.triggered_by}>
            <div className="SingleLine">{params.row.triggered_by}</div>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "tag",
    //   text: "Tags",
    //   headerClasses: "",
    //   classes: "align-middle",
    //   headerStyle: () => {
    //     return { whiteSpace: "nowrap", width: "25%" };
    //   },
    //   renderCell: (params) => {
    //     if (_.isEmpty(cell) || cell == "-") return <span>-</span>;
    //     return (
    //       <span>
    //         <strong>{cell[0]?.Key}</strong> {cell[0]?.Value}
    //       </span>
    //     );
    //   },
    //   sort: true,
    // },
  ];

  return columns;
}
