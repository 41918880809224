import React from 'react';

import { azure_icon } from '@root/components/utilities/CloudIconsUtil';
import aws_icon from '@root/assets/img/cloud_provider_icons/aws_icon.png';
import CountTooltip from '@root/components/table/cell-types/CountTooltip';
import { GetCloudProvider, GetEnvTags } from '@root/pipes/getEnvironmentDetails';
import GCPIcon from '@root/assets/img/header/cloud_providers/gcp.png';

const Environment = ({ environmentName, environmentId }) => {
  const cloud = GetCloudProvider(environmentId);
  const environmentTags = GetEnvTags(environmentId);

  return (
    <div className="d-flex" style={{ overflow: 'hidden' }}>
      <div className="d-flex align-items-center">
        {cloud === 'aws' && <img src={aws_icon} alt="AWS" style={{ width: '32px' }} />}
        {cloud === 'azure' && <img src={azure_icon} alt="AZURE" style={{ width: '32px' }} />}
        {cloud === 'gcp' && <img src={GCPIcon} alt="GCP" style={{ width: '32px' }} />}
      </div>
      <div className="d-flex flex-column align-items-start ml-2 flex-grow-1 overflow-hidden">
        {environmentName ? <div>{environmentName}</div> : null}
        {environmentId ? <div>{environmentId}</div> : null}
        {environmentTags && environmentTags.length ? (
          <CountTooltip
            labels={environmentTags}
            width="auto"
            containerClassName="RemediationPolicyTable__EnvironmentTag"
          />
        ) : null}
      </div>
    </div>
  );
};

export default Environment;
