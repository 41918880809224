import './style.scss';
import { Link } from 'react-router-dom';
// import EnvCellComponent from './env-cell';

import RemediationStartedIcon from './remediation-started.svg';
// import ResourceTagsTooltip from '@root/components/Tooltip/ResourceTagsTooltip';
import DateFormatPipe from '@root/components/utilities/DateFormatPipe';

import { Dialog } from '@mui/material';
import { SubmitButton } from '@root/components/v2/ui-elements/SubmitButton';
import CircularCloseButton from '@root/components/v2/ui-elements/CircularCloseButton';

// const REMEDIATION_EXPIRY_SECONDS = 120;

export const ExpandRow = (
  row,
  setExpandedRowData,
  environments,
  setOpenExpand,
  openRemidiationDialog,
  setOpenRemidiationDialog,
) => {
  let isRemediationSuccess = row.is_remediation_success;

  // const remediationAgeMillis = Date.now() - momentTz.tz(row.triggered_at, 'UTC').toDate().getTime();

  const isRemediationInProgress = false;
  const isRemediationFailed = !isRemediationSuccess && !isRemediationInProgress;

  // const environment = environments.find(e => e.enviromentId === row.account_id);

  // let resourceTags = null;

  try {
    // resourceTags = JSON.parse(row.resource_tags);
  } catch (err) {
    // Probably "No Tags Available" which isn't a valid JSON
    // resourceTags = row.resource_tags;
  }

  return (
    <div className="RemediationReport__expanded-body">
      <div className="RemediationReport__expanded-title">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
          <g clip-path="url(#clip0_12695_394631)">
            <rect width="30" height="30" rx="4" fill="#194BFB" />
            <path
              d="M24 6H6C4.9 6 4 6.9 4 8V22C4 23.1 4.9 24 6 24H24C25.1 24 26 23.1 26 22V8C26 6.9 25.1 6 24 6ZM24 22H6V8H24V22ZM19 20C19.6 20 20 19.6 20 19V15.5C20 14.9 19.6 14.5 19 14.5C18.4 14.5 18 14.9 18 15.5V19C18 19.6 18.4 20 19 20ZM15 20C15.6 20 16 19.6 16 19V11C16 10.4 15.6 10 15 10C14.4 10 14 10.4 14 11V19C14 19.6 14.4 20 15 20ZM11 20C11.6 20 12 19.6 12 19V13C12 12.4 11.6 12 11 12C10.4 12 10 12.4 10 13V19C10 19.6 10.4 20 11 20Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_12695_394631">
              <rect width="30" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span
          style={{ fontFamily: 'Manrope', fontWeight: 600, color: '#1A202C', fontSize: '24px', paddingLeft: '10px' }}>
          Report Summary
        </span>
        <div style={{ flexGrow: '1' }} />
        <CircularCloseButton
          onClick={() => {
            setOpenExpand(false);
          }}
        />
      </div>
      <div
        className="status-bar"
        style={{
          backgroundColor: isRemediationSuccess || isRemediationInProgress ? '#03BB34' : '#F62121',
        }}>
        {isRemediationSuccess
          ? 'Remediation Executed Successfully'
          : isRemediationInProgress
          ? 'Remediation In Progress'
          : 'Failed To Execute Remediation'}
      </div>

      <div id="reportSummary">
        <div className="row-card">
          <div className="colu" style={{ width: '100%' }}>
            <span
              style={{
                color: '#484D56',
                fontFamily: 'Manrope',
                fontWeight: 600,
                fontSize: '12px',
                marginBottom: '15px',
              }}>
              Remediation Timeline
            </span>
            <div className="RemediationTimelineContainer">
              <div className="d-flex flex-column">
                <div>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" fill="black" />
                    <g clip-path="url(#clip0_0_1)" transform="translate(7, 7)">
                      <path
                        d="M21.5489 4.10094C21.6299 4.15513 21.6963 4.22844 21.7423 4.31439C21.7883 4.40033 21.8124 4.49628 21.8125 4.59375V13.5C21.8125 13.6186 21.7769 13.7344 21.7105 13.8326C21.644 13.9308 21.5497 14.0069 21.4396 14.051L21.2188 13.5L21.4396 14.051L21.4361 14.0522L21.4289 14.0558L21.4016 14.0664C21.2455 14.1285 21.0883 14.1879 20.9302 14.2446C20.6167 14.3574 20.1809 14.5094 19.6869 14.6602C18.7179 14.9594 17.4556 15.2812 16.4688 15.2812C15.4629 15.2812 14.6305 14.9488 13.9061 14.6578L13.8729 14.6459C13.12 14.3431 12.4787 14.0938 11.7188 14.0938C10.8875 14.0938 9.77363 14.3669 8.82481 14.6602C8.39999 14.7926 7.97905 14.9371 7.5625 15.0936V22.4062C7.5625 22.5637 7.49994 22.7147 7.38859 22.8261C7.27724 22.9374 7.12622 23 6.96875 23C6.81128 23 6.66026 22.9374 6.54891 22.8261C6.43756 22.7147 6.375 22.5637 6.375 22.4062V4.59375C6.375 4.43628 6.43756 4.28526 6.54891 4.17391C6.66026 4.06256 6.81128 4 6.96875 4C7.12622 4 7.27724 4.06256 7.38859 4.17391C7.49994 4.28526 7.5625 4.43628 7.5625 4.59375V4.92863C7.83087 4.83481 8.1515 4.72675 8.50062 4.61988C9.46962 4.323 10.7331 4 11.7188 4C12.7162 4 13.5285 4.32894 14.2374 4.61631L14.2885 4.63769C15.0271 4.93575 15.6708 5.1875 16.4688 5.1875C17.3 5.1875 18.4139 4.91437 19.3627 4.62106C19.9034 4.45221 20.4377 4.26367 20.9646 4.05581L20.9872 4.0475L20.9919 4.04512H20.9931L21.5489 4.10094ZM20.625 5.44994C20.3638 5.54256 20.055 5.64825 19.7154 5.75275C18.7535 6.052 17.4924 6.37381 16.4688 6.37381C15.4166 6.37381 14.5782 6.03419 13.8527 5.73969L13.8432 5.73613C13.0986 5.43688 12.4776 5.1875 11.7188 5.1875C10.9243 5.1875 9.81163 5.45944 8.85094 5.75513C8.41763 5.88882 7.98799 6.03414 7.5625 6.19094V13.8301C7.82375 13.7375 8.1325 13.6318 8.47213 13.5273C9.434 13.2269 10.6951 12.9062 11.7188 12.9062C12.7246 12.9062 13.557 13.2387 14.2814 13.5297L14.3146 13.5416C15.0675 13.8444 15.7088 14.0938 16.4688 14.0938C17.262 14.0938 18.3759 13.8218 19.3366 13.5261C19.7699 13.3924 20.1995 13.2471 20.625 13.0903V5.45113V5.44994Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_0_1">
                        <rect width="19" height="19" fill="white" transform="translate(4 4)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <span style={{ fontSize: '14px', fontFamily: 'Manrope', fontWeight: 600, color: '#484D56' }}>
                    User-Triggered Remediation Action
                  </span>
                  <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                    API Call Triggered By User
                  </span>
                  <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                    {DateFormatPipe(row.triggered_at)}
                    {/* {moment(
                      momentTz.tz(row.triggered_at, "UTC").toDate()
                    ).format("YYYY-MM-DD hh:mm A")} */}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#000',
                    }}>
                    <img src={RemediationStartedIcon} style={{ width: '18px' }} />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <span style={{ fontSize: '14px', fontFamily: 'Manrope', fontWeight: 600, color: '#484D56' }}>
                    Remediation Policy Matched |{' '}
                    {row.policy_id ? (
                      <Link
                        to={`/remediation-policy?policy_id=${row.policy_id}`}
                        style={{
                          paddingLeft: '8px',
                          textDecoration: 'none',
                          color: '#194BFB',
                          fontFamily: 'Manrope',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                        target="_blank">
                        View Policy
                      </Link>
                    ) : null}
                  </span>
                  <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                    Policy Rules Allow Manual Remediation
                  </span>
                  <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                    {/* {moment(
                      momentTz.tz(row.triggered_at, "UTC").toDate()
                    ).format("YYYY-MM-DD hh:mm A")} */}
                    {DateFormatPipe(row.triggered_at)}
                  </span>
                </div>
              </div>

              {row.final_received_payload && isRemediationSuccess ? (
                <div className="d-flex flex-column">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#000',
                      }}>
                      <img src={RemediationStartedIcon} style={{ width: '18px' }} />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <span style={{ fontSize: '14px', fontFamily: 'Manrope', fontWeight: 600, color: '#484D56' }}>
                      Remediation Executed
                    </span>
                    <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                      Manual Remediation was Executed Successfully
                    </span>
                    <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                      {DateFormatPipe(JSON.parse(row.final_received_payload).datetime)}
                    </span>
                  </div>
                </div>
              ) : isRemediationFailed ? (
                <div className="d-flex flex-column">
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#000',
                      }}>
                      <img src={RemediationStartedIcon} style={{ width: '18px' }} />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <div>
                      <span style={{ fontSize: '14px', fontFamily: 'Manrope', fontWeight: 600, color: '#484D56' }}>
                        Remediation Failed |
                      </span>
                      <span
                        onClick={() => setOpenRemidiationDialog(true)}
                        style={{
                          color: '#F62121',
                          fontSize: '14px',
                          fontFamily: 'Manrope',
                          fontWeight: 600,
                          paddingLeft: '8px',
                          cursor: 'pointer',
                        }}>
                        View
                      </span>
                    </div>
                    <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                      Manual Remediation Failed To Execute
                    </span>
                    <span style={{ fontSize: '12px', fontFamily: 'Manrope', color: '#767980' }}>
                      {/* {moment(momentTz.tz(row.triggered_at, "UTC").toDate())
                        .add(REMEDIATION_EXPIRY_SECONDS, "seconds")
                        .format("YYYY-MM-DD hh:mm A")} */}
                      {DateFormatPipe(row.triggered_at)}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Dialog open={openRemidiationDialog} onClose={() => setOpenRemidiationDialog(false)}>
        <div style={{ padding: '20px' }}>
          <div style={{}}>
            <CircularCloseButton
              onClick={() => setOpenRemidiationDialog(false)}
              style={{ marginLeft: 'auto', marginBottom: '20px' }}
            />
            <div
              style={{
                display: 'flex',
                padding: '20px',
                background: '#FDD3D3',
                boxShadow: '0px 8px 18px 0px rgba(93, 106, 131, 0.05)',
                border: '1px solid var(--alerts-error-base, #F62121)',
                borderRadius: '5px',
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                style={{ marginRight: '20px', overflow: 'visible', marginTop: '5px' }}>
                <path
                  d="M7.51953 7.51953C7.90625 7.13281 8.55078 7.13281 8.9375 7.51953L10.957 9.58203L13.0195 7.51953C13.4062 7.13281 14.0508 7.13281 14.4375 7.51953C14.8672 7.94922 14.8672 8.59375 14.4375 8.98047L12.418 11L14.4375 13.0195C14.8672 13.4492 14.8672 14.0938 14.4375 14.4805C14.0508 14.9102 13.4062 14.9102 13.0195 14.4805L10.957 12.4609L8.9375 14.4805C8.55078 14.9102 7.90625 14.9102 7.51953 14.4805C7.08984 14.0938 7.08984 13.4492 7.51953 13.0195L9.53906 11L7.51953 8.98047C7.08984 8.59375 7.08984 7.94922 7.51953 7.51953ZM22 11C22 17.1016 17.0586 22 11 22C4.89844 22 0 17.1016 0 11C0 4.94141 4.89844 0 11 0C17.0586 0 22 4.94141 22 11ZM11 2.0625C6.05859 2.0625 2.0625 6.10156 2.0625 11C2.0625 15.9414 6.05859 19.9375 11 19.9375C15.8984 19.9375 19.9375 15.9414 19.9375 11C19.9375 6.10156 15.8984 2.0625 11 2.0625Z"
                  fill="#F72121"
                />
              </svg>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: 'Manrope',
                  color: '#484D56',
                }}>
                {row.final_message ? row.final_message : 'Remediation Failed To Execute'}
              </div>
            </div>
          </div>
          <div>
            <SubmitButton
              text="Close"
              style={{ float: 'right', width: 'fit-content' }}
              onClick={() => setOpenRemidiationDialog(false)}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
