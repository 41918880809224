//assessment DRpdown
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
//import CheckBox from '@root/components/v2/ui-elements/CheckBox';
import { Box } from '@mui/material';
//import CustomDropdownOption from './TimeFilterDropdownOption';
import AssessmentDropdownOption from './AssessmentDropdownOption';
import './style.scss';

const AssessmentDropdown = ({
  options,
  value,
  setValue,
  selectedValue,
  setSelectedScanId,
  envtooltiptrue = false,
  singleSelect,
  isSideOverlay = false,
}) => {
  //   const options = [
  //     { label: 'Drift', children: ['Create', 'Modify', 'Delete'] },
  //     { label: 'Threat' },
  //     { label: 'Remediation', children: ['One Click / Manual', 'Auto Remediation'] },
  //   ];
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleOutsideClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  return (
    <div ref={ref} style={{ cursor: 'pointer' }}>
      <Box
        style={{
          display: 'flex',
          padding: isSideOverlay ? '12px' : '5px 8px',
          alignItems: 'center',
          fontFamily: 'Manrope',
          borderRadius: '4px',
          border: isSideOverlay
            ? '1px solid var(--border-line-light, #D1D2D5)'
            : '1px solid var(--border-line-light, #CBD5E0)',
          background: 'var(--others-white, #FFF)',
          boxShadow: !isSideOverlay ? '0px 8px 18px 0px rgba(93, 106, 131, 0.05)' : 'none',
          width: isSideOverlay ? '185px' : '170px',
          height: isSideOverlay ? '40px' : '25px',
          cursor: 'pointer',
          // alignItems: 'center',
          justifyContent: 'start',
          // marginRight: '-8px',
        }}
        onClick={() => setOpen(prev => !prev)}
        // style={{ pointerEvents: disabled || isLoading ? 'none' : '',}}
      >
        {!isSideOverlay && (
          <Box style={{ marginRight: '4px', paddingBottom: '2px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.33333 1.39987V0.949937C5.33339 0.830623 5.38022 0.716213 5.46355 0.631845C5.54687 0.547478 5.65987 0.500056 5.77771 0.5C5.89558 0.5 6.00863 0.547397 6.092 0.631771C6.17537 0.716144 6.22224 0.830587 6.22229 0.949937V1.39987C6.69379 1.39993 7.14596 1.58961 7.47933 1.92721C7.81271 2.2648 8 2.72264 8 3.20004V7.70004C8 8.17742 7.81271 8.63525 7.47932 8.97281C7.14594 9.31036 6.69377 9.5 6.22229 9.5H1.77771C1.30623 9.5 0.854064 9.31036 0.520679 8.97281C0.187294 8.63525 0 8.17742 0 7.70004V3.20004C-3.23701e-09 2.72264 0.187287 2.2648 0.520666 1.92721C0.854045 1.58961 1.30621 1.39993 1.77771 1.39987V0.949937C1.77776 0.830587 1.82463 0.716144 1.908 0.631771C1.99137 0.547397 2.10442 0.5 2.22229 0.5C2.34013 0.500056 2.45313 0.547478 2.53645 0.631845C2.61978 0.716213 2.66661 0.830623 2.66667 0.949937V1.39987H5.33333ZM7.11104 5H0.888959V7.70004C0.888959 7.93872 0.982588 8.16763 1.14925 8.33642C1.31592 8.50522 1.54198 8.60007 1.77771 8.60013H6.22229C6.458 8.60002 6.68403 8.50514 6.85068 8.33636C7.01734 8.16758 7.11099 7.93871 7.11104 7.70004V5ZM6.22229 2.29996V2.74989C6.22224 2.86926 6.17538 2.98373 6.09202 3.06814C6.00865 3.15254 5.8956 3.19999 5.77771 3.20004C5.65985 3.19993 5.54685 3.15246 5.46354 3.06806C5.38022 2.98366 5.33339 2.86923 5.33333 2.74989V2.29996H2.66667V2.74989C2.66661 2.86923 2.61978 2.98366 2.53646 3.06806C2.45315 3.15246 2.34015 3.19993 2.22229 3.20004C2.1044 3.19999 1.99135 3.15254 1.90799 3.06814C1.82462 2.98373 1.77776 2.86926 1.77771 2.74989V2.29996C1.66097 2.29993 1.54537 2.32319 1.43752 2.36842C1.32966 2.41365 1.23167 2.47995 1.14913 2.56354C1.0666 2.64713 1.00114 2.74636 0.956495 2.85558C0.911853 2.96479 0.888904 3.08184 0.888959 3.20004V4.09992H7.11104V3.20004C7.11107 3.08185 7.0881 2.96481 7.04345 2.85561C6.9988 2.74641 6.93334 2.64718 6.85081 2.5636C6.76828 2.48002 6.67029 2.41371 6.56245 2.36848C6.45461 2.32324 6.33902 2.29996 6.22229 2.29996Z"
                fill="#484D56"
              />
            </svg>
          </Box>
        )}
        <Box>
          <input
            value={`${value}`}
            style={{
              border: 'none',
              width: isSideOverlay ? '150px' : '130px',
              height: '20px',
              paddingBottom: '3px',
              justifyContent: 'center',
              alignItems: 'center',
              color: isSideOverlay ? '#767980' : 'var(--secondary-400, #484D56)',
              textLeadingTrim: 'both',
              textEdge: 'cap',
              fontFamily: 'Manrope',
              fontSize: isSideOverlay ? '12px' : '10px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              cursor: 'pointer',
              outline: 'none',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              //border: 'none',
            }}
            readOnly
          />
        </Box>
        <Box style={{ transform: open ? 'rotate(180deg)' : '', transition: 'all 200ms' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="22" viewBox="0 0 9 22" fill="none">
            <path
              d="M-3.1883e-08 9.7294C-2.31213e-08 9.52895 0.0703583 9.35078 0.211075 9.21715C0.492508 8.92762 0.985017 8.92762 1.26645 9.21715L4.50293 12.2684L7.71596 9.21715C7.99739 8.92762 8.4899 8.92762 8.77134 9.21715C9.07622 9.48441 9.07622 9.95212 8.77134 10.2194L5.01889 13.7829C4.73746 14.0724 4.24495 14.0724 3.96352 13.7829L0.211075 10.2194C0.0703582 10.0857 -3.96713e-08 9.90757 -3.1883e-08 9.7294Z"
              fill="#484D56"
            />
          </svg>
        </Box>
      </Box>
      <div
        className="CustomDropdown__Options CustomScrollbar"
        style={{
          display: open ? 'block' : 'none',
          width: isSideOverlay ? '185px' : '173px',
          position: 'absolute',
          zIndex: 1,
          borderRadius: '4px',
          border: '1px solid var(--boder-line-light, #cbd5e0)', // border (you need to specify a value)
          boxShadow: '0px 8px 18px 0px rgba(93, 106, 131, 0.05)', // box-shadow
          marginTop: '6px',
          backgroundColor: 'var(--others-white, #fff)',
          maxHeight: '152px',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
        {options.map((option, index) => (
          <AssessmentDropdownOption
            option={option.label}
            envtip={option.env_id}
            scanId={isSideOverlay ? option.scan_id : ''}
            index={index}
            envtooltip={envtooltiptrue}
            setSelectedScanId={setSelectedScanId}
            isSideOverlay={isSideOverlay}
            length={options.length}
            onClick={e => {
              !(selectedValue === option.value) ? setValue(e) : setValue(null);
            }}
            selected={selectedValue === option.value}
            style={{ borderRadius: '4px' }}
            value={option.value}
            // setValue={setValue}
            singleSelect={singleSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default AssessmentDropdown;
