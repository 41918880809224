import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import './style.scss';

// import EnvToolTip from '@root/components/Tooltip/envTooltip';
import Tooltip from '../../ui-elements/Tooltip';

//import CheckboxAssessment from './CheckboxAssessment';

const AssessmentDropdownOption = ({
  option,
  index,
  onClick,
  envtooltip = false,
  selected,
  value,
  envtip,
  scanId,
  isSideOverlay,
  setSelectedScanId,
}) => {
  const [open] = useState(false);
  const [, setDelayedOpen] = useState(true);
  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);

  const handleScroll = useCallback(() => {
    setVisibleTooltipIndex(null); // Hide tooltip immediately when scrolling
  }, []);

  const handleMouseMove = index => {
    setVisibleTooltipIndex(index);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);
  //   useEffect(() => {
  //     setSelectedSubClildren([]);
  //   }, [selectedChildren]);

  //   useEffect(() => {
  //     if (!_.isEmpty(selectedChildren) && !selected) {
  //       onClick(option);
  //       if (firstRender.current) {
  //         firstRender.current = false;
  //       }
  //     }
  //     if (_.isEmpty(selectedChildren) && !firstRender.current) {
  //       onClick(option);
  //     }
  //   }, [selectedChildren]);

  //   useEffect(() => {
  //     if (!selected && option.children) {
  //       setOpen(false);
  //       setSelectedChildren([]);
  //     }
  //   }, [selected]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setDelayedOpen(open);
      }, 200);
    } else {
      setDelayedOpen(open);
    }
  }, [open]);

  return (
    <>
      {/* <button>
    <span style={{ marginLeft: '12px', marginRight: '3px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.33333 1.39987V0.949937C5.33339 0.830623 5.38022 0.716213 5.46355 0.631845C5.54687 0.547478 5.65987 0.500056 5.77771 0.5C5.89558 0.5 6.00863 0.547397 6.092 0.631771C6.17537 0.716144 6.22224 0.830587 6.22229 0.949937V1.39987C6.69379 1.39993 7.14596 1.58961 7.47933 1.92721C7.81271 2.2648 8 2.72264 8 3.20004V7.70004C8 8.17742 7.81271 8.63525 7.47932 8.97281C7.14594 9.31036 6.69377 9.5 6.22229 9.5H1.77771C1.30623 9.5 0.854064 9.31036 0.520679 8.97281C0.187294 8.63525 0 8.17742 0 7.70004V3.20004C-3.23701e-09 2.72264 0.187287 2.2648 0.520666 1.92721C0.854045 1.58961 1.30621 1.39993 1.77771 1.39987V0.949937C1.77776 0.830587 1.82463 0.716144 1.908 0.631771C1.99137 0.547397 2.10442 0.5 2.22229 0.5C2.34013 0.500056 2.45313 0.547478 2.53645 0.631845C2.61978 0.716213 2.66661 0.830623 2.66667 0.949937V1.39987H5.33333ZM7.11104 5H0.888959V7.70004C0.888959 7.93872 0.982588 8.16763 1.14925 8.33642C1.31592 8.50522 1.54198 8.60007 1.77771 8.60013H6.22229C6.458 8.60002 6.68403 8.50514 6.85068 8.33636C7.01734 8.16758 7.11099 7.93871 7.11104 7.70004V5ZM6.22229 2.29996V2.74989C6.22224 2.86926 6.17538 2.98373 6.09202 3.06814C6.00865 3.15254 5.8956 3.19999 5.77771 3.20004C5.65985 3.19993 5.54685 3.15246 5.46354 3.06806C5.38022 2.98366 5.33339 2.86923 5.33333 2.74989V2.29996H2.66667V2.74989C2.66661 2.86923 2.61978 2.98366 2.53646 3.06806C2.45315 3.15246 2.34015 3.19993 2.22229 3.20004C2.1044 3.19999 1.99135 3.15254 1.90799 3.06814C1.82462 2.98373 1.77776 2.86926 1.77771 2.74989V2.29996C1.66097 2.29993 1.54537 2.32319 1.43752 2.36842C1.32966 2.41365 1.23167 2.47995 1.14913 2.56354C1.0666 2.64713 1.00114 2.74636 0.956495 2.85558C0.911853 2.96479 0.888904 3.08184 0.888959 3.20004V4.09992H7.11104V3.20004C7.11107 3.08185 7.0881 2.96481 7.04345 2.85561C6.9988 2.74641 6.93334 2.64718 6.85081 2.5636C6.76828 2.48002 6.67029 2.41371 6.56245 2.36848C6.45461 2.32324 6.33902 2.29996 6.22229 2.29996Z"
              fill="#484D56"
            />
          </svg>
        </span>
        <span>{option.label}</span>
        <span style={{ marginLeft: '10px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="22" viewBox="0 0 9 22" fill="none">
            <path
              d="M9 8.77267C9 8.98555 8.92264 9.159 8.76791 9.3088L3.88539 13.7792C3.73066 13.9212 3.52436 14 3.28367 14C3.04298 14 2.83668 13.9212 2.68195 13.7792L0.232092 11.5322C0.0773639 11.3903 0 11.2011 0 10.9803C0 10.7595 0.0773639 10.5703 0.232092 10.4284C0.404011 10.2707 0.601719 10.1919 0.833811 10.1919C1.0659 10.1919 1.25501 10.2628 1.41834 10.4047L3.28367 12.1235L7.53868 8.22076C7.69341 8.07884 7.89971 8 8.1404 8C8.38109 8 8.58739 8.07884 8.74212 8.22076C8.91404 8.37845 9 8.55979 9 8.77267Z"
              fill="#767980"
            />
          </svg>
        </span>
    </button> */}
      <Box
        className="CustomDropdown__Option"
        style={{
          borderTop: index === 0 ? 'none' : '1px solid var(--boder-line-light, #cbd5e0)',
          width: isSideOverlay ? '185px' : '179px',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          height: '30px',
          backgroundColor: selected ? '#edf2f7' : '#FFF',
        }}
        onClick={() => {
          onClick(value), isSideOverlay ? setSelectedScanId(scanId) : '';
        }}>
        <Box style={{ marginRight: '4px', marginLeft: '8px', marginTop: '-2px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.33333 1.39987V0.949937C5.33339 0.830623 5.38022 0.716213 5.46355 0.631845C5.54687 0.547478 5.65987 0.500056 5.77771 0.5C5.89558 0.5 6.00863 0.547397 6.092 0.631771C6.17537 0.716144 6.22224 0.830587 6.22229 0.949937V1.39987C6.69379 1.39993 7.14596 1.58961 7.47933 1.92721C7.81271 2.2648 8 2.72264 8 3.20004V7.70004C8 8.17742 7.81271 8.63525 7.47932 8.97281C7.14594 9.31036 6.69377 9.5 6.22229 9.5H1.77771C1.30623 9.5 0.854064 9.31036 0.520679 8.97281C0.187294 8.63525 0 8.17742 0 7.70004V3.20004C-3.23701e-09 2.72264 0.187287 2.2648 0.520666 1.92721C0.854045 1.58961 1.30621 1.39993 1.77771 1.39987V0.949937C1.77776 0.830587 1.82463 0.716144 1.908 0.631771C1.99137 0.547397 2.10442 0.5 2.22229 0.5C2.34013 0.500056 2.45313 0.547478 2.53645 0.631845C2.61978 0.716213 2.66661 0.830623 2.66667 0.949937V1.39987H5.33333ZM7.11104 5H0.888959V7.70004C0.888959 7.93872 0.982588 8.16763 1.14925 8.33642C1.31592 8.50522 1.54198 8.60007 1.77771 8.60013H6.22229C6.458 8.60002 6.68403 8.50514 6.85068 8.33636C7.01734 8.16758 7.11099 7.93871 7.11104 7.70004V5ZM6.22229 2.29996V2.74989C6.22224 2.86926 6.17538 2.98373 6.09202 3.06814C6.00865 3.15254 5.8956 3.19999 5.77771 3.20004C5.65985 3.19993 5.54685 3.15246 5.46354 3.06806C5.38022 2.98366 5.33339 2.86923 5.33333 2.74989V2.29996H2.66667V2.74989C2.66661 2.86923 2.61978 2.98366 2.53646 3.06806C2.45315 3.15246 2.34015 3.19993 2.22229 3.20004C2.1044 3.19999 1.99135 3.15254 1.90799 3.06814C1.82462 2.98373 1.77776 2.86926 1.77771 2.74989V2.29996C1.66097 2.29993 1.54537 2.32319 1.43752 2.36842C1.32966 2.41365 1.23167 2.47995 1.14913 2.56354C1.0666 2.64713 1.00114 2.74636 0.956495 2.85558C0.911853 2.96479 0.888904 3.08184 0.888959 3.20004V4.09992H7.11104V3.20004C7.11107 3.08185 7.0881 2.96481 7.04345 2.85561C6.9988 2.74641 6.93334 2.64718 6.85081 2.5636C6.76828 2.48002 6.67029 2.41371 6.56245 2.36848C6.45461 2.32324 6.33902 2.29996 6.22229 2.29996Z"
              fill="#484D56"
            />
          </svg>
        </Box>
        <span
          style={{
            // marginLeft: '2px',
            width: '123px',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'var(--secondary-400, #484D56)',
            // textLeadingTrim: 'both',
            // textEdge: 'cap',
            fontFamily: 'Manrope',
            fontSize: '10px',
            marginTop: '2px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
          }}>
          {/* 
           The issue is tooltip is persitent after scrolling, so tooltip is also coming for items which are hidden
           so added the condition to only show tooltip when the certain div is on focus and if scrolled up/down the tooltip disppear
           
          */}
          {envtooltip ? (
            <div key={index} onMouseMove={() => handleMouseMove(index)}>
              {visibleTooltipIndex === index ? (
                <Tooltip title={envtip}>
                  <div>{option}</div>
                </Tooltip>
              ) : (
                <div>{option}</div>
              )}
            </div>
          ) : (
            option
          )}
          {/* <Tooltip title={envtip}>
            <div>{option}</div>
          </Tooltip> */}
        </span>
        {!selected && (
          <span style={{ marginRight: '9px', marginBottom: '3px' }}>
            {' '}
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <rect width="14" height="14" rx="2" fill="#white" />
            </svg>
          </span>
        )}

        {/* <div style={{ flexGrow: '1' }} /> */}
        {selected && (
          <div style={{ marginRight: '9px', marginBottom: '3px', marginLeft: '4px' }}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="9" height="22" viewBox="0 0 9 22" fill="none">
              <path
                d="M9 8.77267C9 8.98555 8.92264 9.159 8.76791 9.3088L3.88539 13.7792C3.73066 13.9212 3.52436 14 3.28367 14C3.04298 14 2.83668 13.9212 2.68195 13.7792L0.232092 11.5322C0.0773639 11.3903 0 11.2011 0 10.9803C0 10.7595 0.0773639 10.5703 0.232092 10.4284C0.404011 10.2707 0.601719 10.1919 0.833811 10.1919C1.0659 10.1919 1.25501 10.2628 1.41834 10.4047L3.28367 12.1235L7.53868 8.22076C7.69341 8.07884 7.89971 8 8.1404 8C8.38109 8 8.58739 8.07884 8.74212 8.22076C8.91404 8.37845 9 8.55979 9 8.77267Z"
                fill="#767980"
              />
            </svg> */}
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0451 3.877C12.159 3.99091 12.159 4.17558 12.0451 4.28948L5.45754 10.877C5.34364 10.9909 5.15897 10.9909 5.04506 10.877L2.24921 8.08115C2.13531 7.96725 2.13531 7.78257 2.24921 7.66867L2.65948 7.2584C2.77329 7.14459 2.95776 7.14448 3.07171 7.25814L5.04516 9.22677C5.15907 9.3404 5.34347 9.34033 5.45729 9.22662L11.2226 3.46679C11.3365 3.35298 11.5211 3.35302 11.6349 3.46688L12.0451 3.877Z"
                fill="black"
              />
            </svg>
          </div>
        )}
        {/* <CheckboxAssessment checked={selected} /> */}
      </Box>
    </>
  );
};

export default AssessmentDropdownOption;
