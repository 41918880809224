import React, { Suspense, useEffect, useState, useRef, useMemo } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import PlugNav from '../containers/_PlugNav';
import {
  ChangePassword,
  Compliance,
  ComplianceBreakdown,
  ConnectCloudAccount,
  Environments,
  Error,
  Exceptions,
  NewScan,
  Remediation,
  Risk,
  ScanHistory,
  RBACIndex,
  RulesNew,
  Notifications,
  ScanReport2,
  IdentityNew,
  InventoryOverview2,
  ResourceTable,
  CompliancePack,
  RiskTable,
  RulesTable,
  InventoryNew,
  KeyInsightsNew,
  EventsOverview,
  KubernetesOverview,
  MalwareOverview,
  MalwareOverviewNew,
  Findings,
  BucketProtections,
  EventsTable,
  WorkloadSkeleton,
  PolicySkeleton,
  ReportSkeleton,
  EventTableSkleton,
  EventOverviewSkleton,
  RiskSkeleton,
  RiskTableSkeleton,
  MalwareSkeleton,
  BucketTableSkleton,
  FindingTableSkleton,
  ResourceTableSkleton,
  ResourceSkeleton,
  KeyInsightSkeleton,
  InventoryOverviewSkeleton,
  DashboardSkeleton,
  // Explorer,
  Profile,
  SuppressedEvents,
  Visualization,
  MalwareOverviewStatic,
  // MalwareOverviewNew,
} from './loadable';
import RemediationReport from '@root/containers/remediation-report';
import RemediationPolicy from '@root/containers/remediation-policy';
import AddRemediationPolicy from '@root/containers/remediation-policy/newPolicy';

import Vulnerabilities from '@root/pages/workloload-scanning';
import RouteLoader from '@components/v2/global/RouteLoader';
import DashboardNew from '@root/containers/dashboard/DashboardNew';
import AppLogoLoader from '@root/components/AppLogoLoader';
import ErrorBoundaryMessage from '@root/components/v2/global/ErrorBoundaryMessage';
import OverviewSkeleton from '@root/pages/identity/pages/overview/skeleton';
import { fetchuserPolicyData } from '@root/containers/dashboard/action';
import { fetchuserPolicyTableData } from '@root/containers/dashboard/action';
import { useDispatch } from 'react-redux';
import { useEnvironmentsData, useSelectedEnvironments } from '@root/hooks/environments';
import NotificationSkleton from '@root/pages/notifications/skeleton';
import RbacSkleton from '@root/containers/rbac/skeleton';
import ExceptionTableSkleton from '@root/containers/rules/exceptions/skeleton';
import ScanTableSkleton from '@root/pages/history/skeleton';
import RulesSkeleton from '@root/pages/rules_new/skeleton';
import RulesTableSkleton from '@root/pages/rules_new/rulesTable/skeleton';
import CompliacneSkleton from '@root/containers/compliance/skeleton';
import CompliacneIndividualSkleton from '@root/containers/compliance/individual/skeleton';
import EnvironmentSkleton from '@root/containers/environment/skeleton';

let tenantID = localStorage.getItem('tenantID');
export const scanSocket = new WebSocket(`${import.meta.env.VITE_WEBSOCKET_SCAN_HISTORY}/${tenantID}`);
export let scanSocketListeners = [];

const errorRouteComponentHoc = Component => {
  return props => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

const CustomRoute = ({ path, component, children, ...props }) => {
  return (
    <Route path={path} component={errorRouteComponentHoc(component)} {...props}>
      {children}
    </Route>
  );
};

// This error boundary is placed here and not near the "root" of the app
// because we want to be able to show an error UI inside the "content area".
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    const errorMessage = error.message?.toLowerCase() || '';
    const isModuleLoadError = new RegExp('(failed.*load.*script)|(failed.*fetch.*module)', 'ig').test(errorMessage);
    if (isModuleLoadError) {
      console.error('Caught module load error. Reloading the page.');
      window.location.reload();
    } else {
      this.setState({
        error: error,
        errorInfo: errorInfo,
      });
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return <ErrorBoundaryMessage />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

function AppRoutes({ ...props }) {
  const { pathname } = useLocation();
  // Four Scenarios

  // Fresh login without AWS account.
  // Fresh login with AWS account added but no scans
  // Fresh login with AWS account added
  const [canShowPage, setCanShowPage] = useState(false);
  const socketRef = useRef(scanSocket);
  const dispatch = useDispatch();
  const { selectedEnvironments } = useSelectedEnvironments();
  const { onboarding } = useEnvironmentsData();

  useEffect(() => {
    if (window.localStorage.getItem('dashboardFromLogin')) {
      window.localStorage.removeItem('dashboardFromLogin');
    } else {
      setCanShowPage(true);
    }
  }, [canShowPage]);

  useEffect(() => {
    dispatch(fetchuserPolicyData);
    dispatch(fetchuserPolicyTableData);
  }, [dispatch]);

  const fetchNewScans = async () => {
    socketRef.current = scanSocket;

    scanSocket.onmessage = params => {
      console.log(params, 'scanSocket');

      scanSocketListeners.forEach(listener => {
        listener.func(params);
      });

      // if (newRow.scan_status === 'complete') {
      //   toast(<CustomToast />, {
      //     position: 'top-right',
      //     autoClose: false,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'light',
      //   });
      // }
    };
  };

  useEffect(() => {
    fetchNewScans();
  }, []);

  const fallback = useMemo(() => {
    switch (pathname) {
      case '/identity/overview': {
        return <OverviewSkeleton />;
      }
      case '/vulnerabilities': {
        return <WorkloadSkeleton />;
      }
      case '/remediation-policy': {
        return <PolicySkeleton />;
      }
      case '/remediation-report': {
        return <ReportSkeleton />;
      }
      case '/events/all': {
        return <EventTableSkleton />;
      }
      case '/events/overview': {
        return <EventOverviewSkleton />;
      }
      case '/risk/risk-table': {
        return <RiskTableSkeleton />;
      }
      case '/risk': {
        return <RiskSkeleton />;
      }
      case '/data-security/overview': {
        return <MalwareSkeleton />;
      }
      case '/data-security/findings': {
        return <FindingTableSkleton />;
      }
      case '/data-security/insights': {
        return <BucketTableSkleton />;
      }
      case '/inventory/overview': {
        return <InventoryOverviewSkeleton />;
      }
      case '/inventory/resources': {
        return <ResourceSkeleton />;
      }
      case '/inventory/resources-table': {
        return <ResourceTableSkleton />;
      }
      case '/inventory/key-insight': {
        return <KeyInsightSkeleton />;
      }
      case '/dashboard': {
        return <DashboardSkeleton />;
      }
      case '/notifications': {
        return <NotificationSkleton />;
      }
      case '/notifications/logs': {
        return <NotificationSkleton />;
      }
      case '/rbac': {
        return <RbacSkleton />;
      }
      case '/exceptions': {
        return <ExceptionTableSkleton />;
      }
      case '/scan-history': {
        return <ScanTableSkleton />;
      }
      case '/rules': {
        return <RulesSkeleton />;
      }
      case '/rules/table': {
        return <RulesTableSkleton />;
      }
      case '/compliance': {
        return <CompliacneSkleton />;
      }
      case '/compliance/breakdown': {
        return <CompliacneIndividualSkleton />;
      }
      case '/environments': {
        return <EnvironmentSkleton />;
      }
      default:
        return <RouteLoader />;
    }
  }, [pathname]);

  return (
    <>
      {!canShowPage ? (
        <AppLogoLoader />
      ) : (
        <MainLayout topNav={<PlugNav user={props.user} />}>
          <Suspense fallback={fallback}>
            {selectedEnvironments.map(item => item.id).length > 0 || onboarding ? (
              <ErrorBoundary>
                <Switch>
                  <CustomRoute exact path="/">
                    <Redirect to={'/dashboard'} />
                  </CustomRoute>
                  {/* <CustomRoute path="/dashboard" exact component={Dashboard} /> */}
                  <CustomRoute path="/dashboard/risk" exact component={Risk} />
                  <CustomRoute path="/environments/dashboard/risk" exact component={Risk} />

                  <CustomRoute path="/dashboard/environments" exact component={Environments} />

                  <CustomRoute path="/dashboard/environments/scan-report" exact component={ScanReport2} />
                  <CustomRoute path="/environments/scan-report/events" exact component={EventsTable} />
                  <CustomRoute path="/dashboard/environments/connect" exact component={ConnectCloudAccount} />
                  <CustomRoute path="/dashboard/scan-report" exact component={ScanReport2} />
                  <CustomRoute path="/dashboard/compliance" exact component={Compliance} />
                  <CustomRoute path="/dashboard/compliance/breakdown" exact component={ComplianceBreakdown} />
                  <CustomRoute path="/dashboard/compliance/breakdown/scan-report" exact component={ScanReport2} />

                  {/* <CustomRoute path="/dashboard/inventory/resource-evaluation" exact component={ResourceEvaluation} /> */}
                  <CustomRoute
                    path="/dashboard/inventory/resource-evaluation/scan-report/:id"
                    exact
                    component={ScanReport2}
                  />
                  {/* endregion */}
                  {/* region: Environments Routes */}
                  <CustomRoute path="/environments" exact component={Environments} />
                  <CustomRoute path="/environments/notifications/settings" component={Notifications} />
                  <CustomRoute path="/environments/dashboard/rbac" exact component={RBACIndex} />
                  <CustomRoute path="/environments/scan-report" exact component={ScanReport2} />
                  <CustomRoute path="/environments/connect" exact component={ConnectCloudAccount} />
                  {/* endregion */}
                  {/* region: Inventory Routes */}
                  {/* <CustomRoute path="/inventory/resource-evaluation" exact component={ResourceEvaluation} /> */}
                  <CustomRoute path="/inventory/resource-evaluation/scan-report" exact component={ScanReport2} />
                  {/* endregion */}
                  {/* region: Compliance Routes */}
                  <CustomRoute path="/compliance" exact component={Compliance} />
                  <CustomRoute path="/compliance/breakdown" exact component={ComplianceBreakdown} />
                  <CustomRoute path="/compliance/breakdown/scan-report" exact component={ScanReport2} />
                  {/* endregion */}

                  {/* New Identity routes */}
                  <CustomRoute path="/identity" component={IdentityNew} />
                  <CustomRoute path="/vulnerabilities" component={Vulnerabilities} />

                  {/* New Cloud Events routes */}
                  <CustomRoute path="/events/all/scan-report" exact component={ScanReport2} />
                  {/* <CustomRoute path="/old-events/" exact component={OldEvents} /> */}
                  <CustomRoute path="/events/all/risk" exact component={Risk} />

                  {/* region: Scan History Routes */}
                  <CustomRoute path="/scan-history" exact component={ScanHistory} />
                  <CustomRoute path="/scan-history/scanner" exact component={NewScan} />
                  <CustomRoute path="/scan-history/scan-report" component={ScanReport2} />
                  {/* endregion */}
                  {/* scan histoy page 2 */}

                  <CustomRoute path="/scan-history/scan-report-2" exact component={ScanReport2} />

                  <CustomRoute path="/remediation-report" exact component={RemediationReport} />
                  <CustomRoute path="/remediation-policy" exact component={RemediationPolicy} />
                  <CustomRoute path="/remediation-policy/edit/:policyId" exact component={AddRemediationPolicy} />
                  <CustomRoute path="/remediation-policy/create" exact component={AddRemediationPolicy} />

                  <CustomRoute path="/profile/change-password" exact component={ChangePassword} />

                  <CustomRoute path="/rules" exact component={RulesNew} />

                  <CustomRoute path="/rules/table" exact component={RulesTable} />

                  <CustomRoute path="/exceptions" exact component={Exceptions} />
                  {/* <CustomRoute path="/settings" exact component={Settings} /> */}
                  <CustomRoute path="/risk" exact component={Risk} />
                  <CustomRoute path="/risk/risk-table" exact component={RiskTable} />
                  <CustomRoute path="/risk/risk-table/remediation" exact component={Remediation} />
                  <CustomRoute path="/remediation" exact component={Remediation} />

                  <CustomRoute path="/notifications" component={Notifications} />

                  <CustomRoute path="/rbac" exact component={RBACIndex} />
                  <CustomRoute path="/profile" exact component={Profile} />
                  {/* <CustomRoute path="/events" exact>
                    {' '}
                    <Redirect to={`/events/all${search}`} />
                  </Route> */}

                  <CustomRoute path="/events/all" exact component={EventsTable} />
                  <CustomRoute path="/events/suppressed" exact component={SuppressedEvents} />
                  <CustomRoute path="/events/overview" exact component={EventsOverview} />
                  <CustomRoute path="/environments/dashboard/events" exact component={EventsTable} />

                  <CustomRoute path="/kubernetes-live/overview" exact component={KubernetesOverview} />

                  <CustomRoute path="/inventory/overview" exact component={InventoryNew} />
                  <CustomRoute path="/inventory/resources" exact component={InventoryOverview2} />
                  <CustomRoute path="/inventory/resources-table" exact component={ResourceTable} />

                  <CustomRoute path="/inventory/resources-table/scan-report" exact component={ScanReport2} />
                  <CustomRoute path="/inventory/key-insight" exact component={KeyInsightsNew} />
                  {/* <CustomRoute path="/inventory/key-insight/new" exact component={KeyInsightsNew} /> */}

                  <CustomRoute path="/select_compliance" exact component={CompliancePack} />
                  <CustomRoute path="/dashboard" exact component={DashboardNew} />
                  <CustomRoute path="/data-security/overview-old" exact component={MalwareOverview} />

                  <CustomRoute path="/data-security/overview" exact component={MalwareOverviewNew} />
                  <CustomRoute path="/data-security/overview/new" exact component={MalwareOverviewStatic} />
                  <CustomRoute path="/data-security/findings" exact component={Findings} />
                  <CustomRoute path="/data-security/insights" exact component={BucketProtections} />

                  <CustomRoute path="/explorer" exact component={Visualization} />
                  <CustomRoute path="/visualization" exact component={Visualization} />

                  <CustomRoute component={Error} />
                </Switch>
              </ErrorBoundary>
            ) : (
              <RouteLoader />
            )}
          </Suspense>
        </MainLayout>
      )}
    </>
  );
}

export default AppRoutes;
