import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useCallback } from 'react';

interface Props {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  insights: boolean;
}

function SearchBar({ placeholder, value, onChange, insights = false }: Props) {
  const handleChange = useCallback(
    e => {
      onChange(String(e.target.value || ''));
    },
    [onChange],
  );

  const handleClearClick = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <div className="CodeContainer__SearchBar">
      <div className="CodeContainer__SearchIconContainer">
        <SearchIcon className="CodeContainer__SearchIcon" />
      </div>
      <input
        className="fs__1"
        style={{
          ...(insights && { width: '144px' }),
        }}
        placeholder={placeholder || 'Search'}
        value={value}
        onChange={handleChange}
      />

      {value ? (
        <div className="CodeContainer__SearchClearIconContainer" onClick={handleClearClick}>
          <CloseIcon className="CodeContainer__SearchClearIcon" />
        </div>
      ) : null}
    </div>
  );
}

export default SearchBar;
