/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect, useRef } from 'react';
import * as d3 from 'd3';
import { sankey as d3sankey, sankeyLinkHorizontal, sankeyJustify } from 'd3-sankey';

import './style.scss';

const NODE_WIDTH = 200;
const FULL_OPACITY_STRIP_WIDTH = 10;
const NODE_LABEL_OFFSET = 15;
const MIDDLE_NODE_HEIGHT = 40;
const LINK_WIDTH = 10;

// const SAMPLE_DATA = {
//   nodes: [
//     { node: 0, id: 0, name: 'node0' },
//     { node: 1, id: 1, name: 'node1' },
//     { node: 2, id: 2, name: 'node2' },

//     { node: 3, id: 3, name: 'node3' },
//     { node: 4, id: 4, name: 'node4' },
//     { node: 5, id: 5, name: 'node5' },

//     { node: 6, id: 6, name: 'node6' },
//     { node: 7, id: 7, name: 'node7' },
//     { node: 8, id: 8, name: 'node8' },
//   ],
//   links: [
//     { source: 0, target: 3, value: 2 },
//     { source: 0, target: 4, value: 2 },
//     { source: 1, target: 3, value: 2 },
//     { source: 1, target: 4, value: 2 },
//     { source: 1, target: 5, value: 4 },
//     { source: 2, target: 3, value: 2 },
//     { source: 2, target: 4, value: 2 },

//     { source: 3, target: 7, value: 2 },
//     { source: 4, target: 6, value: 2 },
//     { source: 4, target: 8, value: 2 },
//     { source: 5, target: 8, value: 2 },
//   ],
// };

const SEVERITY_COLORS = {
  critical: '#cd0404',
  high: '#eb6c6c',
  medium: '#ffa825',
  low: '#e8df07',
};

function truncate(str: string, maxLength) {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength - 3)}...`; // - 3 for the ellipsis
  }

  return str;
}

/**
 *
 * @param {*} node
 * @param {*} isHover
 * @param {*} direction left|right|both : the direction to propagate
 * @param {*} startNode the node that the mouseover event started from
 */
function setHoverStateForNodeAndConnections(node, isHover, direction, startNode: any = null) {
  const _startNode = startNode || node;

  if (node.id === startNode?.id) {
    node.isHover = isHover;
  } else {
    node.isHover = _startNode?.isFakeNode ? false : isHover;
  }

  if (direction === 'both' || direction === 'right') {
    // sourceLinks are the links for which the current node is the source.
    node.sourceLinks.forEach(l => {
      l.isHover = _startNode?.isFakeNode ? false : isHover;

      if (!l.target.isFakeNode) {
        setHoverStateForNodeAndConnections(l.target, isHover, 'right', _startNode);
      }
    });
  }

  if (direction === 'both' || direction === 'left') {
    // targetLinks are the links for which the current node is the target.
    node.targetLinks.forEach(l => {
      l.isHover = _startNode?.isFakeNode ? false : isHover;

      if (!l.source.isFakeNode) {
        setHoverStateForNodeAndConnections(l.source, isHover, 'left', _startNode);
      }
    });
  }
}

function initChartContainer() {
  // Set up the canvas
  const chartContainer = d3.select('#PrioritizationFunnel');

  const containerWidth = chartContainer.node().getBoundingClientRect().width;
  const containerHeight = chartContainer.node().getBoundingClientRect().height;

  const margin = { top: 0, right: 0, bottom: 0, left: 0 },
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom;

  const svg = chartContainer.append('svg').attr('viewBox', [0, 0, width, height]);

  return { svg, width, height };
}

function Sankey({ data, totalCount, onAttackPathClick }) {
  const isInitializedRef = useRef(false);

  useLayoutEffect(() => {
    if (isInitializedRef.current) return;
    isInitializedRef.current = true;

    if (!data.nodes?.length) return;

    let linksSelection, nodesSelection;
    let isNodeOrLinkHover = false;

    // format variables
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // append the svg object to the body of the page
    const { svg, width, height } = initChartContainer();

    // svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);

    const root = svg.append('g');

    // Set the sankey diagram properties
    const sankey = d3sankey()
      .nodeWidth(NODE_WIDTH)
      .nodePadding(5)
      .size([width, height])
      .nodeId((node: any) => node.id)
      .nodeAlign(sankeyJustify)
      .nodeSort((a: any) => {
        if (a.placeAtBottom) return 1;
        return -1;
      });

    // load the data
    function init(sankeydata) {
      const graph = sankey(sankeydata);

      // graph.nodes
      //   .filter(n => n.depth === 1)
      //   .sort((a: any, b: any) => (a.y0 > b.y0 ? 1 : -1))
      //   .map((node, index) => {
      //     const sumNodeHeightsAboveCurrent = MIDDLE_NODE_HEIGHT * index;

      //     node.y0 = sumNodeHeightsAboveCurrent;
      //     node.y1 = sumNodeHeightsAboveCurrent + MIDDLE_NODE_HEIGHT;

      //     return node;
      //   });

      // graph.nodes
      //   .filter(n => n.depth === 2)
      //   .sort((a: any, b: any) => (a.y0 > b.y0 ? 1 : -1))
      //   .map((node, index) => {
      //     const sumNodeHeightsAboveCurrent = MIDDLE_NODE_HEIGHT * index;

      //     node.y0 = sumNodeHeightsAboveCurrent;
      //     node.y1 = sumNodeHeightsAboveCurrent + MIDDLE_NODE_HEIGHT;

      //     return node;
      //   });

      // graph.links.forEach((l: any) => {
      //   l.width = 10;

      //   l.y0 = l.source.y0 + LINK_WIDTH / 2;
      //   l.y1 = l.target.y0 + LINK_WIDTH / 2;
      // });

      // add in the nodes
      nodesSelection = root
        .append('g')
        .selectAll('.node')
        .data(graph.nodes)
        .enter()
        .append('g')
        .attr('class', d =>
          d.isFakeNode ? `pf-sankey-node pf-sankey-fake-node ${d.depth === 1 ? 'pf-sankey-clickable' : ''}` : 'node',
        );

      nodesSelection
        .on('mouseover', (e, d) => {
          isNodeOrLinkHover = true;

          setHoverStateForNodeAndConnections(d, true, 'both', d);
          update();
        })
        .on('mouseout', (e, d) => {
          isNodeOrLinkHover = false;

          setHoverStateForNodeAndConnections(d, false, 'both', d);
          update();
        })
        .on('click', (e, d) => {
          if (d.depth === 1) {
            // It's a middle column node
            onAttackPathClick(d.id);
          }
        });

      // add the rectangles for the nodes
      nodesSelection
        .append('rect')
        .attr('x', function (d) {
          return d.x0;
        })
        .attr('y', function (d) {
          return d.y0;
        })
        .attr('height', function (d) {
          return d.y1 - d.y0;
        })
        .attr('width', sankey.nodeWidth())
        .style('fill', function (d) {
          if (d.depth === 2) {
            return (d.color = `${SEVERITY_COLORS[d.name] ? `${SEVERITY_COLORS[d.name]}aa` : color(d.name)}`);
          }

          if (d.depth === 1) {
            return (d.color = '#6554dc');
          }

          return (d.color = '#2E86C1a0');
        });

      // Add full-opacity colored strips on the left/right of the rectangles
      nodesSelection
        .append('rect')
        .attr('x', function (d) {
          if (d.depth === 2) {
            return d.x0 + NODE_WIDTH - FULL_OPACITY_STRIP_WIDTH;
          }

          return d.x0;
        })
        .attr('y', function (d) {
          return d.y0;
        })
        .attr('height', function (d) {
          return d.y1 - d.y0;
        })
        .attr('width', FULL_OPACITY_STRIP_WIDTH)
        .style('fill', function (d) {
          if (d.depth === 1) return '#ffffff00';

          return SEVERITY_COLORS[d.name] || '#2E86C1' || color(d.name);
        });

      // add in the title for the nodes
      nodesSelection
        .append('text')
        .attr('class', d => `pf-sankey-node-label ${d.depth === 1 ? 'light' : ''}`)
        .attr('y', function (d) {
          return (d.y1 + d.y0) / 2;
        })
        .attr('dy', '0.35em')
        .text(function (d) {
          if (d.count !== undefined) {
            return `${truncate(d.name, 26 - String(d.count || '').length)} ${d.count || 0}`;
          }

          return truncate(d.name, 26);
        })
        // .filter(function (d) {
        //   return d.x0 < width / 2;
        // })
        .attr('x', function (d) {
          if (d.depth > 0) {
            return d.x0 + NODE_WIDTH / 2;
          }
          return d.x0 + NODE_LABEL_OFFSET;
        })
        .attr('text-anchor', d => (d.depth > 0 ? 'middle' : 'start'));

      // add in the links

      const linksGsSelection = root.append('g').selectAll('.link').data(graph.links).enter();

      linksGsSelection
        .append('linearGradient')
        .attr('id', link => {
          const gradientId = `gradient-${link.source.id}-${link.target.id}`.replace(/\s/g, '_');
          return gradientId;
        })
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', d => d.source.x1)
        .attr('x2', d => d.target.x0)
        .call(gradient =>
          gradient
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', link => link.source.color),
        )
        .call(gradient =>
          gradient
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', link => link.target.color),
        );

      linksSelection = linksGsSelection
        .append('path')
        .attr('class', d => (d.isFakeLink ? 'pf-sankey-link pf-sankey-fake-link' : 'pf-sankey-link'));

      linksSelection
        .attr('d', sankeyLinkHorizontal())
        .attr('stroke-width', function (d) {
          return d.width;
        })
        .attr('stroke', link => {
          const gradientId = `gradient-${link.source.id}-${link.target.id}`.replace(/\s/g, '_');
          return `url(#${gradientId})`;
        });

      linksSelection
        .on('mouseover', (e, d) => {
          if (d.isFakeLink) return;

          isNodeOrLinkHover = true;

          d.isHover = true;
          d.source.isHover = true;
          d.target.isHover = true;

          setHoverStateForNodeAndConnections(d.source, true, 'left');
          setHoverStateForNodeAndConnections(d.target, true, 'right');
          update();
        })
        .on('mouseout', (e, d) => {
          if (d.isFakeLink) return;

          isNodeOrLinkHover = false;

          d.isHover = false;
          d.source.isHover = false;
          d.target.isHover = false;

          setHoverStateForNodeAndConnections(d.source, false, 'left', null);
          setHoverStateForNodeAndConnections(d.target, false, 'right', null);

          update();
        });

      // Add the middle total count box

      const countContainer = svg.append('g').attr('class', 'pf-sankey-total-count');

      countContainer
        .append('linearGradient')
        .attr('id', link => {
          const gradientId = `gradient-middle-box`.replace(/\s/g, '_');
          return gradientId;
        })
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', d => width / 2)
        .attr('x2', d => width / 2)
        .attr('y1', d => '0%')
        .attr('y2', d => '100%')
        .call(gradient =>
          gradient
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', () => '#6946fa'),
        )
        .call(gradient =>
          gradient
            .append('stop')
            .attr('offset', '70%')
            .attr('stop-color', () => '#6946fa'),
        )
        .call(gradient =>
          gradient
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', () => '#bfb1fa'),
        );

      countContainer
        .append('rect')
        .attr('width', NODE_WIDTH)
        .attr('height', height)
        // .attr('fill', '#00b2ff')
        .attr('fill', `url(#gradient-middle-box)`)
        .attr('x', width / 2 - NODE_WIDTH / 2);

      function appendCircle(radius, opacity) {
        countContainer
          .append('circle')
          .attr('cx', width / 2)
          .attr('cy', height / 2)
          .attr('r', radius)
          .attr('opacity', opacity)
          .attr('fill', '#ffffff');
      }

      // Add concentric circles for the middle total count box.
      appendCircle(NODE_WIDTH / 2 - 40, 0.8);
      appendCircle(NODE_WIDTH / 2 - 50, 1);

      countContainer
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('y', height / 2 + 15)
        .attr('font-size', 45)
        .attr('font-weight', 600)
        .text(totalCount);
    }

    function update() {
      nodesSelection.attr('class', function (d) {
        return d.isHover
          ? `pf-sankey-node is-hover ${d.isFakeNode ? 'pf-sankey-fake-node' : ''} ${
              d.depth === 1 ? 'pf-sankey-clickable' : ''
            }`
          : `pf-sankey-node ${d.isFakeNode ? 'pf-sankey-fake-node' : ''} ${d.depth === 1 ? 'pf-sankey-clickable' : ''}`;
      });

      linksSelection.attr('class', function (d) {
        return d.isHover
          ? `pf-sankey-link is-hover ${d.isFakeLink ? 'pf-sankey-fake-link' : ''}`
          : `pf-sankey-link ${d.isFakeLink ? 'pf-sankey-fake-link' : ''}`;
      });

      svg.attr('class', isNodeOrLinkHover ? 'pf-sankey-root is-hover' : 'pf-sankey-root');
    }

    init(data);
    update();
  }, [data, totalCount, onAttackPathClick]);

  return (
    <>
      <div id="PrioritizationFunnel__AspectRatioContainer">
        <div id="PrioritizationFunnel"></div>
      </div>
    </>
  );
}

export default Sankey;
