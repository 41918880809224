import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './EnvironmentDropdown.scss';
import CheckBox from '@root/components/v2/ui-elements/CheckBox';
import { Box } from '@mui/material';
import EnvironmentDropdownOption from './EnvironmentDropdownOption';

const EnvironmentDropdown = ({
  options,
  value,
  setValue,
  disabled,
  isLoading,
  disabledOptions,
  singleSelect,
  onChange,
}) => {
  //   const options = [
  //     { label: 'Drift', children: ['Create', 'Modify', 'Delete'] },
  //     { label: 'Threat' },
  //     { label: 'Remediation', children: ['One Click / Manual', 'Auto Remediation'] },
  //   ];

  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const isAllSelected = useMemo(() => {
    if (!value || !value.length) return false;

    return options.every(option => option.options.every(o => value.includes(o.value)));
  }, [options, value]);

  const allEnvironmentsCount = useMemo(() => {
    let count = 0;

    // if (!_.isEmpty(options)) {
    for (const group of options) {
      count += group.options?.length;
    }
    // }

    return count;
  }, [options]);

  const handleOptionClick = useCallback(
    option => {
      if (option === 'all') {
        if (isAllSelected) {
          onChange([]);
        } else {
          const selectedValues = [];
          for (const g of options) {
            for (const o of g.options) {
              if (!disabledOptions.includes(o.value)) {
                selectedValues.push(o.value);
              }
            }
          }
          onChange(selectedValues);
        }
      } else {
        if (value.includes(option.value)) {
          onChange(value.filter(v => v !== option.value));
        } else {
          onChange([...value, option.value]);
        }
      }
    },
    [isAllSelected, onChange, options, disabledOptions, value],
  );

  const handleGroupClick = useCallback(
    group => {
      const isAllOptionsInGroupSelected = group.options.every(o => value.includes(o.value));

      if (isAllOptionsInGroupSelected) {
        // Remove all options in group from value
        onChange(value.filter(v => !group.options.find(o => o.value === v)));
      } else {
        // Add all options in the group to value
        onChange([
          ...value,
          ...group.options
            .filter(o => !value.includes(o.value) && !disabledOptions.includes(o.value))
            .map(o => o.value),
        ]);
      }
    },
    [value, onChange, disabledOptions],
  );

  const handleOutsideClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  // const handleOnOptionSelect = option => {
  //   //check if selected options contains the passed option. if yes, remove it from selected options. otherwise add
  //   if (value.some(op => op.value === option.value)) {
  //     setValue(prev => prev.filter(op => op.value !== option.value));
  //   } else {
  //     setValue(prev => [...prev, option]);
  //   }
  // };

  return (
    <div className="EnvironmentDropdown__Container" ref={ref}>
      <Box
        className="EnvironmentDropdown__ValueContainer"
        onClick={() => setOpen(prev => !prev)}
        style={{ pointerEvents: disabled || isLoading ? 'none' : '' }}>
        <input
          className="EnvironmentDropdown__ValueInput"
          onChange={() => {}}
          value={
            !value.length || isAllSelected
              ? `Environments: All`
              : `Environments: ${value.length > 0 ? `+${value.length}` : 'All'}`
          }
        />
        <div style={{ flexGrow: '1' }} />
        <Box
          className="EnvironmentDropdown__DropdownArrow"
          style={{ transform: open ? 'rotate(180deg)' : '', transition: 'all 200ms', height: '40px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path
              d="M-3.1883e-08 9.7294C-2.31213e-08 9.52895 0.0703583 9.35078 0.211075 9.21715C0.492508 8.92762 0.985017 8.92762 1.26645 9.21715L4.50293 12.2684L7.71596 9.21715C7.99739 8.92762 8.4899 8.92762 8.77134 9.21715C9.07622 9.48441 9.07622 9.95212 8.77134 10.2194L5.01889 13.7829C4.73746 14.0724 4.24495 14.0724 3.96352 13.7829L0.211075 10.2194C0.0703582 10.0857 -3.96713e-08 9.90757 -3.1883e-08 9.7294Z"
              fill="#484D56"></path>
          </svg>
        </Box>
      </Box>

      <div className="EnvironmentDropdown__Options" style={{ display: open ? 'block' : 'none' }}>
        <Box
          className="EnvironmentDropdown__Option"
          style={{
            borderTop: 'none',
            padding: '12px 15px',
          }}
          onClick={() => handleOptionClick('all')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M6.3 9.8V11.9C6.3 13.0549 5.35493 14 4.2 14H2.1C0.945069 14 0 13.0549 0 11.9V9.8C0 8.64507 0.945069 7.7 2.1 7.7H4.2C5.35493 7.7 6.3 8.64507 6.3 9.8Z"
              fill="#194BFB"
            />
            <path
              d="M6.3 2.1V4.2C6.3 5.35493 5.35493 6.3 4.2 6.3H2.1C0.945069 6.3 0 5.35493 0 4.2V2.1C0 0.945069 0.945069 0 2.1 0H4.2C5.35493 0 6.3 0.945069 6.3 2.1Z"
              fill="#194BFB"
            />
            <path
              d="M14 9.8V11.9C14 13.0549 13.0549 14 11.9 14H9.8C8.64507 14 7.7 13.0549 7.7 11.9V9.8C7.7 8.64507 8.64507 7.7 9.8 7.7H11.9C13.0549 7.7 14 8.64507 14 9.8Z"
              fill="#194BFB"
            />
            <path
              d="M14 2.1V4.2C14 5.35493 13.0549 6.3 11.9 6.3H9.8C8.64507 6.3 7.7 5.35493 7.7 4.2V2.1C7.7 0.945069 8.64507 0 9.8 0H11.9C13.0549 0 14 0.945069 14 2.1Z"
              fill="#194BFB"
            />
          </svg>
          <div style={{ marginLeft: '13px', color: '#194BFB' }}>All ({allEnvironmentsCount})</div>
          <div style={{ flexGrow: '1' }} />
          <CheckBox checked={isAllSelected} />
        </Box>

        {options.map((option, index) => (
          <EnvironmentDropdownOption
            key={index}
            option={option}
            index={index}
            length={options.length}
            onClick={onChange}
            selected={value.some(op => op === option.value)}
            isAllSelected={isAllSelected}
            handleOptionClick={handleOptionClick}
            disabledOptions={disabledOptions}
            handleGroupClick={handleGroupClick}
            value={value}
            setValue={setValue}
            singleSelect={singleSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default EnvironmentDropdown;
