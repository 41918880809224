/* =====================================================
Copied from https://github.com/react-syntax-highlighter
and then updated to support search highlighting.
===================================================== */

// Get all possible permutations of all power sets
//
// Super simple, non-algorithmic solution since the
// number of class names will not be greater than 4
function powerSetPermutations(arr: any[]) {
  const arrLength = arr.length;
  if (arrLength === 0 || arrLength === 1) return arr;
  if (arrLength === 2) {
    // prettier-ignore
    return [
      arr[0],
      arr[1],
      `${arr[0]}.${arr[1]}`,
      `${arr[1]}.${arr[0]}`
    ];
  }
  if (arrLength === 3) {
    return [
      arr[0],
      arr[1],
      arr[2],
      `${arr[0]}.${arr[1]}`,
      `${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[0]}`,
      `${arr[1]}.${arr[2]}`,
      `${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[1]}`,
      `${arr[0]}.${arr[1]}.${arr[2]}`,
      `${arr[0]}.${arr[2]}.${arr[1]}`,
      `${arr[1]}.${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[0]}.${arr[1]}`,
      `${arr[2]}.${arr[1]}.${arr[0]}`,
    ];
  }
  if (arrLength >= 4) {
    // Currently does not support more than 4 extra
    // class names (after `.token` has been removed)
    return [
      arr[0],
      arr[1],
      arr[2],
      arr[3],
      `${arr[0]}.${arr[1]}`,
      `${arr[0]}.${arr[2]}`,
      `${arr[0]}.${arr[3]}`,
      `${arr[1]}.${arr[0]}`,
      `${arr[1]}.${arr[2]}`,
      `${arr[1]}.${arr[3]}`,
      `${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[1]}`,
      `${arr[2]}.${arr[3]}`,
      `${arr[3]}.${arr[0]}`,
      `${arr[3]}.${arr[1]}`,
      `${arr[3]}.${arr[2]}`,
      `${arr[0]}.${arr[1]}.${arr[2]}`,
      `${arr[0]}.${arr[1]}.${arr[3]}`,
      `${arr[0]}.${arr[2]}.${arr[1]}`,
      `${arr[0]}.${arr[2]}.${arr[3]}`,
      `${arr[0]}.${arr[3]}.${arr[1]}`,
      `${arr[0]}.${arr[3]}.${arr[2]}`,
      `${arr[1]}.${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[0]}.${arr[3]}`,
      `${arr[1]}.${arr[2]}.${arr[0]}`,
      `${arr[1]}.${arr[2]}.${arr[3]}`,
      `${arr[1]}.${arr[3]}.${arr[0]}`,
      `${arr[1]}.${arr[3]}.${arr[2]}`,
      `${arr[2]}.${arr[0]}.${arr[1]}`,
      `${arr[2]}.${arr[0]}.${arr[3]}`,
      `${arr[2]}.${arr[1]}.${arr[0]}`,
      `${arr[2]}.${arr[1]}.${arr[3]}`,
      `${arr[2]}.${arr[3]}.${arr[0]}`,
      `${arr[2]}.${arr[3]}.${arr[1]}`,
      `${arr[3]}.${arr[0]}.${arr[1]}`,
      `${arr[3]}.${arr[0]}.${arr[2]}`,
      `${arr[3]}.${arr[1]}.${arr[0]}`,
      `${arr[3]}.${arr[1]}.${arr[2]}`,
      `${arr[3]}.${arr[2]}.${arr[0]}`,
      `${arr[3]}.${arr[2]}.${arr[1]}`,
      `${arr[0]}.${arr[1]}.${arr[2]}.${arr[3]}`,
      `${arr[0]}.${arr[1]}.${arr[3]}.${arr[2]}`,
      `${arr[0]}.${arr[2]}.${arr[1]}.${arr[3]}`,
      `${arr[0]}.${arr[2]}.${arr[3]}.${arr[1]}`,
      `${arr[0]}.${arr[3]}.${arr[1]}.${arr[2]}`,
      `${arr[0]}.${arr[3]}.${arr[2]}.${arr[1]}`,
      `${arr[1]}.${arr[0]}.${arr[2]}.${arr[3]}`,
      `${arr[1]}.${arr[0]}.${arr[3]}.${arr[2]}`,
      `${arr[1]}.${arr[2]}.${arr[0]}.${arr[3]}`,
      `${arr[1]}.${arr[2]}.${arr[3]}.${arr[0]}`,
      `${arr[1]}.${arr[3]}.${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[3]}.${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[0]}.${arr[1]}.${arr[3]}`,
      `${arr[2]}.${arr[0]}.${arr[3]}.${arr[1]}`,
      `${arr[2]}.${arr[1]}.${arr[0]}.${arr[3]}`,
      `${arr[2]}.${arr[1]}.${arr[3]}.${arr[0]}`,
      `${arr[2]}.${arr[3]}.${arr[0]}.${arr[1]}`,
      `${arr[2]}.${arr[3]}.${arr[1]}.${arr[0]}`,
      `${arr[3]}.${arr[0]}.${arr[1]}.${arr[2]}`,
      `${arr[3]}.${arr[0]}.${arr[2]}.${arr[1]}`,
      `${arr[3]}.${arr[1]}.${arr[0]}.${arr[2]}`,
      `${arr[3]}.${arr[1]}.${arr[2]}.${arr[0]}`,
      `${arr[3]}.${arr[2]}.${arr[0]}.${arr[1]}`,
      `${arr[3]}.${arr[2]}.${arr[1]}.${arr[0]}`,
    ];
  }
}

const classNameCombinations: any = {};

function getClassNameCombinations(classNames: any[]) {
  if (classNames.length === 0 || classNames.length === 1) return classNames;
  const key = classNames.join('.');
  if (!classNameCombinations[key]) {
    classNameCombinations[key] = powerSetPermutations(classNames);
  }
  return classNameCombinations[key];
}

export function createStyleObject(classNames: any[], elementStyle = {}, stylesheet: any) {
  const nonTokenClassNames = classNames.filter(className => className !== 'token');
  const classNamesCombinations = getClassNameCombinations(nonTokenClassNames);
  return classNamesCombinations.reduce((styleObject: any, className: any) => {
    return { ...styleObject, ...stylesheet[className] };
  }, elementStyle);
}

export function createClassNameString(classNames: string[]) {
  return classNames.join(' ');
}

export function createChildren(
  stylesheet: any,
  useInlineStyles: boolean,
  searchString: string,
  searchMatches: any,
  currentResultData: any,
) {
  let childrenCount = 0;
  return (children: any[], parent: any) => {
    childrenCount += 1;
    return children.map((child: any, i: number) => {
      return createElement({
        node: Object.prototype.hasOwnProperty.call(child, 'rowIndex')
          ? child
          : { ...child, rowIndex: parent.rowIndex, tokenIndex: parent.tokenIndex },
        stylesheet,
        useInlineStyles,
        key: `code-segment-${childrenCount}-${i}`,
        searchString,
        searchMatches,
        currentResultData,
      });
    });
  };
}

export default function createElement({
  node,
  stylesheet,
  style = {},
  useInlineStyles,
  key,
  searchString,
  searchMatches,
  currentResultData,
}: any) {
  const { properties, type, tagName: TagName, value, rowIndex, tokenIndex } = node;

  if (type === 'text') {
    if (searchMatches[rowIndex]?.[tokenIndex]) {
      const _value = String(value || '');

      const nonMatchParts = _value.toLowerCase().split(searchString.toLowerCase());

      const leftPart = _value.substring(0, nonMatchParts[0].length);
      const rightPart = _value.substring(leftPart.length + searchString.length);
      const matchedPart = _value.substring(nonMatchParts[0].length, nonMatchParts[0].length + searchString.length);

      const isCurrentResult = currentResultData.rowIndex === rowIndex && currentResultData.tokenIndex === tokenIndex;

      return (
        <span>
          {leftPart}
          <span className={isCurrentResult ? 'CodeContainer__CurrentResult' : 'CodeContainer__Result'}>
            {matchedPart}
          </span>
          {rightPart}
        </span>
      );
      return;
    }

    return value;
  } else if (TagName) {
    const childrenCreator = createChildren(stylesheet, useInlineStyles, searchString, searchMatches, currentResultData);

    let props;

    if (!useInlineStyles) {
      props = {
        ...properties,
        className: createClassNameString(properties.className),
      };
    } else {
      const allStylesheetSelectors = Object.keys(stylesheet).reduce((classes: any, selector: any) => {
        selector.split('.').forEach((className: string) => {
          if (!classes.includes(className)) classes.push(className);
        });
        return classes;
      }, []);

      // For compatibility with older versions of react-syntax-highlighter
      const startingClassName = properties.className && properties.className.includes('token') ? ['token'] : [];

      const className =
        properties.className &&
        startingClassName.concat(
          properties.className.filter((className: string) => !allStylesheetSelectors.includes(className)),
        );

      props = {
        ...properties,
        className: createClassNameString(className) || undefined,
        style: createStyleObject(properties.className, Object.assign({}, properties.style, style), stylesheet),
      };
    }

    const children = childrenCreator(node.children, node);

    return (
      <TagName key={key} {...props}>
        {children}
      </TagName>
    );
  }
}
