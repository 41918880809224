import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EditPolicy from '../newPolicy/editPolicy';
import './style.scss';

const EditModal = props => {
  const [show, setShow] = useState(props.show);

  const handleClose = () => setShow(false);

  return (
    <div className="w-100" style={{ minWidth: '800px' }}>
      <Modal
        id="editPolicy"
        className="w-100"
        show={show}
        centered
        onHide={() => {
          props.onClose();
          handleClose();
        }}>
        <Modal.Header closeButton style={{ backgroundColor: '#EAEFF7' }}>
          <Modal.Title>Edit Remediation Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPolicy></EditPolicy>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            onClick={() => {
              props.onClose();
              handleClose();
            }}>
            Reset
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              props.onClose();
              handleClose();
            }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditModal;
