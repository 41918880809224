import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import './style.scss';

function FullscreenLoader({ useAbsolutePosition } = {}) {
  return (
    <div className="FullscreenLoaderContainer" style={{ position: useAbsolutePosition ? 'absolute' : 'fixed' }}>
      <div>
        <CircularProgress />
      </div>
    </div>
  );
}

export default FullscreenLoader;
