import { Box, Grid, Skeleton } from '@mui/material';
import AllIcon from '@root/assets/img/header/cloud_providers/all.png';
import ComputeIcon from '@root/assets/svg/Service_Category_Icons/Compute.svg';
import ContainersIcon from '@root/assets/svg/Service_Category_Icons/Containers.svg';
import Analytics from '@root/assets/svg/Service_Category_Icons/Data_Analytics.svg';
import Database from '@root/assets/svg/Service_Category_Icons/Database.svg';
import DeveloperIcon from '@root/assets/svg/Service_Category_Icons/Developer_Tools.svg';
import MachineLearningIcon from '@root/assets/svg/Service_Category_Icons/Machine_Learning.svg';
import ManagementToolsIcon from '@root/assets/svg/Service_Category_Icons/Management_Tools.svg';
import NetworkingIcon from '@root/assets/svg/Service_Category_Icons/Networking.svg';
import OperationIcon from '@root/assets/svg/Service_Category_Icons/Operations.svg';
import SecurityIdentityIcon from '@root/assets/svg/Service_Category_Icons/Security_Identity.svg';
import ServerlessIcon from '@root/assets/svg/Service_Category_Icons/Serverless.svg';
import StorageIcon from '@root/assets/svg/Service_Category_Icons/Storage.svg';
import ContentBox from '@root/components/v2/content/ContentBox';
import Text from '@root/components/v2/ui-elements/Text';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import './components.scss';
// import RegionMap from './RegionMap';
// import Box from '@mui/material/Box';
// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';
import RegionsMap from './RegionsMap';
import { makeMapData } from './RegionsMap/utils';

function Legend({ name, color }) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" marginRight="16px">
      <Box width="12px" height="12px" marginRight="5px" borderRadius="50%" backgroundColor={color} />
      <Text lineHeight={1} className="fs__1">
        {name}
      </Text>
    </Box>
  );
}

const service_category_icons = {
  [`Networking`]: NetworkingIcon,
  ['Operations']: OperationIcon,
  ['Developer Tools']: DeveloperIcon,
  ['Machine Learning']: MachineLearningIcon,
  ['Storage']: StorageIcon,
  ['Compute']: ComputeIcon,
  ['Security & Identity']: SecurityIdentityIcon,
  ['Serverless']: ServerlessIcon,
  ['Management Tools']: ManagementToolsIcon,
  ['Containers']: ContainersIcon,
  ['Data Analytics']: Analytics,
  ['Database']: Database,
};

const Regions = ({ allRegionsData, setSelectedTab, selectedTab, isFetchingRegions, cloudProvider }) => {
  const _eventsMapData = useMemo(() => {
    return makeMapData(allRegionsData?.all_regions_with_service_and_resources || {});
  }, [allRegionsData]);

  const [activeTab, setActiveTab] = useState(selectedTab);

  const top_service_categories = useMemo(() => {
    const topCategories = allRegionsData?.top_service_categories || {};

    return Object.keys(allRegionsData?.top_service_categories ? allRegionsData?.top_service_categories : {}).sort(
      (a, b) => ((topCategories[a] || 0) > (topCategories[b] || 0) ? -1 : 1),
    );
  }, [allRegionsData]);

  const handleTabChange = useCallback(provider => {
    setSelectedTab(provider);
    setActiveTab(provider);
  }, [setSelectedTab]);

  const handleClick = useCallback(e => {
    handleTabChange(e.currentTarget.dataset.cloudProvider);
  }, [handleTabChange]);

  const headerEndElement = useMemo(() => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Legend color="#03BB34" name="Active" />
        <Legend color="#476FFC" name="Inactive" />
      </Box>
    );
  }, []);

  // console.log(allRegionsData, top_service_categories, 'active');
  if (isFetchingRegions) {
    return (
      <Grid>
        <ContentBox
          title="Regions"
          titleSize="medium"
          height="500px"
          padding="0px"
          titleContainerStyle={{
            fontSize: '16px !important',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            margin: '0px',
            color: 'var(--keyinsight-text-color)',
            // background: '#F7FAFC',
            // backgroundColor: 'var(--keyinsight-box-header-background)',
          }}
          containerClassName={'border-box-no-pad'}
          paddingMode="content"
          headerEndElement={headerEndElement}>
          <Box position={'relative'} height={'100%'}>
            <Skeleton variant="rectangular" width="100%" height="453px" />
          </Box>
        </ContentBox>
      </Grid>
    );
  } else if (cloudProvider === 'azure') {
    return (
      <Grid>
        <ContentBox
          infoMsg={'Region wise service & resource distribution'}
          title="Regions"
          titleSize="medium"
          height="500px"
          padding="0px"
          titleContainerStyle={{
            fontSize: '16px !important',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            margin: '0px',
            color: 'var(--keyinsight-text-color)',
            // background: '#F7FAFC',
            // backgroundColor: 'var(--keyinsight-box-header-background)',
          }}
          containerClassName={'border-box-no-pad'}
          paddingMode="content"
          headerEndElement={headerEndElement}>
          <Box position={'relative'} height={'100%'}>
            <NoData height={'100%'} />
          </Box>
        </ContentBox>
      </Grid>
    );
  } else {
    if (_.isEmpty(top_service_categories)) {
      return (
        <Grid>
          <ContentBox
            infoMsg={'Region wise service & resource distribution'}
            title="Regions"
            titleSize="medium"
            height="500px"
            padding="0px"
            titleContainerStyle={{
              fontSize: '16px !important',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              margin: '0px',
              color: 'var(--keyinsight-text-color)',
              // background: '#F7FAFC',
              // backgroundColor: 'var(--keyinsight-box-header-background)',
            }}
            containerClassName={'border-box-no-pad'}
            paddingMode="content"
            headerEndElement={headerEndElement}>
            <Box position={'relative'} height={'100%'}>
              <NoData height={'100%'} />
            </Box>
          </ContentBox>
        </Grid>
      );
    }

    return (
      <Grid>
        <ContentBox
          infoMsg={'Region wise service & resource distribution'}
          title="Regions"
          titleSize="medium"
          height="500px"
          padding="0px"
          titleContainerStyle={{
            fontSize: '16px !important',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            margin: '0px',
            color: 'var(--keyinsight-text-color)',
            // background: '#F7FAFC',
            // backgroundColor: 'var(--keyinsight-box-header-background)',
          }}
          containerClassName={'border-box-no-pad'}
          containerStyle={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
          paddingMode="content"
          headerEndElement={headerEndElement}>
          {isFetchingRegions ? (
            <Box position={'relative'} height={'100%'}>
              <Skeleton variant="rectangular" width="100%" height="453px" />
            </Box>
          ) : (
            <Grid className="dashboard_component" display="flex" flexDirection="column" flexGrow={1}>
              <div
                className="CloudProviderBar CustomScrollbar"
                style={{
                  margin: '0',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  justifyContent: 'flex-start',
                  alignItems: 'stretch',
                  gap: '5px',
                  height: 'initial',
                  overflowY: 'auto',
                }}>
                <Tooltip title={`Services: ${allRegionsData?.total_services || 0}`}>
                  <div
                    className={`CloudProviderBar__Option_CE MapsTabItem ${activeTab === 'All' ? 'Active' : ''}`}
                    data-cloud-provider="All"
                    onClick={handleClick}>
                    <img src={AllIcon} />
                    <Text className="bar_text">{`Top 5 (${allRegionsData?.total_services || 0})`}</Text>
                  </div>
                </Tooltip>

                <Tooltip
                  title={`Services: ${allRegionsData?.top_service_categories?.[top_service_categories?.[0]] || 0}`}>
                  <div
                    className={`CloudProviderBar__Option_CE MapsTabItem ${
                      activeTab === top_service_categories[0] ? 'Active' : ''
                    }`}
                    data-cloud-provider={`${top_service_categories[0]}`}
                    onClick={handleClick}>
                    <img src={service_category_icons[top_service_categories[0]]} style={{ width: '20px' }} />
                    <Text className="bar_text">{`${top_service_categories[0]} (${
                      allRegionsData?.top_service_categories?.[top_service_categories[0]] || 0
                    })`}</Text>
                  </div>
                </Tooltip>

                <Tooltip
                  title={`Services: ${allRegionsData?.top_service_categories?.[top_service_categories?.[1]] || 0}`}>
                  <div
                    className={`CloudProviderBar__Option_CE MapsTabItem ${
                      activeTab === top_service_categories[1] ? 'Active' : ''
                    }`}
                    data-cloud-provider={`${top_service_categories[1]}`}
                    onClick={handleClick}>
                    <img src={service_category_icons[top_service_categories[1]]} style={{ width: '20px' }} />
                    <Text className="bar_text">{`${top_service_categories[1]} (${
                      allRegionsData?.top_service_categories?.[top_service_categories[1]] || 0
                    })`}</Text>
                  </div>
                </Tooltip>

                <Tooltip
                  title={`Services: ${allRegionsData?.top_service_categories?.[top_service_categories?.[2]] || 0}`}>
                  <div
                    className={`CloudProviderBar__Option_CE MapsTabItem ${
                      activeTab === top_service_categories[2] ? 'Active' : ''
                    }`}
                    data-cloud-provider={`${top_service_categories[2]}`}
                    onClick={handleClick}>
                    <img src={service_category_icons[top_service_categories[2]]} style={{ width: '20px' }} />
                    <Text className="bar_text">{`${top_service_categories[2]} (${
                      allRegionsData?.top_service_categories?.[top_service_categories[2]] || 0
                    })`}</Text>
                  </div>
                </Tooltip>

                <Tooltip
                  title={`Services: ${allRegionsData?.top_service_categories?.[top_service_categories?.[3]] || 0}`}>
                  <div
                    className={`CloudProviderBar__Option_CE MapsTabItem ${
                      activeTab === top_service_categories[3] ? 'Active' : ''
                    }`}
                    data-cloud-provider={`${top_service_categories[3]}`}
                    onClick={handleClick}>
                    <img src={service_category_icons[top_service_categories[3]]} style={{ width: '20px' }} />
                    <Text className="bar_text">{`${top_service_categories[3]} (${
                      allRegionsData?.top_service_categories?.[top_service_categories[3]] || 0
                    })`}</Text>
                  </div>
                </Tooltip>

                <Tooltip
                  title={`Services: ${allRegionsData?.top_service_categories?.[top_service_categories?.[4]] || 0}`}>
                  <div
                    className={`CloudProviderBar__Option_CE MapsTabItem ${
                      activeTab === top_service_categories[4] ? 'Active' : ''
                    }`}
                    data-cloud-provider={`${top_service_categories[4]}`}
                    onClick={handleClick}>
                    <img src={service_category_icons[top_service_categories[4]]} style={{ width: '20px' }} />
                    <Text className="bar_text">{`${top_service_categories[4]} (${
                      allRegionsData?.top_service_categories?.[top_service_categories[4]] || 0
                    })`}</Text>
                  </div>
                </Tooltip>
              </div>
              {/* <RegionMap data={data} /> */}

              <RegionsMap data={_eventsMapData} />
            </Grid>
          )}
        </ContentBox>
      </Grid>
    );
  }
};
export default Regions;
