import aws from '@root/assets/img/cloud_provider_icons/aws_icon.png';
import azure from '@root/assets/img/cloud_provider_icons/azure_small_icon.png';
// import gcp from '@root/assets/img/cloud_provider_icons/gcp_small_icon.png';
import gcp from '@root/assets/img/header/cloud_providers/gcp.png';
import criticalIcon from '@root/assets/svg/Compliance_V1/Critical.svg';
import highIcon from '@root/assets/svg/Compliance_V1/High.svg';
import lowIcon from '@root/assets/svg/Compliance_V1/Low.svg';
import mediumIcon from '@root/assets/svg/Compliance_V1/Medium.svg';
import info from '@root/assets/svg/workload/info.svg';
import question1 from '@root/assets/svg/workload/question1.png';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import TopOpenTooltip from '@root/components/v2/ui-elements/Tooltip/TopOpenTooltip';
import { Progress } from 'reactstrap';
import './components.scss';
// import HighIcon from '@root/components/svg/high';
// import CriticalIcon from '@root/components/svg/criticalIcon';
// import MediumIcon from '@root/components/svg/mediumIcon';
// import LowIcon from '@root/components/svg/lowIcon';
const columns = randomColors => [
  {
    field: 'top_cve_impact',
    width: 200,
    //
    headerName: (
      <>
        <span style={{ marginRight: '6px' }}> Top CVE’s Impact</span>
        <TopOpenTooltip
          placement="top-start"
          title={
            <div style={{ padding: '15px' }}>
              <span> Top CVE’s impacting resources across the environments</span>
            </div>
          }>
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.22135 10.6038C8.79598 10.6038 10.8831 8.51668 10.8831 5.94205C10.8831 3.36742 8.79598 1.28028 6.22135 1.28027C3.64672 1.28027 1.55957 3.36742 1.55957 5.94205C1.55957 8.51668 3.64672 10.6038 6.22135 10.6038ZM12.002 5.94205C12.002 9.13459 9.41389 11.7227 6.22135 11.7227C3.02881 11.7227 0.440747 9.13459 0.440747 5.94205C0.440747 2.74951 3.02881 0.161449 6.22135 0.161449C9.41389 0.161449 12.002 2.74951 12.002 5.94205ZM6.22135 9.11206C5.91239 9.11206 5.66194 8.8616 5.66194 8.55265L5.66194 5.41993C5.66194 5.11098 5.91239 4.86052 6.22135 4.86052C6.53031 4.86052 6.78076 5.11098 6.78076 5.41993L6.78076 8.55265C6.78076 8.8616 6.5303 9.11206 6.22135 9.11206Z"
              fill="#718096"
            />
            <path
              d="M5.69893 3.85386C5.69893 3.5655 5.93269 3.33174 6.22105 3.33174C6.5094 3.33174 6.74316 3.5655 6.74316 3.85386C6.74316 4.14222 6.5094 4.37598 6.22105 4.37598C5.93269 4.37598 5.69893 4.14222 5.69893 3.85386Z"
              fill="#718096"
            />
          </svg>
        </TopOpenTooltip>
      </>
    ),
    headerClassName: 'fs__2',
    classes: 'align-middle',
    sort: true,
    renderCell: params => {
      return params.row.top_cve_impact;
    },
  },
  {
    field: 'cve_id',
    headerName: 'CVE ID',
    classes: 'align-middle',
    headerClassName: 'fs__2',
    minWidth: 180,
    sort: true,
  },
  {
    field: 'cvss_severity',
    headerName: 'CVSS Severity',
    classes: 'align-middle',
    headerClassName: 'fs__2',
    minWidth: 200,
    sort: true,
    renderCell: params => {
      return (
        <>
          <div>
            <Tooltip title={params.row.cvss_severity}>
              {params.row.cvss_severity === 'HIGH' ? (
                // <HighIcon textDisabled={true} />
                <img src={highIcon} />
              ) : params.row.cvss_severity === 'CRITICAL' ? (
                // <CriticalIcon textDisabled={true} />
                <img src={criticalIcon} />
              ) : params.row.cvss_severity === 'MEDIUM' ? (
                // <MediumIcon textDisabled={true} />
                <img src={mediumIcon} style={{ width: '88px' }} />
              ) : params.row.cvss_severity === 'LOW' ? (
                // <LowIcon textDisabled={true} />
                <img src={lowIcon} />
              ) : params.row.cvss_severity === 'INFORMATIONAL' ? (
                <img
                  src={info}
                  style={{
                    height:
                      params.row.cvss_severity === 'INFORMATIONAL' || params.row.cvss_severity === 'UNTRIAGED'
                        ? '30px'
                        : '',
                    width:
                      params.row.cvss_severity === 'INFORMATIONAL' || params.row.cvss_severity === 'UNTRIAGED'
                        ? '30px'
                        : '',
                  }}
                />
              ) : (
                <img
                  src={question1}
                  style={{
                    height:
                      params.row.cvss_severity === 'INFORMATIONAL' || params.row.cvss_severity === 'UNTRIAGED'
                        ? '30px'
                        : '',
                    width:
                      params.row.cvss_severity === 'INFORMATIONAL' || params.row.cvss_severity === 'UNTRIAGED'
                        ? '30px'
                        : '',
                  }}
                />
              )}
            </Tooltip>
          </div>

          {/* {params.row.priorit_score_dif === '0' ? (
            ''
          ) : params.row.priorit_score_dif === '1' ? (
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.412893 2.23408L4.01989 5.61786C4.56303 6.12738 5.44041 6.12738 5.98354 5.61786L9.59054 2.23408C10.4679 1.411 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.411 0.412893 2.23408Z"
                fill="#F72121"
              />
            </svg>
          ) : params.row.priorit_score_dif === '-1' ? (
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.58711 3.76592L5.98011 0.382145C5.43697 -0.127382 4.5596 -0.127382 4.01646 0.382144L0.409459 3.76592C-0.467918 4.589 0.15878 6 1.39825 6L8.61224 6C9.85171 6 10.4645 4.589 9.58711 3.76592Z"
                fill="#03BB34"
              />
            </svg>
          ) : (
            ''
          )} */}
        </>
      );
    },
  },
  {
    field: 'cloud',
    headerName: 'Cloud',
    classes: 'align-middle',
    headerClassName: 'fs__2',
    minWidth: 150,
    sort: true,
    renderCell: params => {
      return (
        <img
          src={params.row.cloud === 'aws' ? aws : params.row.cloud === 'gcp' ? gcp : azure}
          style={{ width: '28.8px' }}
        />
      );
    },
  },
  {
    field: 'resources',
    headerName: 'Resources',
    classes: 'align-middle',
    headerClassName: 'fs__2',
    minWidth: 200,
    sort: true,
    renderCell: params => {
      return (
        <div style={{ display: 'flex', height: '12px', alignItems: 'center', gap: '10px' }}>
          <Progress
            className="ProgressBar"
            barClassName="ProgressBar__Bar"
            style={{ background: '#E2E8F0', width: '100px' }}
            barStyle={{
              backgroundColor: randomColors[params.row.id - 1],
              borderRadius: '10px',
            }}
            value={params.row.resources}
            max={15}></Progress>
          <span style={{ marginLeft: '10px' }}>{params.row.resources}</span>
        </div>
      );
    },
  },
];
export default columns;
