import { useCallback } from 'react';
import { Grid, Box, Skeleton } from '@mui/material';

import compliantIcon from '@root/assets/svg/Compliance_V1/e-compliant.svg';
import nonCompliantIcon from '@root/assets/svg/Compliance_V1/e-non-compliant.svg';
import critical from '@root/assets/svg/Risk/critical.svg';
import high from '@root/assets/svg/Risk/high.svg';
import low from '@root/assets/svg/Risk/low.svg';
import medium from '@root/assets/svg/Risk/medium.svg';
import externaIcon from '@root/assets/svg/external-link.svg';
// import Loader from '@root/components/common/Loader';
import NoData from '@root/components/common/NoData';
import ContentBox from '@root/components/v2/content/ContentBox';
import { useHistory } from 'react-router';
import BreadcrumbParts from '@root/components/v2/global/breadcrumbs/breadcrumb-parts';
import { buildBreadcrumbUrl } from '@root/hooks/breadcrumbs/useBreadcrumbs';
import PieChartWithNeedle from '../../Charts/PieChartWithNeedle';
import './components.scss';

function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

const CompliancePosture = ({ data, isFetchingCompliancePosture, cloudProvider }) => {
  const history = useHistory();

  const handleHeaderButtonClick = useCallback(() => {
    const url = buildBreadcrumbUrl({
      path: '/compliance',
      breadcrumbs: [BreadcrumbParts.Dashboard, { title: 'Compliance Posture' }],
    });

    history.push(url);
  }, [history]);

  return (
    <Grid>
      <ContentBox
      infoMsg={"Aggregated Compliance score for the applicable compliances"}
        title="Compliance Posture"
        titleSize="medium"
        height="371px"
        titleContainerStyle={{
          // border: "0px",
          fontSize: '16px !important',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottom: '1px solid #CBD5E0',

          margin: '0px',
          color: 'var(--keyinsight-text-color)',
          // background: '#F7FAFC',
          // backgroundColor: 'var(--keyinsight-box-header-background)',
        }}
        headerEndElement={
          <div onClick={handleHeaderButtonClick}>
            <img src={externaIcon} style={{ width: '12px', height: '12px', cursor: 'pointer' }} />
          </div>
        }
        containerClassName={'border-box-no-pad'}
        paddingMode="content">
        {isFetchingCompliancePosture ? (
      
             <Box position={'relative'} height={'100%'}>
                   <Box display="flex" flexDirection="column" alignItems="center" paddingTop="10px" >
                  <Box  height="100%">
                  <Skeleton variant="circular" height="210px" width="210px" style={{ transform: 'none' }} sx={{ margin: '15px' }} />
                  <Skeleton  style={{ transform: 'none' }} sx={{ margin: '10x' }} />
                </Box>  
             
                  <Box display="flex" width="100%" justifyContent="space-between" >

                   <Box display="flex" paddingBottom="15px" >
                    <Skeleton height={20} width={50} style={{ transform: 'none' }} sx={{ margin: '15px' }} />
                    <Skeleton height={20} width={50} style={{ transform: 'none' }} sx={{ margin: '15px' }} />
                    <Skeleton height={20} width={50} style={{ transform: 'none' }} sx={{ margin: '15px' }} />

                  </Box>
               
                   <Box display="flex" paddingBottom="15px" >

                  <Skeleton height={20} width={50} style={{ transform: 'none' }} sx={{ margin: '15px' }} />
                  <Skeleton height={20} width={50} style={{ transform: 'none' }}  sx={{ margin: '15px' }}/>
                   </Box>
                   </Box>
                </Box>
          </Box>
        ) : cloudProvider === 'azure' ? (
          <NoData height={'100%'} />
        ) : (
          <Grid className="dashboard_component" px="15px">
            <Grid>
              <PieChartWithNeedle score={data?.average_compliance || 0} />
            </Grid>
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
              <p className="compliant_score">{kFormatter(data?.num_checks_performed) || 0}</p>
              <p className="compliant_check">Checks Performed</p>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid sx={{ display: 'flex', gap: '15px' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={compliantIcon} style={{ width: '20px', height: '20px', marginRight: '6px' }} />
                  <p className="m-0 severity_score">{data?.compliance_breakup?.compliant_resources || 0}</p>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={nonCompliantIcon} style={{ width: '20px', height: '20px', marginRight: '6px' }} />
                  <p className="m-0 severity_score">{data?.compliance_breakup?.non_compliant_resources || 0}</p>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', gap: '15px' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={critical} style={{ width: '20px', height: '20px', marginRight: '6px' }} />
                  <p className="m-0 severity_score">{data?.severity_breakup?.critical || 0}</p>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={high} style={{ width: '20px', height: '20px', marginRight: '6px' }} />
                  <p className="m-0 severity_score">{data?.severity_breakup?.high || 0}</p>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={medium} style={{ width: '20px', height: '20px', marginRight: '6px' }} />
                  <p className="m-0 severity_score">{data?.severity_breakup?.medium || 0}</p>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={low} style={{ width: '20px', height: '20px', marginRight: '6px' }} />
                  <p className="m-0 severity_score">{data?.severity_breakup?.low || 0}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ContentBox>
    </Grid>
  );
};
export default CompliancePosture;
