import FullscreenLoader from '@root/components/FulscreenLoader';
import { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import agent from '@root/agent';
import { useSelector } from 'react-redux';

const RevokeModal = ({ onPolicyRevoked, setRevokeModalOpen, ...props }) => {
  const [show, setShow] = useState(props.show);
  const [showLoader, setShowLoader] = useState(false);
  const userInfo = useSelector(state => {
    return state.user.info.user;
  });
  const handleClose = () => setShow(false);
  const payload = {
    created_user_email: userInfo.email,
    created_username: userInfo.name,
  };

  const handleRevokeClick = useCallback(async () => {
    setShowLoader(true);
    try {
      const response = await agent.OCR.revoke_policy(props.row.id, payload);

      if (response.error) {
        toast('Failed to revoke policy.', {
          position: 'top-right',
          type: 'error',
          newestOnTop: true,
        });
        throw new Error(response.msg);
      }

      toast('Policy revoked successfully.', {
        position: 'top-right',
        type: 'success',
        newestOnTop: true,
      });
      setRevokeModalOpen(prev => !prev);

      onPolicyRevoked && onPolicyRevoked(props.row.id);
      props.onClose();
      handleClose();
    } catch (err) {
      console.log(err);

      // toast('Failed to revoke policy.', {
      //   position: 'top-right',
      //   type: 'error',
      //   newestOnTop: true,
      // });
    }
    setShowLoader(false);
  }, [props.row, props.onClose, onPolicyRevoked]);

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={() => {
          props.onClose();
          handleClose();
        }}>
        <Modal.Header closeButton style={{ backgroundColor: '#EAEFF7' }}>
          <Modal.Title>Revoke Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Revoke Policy?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleRevokeClick}>
            Revoke
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              props.onClose();
              handleClose();
            }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {showLoader ? <FullscreenLoader /> : null}
    </div>
  );
};

export default RevokeModal;
