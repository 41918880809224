import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import './count-tooltip.scss';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.85rem',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: '#fafafa',
  },
}))(Tooltip);

function MaterialUlTooltip({ label, children }) {
  // if (!label || !label.length) return null;
  return (
    <>
      <HtmlTooltip title={<div className="CountTooltip__Content">{label}</div>} arrow>
        {children}
      </HtmlTooltip>
    </>
  );
}

export default MaterialUlTooltip;
