import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import './style.scss';

function CircularCloseButton({ onClick, ...props }) {
  return (
    <button className="DetailsPanel__CloseButton" onClick={onClick} {...props}>
      <CloseIcon style={{ width: '16px' }} />
    </button>
  );
}

export default CircularCloseButton;
