import React, { useMemo } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import './style.scss';

const LightTooltip = styled(({ className, ...props }: any) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fafafa',
      color: '#121212',
      boxShadow: theme.shadows[2],
      fontSize: 11,
      fontFamily: 'Manrope',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#efefef',
    },
  }),
);

type Props = {
  value: number;
  color?: string;
  max?: number;
  multi?: boolean;
  multiValues?: { tooltipText: string; color: string; value: number }[];
  borderless?: boolean;
  barHeight?: string | number;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
};

function LinearProgress({
  value,
  color = '#03bb34',
  multiValues = [],
  multi = false,
  borderless,
  max = 100,
  barHeight = 10,
  containerClassName,
  containerStyle,
}: Props) {
  const _borderRadius = useMemo(() => {
    return `${Number(String(barHeight).replace('px', '')) / 2}px`;
  }, [barHeight]);

  const _containerStyle = useMemo(() => {
    return {
      ...{ height: `${barHeight}px`, borderRadius: _borderRadius },
      ...(containerStyle || {}),
    };
  }, [_borderRadius, barHeight, containerStyle]);

  return (
    <div
      className={`Seclogic-LinearProgress ${containerClassName || ''} ${borderless ? 'Borderless' : ''}`}
      style={_containerStyle}>
      {multi ? (
        <>
          {multiValues.map((v, index) => {
            const tooltipId = `tooltip-${Date.now()}-${index}`;

            return (
              <LightTooltip title={v.tooltipText} arrow>
                <div
                  key={tooltipId}
                  className={`Seclogic-LinearProgress__Value`}
                  style={{
                    backgroundColor: v.color || '#03bb34',
                    width: `${(v.value / max) * 100}%`,
                    borderTopRightRadius: index === multiValues.length - 1 ? _borderRadius : 0,
                    borderBottomRightRadius: index === multiValues.length - 1 ? _borderRadius : 0,
                  }}
                />
              </LightTooltip>
            );
          })}
        </>
      ) : (
        <div
          className="Seclogic-LinearProgress__Value"
          style={{ backgroundColor: color, width: `${(value / max) * 100}%` }}
        />
      )}
    </div>
  );
}

export default LinearProgress;
