import React from 'react';
import _ from 'lodash';
import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import Text from '@root/components/v2/ui-elements/Text';
import moment from 'moment-timezone';

const DateFormatPipe = (parsedData, textOnly, seconds, formatType) => {
  let parsedISOString = moment(parsedData);
  const offset = moment.parseZone(new Date(parsedData)).utcOffset();
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);
  const utcOffset = `(UTC ${offset < 0 ? '-' : '+'}${hours}:${minutes})`;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let localParsedTime = parsedISOString.tz(timezone);

  if (!_.isEmpty(localParsedTime)) {
    const timestamp = new Date(localParsedTime.toISOString());
    // return `${moment(timestamp).format('YYYY-MM-DD, hh:mm A')} (UTC ${offset < 0 ? '-' : '+'}${hours}:${minutes})`;

    if (textOnly) {
      return parsedData ? moment(timestamp).format('YYYY-MM-DD, hh:mm:ss A') : '--';
    }
    if (seconds) {
      return (
        <Tooltip title={localParsedTime ? utcOffset : ''}>
          <span>
            <Text element="span">{parsedData ? moment(timestamp).format('YYYY-MM-DD, hh:mm:ss A') : '--'}</Text>
          </span>
        </Tooltip>
      );
    }
    if (formatType === 'date') {
      return (
        <Tooltip title={localParsedTime ? utcOffset : ''}>
          <span>
            <Text element="span">{parsedData ? moment(timestamp).format('YYYY-MM-DD') : '--'}</Text>
          </span>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={localParsedTime ? utcOffset : ''}>
        <span>
          <Text element="span">{parsedData ? moment(timestamp).format('YYYY-MM-DD, hh:mm:ss A') : '--'}</Text>
        </span>
      </Tooltip>
    );
  }
};

export default DateFormatPipe;
