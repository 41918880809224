import moment from 'moment';

import Tooltip from '@root/components/v2/ui-elements/Tooltip';
import { useMemo } from 'react';

function LastUpdate({ date }) {
  const utcOffset = useMemo(() => {
    if (!date) return 0;

    const offset = moment.parseZone(date).utcOffset();

    const hours = Math.abs(Math.floor(offset / 60));
    const minutes = Math.abs(offset % 60);

    return `(UTC ${offset < 0 ? '-' : '+'}${hours}:${minutes})`;
  }, [date]);

  if (date !== null && moment(date).isValid()) {
    return (
      <Tooltip title={utcOffset}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <div
            style={{
              height: '10px',
              width: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div
              style={{
                height: '6px',
                width: '6px',
                background: 'rgb(3, 187, 52)',
                borderRadius: '50%',
                boxSizing: 'border-box',
                display: 'inline-block',
              }}></div>
          </div>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.78874 0.267028C4.01999 0.566933 3.96434 0.99752 3.66443 1.22877L1.83586 2.63875C1.53596 2.87 1.10537 2.81435 0.874116 2.51444C0.642864 2.21454 0.698518 1.78395 0.998423 1.5527L2.82699 0.142721C3.1269 -0.0885312 3.55749 -0.032877 3.78874 0.267028ZM12.2113 0.267028C12.4425 -0.032877 12.8731 -0.0885312 13.173 0.142721L15.0016 1.5527C15.3015 1.78395 15.3571 2.21454 15.1259 2.51444C14.8946 2.81435 14.464 2.87 14.1641 2.63875L12.3356 1.22877C12.0357 0.99752 11.98 0.566933 12.2113 0.267028ZM8 1.9002C4.31513 1.9002 1.37143 4.77312 1.37143 8.26439C1.37143 11.7557 4.31513 14.6286 8 14.6286C11.6849 14.6286 14.6286 11.7557 14.6286 8.26439C14.6286 4.77312 11.6849 1.9002 8 1.9002ZM0 8.26439C0 3.96855 3.60573 0.528776 8 0.528776C12.3943 0.528776 16 3.96855 16 8.26439C16 12.5602 12.3943 16 8 16C3.60573 16 0 12.5602 0 8.26439ZM8 4.75872C8.37871 4.75872 8.68571 5.06572 8.68571 5.44443V6.81922C8.68572 7.35702 8.6865 7.71042 8.71385 7.98431C8.73968 8.24298 8.78504 8.37431 8.84338 8.47353C8.90171 8.57275 8.99441 8.67624 9.20788 8.82459C9.43392 8.98167 9.74234 9.1542 10.2123 9.41571L10.2338 9.42767C10.5647 9.61182 10.6837 10.0294 10.4995 10.3603C10.3154 10.6912 9.89785 10.8102 9.56693 10.6261L9.51715 10.5984C9.08313 10.3569 8.71414 10.1515 8.42525 9.95078C8.11754 9.73694 7.85506 9.49844 7.66114 9.16861C7.46722 8.83877 7.38645 8.49344 7.34921 8.12058C7.31426 7.77053 7.31427 7.34827 7.31429 6.85158L7.31429 5.44443C7.31429 5.06572 7.62129 4.75872 8 4.75872Z"
              fill="green"
            />
          </svg> */}
          <span style={{ fontFamily: 'Manrope', fontSize: '12px', fontWeight: '500', color: '#484D56' }}>
            Last Update: {moment(date).format('Y-MM-DD hh:mm:ss A')}
          </span>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={''}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.78874 0.267028C4.01999 0.566933 3.96434 0.99752 3.66443 1.22877L1.83586 2.63875C1.53596 2.87 1.10537 2.81435 0.874116 2.51444C0.642864 2.21454 0.698518 1.78395 0.998423 1.5527L2.82699 0.142721C3.1269 -0.0885312 3.55749 -0.032877 3.78874 0.267028ZM12.2113 0.267028C12.4425 -0.032877 12.8731 -0.0885312 13.173 0.142721L15.0016 1.5527C15.3015 1.78395 15.3571 2.21454 15.1259 2.51444C14.8946 2.81435 14.464 2.87 14.1641 2.63875L12.3356 1.22877C12.0357 0.99752 11.98 0.566933 12.2113 0.267028ZM8 1.9002C4.31513 1.9002 1.37143 4.77312 1.37143 8.26439C1.37143 11.7557 4.31513 14.6286 8 14.6286C11.6849 14.6286 14.6286 11.7557 14.6286 8.26439C14.6286 4.77312 11.6849 1.9002 8 1.9002ZM0 8.26439C0 3.96855 3.60573 0.528776 8 0.528776C12.3943 0.528776 16 3.96855 16 8.26439C16 12.5602 12.3943 16 8 16C3.60573 16 0 12.5602 0 8.26439ZM8 4.75872C8.37871 4.75872 8.68571 5.06572 8.68571 5.44443V6.81922C8.68572 7.35702 8.6865 7.71042 8.71385 7.98431C8.73968 8.24298 8.78504 8.37431 8.84338 8.47353C8.90171 8.57275 8.99441 8.67624 9.20788 8.82459C9.43392 8.98167 9.74234 9.1542 10.2123 9.41571L10.2338 9.42767C10.5647 9.61182 10.6837 10.0294 10.4995 10.3603C10.3154 10.6912 9.89785 10.8102 9.56693 10.6261L9.51715 10.5984C9.08313 10.3569 8.71414 10.1515 8.42525 9.95078C8.11754 9.73694 7.85506 9.49844 7.66114 9.16861C7.46722 8.83877 7.38645 8.49344 7.34921 8.12058C7.31426 7.77053 7.31427 7.34827 7.31429 6.85158L7.31429 5.44443C7.31429 5.06572 7.62129 4.75872 8 4.75872Z"
              fill="#484D56"
            />
          </svg>
          <span
            style={{ fontFamily: 'Manrope', fontSize: '12px', fontWeight: '500', color: '#484D56', marginLeft: '7px' }}>
            Last Update: --
          </span>
        </div>
      </Tooltip>
    );
  }
}

export default LastUpdate;
