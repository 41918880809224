import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import Text from '@root/components/v2/ui-elements/Text';

const DonutChart = props => {
  const { chartData, count_value, colors, valueInPercentage } = props;
  function kFormatter(num) {
    // const n = String(Math.sign(num) * (Math.abs(num) / 1000));
    // if (Math.abs(num) < 999) {
    //   return num;
    // } else if (!n.includes('.')) {
    //   return n + 'k';
    // } else {
    //   return n.slice(0, 3) + 'k';
    // }
    //  return Math.abs(num) > 999
    //    ? String(Math.sign(num) * (Math.abs(num) / 1000)).slice(0, -2) + 'k'
    //    : Math.sign(num) * Math.abs(num);

    if (Math.abs(num) < 1000) {
      return num;
    } else {
      // Round down to the nearest integer and divide by 1000
      const rounded = Math.floor(Math.abs(num));
      return Math.floor(rounded / 1000) + 'k';
    }
  }

  console.log(kFormatter(2000), 'kFormatter');
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      if (payload[0]?.name === 0) return <></>;
      return (
        <div
          style={{
            maxWidth: '200px',

            gap: '5px',
            background: '#FFFFFF',
            padding: '5px',
            borderRadius: '4px',
            border: '1px solid #FFFFFF',
            display: 'flex',
          }}>
          <Text
            element={'span'}
            style={{ display: payload[0]?.name === 0 ? 'none' : '' }}
            className="ChartTooltip__Label">
            {payload[0]?.name}
          </Text>
          <Text
            font="bold"
            element={'span'}
            style={{ display: payload[0]?.name === 0 ? 'none' : '' }}
            className="ChartTooltip__Value">
            {payload[0]?.value}
          </Text>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="dashboard_new" style={{ display: 'flex', justifyContent: 'center' }}>
      <PieChart height={190} width={190}>
        <Pie
          data={[{ value: 100 }]}
          dataKey="value"
          // activeShape={renderActiveShape}
          cx="50%"
          cy="50%"
          innerRadius={51.5}
          outerRadius={87.5}
          fill="#82ca9d"
          labelLine={false}
          isAnimationActive={false}
          stroke={{
            width: 0.1,
            color: '#808080',
          }}
          activeIndex={0}>
          <Cell fill={'#ECEFF2'} fillOpacity={1} />
        </Pie>
        <Pie
          dataKey="value"
          data={chartData}
          // activeShape={renderActiveShape}
          cx="50%"
          cy="50%"
          innerRadius={61.5}
          outerRadius={77.5}
          fill="#82ca9d"
          labelLine={false}
          isAnimationActive={false}
          stroke={{
            width: 0.1,
            color: '#808080',
          }}
          activeIndex={0}>
          {chartData.map(d => (
            <Cell key={`cell-${d.name}`} fill={colors[d.name] || '#f00'} fillOpacity={1} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fill: '#484D56', fontFamily: 'Manrope', fontSize: '22px', fontWeight: 800 }}>
          {count_value && valueInPercentage ? count_value : kFormatter(count_value)}
        </text>
      </PieChart>
    </div>
  );
};
export default DonutChart;
