import Text from '@root/components/v2/ui-elements/Text';
import './style.scss';
import { useHistory } from 'react-router';
import _ from 'lodash';

function PageHeader({
  title,
  children,
  breadcrumb = false,
  path,
  subtitle,
  subtitlePath = '',
  subsubtitle = false,
  subsubtitlepath,
  subsubsubtitle,
}) {
  const history = useHistory();

  return (
    <div className={`PageHeader`} style={{}}>
      {typeof title === 'string' ? (
        <>
          <Text
            element="h2"
            className={`PageHeader__Title`}
            style={{
              ...(breadcrumb && {
                display: 'flex',
              }),
            }}>
            <Text
              onClick={() => {
                if (path) {
                  history.push(window.location.pathname.split('/')[0] + `${path}`);
                }
                // history.go(0);
              }}
              element="span"
              font="bold"
              className="fs__5"
              color="#1A202C"
              style={{
                ...(breadcrumb && {
                  marginRight: '10px',
                }),
                cursor: 'pointer',
              }}>
              {title}
            </Text>
            {breadcrumb && (
              <svg
                width="9"
                height="13"
                viewBox="0 0 9 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: '17px' }}>
                <path
                  d="M7.79297 7.21484L2.48828 12.5469C2.21484 12.793 1.80469 12.793 1.55859 12.5469L0.929688 11.918C0.683594 11.6719 0.683594 11.2617 0.929688 10.9883L5.14062 6.75L0.929688 2.53906C0.683594 2.26562 0.683594 1.85547 0.929688 1.60938L1.55859 0.980469C1.80469 0.734375 2.21484 0.734375 2.48828 0.980469L7.79297 6.3125C8.03906 6.55859 8.03906 6.96875 7.79297 7.21484Z"
                  fill="#1A202C"
                />
              </svg>
            )}
            {subtitle && breadcrumb && !subtitlePath && (
              <Text
                element="span"
                font="medium"
                color="#A3A6AB"
                className="fs__2"
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  borderBottom: 'none',
                  alignItems: 'center',
                  // fontWeight: '500',
                }}>
                {subtitle}
              </Text>
            )}
            {subtitle && breadcrumb && subtitlePath && (
              <Text
                onClick={() => {
                  if (subtitlePath) {
                    if (_.isFunction(subtitlePath)) {
                      subtitlePath();
                    } else {
                      history.push(subtitlePath);
                    }
                  }
                  // history.go(0);
                }}
                element="span"
                font="bold"
                className="fs__5"
                color="#1A202C"
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  // borderBottom: 'none',
                  alignItems: 'center',
                  cursor: 'pointer',
                  // fontWeight: '500',
                }}>
                {subtitle}
              </Text>
            )}
            {breadcrumb && subsubtitle && (
              <svg
                width="9"
                height="13"
                viewBox="0 0 9 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: '17px' }}>
                <path
                  d="M7.79297 7.21484L2.48828 12.5469C2.21484 12.793 1.80469 12.793 1.55859 12.5469L0.929688 11.918C0.683594 11.6719 0.683594 11.2617 0.929688 10.9883L5.14062 6.75L0.929688 2.53906C0.683594 2.26562 0.683594 1.85547 0.929688 1.60938L1.55859 0.980469C1.80469 0.734375 2.21484 0.734375 2.48828 0.980469L7.79297 6.3125C8.03906 6.55859 8.03906 6.96875 7.79297 7.21484Z"
                  fill="#1A202C"
                />
              </svg>
            )}
            {subtitle && breadcrumb && subsubtitle && !subsubtitlepath && (
              <Text
                element="span"
                font="medium"
                color="#A3A6AB"
                className="fs__2"
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  borderBottom: 'none',
                  alignItems: 'center',
                  // fontWeight: '500',
                }}>
                {subsubtitle}
              </Text>
            )}
            {subtitle && breadcrumb && subsubtitle && subsubtitlepath && (
              <Text
                element="span"
                font="bold"
                className="fs__5"
                color="#1A202C"
                onClick={
                  () => history.push(subsubtitlepath)
                  // history.go(0);
                }
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  // borderBottom: 'none',
                  alignItems: 'center',
                  cursor: 'pointer',
                  // fontWeight: '500',
                }}>
                {subsubtitle}
              </Text>
            )}
            {breadcrumb && subsubsubtitle && (
              <svg
                width="9"
                height="13"
                viewBox="0 0 9 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: '17px' }}>
                <path
                  d="M7.79297 7.21484L2.48828 12.5469C2.21484 12.793 1.80469 12.793 1.55859 12.5469L0.929688 11.918C0.683594 11.6719 0.683594 11.2617 0.929688 10.9883L5.14062 6.75L0.929688 2.53906C0.683594 2.26562 0.683594 1.85547 0.929688 1.60938L1.55859 0.980469C1.80469 0.734375 2.21484 0.734375 2.48828 0.980469L7.79297 6.3125C8.03906 6.55859 8.03906 6.96875 7.79297 7.21484Z"
                  fill="#1A202C"
                />
              </svg>
            )}
            {subtitle && breadcrumb && subsubsubtitle && (
              <Text
                element="span"
                font="medium"
                color="#A3A6AB"
                className="fs__2"
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  borderBottom: 'none',
                  alignItems: 'center',
                  // fontWeight: '500',
                }}>
                {subsubsubtitle}
              </Text>
            )}
          </Text>
        </>
      ) : (
        title
      )}

      <div className={`PageHeader__ContentContainer`}>{children}</div>
    </div>
  );
}

export default PageHeader;
