import { useCallback, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import usePubSub from './hooks/usePubSub';
import { updateNotificationBanner } from '@components/notification/actions';
import { ADD_ONGOING_REMEDIATION, REMOVE_ONGOING_REMEDIATIONS } from './constants/actionTypes';
import agent from './agent';

import momentTz from 'moment-timezone';

const INTERVAL_SECONDS = 20;
const REMEDIATION_EXPIRY_SECONDS = 120;

function RemediationHandler() {
  const dispatch = useDispatch();

  const ongoingRemediations = useSelector(store => {
    return store.inProgress.remediations;
  });
  const ongoingRemediationsRef = useRef(ongoingRemediations);
  ongoingRemediationsRef.current = ongoingRemediations;

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        try {
          if (!ongoingRemediationsRef.current || !ongoingRemediationsRef.current.length) return;

          const response = await agent.OCR.fetch_remediations_by_ids(
            ongoingRemediationsRef.current.map(r => r.remediation_id),
          );

          if (response.error) throw new Error(response.msg);

          let removedRemediationIds = [];

          const remediations = (response.data || []).map(r => ({
            ...r,
            final_received_payload: JSON.parse(r.final_received_payload || 'null'),
          }));

          if (remediations && remediations.length) {
            for (const r of remediations) {
              if (
                !ongoingRemediationsRef.current.find(remediation => remediation.remediation_id === r.remediation_id)
              ) {
                continue;
              }

              const remediationAgeMillis = Date.now() - momentTz.tz(r.triggered_at, 'UTC').toDate().getTime();
              if (r.is_remediation_success) {
                // Remediation completed or failed due to exceeding REMEDIATION_EXPIRY_SECONDS seconds
                updateNotificationBanner(dispatch, {
                  status: 'success',
                  msg: `Remediation completed for rule ID ${r.rule_id}.`,
                  id: r.remediation_id,
                });

                removedRemediationIds.push(r.remediation_id);
              } else if (
                remediationAgeMillis > REMEDIATION_EXPIRY_SECONDS * 1000 ||
                r?.final_received_payload?.status === 'FAILED'
              ) {
                updateNotificationBanner(dispatch, {
                  status: 'error',
                  msg: `Remediation ${r.rule_id} failed. ${
                    r.final_received_payload ? r.final_received_payload.message : ''
                  }`,
                  id: r.remediation_id,
                });
                removedRemediationIds.push(r.remediation_id);
              }
            }
          }

          if (removedRemediationIds.length) {
            dispatch({
              type: REMOVE_ONGOING_REMEDIATIONS,
              payload: removedRemediationIds,
            });
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }, INTERVAL_SECONDS * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  usePubSub(
    'remediation_started',
    useCallback(data => {
      dispatch({ type: ADD_ONGOING_REMEDIATION, payload: data });

      updateNotificationBanner(dispatch, {
        status: 'inProgress',
        msg: `Remediation in progress`,
        id: data.remediation_id,
      });
    }, [dispatch]),
  );

  return null;
}

export default RemediationHandler;
