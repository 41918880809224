import MuiTable from '@components/table/table';
import { Box, Skeleton } from '@mui/material';
import agent from '@root/agent';
// import Loader from '@root/components/common/Loader';
import CloudProviderBar from '@root/components/v2/content/CloudProviderBar';
import ContentBox from '@root/components/v2/content/ContentBox';
import LastUpdate from '@root/components/v2/content/LastUpdate';
import PageContainer from '@root/components/v2/layout/PageContainer';
import PageContent from '@root/components/v2/layout/PageContent';
import PageHeader from '@root/components/v2/layout/PageHeader';
import { useEnvironments, useSelectedEnvironments } from '@root/hooks/environments';
import { useQuery as useQueryReact } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { columns } from './columns';

import { DEFAULT_PAGE_SIZE } from '@components/table/table';
import NoData from '@root/components/common/NoData';
import HeaderBarNew from '@root/components/HeaderBarNew';
// workload icons

import Filter from '@root/components/Filter';
import TableSkeleton from '@root/components/table/skeleton';
import { useCloudProvider } from '@root/components/v2/content/CloudProviderBar/CloudProviderContext';
import Breadcrumbs from '@root/components/v2/global/breadcrumbs';
import useDebouncedValue from '@root/hooks/useDebouncedValue';
import { useQuery as useQ } from '@root/hooks/useQuery';
import useSortData from '@root/hooks/useSortData';
import { ExportPipe } from './export';
import ThreatDialog from './Modal/ThreatDialog';
import SideOverlay from './SiderOverlay';

const BREADCRUMBS = [{ title: 'Vulnerabilities', path: '/vulnerabilities' }];

function Vulnerabilities() {
  const query = useQ();
  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);

  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  // const [cloudProvider, setCloudProvider] = useState('all');
  const { cloudProvider } = useCloudProvider();
  const { selectedEnvironments } = useSelectedEnvironments();
  const allEnvironments = useEnvironments();
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // mui counts pages from 0
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const selectedFilterProperties = useMemo(() => {
    return [];
  }, []);
  const [searchString, setSearchString] = useState('');

  const debouncedSearchString = useDebouncedValue(searchString, 750);
  const [threatModelData, setThreatModelData] = useState('');
  const [isFilterSelected, setIsFilterSelected] = useState();
  const from = query.get('from');
  const params = new URLSearchParams(window.location.search);
  const cve_idPayload = params.get('cve_ids') ? params.get('cve_ids')?.split(',') : [];
  const cve_ids = cve_idPayload;
  const [propertiesFilter, setPropertiesFilter] = useState({
    cve_id: cve_ids ? cve_ids : [],
    cve_risk: [],
    cvss_severity: [],

    fix: [],
    match: 'Any',
  });
  const { sortData, handleSortModelChange } = useSortData();

  const payloadFilter = useMemo(() => {
    const payload = {
      cve_id: cve_ids ? cve_ids : [],
      cve_risk: [],
      cvss: [],

      fix: [],
      match: 'Any',
    };

    for (const p of selectedFilterProperties) {
      if (!payload[p.id]) {
        payload[p.id] = [];
      }

      payload[p.id].push(p.value);
    }

    return payload;
  }, [cve_ids, selectedFilterProperties]);

  const environments = useMemo(() => {
    if (selectedEnvironments?.length) return selectedEnvironments;
    return allEnvironments;
  }, [selectedEnvironments, allEnvironments]);

  useEffect(
    () =>
      propertiesFilter?.cve_id.length > 0 ||
      propertiesFilter?.cve_risk.length ||
      propertiesFilter?.cvss_severity.length ||
      propertiesFilter?.fix.length
        ? setIsFilterSelected(true)
        : setIsFilterSelected(false),
    [propertiesFilter],
  );

  const { data: allData, isFetching: isFetchingAllData } = useQueryReact(
    [
      'get_vulnerability_data',
      propertiesFilter.cve_id,
      propertiesFilter.cve_risk,
      propertiesFilter.cvss_severity,
      propertiesFilter.fix,
      debouncedSearchString,
      environments.map(e => e.id),
      // selectedEnvironmentTags,
      currentPage,
      pageSize,
      cloudProvider,
      sortData,
    ],
    () =>
      agent.Vulnerabilites.get_vulnerability_data({
        // scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        search_keyword: debouncedSearchString ? debouncedSearchString : '',
        properties_filter: {
          cve_id: propertiesFilter.cve_id,
          cve_risk: propertiesFilter.cve_risk,
          cvss: propertiesFilter.cvss_severity,

          fix: propertiesFilter.fix,
          match: propertiesFilter.match,
        },
        sort: sortData,
        page_no: currentPage,
        row_per_page: pageSize,
      }),
  );

  console.log('allData', allData);

  const { data: resourceData, isFetching: isFetchingResourceData } = useQueryReact(
    ['get_resource_data', selectedUser?.cve_id, cloudProvider],
    () =>
      agent.Vulnerabilites.get_resource_data({
        // scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        cloud_provider: cloudProvider,
        search_keyword: '',
        properties_filter: {
          cve_id: [selectedUser?.cve_id],
          cve_risk: [],
          cvss: [],

          fix: [],
          match: 'Any',
        },
        page_no: 1,
        row_per_page: 25,
      }),
  );

  const { data: filterData, isFetching: isFetchingFilterData } = useQueryReact(['get_filter_data', cloudProvider], () =>
    agent.Vulnerabilites.get_filter_data({
      environments: environments.map(e => e.id),
      cloud_provider: cloudProvider,
    }),
  );

  function* generateValueMapping(values) {
    for (let i = 0; i < values?.length; i++) {
      yield {
        title: values[i],
        value: values[i],
      };
    }
  }

  useEffect(() => {
    if (isFetchingFilterData === false) {
      const keys = Object.keys(filterData?.data);
      let filterMapping = [];
      keys.forEach(key => {
        filterMapping.push({
          id: key,
          title: key.replaceAll('_', ' ').toUpperCase(),
          values: [...generateValueMapping(filterData?.data[key])],
        });
      });
    }
  }, [filterData, isFetchingFilterData]);

  const lastUpdated = useMemo(() => {
    const usersTopRowResponse = allData?.data?.data?.length > 0 ? allData?.data?.data[0].details['Last Updated'] : null;

    return usersTopRowResponse;
  }, [allData]);

  // const handleCloudProviderChange = useCallback(provider => {
  //   setCloudProvider(provider);
  // }, []);

  const handleRowClick = useCallback(r => {
    setSelectedUser(r.row);
    setShowDetailsPanel(true);
  }, []);
  const handleCloseDetailsPanel = useCallback(() => {
    setShowDetailsPanel(false);
    setSelectedUser(null);
  }, []);

  const handlePageChange = useCallback(
    page => {
      if (isFetchingResourceData) return;
      setCurrentPage(page);
    },
    [isFetchingResourceData],
  );

  const handlePageSizeChange = useCallback(_pageSize => {
    setCurrentPage(1);
    setPageSize(_pageSize);
  }, []);

  useEffect(() => {
    if (environments) {
      setCurrentPage(1);
    }
  }, [environments]);

  const { data: exportData, isFetching: isFetchingExportData } = useQueryReact(
    ['vulnerability-export', payloadFilter, filterType],
    () =>
      agent.Vulnerabilites.get_export_data({
        // scope: selectedEnvironmentTags,
        environments: environments.map(e => e.id),
        // assessment_time_filter: selectedValue,
        cloud_provider: '',
        search_keyword: debouncedSearchString ? debouncedSearchString : '',
        properties_filter:
          filterType === 'all'
            ? {
                cve_id: [],
                cve_risk: [],
                cvss: [],

                fix: [],
                match: 'Any',
              }
            : payloadFilter,
        page_no: 1,
        row_per_page: 1000,
      }),
  );
  const handleExportData = (place, type) => {
    {
      type === 'all' ? setFilterType('all') : setFilterType('filter');
    }
    console.log('DATATA', place, type);
    if (type === 'all' && !isFetchingExportData) {
      ExportPipe(exportData?.data);
    }
    if (type === 'filter' && !isFetchingExportData) {
      ExportPipe(exportData?.data);
    }
    // CloudEventsExportPipe(allData?.data.data);
  };
  const handleUpdatePropertiesFilter = values => {
    setPropertiesFilter(prev => {
      Object.keys(prev).map(key => {
        if (Object.keys(values).includes(key)) {
          prev[key] = values[key];
        } else {
          prev[key] = [];
        }
      });
      return { ...prev };
    });
  };

  const propertyKey = ['CVE ID', 'CVSS Severity', 'Fix', 'CVE Risk'];

  return (
    <PageContainer>
      {from === 'Resources' ? (
        <PageHeader
          title={<Breadcrumbs breadcrumbs={[{ title: 'Resources' }]} />}
          breadcrumb={true}
          path={'/inventory/key-insight'}
          subtitle={'Vulnerabilities'}></PageHeader>
      ) : (
        <PageHeader title={<Breadcrumbs breadcrumbs={BREADCRUMBS} />}></PageHeader>
      )}

      <PageContent>
        <CloudProviderBar
          // cloudProvider={cloudProvider}
          // onChange={handleCloudProviderChange}
          rightElement={
            isFetchingAllData ? (
              <Box>
                <Skeleton width="100px" />
              </Box>
            ) : (
              <LastUpdate date={lastUpdated} />
            )
          }
        />

        {cloudProvider === 'azure' ? (
          <NoData height="800px" />
        ) : (
          <ContentBox>
            {/* <HeaderBar
              searchBar
              searchBarValue={searchString}
              searchBarOnChange={handleSearchChange}
              title=""
              onFilterButtonClick={handleFilterButtonClick}
            /> */}
            <HeaderBarNew
              // marginTop={'5px'}
              marginBottom={'13px'}
              title={''}
              searchBar
              searchBarValue={searchString}
              setSearchTerm={setSearchString}
              handleExportData={handleExportData}
              exportButton={true}
              isFilterSelected={isFilterSelected}
              searchBarOnChange={e => setSearchString(e.target.value)}
              isFilterActive={filterOpen}
              onFilterButtonClick={() => {
                setFilterOpen(prev => !prev);
              }}
            />

            <Filter
              showCategoryFilter={false}
              propertyKeys={propertyKey}
              propertyValues={{ ...filterData?.data, cvss_severity: filterData?.data.cvss }}
              handleUpdatePropertiesFilter={handleUpdatePropertiesFilter}
              selectedFilterProperties={propertiesFilter}
              open={filterOpen}>
              {isFetchingAllData ? (
                <div className=" card" style={{ height: '85vh', width: '100%' }}>
                  <TableSkeleton />
                </div>
              ) : (
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex={1}
                    // visibility={isFetchingTableData ? 'hidden' : 'visible'}
                    sx={{ height: '85vh' }}>
                    <MuiTable
                      columns={columns(open, setOpen, setShowDetailsPanel, setThreatModelData)}
                      rows={
                        allData?.data?.data?.map((obj, index) => {
                          return { ...obj, id: index + 1 };
                        }) || []
                      }
                      onRowClick={handleRowClick}
                      paginationMode="server"
                      sortingMode="server"
                      total_size={allData?.data?.num_records}
                      handleSortModelChange={handleSortModelChange}
                      // LoaderState={isFetchingNextPage}
                      page={currentPage}
                      onPageChange={handlePageChange}
                      pageSize={pageSize}
                      onPageSizeChange={handlePageSizeChange}
                    />

                    {showDetailsPanel && !open && (
                      <SideOverlay
                        key={showDetailsPanel.toString()}
                        selectedRow={selectedUser}
                        resourceData={resourceData}
                        // onOptimizeClick={handleOptimizeClick}
                        onClose={handleCloseDetailsPanel}
                      />
                    )}
                  </Box>
                </div>
              )}
            </Filter>
          </ContentBox>
        )}
      </PageContent>

      {selectedUser && open ? (
        <ThreatDialog
          open={open}
          setOpen={setOpen}
          threat_data={threatModelData}
          selectedRow={selectedUser}
          setShowDetailsPanel={setShowDetailsPanel}
        />
      ) : null}
    </PageContainer>
  );
}

export default Vulnerabilities;
