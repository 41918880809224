import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
// import { axiosPost } from '@root/services/axios/axiosPost';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NotificationPanelSkleton from './Skleton';
import NotificationMap from './NotificationMapping';
import NotificationMonitor from '../../../../assets/svg/icons/notifications-monitor.svg';
import { fetchNotificationlistData } from './action';
import './style.scss';

const CustomSwitch = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(22px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#194BFB',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#194BFB',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 16,
      height: 16,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#CBD5E0',
      opacity: 1,
    },
    '& .MuiTypography-root': {
      color: '#484D56',
      fontFamily: 'Manrope',
      fontSize: '16px',
    },
  }),
);

function NotificationsPanel({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isTodayAllRead, setIsTodayAllRead] = useState(true);
  const [isYesterdayAllRead, setIsYesterdayAllRead] = useState(true);
  const [isPastAllRead, setIsPastAllRead] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const notificationList = useSelector(state => state.notification.notification_list.results);
  const { page, prevPage, hasMore } = useSelector(state => state.notification.notification_list);
  // const notificationCount = useSelector(state => state.notification.notification_list.unread_count);
  // const [todayNotificationsList, setTodayNotificationsList] = useState([])
  // const [yesterdayNotificationsList, setYesterdayNotificationsList] = useState([])
  // const [pastNotificationsList, setPastNotificationsList] = useState([])

  const todaysDate = moment().format('YYYYY-DD-MM');
  const yesterdayDate = moment().subtract(1, 'days').format('YYYYY-DD-MM');
  const [onlyUnread, setOnlyUnread] = useState(false);

  // useEffect(() => {
  // }, [onlyUnread]);

  useEffect(() => {
    const container = document.querySelector('.infinite-scroll-component');

    const handleScroll = () => {
      if (container) {
        setScrollPosition(container.scrollTop);
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleShowUnread = () => {
    dispatch({
      type: 'RESET_BELL_PAGE',
    });
    setOnlyUnread(prev => !prev);
    fetchNotificationsData(true, !onlyUnread);
  };

  const history = useHistory();
  const [todayNotificationsList, setTodayNotificationsList] = useState([]);
  const [yesterdayNotificationsList, setYesterdayNotificationsList] = useState([]);
  const [pastNotificationsList, setPastNotificationsList] = useState([]);

  useEffect(() => {
    if (_.isEmpty(notificationList)) {
      setTodayNotificationsList([]);
      setYesterdayNotificationsList([]);
      setPastNotificationsList([]);
    } else {
      notificationList.forEach(item => {
        // todays notification
        let date = moment(item.created_time).format('YYYYY-DD-MM');
        if (date === todaysDate) {
          const isExist = todayNotificationsList.find(e => e.id === item.id);
          if (!isExist) {
            setTodayNotificationsList(prev => [...prev, item]);
          }
        } else if (yesterdayDate === date) {
          //yesterday notification
          const isExist = yesterdayNotificationsList.find(e => e.id === item.id);
          if (!isExist) setYesterdayNotificationsList(prev => [...prev, item]);
        } else {
          //past notification
          const isExist = pastNotificationsList.find(e => e.id === item.id);
          if (!isExist) setPastNotificationsList(prev => [...prev, item]);
        }
        // setTodayNotificationsList([...todayNotificationsList]);
      });
    }
  }, [
    notificationList,
    todayNotificationsList,
    pastNotificationsList,
    yesterdayNotificationsList,
    todaysDate,
    yesterdayDate,
  ]);

  const fetchNotificationsData = async (firstLoad, onlyUnread) => {
    if (firstLoad) {
      setLoading(true);

      const res = await fetchNotificationlistData(dispatch, 1, onlyUnread);
      if (res) {
        setLoading(false);
      }
    } else if (prevPage !== page) {
      fetchNotificationlistData(dispatch, page, onlyUnread);
    }
  };

  // const handleOnNotificationClick = async (id, status) => {
  //   await axiosPost('update-bell-notification', { data: { id: [id] } });

  //   // if (todayNotificationsList.find(notif => notif.id === id)) {
  //   //   setTodayNotificationsList(prev => [...prev, todayNotificationsList.find(notif => notif.id === id)])
  //   // }

  //   onClose();

  //   if (!status) {
  //     dispatch({
  //       type: 'RESET_BELL_PAGE',
  //       payload: notificationCount - 1,
  //     });
  //   }
  //   history.push(`/notifications/logs?id=${id}`);
  // };

  // const handleReadAll = async () => {
  //   setLoading(true);
  //   await axiosPost('update-bell-notification', { data: { id: -999 } });

  //   dispatch({
  //     type: 'RESET_BELL_PAGE',
  //     payload: null,
  //   });

  //   const res2 = await fetchNotificationlistData(dispatch, 1, onlyUnread);
  //   if (res2) {
  //     setLoading(false);
  //   }
  // };

  // const sortNotificationDateWise = data => {
  //   const sortedData = data.sort((a, b) => moment.utc(b.timestamp).diff(moment.utc(a.timestamp)));
  //   return sortedData.map(item => {
  //     let stillUtc = moment.utc(item.timestamp).toDate();
  //     let local = moment(moment.utc(stillUtc).toDate()).local().format('hh:mm A | MMMM, Do YYYY');
  //     console.log(item)
  //     return (
  //       <div
  //         // key={item.id}
  //         style={{ textDecoration: 'none', cursor: 'pointer' }}
  //         className="NotificationPanel__Item__Wrapper"
  //         onClick={() => {
  //           handleOnNotificationClick(item.id, item.status);
  //         }}>
  //         <Box className="CustomScrollbar NotificationPanel__Item" key={item.id}>
  //           <div className="NotificationPanel__ItemStatusIndicator">
  //             {!item.status ? (
  //               <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
  //                 <circle cx="4.5" cy="4.5" r="4.5" fill="#194BFB" />
  //               </svg>
  //             ) : (
  //               <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
  //                 <circle cx="4.5" cy="4.5" r="4.5" fill="#AEB1C0" />
  //               </svg>
  //             )}
  //           </div>
  //           <div> {item.event} : {item.child_event}</div>
  //           <div style={{ flexGrow: '1' }} />
  //           <div className="NotificationPanel__DateTime">{local}</div>
  //           <div className="NotificationPanel__Name">{item.name}</div>
  //           {/* <Box display="flex" flexDirection="row">
  //             <Box flexGrow="1" marginBottom={'8px'} className="NotificationPanel__Item__Title">
  //               {item.name}
  //             </Box>
  //             <Box textAlign="center">
  //               <div>
  //                 <Badge color="primary" variant="dot" invisible={!item.status} />{' '}
  //               </div>
  //               {local}
  //             </Box>
  //           </Box>

  //           <Box
  //             className="NotificationPanel__Item__Environment"
  //             display="flex"
  //             flexDirection="row"
  //             alignItems={'center'}>
  //             <Box>
  //               <div>
  //                 {item.event} : {item.child_event}
  //               </div>
  //             </Box>
  //           </Box>
  //           <Divider /> */}
  //         </Box>
  //       </div>
  //     );
  //   });
  // };

  return (
    <>
      <div className={`NotificationsPanelBackdrop ${isOpen ? 'Visible' : ''}`} onClick={onClose}></div>
      <div className={`NotificationsPanelContainer ${isOpen ? 'Visible' : ''}`}>
        <Box className="NotificationsPanel__Header">
          <h3>Alerts</h3>
          <Box
            className="NotificationsPanel__HeaderSubtitle"
            sx={{ display: notificationList.length === 0 ? 'none' : '' }}>
            Show only unread
          </Box>{' '}
          <CustomSwitch
            sx={{ marginLeft: '9px', display: notificationList.length === 0 ? 'none' : '' }}
            onChange={handleShowUnread}
          />
        </Box>

        <Box
          height="200px"
          flexGrow={1}
          flexShrink={1}
          className="CustomScrollbar NotificationsPanel__NotificationList">
          {notificationList.length === 0  || loading? (
            <div style={{ height: '100%' }}>
              <NotificationPanelSkleton />
            </div>
          ) : notificationList.length === 0 ? (
            <>
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'Manrope',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}>
                {onlyUnread ? (
                  <b>There are no unread alerts</b>
                ) : (
                  <div className="NotificationPanel__PastAreaContent">
                    <img src={NotificationMonitor} alt="notification-monitor" />
                    <b style={{ marginTop: '8px' }} className="txt">
                      There are no alerts{' '}
                    </b>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <InfiniteScroll
                dataLength={notificationList.length}
                next={() => fetchNotificationsData(false, onlyUnread)}
                hasMore={hasMore}
                height={648}
                initialScrollY={0}
                loader={
                  <div style={{ height: '100%' }}>
                    <NotificationPanelSkleton />
                  </div>
                }
                endMessage={
                  <div className="NotificationPanel__PastAreaContent">
                    <img src={NotificationMonitor} alt="notification-monitor" />
                    <p className="txt">That’s all your alerts from the last 20 days. </p>
                  </div>
                }>
                <>
                  {todayNotificationsList.length > 0 ? (
                    <>
                      <div className={`NotificationsPanel__NewContainer ${scrollPosition >= 0 ? 'sticky' : ''}`}>
                        <div style={{ color: '#1A202C' }}>Today</div>
                        <div style={{ flexGrow: '1' }} />
                        {isTodayAllRead && (
                          <Box
                            // onClick={() => handleReadAll()}
                            onClick={() => setIsTodayAllRead(!isTodayAllRead)}
                            style={{
                              borderRight: '1px solid #000000',
                              paddingRight: '8px',
                              marginRight: '8px',
                              cursor: 'pointer',
                              lineHeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <svg
                              style={{ marginRight: 2 }}
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.75 9.75L4.25 12.25M7.75 8.25L10.25 5.75M5.75 9.75L8.25 12.25L14.25 5.75"
                                stroke="#194BFB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Mark all as read
                          </Box>
                        )}
                        <Box
                          onClick={() => {
                            history.push('/notifications/logs');
                            onClose();
                          }}
                          style={{ cursor: 'pointer' }}>
                          View all alerts
                        </Box>
                      </div>
                      <div className="NotificationPanel__MainItemWrapper">
                        {todayNotificationsList.map((notification, idx) => (
                          <NotificationMap
                            notification={notification}
                            key={idx}
                            onClick={() => history.push(`/notifications/logs?id=${notification.id}`)}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>

                <>
                  {yesterdayNotificationsList.length > 0 ? (
                    <>
                      <div className={`NotificationsPanel__NewContainer ${scrollPosition >= 100 ? 'sticky' : ''}`}>
                        <div style={{ color: '#1A202C' }}>Yesterday</div>
                        <div style={{ flexGrow: '1' }} />
                        {isYesterdayAllRead && (
                          <Box
                            // onClick={() => handleReadAll()}
                            onClick={() => setIsYesterdayAllRead(!isYesterdayAllRead)}
                            style={{
                              borderRight: '1px solid #000000',
                              paddingRight: '8px',
                              marginRight: '8px',
                              cursor: 'pointer',
                              lineHeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <svg
                              style={{ marginRight: 2 }}
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.75 9.75L4.25 12.25M7.75 8.25L10.25 5.75M5.75 9.75L8.25 12.25L14.25 5.75"
                                stroke="#194BFB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Mark all as read
                          </Box>
                        )}
                        <Box
                          onClick={() => {
                            history.push('/notifications/logs');
                            onClose();
                          }}
                          style={{ cursor: 'pointer' }}>
                          View all alerts
                        </Box>
                      </div>
                      <div className="NotificationPanel__MainItemWrapper">
                        {yesterdayNotificationsList.map((notification, idx) => (
                          <NotificationMap
                            notification={notification}
                            key={idx}
                            onClick={() => history.push(`/notifications/logs?id=${notification.id}`)}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>

                <>
                  {pastNotificationsList.length > 0 ? (
                    <>
                      <div className={`NotificationsPanel__NewContainer ${scrollPosition >= 200 ? 'sticky' : ''}`}>
                        <div style={{ color: '#1A202C' }}>Past</div>
                        <div style={{ flexGrow: '1' }} />
                        {isPastAllRead && (
                          <Box
                            // onClick={() => handleReadAll()}
                            onClick={() => setIsPastAllRead(!isPastAllRead)}
                            style={{
                              borderRight: '1px solid #000000',
                              paddingRight: '8px',
                              marginRight: '8px',
                              cursor: 'pointer',
                              lineHeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <svg
                              style={{ marginRight: 2 }}
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.75 9.75L4.25 12.25M7.75 8.25L10.25 5.75M5.75 9.75L8.25 12.25L14.25 5.75"
                                stroke="#194BFB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Mark all as read
                          </Box>
                        )}
                        <Box
                          onClick={() => {
                            history.push('/notifications/logs');
                            onClose();
                          }}
                          style={{ cursor: 'pointer' }}>
                          View all alerts
                        </Box>
                      </div>
                      <div className="NotificationPanel__MainItemWrapper">
                        {pastNotificationsList.map((notification, idx) => (
                          <NotificationMap
                            notification={notification}
                            key={idx}
                            onClick={() => history.push(`/notifications/logs?id=${notification.id}`)}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className={`NotificationsPanel__NewContainer ${scrollPosition >= 200 ? 'sticky' : ''}`}>
                        <div style={{ color: '#1A202C' }}>Past</div>
                        <div style={{ flexGrow: '1' }} />
                        {isPastAllRead && (
                          <Box
                            // onClick={() => handleReadAll()}
                            onClick={() => setIsPastAllRead(!isPastAllRead)}
                            style={{
                              borderRight: '1px solid #000000',
                              paddingRight: '8px',
                              marginRight: '8px',
                              cursor: 'pointer',
                              lineHeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <svg
                              style={{ marginRight: 2 }}
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.75 9.75L4.25 12.25M7.75 8.25L10.25 5.75M5.75 9.75L8.25 12.25L14.25 5.75"
                                stroke="#194BFB"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Mark all as read
                          </Box>
                        )}
                        <Box
                          onClick={() => {
                            history.push('/notifications/logs');
                            onClose();
                          }}
                          style={{ cursor: 'pointer' }}>
                          View all alerts
                        </Box>
                      </div>
                      <div className="NotificationPanel__MainItemWrapper">
                        <div
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          className="NotificationPanel__Item__Wrapper">
                          <Box className="CustomScrollbar NotificationPanel__Item">
                            <div className="NotificationPanel__ItemStatusIndicatorWrapper">
                              <div className={`NotificationPanel__ItemStatusIndicator Low__Status`}></div>
                            </div>
                            <div className="NotificationPanel__ItemInfoWrapper">
                              <div className="NotificationPanel__ItemEventTimeWrapper">
                                <div className="NotificationPanel__Event">Test</div>
                                <div className="NotificationPanel__DateTime">2 day ago</div>
                              </div>
                              <div className="NotificationPanel__Status">Ok</div>
                              <div className="NotificationPanel__Feature">
                                Affected Resources: <span>38</span>
                              </div>
                              <div className="NotificationPanel__EnvironmnetID">
                                Environment ID: <span>88923749</span>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          className="NotificationPanel__Item__Wrapper">
                          <Box className="CustomScrollbar NotificationPanel__Item">
                            <div className="NotificationPanel__ItemStatusIndicatorWrapper">
                              <div className={`NotificationPanel__ItemStatusIndicator Low__Status`}></div>
                            </div>
                            <div className="NotificationPanel__ItemInfoWrapper">
                              <div className="NotificationPanel__ItemEventTimeWrapper">
                                <div className="NotificationPanel__Event">Test</div>
                                <div className="NotificationPanel__DateTime">2 day ago</div>
                              </div>
                              <div className="NotificationPanel__Status">Ok</div>
                              <div className="NotificationPanel__Feature">
                                Affected Resources: <span>38</span>
                              </div>
                              <div className="NotificationPanel__EnvironmnetID">
                                Environment ID: <span>88923749</span>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          className="NotificationPanel__Item__Wrapper">
                          <Box className="CustomScrollbar NotificationPanel__Item">
                            <div className="NotificationPanel__ItemStatusIndicatorWrapper">
                              <div className={`NotificationPanel__ItemStatusIndicator Low__Status`}></div>
                            </div>
                            <div className="NotificationPanel__ItemInfoWrapper">
                              <div className="NotificationPanel__ItemEventTimeWrapper">
                                <div className="NotificationPanel__Event">Test</div>
                                <div className="NotificationPanel__DateTime">2 day ago</div>
                              </div>
                              <div className="NotificationPanel__Status">Ok</div>
                              <div className="NotificationPanel__Feature">
                                Affected Resources: <span>38</span>
                              </div>
                              <div className="NotificationPanel__EnvironmnetID">
                                Environment ID: <span>88923749</span>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          className="NotificationPanel__Item__Wrapper">
                          <Box className="CustomScrollbar NotificationPanel__Item">
                            <div className="NotificationPanel__ItemStatusIndicatorWrapper">
                              <div className={`NotificationPanel__ItemStatusIndicator Low__Status`}></div>
                            </div>
                            <div className="NotificationPanel__ItemInfoWrapper">
                              <div className="NotificationPanel__ItemEventTimeWrapper">
                                <div className="NotificationPanel__Event">Test</div>
                                <div className="NotificationPanel__DateTime">2 day ago</div>
                              </div>
                              <div className="NotificationPanel__Status">Ok</div>
                              <div className="NotificationPanel__Feature">
                                Affected Resources: <span>38</span>
                              </div>
                              <div className="NotificationPanel__EnvironmnetID">
                                Environment ID: <span>88923749</span>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          className="NotificationPanel__Item__Wrapper">
                          <Box className="CustomScrollbar NotificationPanel__Item">
                            <div className="NotificationPanel__ItemStatusIndicatorWrapper">
                              <div className={`NotificationPanel__ItemStatusIndicator Low__Status`}></div>
                            </div>
                            <div className="NotificationPanel__ItemInfoWrapper">
                              <div className="NotificationPanel__ItemEventTimeWrapper">
                                <div className="NotificationPanel__Event">Test</div>
                                <div className="NotificationPanel__DateTime">2 day ago</div>
                              </div>
                              <div className="NotificationPanel__Status">Ok</div>
                              <div className="NotificationPanel__Feature">
                                Affected Resources: <span>38</span>
                              </div>
                              <div className="NotificationPanel__EnvironmnetID">
                                Environment ID: <span>88923749</span>
                              </div>
                            </div>
                          </Box>
                        </div>
                        <div
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          className="NotificationPanel__Item__Wrapper">
                          <Box className="CustomScrollbar NotificationPanel__Item">
                            <div className="NotificationPanel__ItemStatusIndicatorWrapper">
                              <div className={`NotificationPanel__ItemStatusIndicator Low__Status`}></div>
                            </div>
                            <div className="NotificationPanel__ItemInfoWrapper">
                              <div className="NotificationPanel__ItemEventTimeWrapper">
                                <div className="NotificationPanel__Event">Test</div>
                                <div className="NotificationPanel__DateTime">2 day ago</div>
                              </div>
                              <div className="NotificationPanel__Status">Ok</div>
                              <div className="NotificationPanel__Feature">
                                Affected Resources: <span>38</span>
                              </div>
                              <div className="NotificationPanel__EnvironmnetID">
                                Environment ID: <span>88923749</span>
                              </div>
                            </div>
                          </Box>
                        </div>
                      </div> */}
                    </>
                  )}
                </>
              </InfiniteScroll>
              {/* )} */}
            </>
          )}
        </Box>

        {/* <Box className="NotificationsPanel__Footer">
          <Box className="NotificationsPanel__FooterLinkTitle">Show only unread</Box>
        </Box> */}
      </div>
    </>
  );
}

export default NotificationsPanel;
