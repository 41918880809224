import React from 'react';

export const Dashboard = React.lazy(() => import('@containers/dashboard'));
export const CloudAccounts = React.lazy(() => import('@containers/cloud-accounts'));
export const ScanHistory = React.lazy(() => import('@root/pages/history'));
export const NewScan = React.lazy(() => import('@root/pages/scanner'));
export const Compliance = React.lazy(() => import('@root/containers/compliance/index'));
export const ComplianceBreakdown = React.lazy(() => import('@root/containers/compliance/individual'));
export const Risk = React.lazy(() => import('@containers/risk'));

export const RiskTable = React.lazy(() => import('@root/containers/risk/table/RiskTable'));
export const Remediation = React.lazy(() => import('@containers/remediation'));
export const Exceptions = React.lazy(() => import('@containers/rules/exceptions'));
export const Onboarding = React.lazy(() => import('@containers/cloud-accounts/NoAccount'));
export const Environments = React.lazy(() => import('@root/containers/environment/index'));
export const ChangePassword = React.lazy(() => import('@containers/profile/changePassword'));
export const Notifications = React.lazy(() => import('@root/pages/notifications'));
export const RBACIndex = React.lazy(() => import('@containers/rbac'));
export const ConnectCloudAccount = React.lazy(() => import('@containers/cloud-accounts/NoAccount/Connect'));
export const Error = React.lazy(() => import('@components/error/404Screen'));
export const RulesNew = React.lazy(() => import('@root/pages/rules_new'));
export const RulesTable = React.lazy(() => import('@root/pages/rules_new/rulesTable'));
export const Profile = React.lazy(() => import('@root/pages/profile'));

export const ScanReport2 = React.lazy(() => import('@pages/new-scan-report'));
export const IdentityNew = React.lazy(() => import('@pages/identity'));

export const EventsOverview = React.lazy(() => import('@root/pages/events'));
export const EventsTable = React.lazy(() => import('@root/pages/events/Table'));
export const SuppressedEvents = React.lazy(() => import('@root/pages/events/SuppressedEvents'));
export const InventoryNew = React.lazy(() => import('@root/pages/inventory1.3'));
export const KeyInsightsNew = React.lazy(() => import('@root/pages/inventory-overviewNew/key-insights/index'));
export const InventoryOverview2 = React.lazy(() => import('@root/pages/inventory-overview'));
export const ResourceTable = React.lazy(() => import('@root/pages/inventory-overview/resource-evaluation'));
export const CompliancePack = React.lazy(() => import('@root/components/compliancePack'));
export const MalwareOverview = React.lazy(() => import('@root/pages/Malware/Overview/index'));
export const MalwareOverviewNew = React.lazy(() => import('@root/pages/Malware/Overview/index-new'));
export const MalwareOverviewStatic = React.lazy(() => import('@root/pages/Malware/Overview/malware-static'));
export const Findings = React.lazy(() => import('@root/pages/Malware/Findings/index'));
export const KubernetesOverview = React.lazy(()=> import('@root/pages/kubernetes/index'))
export const BucketProtections = React.lazy(() => import('@root/pages/Malware/BucketProtection/index'));
export const WorkloadSkeleton = React.lazy(() => import('@root/pages/workloload-scanning/WorkloadSkeleton'));
export const PolicySkeleton = React.lazy(() => import('./../containers/remediation-policy/PolicySkeleton'));
export const ReportSkeleton = React.lazy(() => import('./../containers/remediation-report/ReportSkeleton'));
export const EventOverviewSkleton = React.lazy(() => import('./../pages/events/skeleton'));
export const EventTableSkleton = React.lazy(() => import('./../pages/events/Table/skeleton'));
export const RiskTableSkeleton = React.lazy(() => import('@root/containers/risk/table/skeleton'));
export const RiskSkeleton = React.lazy(() => import('./../containers/risk/skeleton'));
export const MalwareSkeleton = React.lazy(() => import('./../pages/Malware/Overview/skeleton-new'));
export const BucketTableSkleton = React.lazy(() => import('./../pages/Malware/BucketProtection/skeleton'));
export const FindingTableSkleton = React.lazy(() => import('./../pages/Malware/Findings/skeleton'));
export const ResourceTableSkleton = React.lazy(() =>
  import('./../pages/inventory-overview/resource-evaluation/skeleton'),
);
export const ResourceSkeleton = React.lazy(() => import('./../pages/inventory-overview/resources/skeleton'));
export const KeyInsightSkeleton = React.lazy(() => import('../pages/inventory-overviewNew/key-insights/skeleton'));
export const InventoryOverviewSkeleton = React.lazy(() => import('./../pages/inventory1.3/skeleton'));
export const DashboardSkeleton = React.lazy(() => import('./../containers/dashboard/skeleton'));
export const Explorer = React.lazy(() => import('@pages/explorer'));
export const Visualization = React.lazy(() => import('@pages/visualization'));
