import { useEffect, useState } from 'react';
import Progressbar from 'react-js-progressbar';

const GaugeChart = ({ score = 0, count = 0 }) => {
  const [percentage, setPercentage] = useState(0);
  const [barColor, setBarColor] = useState('#FFA825');

  useEffect(() => {
    setPercentage(score);

    /*

      High = Green | Score between 90-100  #03CB38
      Medium = Orange |  Score between 50-89 #FFA825
      Low = Light Red | Score between 31-49 #EB6C6C
      Critical = Dark Red | Score 0 - 30 #CD0404

    */

    if (score >= 90) setBarColor('#03CB38');
    else if (score >= 50 && score < 90) setBarColor('#FFA825');
    else if (score >= 31 && score < 50) setBarColor('#EB6C6C');
    else if (score >= 0 && score < 31) setBarColor('#CD0404');
  }, [score]);

  return (
    <>
      <div id="progressbarContainer" style={{ marginTop: '15px', height: '165px' }}>
        <Progressbar
          input={percentage}
          shape={'semi circle'}
          pathLinecap={'round'}
          pathShadow={'none'}
          size={'195px'}
          pathWidth={30}
          clockwise={false}
          pathColor={barColor} // use an array for gradient color.
          trailWidth={26}
          trailColor="#EDF2F7" // use a string for solid color.
          textStyle={{ fill: '#484D56', fontSize: '28px', fontFamily: 'Manrope', fontWeight: 700 }} // middle text style
        >
          <span
            style={{
              position: 'absolute',
              left: '35%',
              top: '79%',
              fontSize: '14px',
              fontFamily: 'Manrope',
              fontWeight: 600,
              color: '#484D56',
            }}>
            {' '}
            {` Total : ${count}`}
          </span>
        </Progressbar>
      </div>
    </>
  );
};
export default GaugeChart;
