import MuiTable from "@components/table/table";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from "@mui/material";
import agent from "@root/agent";
import { useEffect, useMemo, useRef, useState } from "react";
import { makeColumns } from "./report/columns";
import "./style.scss";

import HeaderBarNew from "@components/HeaderBarNew";
import { Dialog } from "@mui/material";
import NoData from "@root/components/common/NoData";
import CloudProviderBar from "@root/components/v2/content/CloudProviderBar";
import ContentBox from "@root/components/v2/content/ContentBox";
import LastUpdate from "@root/components/v2/content/LastUpdate";
import PageContainer from "@root/components/v2/layout/PageContainer";
import PageContent from "@root/components/v2/layout/PageContent";
import PageHeader from "@root/components/v2/layout/PageHeader";
import Column from "@root/components/v2/layout/columns/Column";
import ColumnsContainer from "@root/components/v2/layout/columns/ColumnsContainer";
import { useQuery as useQueryReact } from "@tanstack/react-query";
import { ExpandRow } from "./report/expand";

import Filter from "@root/components/Filter";
import Breadcrumbs from "@root/components/v2/global/breadcrumbs";
import TableSkeleton from "@root/components/table/skeleton";
import useTimeFilter from "@root/hooks/SelectTimeFilter";
import {
  useEnvironments,
  useSelectedEnvironments,
} from "@root/hooks/environments";
import OptimizeModal from "./OptimizeModal";
import ReportSkeleton from "./ReportSkeleton";
import { ExportPipe } from "./export";
import { useCloudProvider } from "@root/components/v2/content/CloudProviderBar/CloudProviderContext";

const BREADCRUMBS = [{ title: "Reports" }];

const RemediationReport = () => {
  // const cloudProvider = useSelector(state => state.oneClick.cloud);
  // const [cloudProvider, setCloudProvider] = useState("aws");
  const { cloudProvider } = useCloudProvider();
  const pageRef = useRef(1);
  const setPageRef = (number) => (pageRef.current = number);
  const [pageSize, setPageSize] = useState(10);
  const [lastUpdated, setlastUpdated] = useState(null);

  const [expandedRowData, setExpandedRowData] = useState([]);

  // const [resourceDataMap, setResourceDataMap] = useState({}); // { remediation_id: resourceData, ... }

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const [openRemidiationDialog, setOpenRemidiationDialog] = useState(false);
  const [openExpand, setOpenExpand] = useState(false);
  const { timeFilter } = useTimeFilter();

  const { selectedEnvironments, selectedEnvironmentTags } =
    useSelectedEnvironments();
  const [selectedFilterServices, setSelectedFilterServices] = useState([]);
  const [selectedFilterServiceCategory, setSelectedFilterServiceCategory] =
    useState([]);
  const [selectedFilterServiceTypes, setSelectedFilterServiceTypes] = useState(
    []
  );
  const allEnvironments = useEnvironments();
  const [resourceId, setResourceId] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [region, setRegion] = useState("");
  const [remediationId, setRemediationId] = useState("");
  const [showOptimizeModal, setShowOptimizeModal] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const environments = useMemo(() => {
    if (selectedEnvironments.length) return selectedEnvironments;
    return allEnvironments;
  }, [selectedEnvironments, allEnvironments]);
  const [propertiesFilter, setPropertiesFilter] = useState({
    rule_id: [],
    status: [],
    severity: [],
    trigger: [],

    match: "Any",
  });

  useEffect(() => {
    propertiesFilter.trigger.forEach((e) =>
      e === "AUTOMATED" ? "Auto Remediation" : e
    );
    console.log(propertiesFilter, "propertiesFilter");
  }, [propertiesFilter]);
  // const {
  //   data: eventFilterPropertyKeys,
  //   isLoading: isLoadingEventFilterPropertyKeys,
  // } = useQueryReact(
  //   [
  //     "get_event_filter_property_keys",
  //     environments.map((e) => e.id),
  //     selectedEnvironmentTags,
  //     cloudProvider,
  //   ],
  //   () =>
  //     agent.CloudEvents.get_event_filter_property_keys({
  //       scope: selectedEnvironmentTags,
  //       environments: environments.map((e) => e.id),
  //       cloud_provider: cloudProvider,
  //       event_type: "Remediation",
  //       is_remediation: true,
  //       property_keys: [],
  //       all_resources: {
  //         service_category: [],
  //         service: [],
  //         resource_type: [],
  //       },
  //     })
  // );
  console.log('please check');
  const {
    data: eventFilterPropertyValues,
    isLoading: isLoadingEventFilterPropertyValues,
  } = useQueryReact(
    [
      "get_reports_property_values",
      environments.map((e) => e.id),
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
    ],
    () =>
      agent.Reports.get_reports_property_values({
        rule_id: [],
        status: [],
        trigger: [],
        severity: [],
      })
  );

  const {
    data: eventFilterServiceCategory,
    isLoading: isLoadingEventFilterServiceCategory,
  } = useQueryReact(
    [
      "get_event_filter_service_category",
      environments.map((e) => e.id),
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
    ],
    () =>
      agent.CloudEvents.get_event_filter_service_category({
        scope: selectedEnvironmentTags,
        environments: environments.map((e) => e.id),
        cloud_provider: cloudProvider,
        event_type: "Remediation",
      })
  );
  const { data: configData, isLoading: isLoadingConfigData } = useQueryReact(
    [
      "remediation_config",
      environments.map((e) => e.id),
      resourceId,
      resourceType,
      remediationId,
      region,
      selectedEnvironmentTags,
      cloudProvider,
      timeFilter,
    ],
    () =>
      agent.Reports.remediation_config({
        scope: selectedEnvironmentTags,
        environments: environments.map((e) => e.id),
        resource_id: resourceId,
        remediation_id: remediationId,
        regions: region,
        resource_type: resourceType,
      })
  );
  const {
    data: tableData,
    isFetching: isFetchingTableData,
    isLoading: isLoadingTableData,
  } = useQueryReact(
    [
      "get_table_data",
      environments.map((e) => e.id),
      selectedEnvironmentTags,
      cloudProvider,
      propertiesFilter?.rule_id,
      propertiesFilter?.status,
      propertiesFilter?.severity,
      propertiesFilter?.trigger,
      propertiesFilter?.match,
      timeFilter,
      searchTerm,
      page,
      pageSize,
      selectedFilterServices,
      selectedFilterServiceTypes,
      selectedFilterServiceCategory,
    ],
    () =>
      agent.Reports.get_table_data({
        scope: selectedEnvironmentTags,
        environments: environments.map((e) => e.id),
        cloud_provider: cloudProvider,
        event_type: "Remediation",
        search_keyword: searchTerm ? searchTerm : "",
        // date_filter:
        //   valueFrom || valueTo
        //     ? [{ from: dayjs(valueFrom).format('YYYY-MM-DD'), to: dayjs(valueTo).format('YYYY-MM-DD') }]
        //     : [],
        all_resource: {
          service_category: selectedFilterServiceCategory,
          service: selectedFilterServices,
          resource_type: selectedFilterServiceTypes,
        },
        date_filter: timeFilter
          ? timeFilter === "24h"
            ? "24 hours"
            : timeFilter === "All"
            ? "all"
            : timeFilter.replace("d", " days")
          : "",
        properties_filter: propertiesFilter,
        unique_id: "121",
        page_no: page,
        row_per_page: pageSize,
      })
  );

  const updateFilterAllResources = (
    service_category,
    service,
    resource_type
  ) => {
    setSelectedFilterServiceCategory([...service_category]);
    setSelectedFilterServices([...service]);
    setSelectedFilterServiceTypes([...resource_type]);

    setPage(1);
  };
  const handleUpdatePropertiesFilter = (values) => {
    setPropertiesFilter((prev) => {
      Object.keys(prev).map((key) => {
        if (Object.keys(values).includes(key)) {
          prev[key] = values[key];
        } else {
          prev[key] = [];
        }
      });
      return { ...prev };
    });
  };

  const handleTableChange = (p) => {
    if (p < page) {
      setPageRef(page - 1);
      setPage(page - 1);
    } else {
      setPageRef(page + 1);
      setPage(page + 1);
    }
  };
  const handlePageChange = (page) => {
    if (isFetchingTableData) return;
    setPage(page);
  };

  useEffect(() => {
    // if (!lastUpdated) {
    setlastUpdated(tableData?.data?.[0]?.triggered_at || null);
    // }
  }, [tableData, timeFilter]);

  useEffect(() => {
    selectedFilterServiceCategory.length > 0 ||
    selectedFilterServices.length > 0 ||
    selectedFilterServiceTypes.length > 0 ||
    propertiesFilter.rule_id.length > 0 ||
    propertiesFilter.status.length > 0 ||
    propertiesFilter.severity.length > 0 ||
    propertiesFilter.trigger.length > 0
      ? setIsFilterSelected(true)
      : setIsFilterSelected(false);
  }, [
    selectedFilterServiceCategory,
    selectedFilterServices,
    selectedFilterServiceTypes,
    propertiesFilter.rule_id,
    propertiesFilter.status,
    propertiesFilter.severity,
    propertiesFilter.trigger,
  ]);

  const handleExportData = async (data, type) => {
    if (type === "all") {
      const exportTableData = await agent.Reports.get_table_data({
        scope: selectedEnvironmentTags,
        environments: environments.map((e) => e.id),
        cloud_provider: cloudProvider,
        event_type: "Remediation",
        search_keyword: searchTerm ? searchTerm : "",
        // date_filter:
        //   valueFrom || valueTo
        //     ? [{ from: dayjs(valueFrom).format('YYYY-MM-DD'), to: dayjs(valueTo).format('YYYY-MM-DD') }]
        //     : [],
        all_resource: {
          service_category: [],
          service: [],
          resource_type: [],
        },
        date_filter: timeFilter
          ? timeFilter === "24h"
            ? "24 hours"
            : timeFilter === "All"
            ? "all"
            : timeFilter.replace("d", " days")
          : "",
        properties_filter: {
          rule_id: [],
          status: [],
          severity: [],
          trigger: [],

          match: "Any",
        },
        unique_id: "121",
        page_no: 1,
        row_per_page: 1000,
      });

      ExportPipe(exportTableData?.data);
    } else {
      const exportTableData = await agent.Reports.get_table_data({
        scope: selectedEnvironmentTags,
        environments: environments.map((e) => e.id),
        cloud_provider: cloudProvider,
        event_type: "Remediation",
        search_keyword: searchTerm ? searchTerm : "",
        // date_filter:
        //   valueFrom || valueTo
        //     ? [{ from: dayjs(valueFrom).format('YYYY-MM-DD'), to: dayjs(valueTo).format('YYYY-MM-DD') }]
        //     : [],
        all_resource: {
          service_category: selectedFilterServiceCategory,
          service: selectedFilterServices,
          resource_type: selectedFilterServiceTypes,
        },
        date_filter: timeFilter
          ? timeFilter === "24h"
            ? "24 hours"
            : timeFilter === "All"
            ? "all"
            : timeFilter.replace("d", " days")
          : "",
        properties_filter: propertiesFilter,
        unique_id: "121",
        page_no: 1,
        row_per_page: 1000,
      });
      ExportPipe(exportTableData?.data);
    }
  };
  const _peopertyKeys = ["Rule ID", "Status", "Severity", "Trigger"];
  if (
    isLoadingTableData &&
    isLoadingConfigData &&
    isLoadingEventFilterServiceCategory &&
    isLoadingEventFilterPropertyValues
  ) {
    return <ReportSkeleton />;
  }
  return (
    <PageContainer>
      <PageHeader title={<Breadcrumbs breadcrumbs={BREADCRUMBS} />} />

      <PageContent>
        <CloudProviderBar
          // cloudProvider={cloudProvider}
          // onChange={(e) => setCloudProvider(e)}
          rightElement={
            <LastUpdate
              date={
                cloudProvider !== "azure" && lastUpdated
                  ? new Date(lastUpdated)
                  : null
              }
            />
          }
        />

        <ContentBox>
          {!(cloudProvider !== "azure") ? (
            <div
              style={{ height: "80vh", display: "grid", placeItems: "center" }}
            >
              <NoData />
            </div>
          ) : (
            <>
              <HeaderBarNew
                title={""}
                searchBar
                searchBarValue={searchTerm}
                setSearchTerm={setSearchTerm}
                exportButton={true}
                handleExportData={handleExportData}
                onSearchBarSubmit={(e) => e.preventDefault()}
                searchBarOnChange={(e) => setSearchTerm(e.target.value)}
                isFilterActive={drawerOpen}
                isFilterSelected={isFilterSelected}
                onFilterButtonClick={() => {
                  setDrawerOpen((prev) => !prev);
                }}
              ></HeaderBarNew>
              <ColumnsContainer columnSeparation={"5px"}>
                <Column>
                  <Filter
                    data={eventFilterServiceCategory?.data}
                    propertyKeys={_peopertyKeys}
                    propertyValues={eventFilterPropertyValues?.data}
                    updateFilterAllResources={updateFilterAllResources}
                    handleUpdatePropertiesFilter={handleUpdatePropertiesFilter}
                    selectedFilterProperties={propertiesFilter}
                    open={drawerOpen}
                  >
                    <div style={{ height: "83vh" }}>
                      {!isFetchingTableData ? (
                        <MuiTable
                          components={{
                            DetailPanelExpandIcon: () => (
                              <div>
                                <ChevronRight />
                              </div>
                            ),
                            DetailPanelCollapseIcon: ExpandMore,
                            NoRowsOverlay: () => <NoData height="500px" />,
                          }}
                          // getRowClassName={params => {
                          //   return `bl-${
                          //     rulesMap[params.row.rule_id] ? rulesMap[params.row.rule_id].risk.toLowerCase() : ''
                          //   }`;
                          // }}
                          rowThreshold={0}
                          getDetailPanelHeight={() => {
                            return "auto";
                          }}
                          rows={tableData?.data}
                          header
                          pagination={true}
                          rowsPerPageOptions={[5, 10, 20, 50, 100]}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          pageSize={pageSize}
                          page={page}
                          columns={makeColumns(
                            setExpandedRowData,
                            setOpenExpand,
                            setShowOptimizeModal,
                            setRegion,
                            setResourceId,
                            setRemediationId,
                            setResourceType
                          )}
                          // pinnedColumns={_pinnedColumns}
                          // onPinnedColumnsChange={setPinnedColumns}
                          onTableChange={handleTableChange}
                          rowHeight={80}
                          paginationMode="server"
                          total_size={tableData?.num_records}
                          onPageChange={handlePageChange}
                        />
                      ) : (
                        <Box
                          position={"relative"}
                          height={"100%"}
                          width={"100%"}
                        >
                          <TableSkeleton />
                        </Box>
                      )}
                    </div>
                  </Filter>
                </Column>
              </ColumnsContainer>

              <div id="remediationReport">
                <div
                  className={`FilterWrapper ${drawerOpen ? "FilterOpen" : ""}`}
                >
                  <Dialog
                    open={openExpand}
                    onClose={() => {
                      setOpenExpand(false);
                    }}
                    maxWidth="lg"
                  >
                    {ExpandRow(
                      expandedRowData,
                      setExpandedRowData,
                      environments,
                      setOpenExpand,
                      openRemidiationDialog,
                      setOpenRemidiationDialog
                    )}
                  </Dialog>
                </div>
              </div>
            </>
          )}
        </ContentBox>
      </PageContent>
      <OptimizeModal
        type="user"
        open={showOptimizeModal}
        onClose={() => setShowOptimizeModal(false)}
        configData={configData}
      />
    </PageContainer>
  );
};

export default RemediationReport;
