import { faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOCRPermissions } from '@root/hooks/permissions';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

import cx from 'classnames';
import './style.scss';

const RemediationPolicyMenuButton = ({ row, onPolicyRevoked, setRevokeModalOpen, ...props }) => {
  const history = useHistory();
  const RevokeModal = props.RevokeModal;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggle = e => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.target);
  };
  const [isRevokeModalStatus, setRevokeModalStatus] = useState(false);

  const { isEditAllowed, isRevokeAllowed } = useOCRPermissions();

  const noPermissionError = () => {
    toast.error("You don't have permission to perform this action");
  };

  return (
    <div id="remediationOrExceptionButtons" className="w-25">
      {RevokeModal && isRevokeModalStatus && (
        <RevokeModal
          show={isRevokeModalStatus}
          row={row}
          onClose={() => setRevokeModalStatus(false)}
          onPolicyRevoked={onPolicyRevoked}
          setRevokeModalOpen={setRevokeModalOpen}
        />
      )}

      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            isEditAllowed ? history.push(`/remediation-policy/edit/${row.policy_id}`) : noPermissionError()
          }>
          <FontAwesomeIcon icon={faEdit} className="ico" /> &nbsp; Edit Policy
        </MenuItem>

        <MenuItem
          onClick={() => {
            isRevokeAllowed ? setRevokeModalStatus(true) : noPermissionError();
            setAnchorEl(null);
          }}>
          <FontAwesomeIcon icon={faTrash} className="ico" /> &nbsp; Revoke Policy
        </MenuItem>
      </Menu>

      <div className={cx('option', anchorEl ? 'active' : '')} onClick={toggle}>
        <FontAwesomeIcon icon={faEllipsisV} className="ico" />
      </div>
    </div>
  );
};

export default RemediationPolicyMenuButton;
