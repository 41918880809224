import MuitoolTip from '@root/components/Tooltip/materialUiTooltip';

// import networkIcon from '@assets/svg/risk/Network.svg';
// import dataIcon from '@assets/svg/risk/Data.svg';
// import identityIcon from '@assets/svg/risk/Identity.svg';
// import lateralMovementIcon from '@assets/svg/risk/LateralMovement.svg';
// import encryptionAtRestIcon from '@assets/svg/risk/EncryptionAtRest.svg';
// import encryptionInEncryptionIcon from '@assets/svg/risk/EncryptionInEncryption.svg';
// import highAvailabilityIcon from '@assets/svg/risk/HighAvailability.svg';
// import loggingAndMonitoringIcon from '@assets/svg/risk/LoggingAndMonitoring.svg';

import anomalousLogo from '@root/assets/svg/Cloud_Events/Anomalous.svg';
import dataLogo from '@root/assets/svg/Cloud_Events/Data.svg';
import encryptionLogo from '@root/assets/svg/Cloud_Events/Encryption.svg';
import exposedSecretsLogo from '@root/assets/svg/Cloud_Events/ExposedSecrets.svg';
import highAvailabilityLogo from '@root/assets/svg/Cloud_Events/HighAvailability.svg';
import identityLogo from '@root/assets/svg/Cloud_Events/Identity.svg';
import lateralMovementLogo from '@root/assets/svg/Cloud_Events/LateralMovement.svg';
import loggingMonitoringLogo from '@root/assets/svg/Cloud_Events/LoggingMonitoring.svg';
import networkLogo from '@root/assets/svg/Cloud_Events/Network.svg';
import noRiskLogo from '@root/assets/svg/Cloud_Events/No_Risk.svg';
import threatLogo from '@root/assets/svg/Cloud_Events/Threat.svg';
import userLogo from '@root/assets/svg/Cloud_Events/User.svg';
import vulnerabilitiesLogo from '@root/assets/svg/Cloud_Events/Vulnerabilities.svg';
import administratorLogo from '@root/assets/svg/Cloud_Events/administrator.svg';
import credentialExposureLogo from '@root/assets/svg/Cloud_Events/credentialExposure.svg';
import inactiveLogo from '@root/assets/svg/Cloud_Events/inactive.svg';
import infrastructureModificationLogo from '@root/assets/svg/Cloud_Events/infrastructureModification.svg';
import misconfigurationIcon from '@root/assets/svg/Cloud_Events/misconfiguration.svg';
import overPrivilegedLogo from '@root/assets/svg/Cloud_Events/overPrivileged.svg';
import privilegeEscalationLogo from '@root/assets/svg/Cloud_Events/privilegeEscalation.svg';
import privilegedLogo from '@root/assets/svg/Cloud_Events/privileged.svg';
import reconnaissanceLogo from '@root/assets/svg/Cloud_Events/reconnaissance.svg';
import Malware from '@root/assets/svg/Threats/Malware.svg';

const IMAGE_MAP = {
  ['Data']: dataLogo,
  ['Credential Exposure']: credentialExposureLogo,
  ['Privilege Escalation']: privilegeEscalationLogo,
  ['Reconnaissance']: reconnaissanceLogo,
  ['Privileged']: privilegedLogo,
  ['Inactive']: inactiveLogo,
  ['Threat']: threatLogo,
  ['Administrator']: administratorLogo,
  ['Admin']: administratorLogo,
  ['Infrastructure Modification']: infrastructureModificationLogo,
  ['Over Privileged']: overPrivilegedLogo,
  ['Data Security']: dataLogo,
  ['Encryption']: encryptionLogo,
  ['Exposed Secrets']: exposedSecretsLogo,
  ['Network']: networkLogo,
  ['Anomalous']: anomalousLogo,
  ['Logging And Monitoring']: loggingMonitoringLogo,
  ['Logging & Monitoring']: loggingMonitoringLogo,
  ['Lateral Movement']: lateralMovementLogo,
  ['High Availability']: highAvailabilityLogo,
  ['Identity']: identityLogo,
  ['User']: userLogo,
  ['Vulnerabilities']: vulnerabilitiesLogo,
  ['NoRisk']: noRiskLogo,
  ['Misconfiguration']: misconfigurationIcon,
  ['Malware']: Malware,
};

export const RiskTypeIcons = ({ name, rowIndex, width, tooltip }) => {
  return (
    <>
      {name && IMAGE_MAP[name] ? (
        <span style={{ paddingRight: '5px' }}>
          {tooltip ? (
            <MuitoolTip label={name?.toUpperCase()}>
              <img
                id={`${name?.toLowerCase()}-${rowIndex}`}
                src={IMAGE_MAP[name]}
                style={{ objectFit: 'contain', width: width ?? '20px', height: '20px' }}
                alt={name}
              />
            </MuitoolTip>
          ) : (
            <img
              id={`${name?.toLowerCase()}-${rowIndex}`}
              src={IMAGE_MAP[name]}
              style={{ objectFit: 'contain', width: width ?? '20px', height: '20px' }}
              alt={name}
            />
          )}
        </span>
      ) : (
        name
      )}
    </>
  );
};
