import React, { useMemo, useCallback, useState } from 'react';
import Popper from '@mui/base/Popper';
import { Box, styled } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import { default as MUISelect } from '@mui/base/Select';

import CloseIcon from '@mui/icons-material/Close';
import UnfoldMoreRoundedIcon from '@mui/icons-material/ExpandMore';

import Text from '@root/components/v2/ui-elements/Text';
import './style.scss';

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const Chip = ({ options, value, onRemove }) => {
  console.log(options, value);
  const option = useMemo(() => {
    return options.find(o => o.value === value);
  }, [options, value]);

  const handleRemoveClick = useCallback(
    e => {
      e.stopPropagation();

      if (onRemove) {
        onRemove(value);
      }
    },
    [onRemove, value],
  );

  return (
    <Box className="Select__Chip">
      <Text className="Select__Chip__Text">{option?.label}</Text>
      <button onClick={handleRemoveClick}>
        <CloseIcon fontSize="20px" />
      </button>
    </Box>
  );
};

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, className, ...other } = props;

  const style = useMemo(() => {
    return {
      ...(ownerState.width && { width: ownerState.width, minWidth: ownerState.minWidth }),
      ...(ownerState.height && { height: ownerState.height }),
    };
  }, [ownerState]);

  return (
    <button
      className={`${className} Select__Button ${ownerState.disabled ? 'Disabled' : ''} ${
        ownerState.complianceRuleAction ? 'ComplianceRuleActive' : ''
      }`}
      type="button"
      style={{ ...style, backgroundColor: props.transparentBg ? 'transparent' : null }}
      {...other}
      ref={ref}>
      <Text className="Select__ButtonText fs__0" font="bold">
        {ownerState.renderValue(ownerState.value) ?? ownerState.placeholder}
      </Text>
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 12px 0;
  // min-width: 184px;
  border-radius: 5px;
  overflow: auto;
  outline: 0px;
  list-style: none;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid #CBD5E0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 8px 18px rgba(93, 106, 131, 0.1);

  `,
);

const MyPopper = props => {
  return (
    <Popper
      {...props}
      style={{
        width: props.anchorEl?.clientWidth,
      }}
      className={props.ownerState.regionDropdown ? 'popper-zindex' : 'normal-zindex'}
    />
  );
};

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const slots = useMemo(() => {
    return {
      root: Button,
      listbox: StyledListbox,
      popper: MyPopper,
      ...props.slots,
    };
  }, [props.slots]);

  return <MUISelect {...props} ref={ref} slots={slots} transparentBg={props.transparentBg} />;
});

function Select({
  placeholder,
  options,
  value,
  enableSelectAll,
  allSelectedButtonLabel,
  width,
  height = '34px',
  minWidth,
  onChange,
  mode = 'multiple',
  enableChips = false,
  disabled = false,
  transparentBg,
  complianceRuleAction = false,
  showCheckBox = true,
  regionDropdown = false,
  jira_input,
}) {
  // key is used to force the Select component to be recreated. As a workaround to force it to close in mode === 'single'
  const [key, setKey] = useState(Date.now());
  const isAllSelected = useMemo(() => {
    if (!value || !value.length) return false;

    return options.every(o => value.find(v => v === o.value));
  }, [options, value]);

  const handleChipRemove = useCallback(
    v => {
      onChange(value.filter(_v => _v !== v));
    },
    [onChange, value],
  );

  const renderValue = useCallback(
    value => {
      if (!options.length || !value || (Array.isArray(value) && !value.length)) {
        return <span className={jira_input ? 'jira_input' : ''}>{placeholder || 'Select'}</span>;
      }

      const firstOption = options.find(o => o.value === value[0]);

      if (!firstOption) return null;

      if (Array.isArray(value) && value.length) {
        return (
          <div className="Select__SelectedValueContainer">
            {allSelectedButtonLabel && isAllSelected ? (
              <Text element="span" font="bold" className={jira_input ? 'jira_input' : ''}>
                {allSelectedButtonLabel}
              </Text>
            ) : (
              <>
                {enableChips ? (
                  <>
                    {value.slice(0, 2).map(v => (
                      <Chip value={v} options={options} onRemove={handleChipRemove} />
                    ))}

                    <Text font="semi-bold" className="Select__SelectedValue__Count">
                      {value.length > 2 ? `+${value.length - 2}` : null}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text element="span" font="bold" className={jira_input ? 'jira_input' : ''}>
                      {firstOption.label}
                    </Text>
                    <Text font="semi-bold" className="Select__SelectedValue__Count">
                      {value.length > 1 ? `+${value.length - 1}` : null}
                    </Text>
                  </>
                )}
              </>
            )}
          </div>
        );
      }

      return <span>{firstOption.label}</span>;
    },
    [options, jira_input, placeholder, allSelectedButtonLabel, isAllSelected, enableChips, handleChipRemove],
  );

  const handleOptionClick = useCallback(
    option => {
      if (mode === 'single') {
        onChange([option.value]);
        setKey(Date.now()); // Trick to force the CustomSelect component to "close" by re-creating it. TODO: Find a proper solution since the `open` prop does not work.
      } else {
        if (option === 'all') {
          if (isAllSelected) {
            onChange([]);
          } else {
            onChange(options.map(o => o.value));
          }
        } else {
          if (value.includes(option.value)) {
            onChange(value.filter(v => v !== option.value));
          } else {
            onChange([...value, option.value]);
          }
        }
      }
    },
    [mode, onChange, isAllSelected, options, value],
  );

  return (
    <CustomSelect
      key={key}
      value={value}
      placeholder={placeholder}
      renderValue={renderValue}
      regionDropdown={regionDropdown}
      multiple
      width={width}
      height={height}
      minWidth={minWidth}
      transparentBg={transparentBg}
      complianceRuleAction={complianceRuleAction}
      disabled={disabled}>
      {!options.length ? (
        <Box padding="24px 16px" textAlign="center">
          <Text font="italic">No options</Text>
        </Box>
      ) : null}
      <div style={regionDropdown ? { maxHeight: '280px' } : {}}>
        {enableSelectAll && options.length ? (
          <div value={'all'} disabled className="Select__Option" onClick={() => handleOptionClick('all')}>
            <Text element="div" className="Select__OptionLabel" font="semi-bold">
              All
            </Text>
            {mode === 'multiple' ? <Checkbox className="Select__OptionCheckbox" checked={isAllSelected} /> : null}
          </div>
        ) : null}
        <div style={{ maxHeight: '300px', zIndex: '100' }}>
          {options.map(option => (
            <div
              key={option.value}
              value={option.value}
              className={`Select__Option ${value.includes(option.value) ? 'Selected' : ''}`}
              onClick={() => handleOptionClick(option)}>
              <Text element="div" className="Select__OptionLabel" font="semi-bold">
                {option.label}
              </Text>
              {showCheckBox ? (
                <Checkbox
                  checked={value.includes(option.value)}
                  className="Select__OptionCheckbox"
                  style={{ visibility: mode === 'multiple' ? 'visible' : 'hidden' }}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </CustomSelect>
  );
}

export default Select;
