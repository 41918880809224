import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

export const ExportPipe = data => {
  let exportableName = 'Remediation Policy';

  let HeadingForCloudEventsTable = [Object.keys(data[0])];

  const ws_scan_report = XLSX.utils.book_new();
  XLSX.utils.sheet_add_json(ws_scan_report, data, {
    origin: 'A2',
    skipHeader: true,
  });

  XLSX.utils.sheet_add_aoa(ws_scan_report, HeadingForCloudEventsTable);

  XLSX.utils.book_append_sheet(ws_scan_report, ws_scan_report, 'Remediation Policy');
  toast.dismiss();
  toast.success('Your Report is ready!');
  XLSX.writeFile(ws_scan_report, exportableName + '.xlsx');
};
