import React, { useMemo } from 'react';
import MissingIcon from '@mui/icons-material/PriorityHighOutlined';
import './style.scss';

/**
 *
 * @param {*} param0
 * @param {*} param0.width px|em
 * @param {*} param0.height px|em
 * @returns
 */
function IconButton({
  width = '36px',
  height = '36px',
  className,
  style,
  marginRight,
  marginLeft,
  circular,
  borderless,
  onClick,
  children,
  disabled,
}) {
  const _style = useMemo(() => {
    return {
      width,
      height,
      ...(style || {}),
      ...(circular && { borderRadius: 999 }),
      ...(borderless && { border: 0 }),
      ...(marginRight && { marginRight }),
      ...(marginLeft && { marginLeft }),
    };
  }, [borderless, circular, height, marginLeft, marginRight, style, width]);

  return (
    <button
      className={`IconButton ${className || ''} ${disabled ? 'IconButton__Disabled' : ''}`}
      style={_style}
      disabled={disabled}
      onClick={onClick}>
      {children || <MissingIcon />}
    </button>
  );
}

export default IconButton;
