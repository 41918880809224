type AwsRegion = { regionCode: string; countryCode: string; lat: number; lng: number };

const regions: AwsRegion[] = [
  { regionCode: 'us-east-1', countryCode: 'USA', lat: 38.9940541, lng: -77.4524237 },
  { regionCode: 'us-east-2', countryCode: 'USA', lat: 40.0946354, lng: -82.7541337 },
  { regionCode: 'us-west-1', countryCode: 'USA', lat: 37.44368, lng: -122.153664 },
  { regionCode: 'us-west-2', countryCode: 'USA', lat: 45.9174667, lng: -119.2684488 },

  {
    regionCode: 'ca-central-1',
    countryCode: 'CANADA',
    lat: 45.5,
    lng: -73.6,
  },

  { regionCode: 'sa-east-1', countryCode: 'BRAZIL', lat: -23.4925798, lng: -46.8105593 },

  { regionCode: 'eu-central-1', countryCode: 'GERMANY', lat: 50.0992094, lng: 8.6303932 },
  { regionCode: 'eu-central-2', countryCode: 'SWITZERLAND', lat: 47.3769, lng: 8.5417 },
  {
    regionCode: 'eu-west-1',
    countryCode: 'IRELAND',
    lat: 53.4056545,
    lng: -6.224503,
  },
  {
    regionCode: 'eu-west-2',
    countryCode: 'ENGLAND',
    lat: 51.5085036,
    lng: -0.0609266,
  },
  {
    regionCode: 'eu-west-3',
    countryCode: 'FRANCE',
    lat: 48.6009709,
    lng: 2.2976644,
  },
  {
    regionCode: 'eu-north-1',
    countryCode: 'SWEDEN',
    lat: 59.326242,
    lng: 17.8419717,
  },
  {
    regionCode: 'eu-south-1',
    countryCode: 'ITALY',
    lat: 45.4628328,
    lng: 9.1076927,
  },
  {
    regionCode: 'eu-south-2',
    countryCode: 'SPAIN',
    lat: 40.4637,
    lng: 3.7492,
  },

  {
    regionCode: 'af-south-1',
    countryCode: 'SOUTH_AFRICA',
    lat: -33.914651,
    lng: 18.3758801,
  },

  {
    regionCode: 'ap-east-1',
    countryCode: 'CHINA',
    lat: 22.2908475,
    lng: 114.2723379,
  },
  {
    regionCode: 'ap-south-1',
    countryCode: 'INDIA',
    lat: 19.2425503,
    lng: 72.9667878,
  },
  {
    regionCode: 'ap-south-2',
    countryCode: 'INDIA',
    lat: 17.385,
    lng: 78.4867,
  },
  {
    regionCode: 'ap-southeast-1',
    countryCode: 'SINGAPORE',
    lat: 1.3218269,
    lng: 103.6930643,
  },
  {
    regionCode: 'ap-southeast-2',
    countryCode: 'AUSTRALIA',
    lat: -33.9117717,
    lng: 151.1907535,
  },
  {
    regionCode: 'ap-southeast-3',
    countryCode: 'INDONESIA',
    lat: 6.1944,
    lng: 106.8229,
  },
  {
    regionCode: 'ap-southeast-4',
    countryCode: 'AUSTRALIA',
    lat: 37.8136,
    lng: 144.9631,
  },
  {
    regionCode: 'ap-northeast-1',
    countryCode: 'JAPAN',
    lat: 35.617436,
    lng: 139.7459176,
  },
  {
    regionCode: 'ap-northeast-2',
    countryCode: 'SOUTH_KOREA',
    lat: 37.5616592,
    lng: 126.8736237,
  },
  {
    regionCode: 'ap-northeast-3',
    countryCode: 'JAPAN',
    lat: 34.6937,
    lng: 135.5023,
  },

  {
    regionCode: 'il-central-1',
    countryCode: 'ISRAEL',
    lat: 32.0853,
    lng: 34.7818,
  },

  {
    regionCode: 'me-south-1',
    countryCode: 'BAHRAIN',
    lat: 25.941298,
    lng: 50.3073907,
  },
  {
    regionCode: 'me-central-1',
    countryCode: 'UAE',
    lat: 23.4241,
    lng: 53.8478,
  },







  
  { regionCode: 'asia-east1', countryCode: 'TW', lat: 23.9919519, lng: 120.3300829 },
  { regionCode: 'asia-east2', countryCode: 'HK', lat: 22.319304, lng: 114.169361 },
  { regionCode: 'asia-southeast1', countryCode: 'SG', lat: 1.352083, lng: 103.819836 },
  { regionCode: 'asia-southeast2', countryCode: 'ID', lat: -6.208763, lng: 106.845599 },
  { regionCode: 'asia-northeast1', countryCode: 'JP', lat: 35.689488, lng: 139.691706 },
  { regionCode: 'asia-northeast2', countryCode: 'JP', lat: 34.693725, lng: 135.502254 },
  { regionCode: 'asia-northeast3', countryCode: 'KR', lat: 37.566535, lng: 126.977969 },
  { regionCode: 'asia-south1', countryCode: 'IN', lat: 19.0825223, lng: 72.7411014 },
  { regionCode: 'asia-south2', countryCode: 'IN', lat: 28.704059, lng: 77.10249 },
  { regionCode: 'us-central1', countryCode: 'USA', lat: 41.2326957, lng: -95.9167207 },
  { regionCode: 'us-west1', countryCode: 'USA', lat: 45.6094756, lng: -121.2088799 },
  { regionCode: 'us-west2', countryCode: 'USA', lat: 34.052235, lng: -118.243683 },
  { regionCode: 'us-west3', countryCode: 'USA', lat: 40.7608, lng: -111.891 },
  { regionCode: 'us-west4', countryCode: 'USA', lat: 36.1699, lng: -115.1398 },
  { regionCode: 'us-east1', countryCode: 'USA', lat: 33.1637261, lng: -80.1845003 },
  { regionCode: 'us-east4', countryCode: 'USA', lat: 39.0300218, lng: -77.5121424 },
  { regionCode: 'us-east5', countryCode: 'USA', lat: 39.983334, lng: -82.98333 },
  { regionCode: 'us-south1', countryCode: 'USA', lat: 32.779167, lng: -96.808891 },
  { regionCode: 'northamerica-northeast1', countryCode: 'CA', lat: 45.5581968, lng: -73.8703852 },
  { regionCode: 'northamerica-northeast2', countryCode: 'CA', lat: 43.653226, lng: -79.383184 },
  { regionCode: 'southamerica-east1', countryCode: 'BR', lat: -23.286926, lng: -46.627497 },
  { regionCode: 'europe-central2', countryCode: 'PL', lat: 52.229676, lng: 21.012229 },
  { regionCode: 'europe-west1', countryCode: 'BE', lat: 50.489824, lng: 3.7378805 },
  { regionCode: 'europe-west2', countryCode: 'GB', lat: 51.500152, lng: -0.126236 },
  { regionCode: 'europe-west3', countryCode: 'DE', lat: 50.111512, lng: 8.680506 },
  { regionCode: 'europe-west4', countryCode: 'NL', lat: 53.4356948, lng: 6.7980783 },
  { regionCode: 'europe-west6', countryCode: 'CH', lat: 47.376887, lng: 8.541694 },
  { regionCode: 'europe-north1', countryCode: 'FI', lat: 60.5695912, lng: 27.2097431 },
  { regionCode: 'australia-southeast1', countryCode: 'AU', lat: -33.867139, lng: 151.207114 },
  { regionCode: 'australia-southeast2', countryCode: 'AU', lat: -37.813628, lng: 144.963058 }
];

export default regions;
export { type AwsRegion };
